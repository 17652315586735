import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const DiscordSocialIcon: FC = () => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_445_14943)">
      <path
        d="M31.2274 10.1372C29.2527 9.23117 27.1352 8.56362 24.9212 8.18129C24.8809 8.17391 24.8406 8.19235 24.8198 8.22923C24.5475 8.7136 24.2458 9.3455 24.0346 9.84217C21.6532 9.48566 19.2841 9.48566 16.9517 9.84217C16.7404 9.33446 16.4278 8.7136 16.1542 8.22923C16.1334 8.19358 16.0931 8.17514 16.0528 8.18129C13.84 8.5624 11.7225 9.22995 9.74659 10.1372C9.72948 10.1446 9.71482 10.1569 9.70509 10.1729C5.68856 16.1735 4.58826 22.0266 5.12803 27.8072C5.13047 27.8355 5.14635 27.8625 5.16833 27.8797C7.81832 29.8258 10.3853 31.0072 12.9046 31.7903C12.9449 31.8027 12.9876 31.7879 13.0133 31.7547C13.6092 30.9409 14.1405 30.0828 14.5959 29.1804C14.6228 29.1275 14.5972 29.0648 14.5422 29.0439C13.6996 28.7243 12.8973 28.3346 12.1255 27.892C12.0644 27.8564 12.0595 27.769 12.1157 27.7272C12.2781 27.6055 12.4406 27.4789 12.5957 27.351C12.6237 27.3277 12.6628 27.3228 12.6958 27.3375C17.7662 29.6525 23.2554 29.6525 28.266 27.3375C28.299 27.3215 28.3381 27.3265 28.3674 27.3498C28.5225 27.4777 28.6849 27.6055 28.8485 27.7272C28.9047 27.769 28.901 27.8564 28.84 27.892C28.0682 28.3432 27.2658 28.7243 26.422 29.0427C26.3671 29.0636 26.3426 29.1275 26.3695 29.1804C26.8348 30.0815 27.366 30.9396 27.9509 31.7535C27.9754 31.7879 28.0193 31.8027 28.0596 31.7903C30.5912 31.0072 33.1581 29.8258 35.8081 27.8797C35.8313 27.8625 35.846 27.8367 35.8484 27.8084C36.4944 21.1254 34.7664 15.3203 31.2677 10.1741C31.2591 10.1569 31.2445 10.1446 31.2274 10.1372ZM15.3531 24.2874C13.8266 24.2874 12.5688 22.886 12.5688 21.1648C12.5688 19.4437 13.8022 18.0422 15.3531 18.0422C16.9162 18.0422 18.1619 19.456 18.1374 21.1648C18.1374 22.886 16.904 24.2874 15.3531 24.2874ZM25.6478 24.2874C24.1213 24.2874 22.8635 22.886 22.8635 21.1648C22.8635 19.4437 24.0968 18.0422 25.6478 18.0422C27.2109 18.0422 28.4565 19.456 28.4321 21.1648C28.4321 22.886 27.2109 24.2874 25.6478 24.2874Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_445_14943">
        <rect width="30.9818" height="24" fill="white" transform="translate(5 8)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default DiscordSocialIcon;
