import { styled, keyframes } from '@mui/material';
import BaseIcon from '../../components/icons/BaseIcon';

const Pulsate = keyframes`
  0% {
    box-shadow: 0 0 3rem 0.3rem #7900ff;
  }

  50% {
    box-shadow: 0 0 3rem 0.55rem #7900ff;
  }

  100% {
    box-shadow: 0 0 3rem 0.3rem #7900ff;
  }
`;
const PulsateBg = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
`;
const PulsateBgShort = keyframes`
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
    transform: translateY(-25px);
  }
`;
const Root = styled('div')`
  .main-title {
    display: inline-block;
    border-top: 1px solid #fec300;
    border-bottom: 1px solid #fec300;
  }
`;
const VideoWrapper = styled('div')`
  position: relative;
  z-index: 2;
  top: -3px;
  &:before {
    content: '';
    position: absolute;
    top: -3.5rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    height: 113%;
    max-height: 1480px;
    background-color: #7900ff;
    box-shadow: 0 0 3rem 0.5rem #7900ff;
    animation: ${Pulsate} 1.4s infinite normal both ease-in;
    ${({ theme }) => theme.breakpoints.down('xl')} {
      top: -2.5rem;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      height: 120%;
    }
  }
  .video-container-wrapper {
    background-color: #000;
    img {
      border: 0;
    }
    ${({ theme }) => theme.breakpoints.up('sm')} {
      &.mini {
        iframe {
          position: fixed;
          bottom: 70px;
          right: 20px;
          top: inherit;
          left: inherit;
          width: 23vw;
          height: 13vw;
          min-width: 368px;
          min-height: 200px;
        }
      }
    }
  }
  .video-embed-responsive {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;
const VideoInner = styled('div')`
  position: relative;
  margin: 0 4rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 0 1rem;
    top: -1rem;
  }
`;

const CollectionSection = styled('div')`
  position: relative;
  top: -30px;
  background: url('/assets/lhu_base_bkg.jpg') no-repeat bottom center;
  background-color: #fff;
  background-size: 105%;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    top: -21px;
    background-size: 120%;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    background: url('/assets/lhu_base_bkg.jpg') no-repeat bottom center / cover;
    animation: ${PulsateBgShort} 8s infinite forwards ease-in-out;
  }
`;
const CollectionTitleWrapper = styled('div')`
  padding: 5px 20px;
`;

const CollectionTitle = styled('div')`
  padding: 0 20px;
`;

const DiamondIcon = styled(BaseIcon)`
  position: absolute;
  z-index: 0;
  bottom: -13vw;
  left: 0;
  right: 0;
  padding: 0 20px;
  margin: auto;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  svg {
    width: 100%;
    height: 100%;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    bottom: -29vw;
    left: -15%;
    width: 130%;
    display: block;
  }
`;

const MainWrapper = styled('div')`
  background-color: #000;
`;

const StoryWrapper = styled('div')`
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: #7900ff;
    background: radial-gradient(circle at center -22rem, rgba(121, 0, 255, 1) 30%, rgba(2, 0, 36, 1) 50%);
    background-repeat: no-repeat;
    animation: ${PulsateBg} 5s infinite normal both ease-in-out;
    ${({ theme }) => theme.breakpoints.down('md')} {
      background: radial-gradient(circle at center -9rem, rgba(121, 0, 255, 1) 15%, rgba(2, 0, 36, 1) 40%);
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      background: radial-gradient(circle at center -10rem, rgba(121, 0, 255, 1) 20%, rgba(2, 0, 36, 1) 30%);
    }
  }
  .headline {
    color: #fff;
  }

  p {
    color: #fff;
  }
  .brand-icon {
    width: 5rem;
    text-align: center;
    margin: auto;
  }
  .story-section {
    h2.main-title {
      color: #fff;
      margin-top: 10rem;
      font-size: 5.5rem;
      font-weight: 600;
      padding: 0 2rem;
      height: 126px;
      line-height: 115px;
      ${({ theme }) => theme.breakpoints.down('xl')} {
        margin-top: 6rem;
        font-size: 4rem;
        height: 96px;
        line-height: 90px;
      }
      ${({ theme }) => theme.breakpoints.down('sm')} {
        margin-top: 5rem;
        font-size: 2rem;
        height: 70px;
        line-height: 66px;
      }
      &:after,
      &:before {
        content: '';
        display: block;
        position: absolute;
      }
      &:before {
        content: '';
        top: calc(50% - 1px);
        right: -44px;
        width: 90px;
        height: 90px;
        transform-origin: 50% 50%;
        -webkit-transform: translate(0, -0.5em) rotate(45deg);
        -moz-transform: translate(0, -0.5em) rotate(45deg);
        -ms-transform: translate(0, -0.5em) rotate(45deg);
        transform: translate(0, -0.5em) rotate(45deg);
        box-shadow: inset -1px 1px 0 0 #fec300;
        ${({ theme }) => theme.breakpoints.down('xl')} {
          top: calc(50% - 2px);
          right: -34px;
          width: 68px;
          height: 68px;
        }
        ${({ theme }) => theme.breakpoints.down('sm')} {
          top: calc(50% - 9px);
          right: -25px;
          width: 50px;
          height: 50px;
        }
      }
      &:after {
        content: '';
        top: calc(50% - 1px);
        left: -44px;
        width: 90px;
        height: 90px;
        transform-origin: 50% 50%;
        -webkit-transform: translate(0, -0.5em) rotate(45deg);
        -moz-transform: translate(0, -0.5em) rotate(45deg);
        -ms-transform: translate(0, -0.5em) rotate(45deg);
        transform: translate(0, -0.5em) rotate(45deg);
        box-shadow: inset 1px -1px 0 0 #fec300;
        ${({ theme }) => theme.breakpoints.down('xl')} {
          top: calc(50% - 2px);
          left: -34px;
          width: 68px;
          height: 68px;
        }
        ${({ theme }) => theme.breakpoints.down('sm')} {
          top: calc(50% - 9px);
          left: -25px;
          width: 50px;
          height: 50px;
        }
      }
    }
    .content {
      max-width: 400px;
      color: #fff;
    }
  }
  .collection-section {
    h3.headline {
      font-size: 8rem;
      line-height: 1;
      margin: auto;
      ${({ theme }) => theme.breakpoints.down('xl')} {
        font-size: 5rem;
      }
      ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 3.5rem;
      }
    }
    .content {
      color: #fec300;
      font-size: 3rem;
      font-weight: bold;
      letter-spacing: 0.8rem;
      ${({ theme }) => theme.breakpoints.down('xl')} {
        font-size: 2.2rem;
        letter-spacing: 0.6rem;
      }
      ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 1.7rem;
        letter-spacing: 0.4rem;
      }
    }
  }
`;

const RoadMapWrapper = styled('div')`
  position: relative;
  margin-top: 8rem;
  background-color: #eeede9;
  background-image: url('/assets/lhu_roadmap_bkg.jpg');
  background-repeat: no-repeat;
  background-position: right top;
  height: 160vh;
  @media (max-width: 1799px) {
    background-position: 50% top;
    background-size: cover;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    background-position: 40% top;
    height: 130vh;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    background-position: 50% top;
    height: 120vh;
    min-height: 1230px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(238, 237, 233, 0.6);
    }
  }
  h3.headline {
    color: #fec300;
  }
  .roadmap-content {
    ${({ theme }) => theme.breakpoints.down('md')} {
      display: block;
      margin: auto;
    }
    > .MuiBox-root {
      ${({ theme }) => theme.breakpoints.down('md')} {
        display: block;
        margin: auto;
      }
    }

    h3.headline {
      font-size: 8rem;
      line-height: 1;
      margin: 9rem 0 1rem;
      ${({ theme }) => theme.breakpoints.down('xl')} {
        font-size: 5rem;
      }
      ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 3.5rem;
      }
    }
  }
`;

const PromoWrapper = styled('div')`
  position: relative;
  box-sizing: border-box;
  background: url('/assets/lhu_promo_bkg.jpg') no-repeat bottom center / cover;
`;

const NewsletterWrapper = styled('div')`
  position: relative;
  box-sizing: border-box;
  text-align: center;
  margin: auto;
  padding: 3rem 20px;
  background: #d5aa1e;
`;

const VideoEmbed = styled('div')`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  &:before {
    display: block;
    clear: both;
    content: '';
    padding-top: 56.25%;
  }
  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const FaqsWrapper = styled('div')`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  background-color: #262626;
  overflow: hidden;
`;

export default {
  Root,
  DiamondIcon,
  VideoWrapper,
  VideoEmbed,
  VideoInner,
  CollectionSection,
  MainWrapper,
  StoryWrapper,
  RoadMapWrapper,
  PromoWrapper,
  CollectionTitle,
  CollectionTitleWrapper,
  NewsletterWrapper,
  FaqsWrapper,
};
