import { styled } from '@mui/material';

const Section = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
`;

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 32px;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: center;
  }
`;

export default { Section, Row };
