import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { IconButton, Fade, Typography } from '@mui/material';
import { AccountCircle, ArrowRight } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import Styled from './AccountNavigation.styles';
import SessionContext from '../../modules/session/Session.context';
import MenuItem from '../navigation/MenuItem';
import Divider from './Divider';
import routes from '../../config/routes';
import Avatar from './Avatar';
import Box from './Box';
import Button from './Button';
import useAnalytics from '../../modules/analytics/useAnalytics';

const AccountNavigation: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { onPageAction } = useAnalytics();
  const { pathname } = useLocation();

  const {
    showProfileMenu,
    onUpdateProfileMenuVisibility,
    showNavigationMenu,
    account,
    onUpdateNavigationMenuVisibility,
    onUpdateAccount,
  } = useContext(SessionContext);
  const navigate = useNavigate();

  const handleOnShowProfileMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      onUpdateProfileMenuVisibility(true);
      onUpdateNavigationMenuVisibility(false);
    },
    [onUpdateNavigationMenuVisibility, onUpdateProfileMenuVisibility],
  );
  const handleOnHideProfileMenu = useCallback(
    () => onUpdateProfileMenuVisibility(false),
    [onUpdateProfileMenuVisibility],
  );
  const handleOnGoToMyProfile = useCallback(() => {
    onUpdateProfileMenuVisibility(false);
    navigate(routes.account.path);
  }, [navigate, onUpdateProfileMenuVisibility]);
  const handleOnSignIn = useCallback(() => {
    onPageAction({
      title: document.title,
      path: pathname,
      href: location.href,
      actionName: 'sign_in',
      actionCategory: 'account',
    });
    // TODO: remove this method when authentication be connected, this is just for test purpose
    onUpdateAccount({ id: 'user-test-env', name: '@jameshue', email: 'user@forte.io' });
  }, [onPageAction, pathname, onUpdateAccount]);

  const handleOnSignOut = useCallback(() => {
    onPageAction({
      title: document.title,
      path: pathname,
      href: location.href,
      actionName: 'sign_out',
      actionCategory: 'account',
    });
    // TODO: remove this method when authentication be connected, this is just for test purpose
    handleOnHideProfileMenu();
    onUpdateAccount(null);
  }, [onPageAction, pathname, onUpdateAccount, handleOnHideProfileMenu]);

  const signedIn = useMemo(() => !!account?.id, [account]);
  const disableSignIn = true; // Added to Disable Menu

  return (
    <>
      {!signedIn && !showNavigationMenu && !disableSignIn && (
        <Button type="secondary" size="sm" onClick={handleOnSignIn}>
          Sign In
        </Button>
      )}

      {signedIn && (
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOnShowProfileMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
      )}

      <Styled.Root
        open={Boolean(anchorEl) && showProfileMenu}
        marginThreshold={0}
        onClose={handleOnHideProfileMenu}
        TransitionComponent={Fade}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box display="flex" flexDirection="column" padding="20px 0" gap="16px">
          <Box display="flex" flexDirection="column" alignItems="flex-start" gap="16px">
            <Box display="flex" flexDirection="row" alignItems="center" padding="0 20px" gap="16px">
              <Avatar width="64px" height="64px" />
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Typography variant="title.lg-strong">James Hue</Typography>
                <Typography variant="body.md">@JamesHue</Typography>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <MenuItem variant="extra-small" onClick={handleOnGoToMyProfile}>
                My Example Profile
              </MenuItem>
              <MenuItem variant="extra-small" icon={<ArrowRight />}>
                FortePay Account
              </MenuItem>
            </Box>
          </Box>

          <Divider margin="0 20px" />
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <MenuItem variant="extra-small">FAQs</MenuItem>
            <MenuItem variant="extra-small" onClick={handleOnSignOut}>
              Sign Out
            </MenuItem>
          </Box>
        </Box>
      </Styled.Root>
    </>
  );
};

export default AccountNavigation;
