import { FC, ReactNode, useCallback, useContext, useMemo } from 'react';
// import { PooleClient } from '@forte/poole-browser';
import AnalyticsContext, {
  IAnalyticsProviderContext,
  IEventBaseConfig,
  IPageActionEventKeys,
  IPageViewEventKeys,
  IPurchaseActionEventKeys,
} from './Analytics.context';
import { analyticsEventConfig } from './config';
import logger from '../logger';
import sessionProviderContext from '../session/Session.context';

interface IAnalyticsProviderProps {
  children: ReactNode;
}

// const pooleClient = new PooleClient(analyticsAPIKey, { dev: false });
const log = logger.getLogger('dropkit');

// @ts-ignore
const AnalyticsProvider: FC<IAnalyticsProviderProps> = ({ children }) => {
  const { account } = useContext(sessionProviderContext);

  const getBaseConfig = useCallback((): IEventBaseConfig => {
    const cts = new Date().valueOf();
    const ua = window.navigator.userAgent;
    const ruid = new Date().valueOf().toString();
    const lang = 'en';
    const av = '0.1.0';

    return {
      // @ts-ignore
      ...analyticsEventConfig,
      cts,
      ua,
      ruid,
      lang,
      av,
    };
  }, []);

  const onSendEvent = useCallback(
    // @ts-ignore
    (event: IPageViewEventKeys | IPageActionEventKeys | IPurchaseActionEventKeys) => {
      /*
      pooleClient
        .sendEvent({
          ...getBaseConfig(),
          ...event,
        })
        .then(response => {
          log.info(response);
        })
        .catch(error => {
          log.error(error);
        });
       */
    },
    // eslint-disable-next-line
    [getBaseConfig],
  );

  const handleOnRegisterPageViewEvent: IAnalyticsProviderContext['pageView'] = useCallback(
    // @ts-ignore
    event => {
      const { referrer, saleState, title, href, path, sort, type, assetId, searchKeyword } = event;
      const guid = '';
      const wid = '';
      const sessionState = account?.id ? 'logged in' : 'visitor';

      log.info(`register event: DropKitViews:PageView`);

      onSendEvent({
        ec: 'DropKitViews',
        en: 'PageView',
        pg_title: title,
        pg_href: href,
        pg_type: type,
        referrer,
        sort,
        asset_id: assetId,
        guid,
        pg_path: path,
        sale_state: saleState,
        wid,
        search_keyword: searchKeyword,
        session_state: sessionState,
      } as IPageViewEventKeys);
    },
    [account?.id, onSendEvent],
  );

  const handleOnRegisterPageActionEvent: IAnalyticsProviderContext['pageAction'] = useCallback(
    // @ts-ignore
    event => {
      const { targetPage, path, href, title, actionName, actionCategory } = event;
      const guid = '';
      const wid = '';
      const sessionState = account?.id ? 'logged in' : 'visitor';

      log.info(`register event: DropKitActions:PageAction`);

      onSendEvent({
        ec: 'DropKitActions',
        en: 'PageAction',
        pg_title: title,
        pg_href: href,
        guid,
        pg_path: path,
        wid,
        session_state: sessionState,
        action_category: actionCategory,
        action_name: actionName,
        target_page: targetPage,
      } as IPageActionEventKeys);
    },
    [account?.id, onSendEvent],
  );

  const handleOnRegisterPurchaseActionEvent: IAnalyticsProviderContext['purchaseAction'] = useCallback(
    // @ts-ignore
    event => {
      const {
        actionCategory,
        actionName,
        href,
        path,
        title,
        targetPage,
        assetId,
        txnQty,
        txnCur,
        assetQty,
        assetName,
      } = event;
      const guid = '';
      const wid = '';
      const sessionState = account?.id ? 'logged in' : 'visitor';

      log.info(`register event: DropKitActions:PurchaseAction`);

      onSendEvent({
        ec: 'DropKitActions',
        en: 'PurchaseAction',
        pg_title: title,
        pg_href: href,
        guid,
        pg_path: path,
        wid,
        session_state: sessionState,
        action_category: actionCategory,
        action_name: actionName,
        target_page: targetPage,
        asset_id: assetId,
        asset_name: assetName,
        asset_qty: assetQty,
        txn_cur: txnCur,
        txn_qty: txnQty,
      } as IPurchaseActionEventKeys);
    },
    [account?.id, onSendEvent],
  );

  const value: IAnalyticsProviderContext = useMemo(
    () => ({
      pageView: handleOnRegisterPageViewEvent,
      pageAction: handleOnRegisterPageActionEvent,
      purchaseAction: handleOnRegisterPurchaseActionEvent,
    }),
    [handleOnRegisterPageActionEvent, handleOnRegisterPageViewEvent, handleOnRegisterPurchaseActionEvent],
  );

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsProvider;
