import { FC, SyntheticEvent, useCallback } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../../components/icons/iconic/search.svg';
import BaseIcon from '../../../components/icons/BaseIcon';

interface ISearchBarOption {
  id: string;
  label: string;
}

interface ISearchBarProps {
  options: ISearchBarOption[];
  loading: boolean;
  onChange: (option: ISearchBarOption['id']) => void;
  value?: ISearchBarOption;
}

const SearchBar: FC<ISearchBarProps> = ({ options, loading, onChange, value }) => {
  const handleOnChange = useCallback(
    (_: SyntheticEvent<Element, Event>, option: ISearchBarOption | null) => {
      onChange(option?.id || '');
    },
    [onChange],
  );

  return (
    <Autocomplete
      value={value}
      onChange={handleOnChange}
      loading={loading}
      disablePortal
      clearIcon={null}
      popupIcon={null}
      noOptionsText="No items match your search"
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: <BaseIcon icon={<SearchIcon />} width="15px" margin="0 20px 0 0" />,
          }}
          placeholder="Search by name or attribute"
        />
      )}
      options={options}
    />
  );
};

export default SearchBar;
