import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const CloseIcon: FC = () => (
  <SvgIcon width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.625 1.375L1.375 6.625M1.375 1.375L6.625 6.625"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default CloseIcon;
