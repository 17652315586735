import { CSSProperties } from 'react';
import { styled } from '@mui/material';

export interface IPictureStyledProps {
  borderRadius?: CSSProperties['borderRadius'];
  height?: CSSProperties['height'];
  border?: CSSProperties['border'];
  loaded?: boolean;
  margin?: CSSProperties['margin'];
  objectFit?: CSSProperties['objectFit'];
  padding?: CSSProperties['padding'];
  maxWidth?: CSSProperties['maxWidth'];
  width?: CSSProperties['width'];
}

const Pic = styled('img')<IPictureStyledProps>`
    border-radius: ${({ borderRadius = '0px' }) => borderRadius};
    height: ${({ height }) => height};
    margin: ${({ margin }) => margin};
    object-fit: ${({ objectFit }) => objectFit};
    opacity: ${({ loaded }) => (loaded ? 1 : 0)};
    padding: ${({ padding }) => padding};
    transition: opacity 0.3s ease-in;
    width: ${({ width = '100%' }) => width};
    max-width: ${({ maxWidth = '100%' }) => maxWidth};
    border: ${({ border }) => border};
  }
`;

export default { Pic };
