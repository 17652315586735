import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import routes from '../../../config/routes';
import Button from '../../../components/basics/Button';
import CardHorizontal from '../../../components/media/CardHorizontal';

const ListedPage: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  // TODO: remove to get data from services and handle empty states
  const hasData = Math.random() < 0.5;

  return (
    <Container>
      {!hasData && (
        <Box marginTop={`${theme.custom.spacing.Spacing3Xl}px`}>
          <Typography variant="text-default">You have no listed items</Typography>
        </Box>
      )}

      {hasData && (
        <Box marginTop={`${theme.custom.spacing.Spacing3Xl}px`}>
          <Grid container spacing={4}>
            {new Array(3).fill('').map((_, index) => (
              <Grid item sm={4} xs={12} key={`item-${index}`}>
                <CardHorizontal
                  onClick={() => {
                    navigate(routes.marketplaceNftDetail.path, {
                      state: { listed: true, owned: true, origin: 'Account' },
                    });
                  }}
                  key={`CardHighlightSmall-listed-item-${index}`}
                />
              </Grid>
            ))}
          </Grid>
          <Box
            margin="65px 0 0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="15px"
          >
            <Typography variant="text-weak">List one of your collected items</Typography>
            <Button type="secondary" size="lg">
              View Collected
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default ListedPage;
