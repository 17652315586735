import { FC } from 'react';
import { Link, useTheme, useMediaQuery } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CheckCircleIcon } from '../icons/iconic/check-circle.svg';
import * as Styled from './Requirements.styles';
import SubHeaderBody from './SubHeaderBody';
import Rarity from './Rarity';
import FormFeedback from './FormFeedback';
import { nameSpace } from '../../modules/i18n';
import Button from '../basics/Button';
import Box from '../basics/Box';

interface IRequirementsProps {
  showSuccessAlert?: boolean;
  showWarningAlert?: boolean;
  showCheckButton?: boolean;
  showRefreshButton?: boolean;
  onClickCheckButton?: VoidFunction;
  onClickRefreshButton?: VoidFunction;
}

const Requirements: FC<IRequirementsProps> = ({
  showSuccessAlert = false,
  showWarningAlert = false,
  showCheckButton = false,
  showRefreshButton = false,
  onClickCheckButton,
  onClickRefreshButton,
}) => {
  const { t } = useTranslation(nameSpace.feedback);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      display="grid"
      gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
      padding="0 0 32px"
      margin={isMobile ? '30px 0' : '120px 0'}
    >
      <SubHeaderBody header={t('requirements.title')} body={t('requirements.description')} />

      <Box display="flex" flexDirection="column" padding={isMobile ? '0' : '0 20px'} gap="10px">
        <Styled.List>
          <Rarity icon={<CheckCircleIcon />} title={t('requirements.age')} />
          <Rarity
            icon={<CheckCircleIcon />}
            title={
              <Trans
                ns={nameSpace.feedback}
                i18nKey="requirements.verified-game-account"
                components={{
                  highlight: <Link href="/create-account" />,
                }}
              />
            }
          />
          <Rarity
            icon={<CheckCircleIcon />}
            title={
              <Trans
                ns={nameSpace.feedback}
                i18nKey="requirements.verified-discord"
                components={{
                  highlight: <Link href="/create-discord" />,
                }}
              />
            }
          />
        </Styled.List>

        {showSuccessAlert && <FormFeedback variant="success" message={t('requirements.alert.success')} />}
        {showWarningAlert && <FormFeedback variant="warning" message={t('requirements.alert.warning')} />}
        {showCheckButton && (
          <Button onClick={onClickCheckButton} type="primary" size="lg">
            {t('requirements.actions.check-requirements')}
          </Button>
        )}
        {showRefreshButton && (
          <Button onClick={onClickRefreshButton} type="primary" size="lg">
            {t('requirements.actions.refresh-requirements')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Requirements;
