import { FC } from 'react';
import AccountPage from './AccountPage';
import routes from '../../config/routes';
import useSession from '../../modules/session/useSession';

const MyAccount: FC = () => {
  const { account } = useSession();
  return (
    <AccountPage
      basePath={routes.account.path}
      isLoggedUserProfile
      nestedRoutes={routes.account.nestedRoutes}
      userData={{ username: account?.name || '', exam: '1,237.1928', estimated: '98.00' }}
    />
  );
};

export default MyAccount;
