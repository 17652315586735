import { FC } from 'react';
import BaseIcon from '../icons/BaseIcon';
import PlayIcon from '../icons/PlayIcon';
import * as Styled from './Hero.styles';

const Hero: FC = () => {
  return (
    <Styled.Root>
      <Styled.CustomContainer>
        <Styled.Media>
          <BaseIcon icon={<PlayIcon />} height="100px" width="100px" />
        </Styled.Media>
      </Styled.CustomContainer>
    </Styled.Root>
  );
};

export default Hero;
