import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import Media from './Media';
import * as Styled from './CardHorizontal.styles';

interface ICardHorizontalProps {
  categoryName?: string;
  title?: string;
  src?: string;
  lowLabel?: string;
  avgLabel?: string;
  highLabel?: string;
  lowValue?: string;
  avgValue?: string;
  highValue?: string;
  onClick?: VoidFunction;
}

// TODO: remove default values from component [ALEXIS]
const CardHorizontal: FC<ICardHorizontalProps> = props => {
  const theme = useTheme();
  const {
    categoryName = 'Collection Name',
    title = 'NFT Example Title',
    src = '',
    lowLabel = 'Lowest',
    avgLabel = 'Average',
    highLabel = 'Highest',
    lowValue = '$99.00 USD',
    avgValue = '$105.00 USD',
    highValue = '$349.00 USD',
    onClick,
  } = props;
  return (
    <Styled.Root onClick={onClick}>
      <Styled.MediaContainer>
        <Media source={src} border="none" width="100%" size="default" aspectRatio="1:1" borderRadius="0" />
      </Styled.MediaContainer>
      <Styled.DetailsContainer>
        <Typography variant="text-weak" marginBottom={`${theme.custom.spacing.SpacingSm}px`}>
          {categoryName}
        </Typography>
        <Typography variant="text-default" fontWeight="bold" marginBottom={`${theme.custom.spacing.Spacing6Xl}px`}>
          {title}
        </Typography>
        <Styled.DetailsList>
          <Styled.DetailsListItem>
            <Typography variant="text-weak">{lowLabel}</Typography>
            <Typography variant="text-default" fontSize="14px" fontWeight="bold">
              {lowValue}
            </Typography>
          </Styled.DetailsListItem>
          <Styled.DetailsListItem>
            <Typography variant="text-weak">{avgLabel}</Typography>
            <Typography variant="text-weak">{avgValue}</Typography>
          </Styled.DetailsListItem>
          <Styled.DetailsListItem>
            <Typography variant="text-weak">{highLabel}</Typography>
            <Typography variant="text-weak">{highValue}</Typography>
          </Styled.DetailsListItem>
        </Styled.DetailsList>
      </Styled.DetailsContainer>
    </Styled.Root>
  );
};

export default CardHorizontal;
