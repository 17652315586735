import { FC } from 'react';
import {
  Drawer,
  Fab,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import useConfiguration from '../useConfiguration';
import { ReactComponent as Settings2Icon } from '../../../components/icons/iconic/settings-2.svg';
import Box from '../../../components/basics/Box';
import useEvents from '../../events/useEvents';
import { IEventsContext } from '../../events/Events.context';

const Configuration: FC = () => {
  const { showConfiguration, onShowConfiguration, onHideConfiguration } = useConfiguration();
  const theme = useTheme();
  const events = useEvents();

  return (
    <>
      {showConfiguration && (
        <Fab
          size="small"
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
          onClick={onShowConfiguration}
        >
          <Settings2Icon />
        </Fab>
      )}
      <Drawer open={showConfiguration} anchor="right" onClose={onHideConfiguration}>
        <Box display="flex" flexDirection="column" padding={`${theme.custom.spacing.SpacingXl}px`} minWidth="320px">
          <Typography variant="headline.mobile-sm" marginBottom={`${theme.custom.spacing.Spacing2Xl}px`}>
            Configuration
          </Typography>
          <Typography variant="title.lg" marginBottom={`${theme.custom.spacing.SpacingXl}px`}>
            Events
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="events-status-label">Status</InputLabel>
            <Select
              labelId="events-status-label"
              id="events-status-select"
              value={events.status}
              label="Age"
              onChange={e => {
                const value = e.target.value as IEventsContext['status'];
                events.onChangeStatus(value);
              }}
            >
              <MenuItem value="pre">Pre</MenuItem>
              <MenuItem value="live">Live</MenuItem>
              <MenuItem value="ended">Ended</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={events.joinEvent}
                  onChange={() => {
                    events.onChangeJoinEvent(!events.joinEvent);
                  }}
                />
              }
              label="Joint in Event"
            />
          </FormControl>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={events.purchaseLimitReached}
                  onChange={() => {
                    events.onChangePurchaseLimitReached(!events.purchaseLimitReached);
                  }}
                />
              }
              label="Purchase limit reached"
            />
          </FormControl>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={events.eventSoldOut}
                  onChange={() => {
                    events.onChangeEventSoldOut(!events.eventSoldOut);
                  }}
                />
              }
              label="Event sold out"
            />
          </FormControl>
        </Box>
      </Drawer>
    </>
  );
};

export default Configuration;
