import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import BaseIcon from '../icons/BaseIcon';
import ForteWhiteHeaderIcon from '../icons/ForteWhiteHeaderIcon';
import { ReactComponent as LockIcon } from '../icons/iconic/lock-fill.svg';
import N3tworkWhiteHeaderIcon from '../icons/N3tworkWhiteHeaderIcon';
import { nameSpace } from '../../modules/i18n';
import Box from '../basics/Box';

const BaseFooter: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation(nameSpace.common);

  return (
    <Box padding="25px 0 0">
      <Container>
        <Box display="flex" justifyContent="center" margin="0 0 25px" alignItems="center">
          <BaseIcon icon={<LockIcon />} margin={`0 ${theme.custom.spacing.SpacingMd}px 0 0`} />
          <Typography variant="text-weak" marginRight={`${theme.custom.spacing.SpacingSm}px`}>
            {t('common.footer.powered.by')}
          </Typography>
          <BaseIcon
            hoverColor="#FEC300"
            cursor="pointer"
            icon={<ForteWhiteHeaderIcon />}
            padding="10px 15px 0 8px"
            onClick={() => window.open(t('common.footer.forte.link'), '_blank')}
          />
          <BaseIcon
            hoverColor="#FEC300"
            cursor="pointer"
            icon={<N3tworkWhiteHeaderIcon />}
            padding="5px 15px 0 0"
            onClick={() => window.open(t('common.footer.n3twork.link'), '_blank')}
          />
        </Box>

        <Grid container spacing={2} padding="0 0 25px">
          <Grid item xs={12} justifyContent="center" display="flex">
            <Box justifyContent="center" display="flex" maxWidth="433px">
              <Typography
                variant="code.sm"
                textAlign={isMobile ? 'justify' : 'center'}
                color={theme.custom.colors.TextWeak}
              >
                &copy; {new Date().getFullYear()} {t('common.footer.rights')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} justifyContent="center" display="flex">
            <Box className="legal-links" justifyContent="center" display="flex" maxWidth="800px">
              <Typography variant="code.sm" onClick={() => window.open(t('common.footer.bottom.link.1'), '_blank')}>
                {t('common.footer.bottom.menu.1')}
              </Typography>
              <Typography variant="code.sm" onClick={() => window.open(t('common.footer.bottom.link.2'), '_blank')}>
                {t('common.footer.bottom.menu.2')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BaseFooter;
