import { styled } from '@mui/material';

const Root = styled('div')`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 66px 1fr;
  align-content: center;
  justify-content: center;
  margin: 0 0 20px;
`;

const DescriptionWrapper = styled('div')`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-content: center;
`;

export default { Root, DescriptionWrapper };
