import { FC, useCallback, useMemo, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import * as Styled from './CardHighlight.styles';
import Media from './Media';
import Button from '../basics/Button';
import Box from '../basics/Box';

interface ICardHighlight {
  title: string;
  percentage: string;
  rarity: string;
  mediaSource?: string;
  actionTitle: string;
  onClickActionButton: () => void;
}

const CardHighlight: FC<ICardHighlight> = ({
  title,
  percentage,
  rarity,
  actionTitle,
  onClickActionButton,
  mediaSource,
}) => {
  const theme = useTheme();
  const [hover, setHover] = useState<boolean>(false);

  const handleOnHover = useCallback(() => setHover(!hover), [hover]);

  const content = useMemo(
    () => (
      <Box zIndex={10} display="flex" flexDirection="column" gap="7px">
        <Typography variant="headline.mobile-sm" color={theme.custom.colors.TextOnAccentWeak}>
          {title}
        </Typography>
        <Typography variant="headline.mobile-sm" color={theme.custom.colors.TextDefault}>
          {percentage}
        </Typography>
        <Typography variant="label.md" color={theme.custom.colors.TextDefault}>
          {rarity}
        </Typography>
      </Box>
    ),
    [theme.custom.colors.TextOnAccentWeak, theme.custom.colors.TextDefault, title, percentage, rarity],
  );

  return (
    <Styled.Root onMouseEnter={handleOnHover} onMouseLeave={handleOnHover}>
      <Styled.Media>
        <Media source={mediaSource} size="default" width="316px" aspectRatio="1:1" height="316px" borderRadius="0" />
      </Styled.Media>
      <Styled.DetailsWrapper>
        <AnimatePresence>
          <Styled.Details animate={{ y: hover ? -65 : 0 }} transition={{ duration: 0.15, ease: 'easeOut' }}>
            <Styled.Gradient animate={{ opacity: +hover }} transition={{ duration: 0.15, ease: 'easeOut' }} />
            {content}
            <Box margin="32px 0 0">
              <Button type="primary" size="lg" onClick={onClickActionButton} fullWidth>
                {actionTitle}
              </Button>
            </Box>
          </Styled.Details>
        </AnimatePresence>
      </Styled.DetailsWrapper>
    </Styled.Root>
  );
};

export default CardHighlight;
