import { CSSProperties, FC } from 'react';
import Styled from './RevealMediaPlaceholder.styles';
import QuestionMarkIcon from '../icons/QuestionMarkIcon';

export interface IRevealMediaPlaceholderProps {
  width: CSSProperties['width'];
  height: CSSProperties['height'];
  iconWidth: CSSProperties['width'];
  iconHeight: CSSProperties['height'];
  minWidth?: CSSProperties['minWidth'];
  minHeight?: CSSProperties['minHeight'];
  maxWidth?: CSSProperties['maxWidth'];
  maxHeight?: CSSProperties['maxHeight'];
  onClick?: VoidFunction;
  type?: 'reveal' | 'unreleased';
  selected?: boolean;
}

const RevealMediaPlaceholder: FC<IRevealMediaPlaceholderProps> = ({
  width,
  height,
  maxHeight,
  maxWidth,
  minWidth,
  minHeight,
  onClick = () => undefined,
  iconHeight,
  iconWidth,
  type,
  selected = false,
}) => {
  return (
    <Styled.Root
      maxheight={maxHeight}
      minheight={minHeight}
      maxwidth={maxWidth}
      minwidth={minWidth}
      height={height}
      width={width}
      selected={+selected}
    >
      <Styled.Icon icon={<QuestionMarkIcon />} width={iconWidth} height={iconHeight} />
      {type && (
        <Styled.OptionWrapper>
          {type === 'reveal' && <Styled.RevealOption onClick={onClick}>Reveal</Styled.RevealOption>}
          {type === 'unreleased' && <Styled.UnreleasedOption onClick={onClick}>Unreleased</Styled.UnreleasedOption>}
        </Styled.OptionWrapper>
      )}
    </Styled.Root>
  );
};

export default RevealMediaPlaceholder;
