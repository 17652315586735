import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const EmailIcon: FC = () => (
  <SvgIcon viewBox="0 0 330.001 330.001">
    <g>
      <path d="M173.871,177.097c-2.641,1.936-5.756,2.903-8.87,2.903c-3.116,0-6.23-0.967-8.871-2.903L30,84.602L0.001,62.603L0,275.001c0.001,8.284,6.716,15,15,15L315.001,290c8.285,0,15-6.716,15-14.999V62.602l-30.001,22L173.871,177.097z" />
      <polygon points="165.001,146.4 310.087,40.001 19.911,40" />
    </g>
  </SvgIcon>
);

export default EmailIcon;
