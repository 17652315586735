import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';
import BaseIcon from '../icons/BaseIcon';
import Box from '../basics/Box';

interface IListItemProps {
  icon: ReactNode;
  label: string;
  description?: string;
  defaultPadding?: boolean;
}

const ListItem: FC<IListItemProps> = ({ label, icon, description, defaultPadding }) => {
  return (
    <Box
      display="grid"
      margin="0 16px 24px"
      gridTemplateColumns="30px 1fr"
      gridTemplateRows="30px"
      gap="12px"
      alignItems="center"
      padding={defaultPadding ? '0 20px' : '0'}
    >
      <BaseIcon icon={icon} height="20px" width="20px" />
      <Typography variant="h5">{label}</Typography>

      {description && (
        <>
          <div />
          <Typography variant="h6">{description}</Typography>
        </>
      )}
    </Box>
  );
};

export default ListItem;
