import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import CurrencyContext, { ICurrencyContext } from './Currency.context';
import CurrencyPicker from './components/CurrencyPicker';

interface ICurrencyProviderProps {
  children: ReactNode;
}

const CurrencyProvider: FC<ICurrencyProviderProps> = ({ children }) => {
  const [currencies] = useState<ICurrencyContext['currencies']>([
    { code: 'USD', label: 'Dollars' },
    { code: 'ETH', label: 'Ethereum' },
    { code: 'EXAM', label: 'Example Game Coin' },
  ]);
  const [selectedCurrency, setSelectedCurrency] = useState<ICurrencyContext['selectedCurrency']>('USD');
  const [showCurrencyPicker, setShowCurrencyPicker] = useState(false);

  const handleOnHideSelector = useCallback(() => {
    setShowCurrencyPicker(false);
  }, []);

  const handleOnShowSelector = useCallback(() => {
    setShowCurrencyPicker(true);
  }, []);

  const value = useMemo(
    () => ({
      currencies,
      selectedCurrency,
      onChangeCurrency: setSelectedCurrency,
      onHideCurrencyPicker: handleOnHideSelector,
      onShowCurrencyPicker: handleOnShowSelector,
    }),
    [selectedCurrency, currencies, setSelectedCurrency, handleOnHideSelector, handleOnShowSelector],
  );

  return (
    <CurrencyContext.Provider value={value}>
      {children}
      <CurrencyPicker
        show={showCurrencyPicker}
        currencies={currencies}
        onHide={handleOnHideSelector}
        onChangeCurrency={setSelectedCurrency}
        selectedCurrency={selectedCurrency}
      />
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
