import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AccountPage from './AccountPage';
import routes from '../../config/routes';

const OthersAccount: FC = () => {
  const { userId = '' } = useParams();

  const basePath = useMemo(() => routes.othersAccount.path.replace(':userId', userId), [userId]);

  return (
    <AccountPage
      basePath={basePath}
      isLoggedUserProfile={false}
      nestedRoutes={routes.othersAccount.nestedRoutes}
      userData={{ username: '@username' }}
    />
  );
};

export default OthersAccount;
