import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import BaseLayout from '../../components/layouts/BaseLayout';
import Box from '../../components/basics/Box';
import Button from '../../components/basics/Button';
import ProgressInfo from './components/ProgressInfo';
import * as Styled from './Queue.styles';
import BaseFooter from '../../components/navigation/BaseFooter';
import NavBarSimple from '../../components/navigation/NavBarSimple';
import routes from '../../config/routes';
import useAnalytics from '../../modules/analytics/useAnalytics';

const hasMarketplace = false;
const totalLine = Math.floor(Math.random() * 300) + 100;
const items = Math.floor(Math.random() * 300) + 100;

const Queue: FC = () => {
  const [queue, setQueue] = useState({ line: 0, items });
  const [showLeaveConfirmDialog, setShowLeaveConfirmDialog] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { onPageAction } = useAnalytics();
  const { pathname } = useLocation();

  const titleVariant = useMemo(() => (isMobile ? 'headline.mobile-lg' : 'headline.desktop-lg'), [isMobile]);
  const buttonSize = useMemo(() => (isMobile ? 'sm' : 'lg'), [isMobile]);
  const returnButtonLabel = useMemo(() => (hasMarketplace ? 'Visit Marketplace' : 'Return Home'), []);

  const handleOnShowLeaveConfirmDialog = useCallback(() => setShowLeaveConfirmDialog(true), []);
  const handleOnHideLeaveConfirmDialog = useCallback(() => setShowLeaveConfirmDialog(false), []);
  const handleOnLeave = useCallback(() => {
    handleOnHideLeaveConfirmDialog();
    navigate(routes.root.path);
    onPageAction({
      title: document.title,
      path: pathname,
      href: location.href,
      actionName: 'leave_queue',
      actionCategory: 'navigation',
    });
  }, [handleOnHideLeaveConfirmDialog, navigate, onPageAction, pathname]);

  const calculateNextValue = useCallback(() => {
    return {
      line: queue.line + (Math.floor(Math.random() * 10) + 5),
      items: queue.items - (Math.floor(Math.random() * 10) + 5),
    };
  }, [queue.line, queue.items]);

  const lineProgressValue = useMemo(() => {
    const value = (queue.line * 100) / totalLine;

    return value <= 100 ? value : 100;
  }, [queue.line]);
  const currentLineNumber = useMemo(() => {
    const value = totalLine - queue.line;

    return value >= 0 ? value : 0;
  }, [queue.line]);

  const showInitialAlert = useMemo(() => currentLineNumber <= 150 && currentLineNumber >= 100, [currentLineNumber]);
  const showFinalAlert = useMemo(() => currentLineNumber <= 100 && currentLineNumber >= 0, [currentLineNumber]);

  const soldOut = useMemo(() => queue.items <= 0, [queue.items]);
  const almostSoldOut = useMemo(() => queue.items >= 1 && queue.items <= 30, [queue.items]);

  useEffect(() => {
    document.title = 'Queue';
  }, []);

  useEffect(() => {
    if (currentLineNumber === 0 || soldOut) {
      return () => undefined;
    }

    const timer = setTimeout(() => {
      setQueue(calculateNextValue());
    }, Math.floor(Math.random() * 2000) + 100);

    return () => clearTimeout(timer);
  });

  return (
    <>
      <BaseLayout footer={<BaseFooter />} navBar={<NavBarSimple />}>
        <Styled.Media>
          <Styled.Hero />
        </Styled.Media>
        <Styled.Root>
          <Box position="absolute" left="0" right="0" top="20px" width="100%" justifyContent="center" display="flex">
            <AnimatePresence>
              {soldOut && (
                <motion.div
                  initial={{ y: -50, opacity: 0 }}
                  exit={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 1.0 }}
                >
                  <Box width="fit-content">
                    <Alert severity="error" variant="filled">
                      We’re sorry, this item is now sold out
                    </Alert>
                  </Box>
                </motion.div>
              )}
            </AnimatePresence>
          </Box>

          <Box position="absolute" left="0" right="0" top="20px" width="100%" justifyContent="center" display="flex">
            <AnimatePresence>
              {!soldOut && almostSoldOut && (
                <motion.div
                  initial={{ y: -50, opacity: 0 }}
                  exit={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                >
                  <Box width="fit-content">
                    <Alert severity="warning" variant="filled">
                      This item is almost sold out
                    </Alert>
                  </Box>
                </motion.div>
              )}
            </AnimatePresence>
          </Box>

          <Box position="absolute" left="0" right="0" top="20px" width="100%" justifyContent="center" display="flex">
            <AnimatePresence>
              {showInitialAlert && !soldOut && !almostSoldOut && (
                <motion.div
                  initial={{ y: -50, opacity: 0 }}
                  exit={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Box width="fit-content">
                    <Alert severity="info" variant="filled">
                      You’re almost there. Please ensure you have your payment details ready
                    </Alert>
                  </Box>
                </motion.div>
              )}
            </AnimatePresence>
          </Box>

          <Box position="absolute" left="0" right="0" top="20px" width="100%" justifyContent="center" display="flex">
            <AnimatePresence>
              {showFinalAlert && !soldOut && !almostSoldOut && (
                <motion.div
                  initial={{ y: -50, opacity: 0 }}
                  exit={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 1.0 }}
                >
                  <Box width="fit-content">
                    <Alert severity="info" variant="filled">
                      You’re next. You’ll have 15 minutes to complete your purchase.
                    </Alert>
                  </Box>
                </motion.div>
              )}
            </AnimatePresence>
          </Box>

          <AnimatePresence>
            <motion.div
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            >
              <Container>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  marginTop="20vh"
                >
                  <Box display="flex" flexDirection="column" maxWidth="450px">
                    {soldOut ? (
                      <>
                        <Typography variant={titleVariant} sx={{ marginBottom: `${theme.custom.spacing.SpacingMd}px` }}>
                          This line has ended
                        </Typography>
                        <Typography variant="body.lg" sx={{ marginBottom: `${theme.custom.spacing.Spacing2Xl}px` }}>
                          We’re sorry, Example has now sold out, you will not be able to complete your purchase from
                          this line. Thank you for your support.
                          <br />
                          Sales of NFT Example may be available on the official marketplace.
                        </Typography>
                        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                          <Button type="primary" size={buttonSize} onClick={handleOnLeave}>
                            {returnButtonLabel}
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Typography variant={titleVariant} sx={{ marginBottom: `${theme.custom.spacing.SpacingMd}px` }}>
                          You are now in line
                        </Typography>
                        <Typography variant="body.lg" sx={{ marginBottom: `${theme.custom.spacing.Spacing2Xl}px` }}>
                          You are in line to purchase <b>Example</b>. You will have <b>15 minutes</b> to complete your
                          purchase at the checkout.
                        </Typography>

                        <LinearProgress
                          variant="determinate"
                          value={lineProgressValue}
                          sx={{ marginBottom: `${theme.custom.spacing.Spacing2Xl}px` }}
                        />

                        <Box marginBottom={`${theme.custom.spacing.Spacing3Xl}px`}>
                          <ProgressInfo line={`${currentLineNumber}`} wait="14 mins" />
                        </Box>
                        <Box
                          marginTop={`${theme.custom.spacing.Spacing4Xl}px`}
                          marginBottom={`${theme.custom.spacing.Spacing5Xl}px`}
                          display="flex"
                          justifyContent="center"
                        >
                          <Button type="tertiary" size={buttonSize} onClick={handleOnShowLeaveConfirmDialog}>
                            Leave the Line
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Container>
            </motion.div>
          </AnimatePresence>
        </Styled.Root>
      </BaseLayout>

      <Dialog open={showLeaveConfirmDialog} onClose={handleOnHideLeaveConfirmDialog}>
        <DialogTitle>Leave the Line?</DialogTitle>

        <DialogContent>
          <DialogContentText>
            If you leave the line you will lose your place. If you want to purchase this item you will need to rejoin
            the line.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="secondary" size={buttonSize} onClick={handleOnHideLeaveConfirmDialog}>
            Cancel
          </Button>
          <Button type="primary" size={buttonSize} onClick={handleOnLeave}>
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Queue;
