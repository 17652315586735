import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';
import Box from '../basics/Box';
import BaseIcon from '../icons/BaseIcon';
import { ReactComponent as BackIcon } from '../icons/iconic/arrow-left.svg';
import { ReactComponent as ShareIcon } from '../icons/iconic/upload.svg';

interface IPageNavigationProps {
  path: string;
  label: string;
  params?: Record<string, string>;
  onShare?: VoidFunction;
  onBack?: VoidFunction;
}

const PageNavigation: FC<IPageNavigationProps> = props => {
  const theme = useTheme();
  const { label, path, params, onShare, onBack } = props;
  const navigate = useNavigate();

  const handleOnBack = useCallback(() => {
    if (onBack) {
      onBack();
      return;
    }

    navigate(path, params);
  }, [navigate, onBack, path, params]);

  return (
    <Box
      display="flex"
      margin="68px 0 0"
      width="100%"
      padding="20px 0"
      justifyContent="space-between"
      sx={{ cursor: 'pointer' }}
    >
      <Box display="flex" onClick={handleOnBack}>
        <BaseIcon icon={<BackIcon />} color={theme.custom.colors.TextWeak} />
        <Box marginLeft="5px">
          <Typography variant="text-weak">{label}</Typography>
        </Box>
      </Box>
      <Box onClick={onShare} sx={{ cursor: 'pointer' }}>
        <BaseIcon icon={<ShareIcon />} color={theme.custom.colors.TextWeak} />
      </Box>
    </Box>
  );
};

export default PageNavigation;
