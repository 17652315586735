import { FC } from 'react';
import { Typography } from '@mui/material';
import Styled from './TransactionItem.styles';
import Media from '../../../components/media/Media';

interface ITransactionItemProps {
  imageSource: string;
  name: string;
  value: string;
  packageName: string;
  eventName: string;
  status: string;
  date: string;
}

const TransactionItem: FC<ITransactionItemProps> = ({
  imageSource,
  date,
  eventName,
  name,
  packageName,
  status,
  value,
}) => {
  return (
    <Styled.Root>
      <Media source={imageSource} borderRadius="8px" size="small" height="66px" width="66px" />
      <div>
        <Styled.DescriptionWrapper>
          <Typography variant="h6">{name}</Typography>
          <Typography variant="h6">{value}</Typography>
        </Styled.DescriptionWrapper>

        <Styled.DescriptionWrapper>
          <Typography variant="caption">{packageName}</Typography>
          <Typography variant="caption">{date}</Typography>
        </Styled.DescriptionWrapper>

        <Styled.DescriptionWrapper>
          <Typography variant="caption">{eventName}</Typography>
          <Typography variant="caption">{status}</Typography>
        </Styled.DescriptionWrapper>
      </div>
    </Styled.Root>
  );
};

export default TransactionItem;
