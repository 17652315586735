import { CSSProperties, FC, ReactNode } from 'react';
import { ReactComponent as BadgeIcon } from '../../../../components/icons/badge.svg';
import * as Styled from './GameBadge.styles';

interface IGameBadgeProps {
  children?: ReactNode;
  padding?: CSSProperties['padding'];
}

const GameBadge: FC<IGameBadgeProps> = props => {
  const { children, padding = '0' } = props;

  return (
    <Styled.Root padding={padding}>
      <Styled.Wrapper>
        <Styled.Hexagon icon={<BadgeIcon />} />
        {children}
      </Styled.Wrapper>
    </Styled.Root>
  );
};

export default GameBadge;
