import { FC, useCallback } from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Button from '../../../components/basics/Button';
import BaseIcon from '../../../components/icons/BaseIcon';
import { ReactComponent as PlusIcon } from '../../../components/icons/iconic/plus.svg';
import TransactionItem from '../components/TransactionItem';
import useAnalytics from '../../../modules/analytics/useAnalytics';

const HistoryPage: FC = () => {
  const { onPageAction } = useAnalytics();
  const { pathname } = useLocation();
  const theme = useTheme();

  // TODO: remove to get data from services and handle empty states
  const hasData = Math.random() < 0.5;

  const handleOnBuy = useCallback(() => {
    onPageAction({
      title: document.title,
      path: pathname,
      href: location.href,
      actionName: 'buy_nft',
      actionCategory: 'buy',
    });
  }, [onPageAction, pathname]);

  return (
    <Container>
      {!hasData && (
        <Box marginTop={`${theme.custom.spacing.Spacing3Xl}px`}>
          <Typography variant="text-default">No transaction history yet</Typography>
        </Box>
      )}
      {hasData && (
        <>
          <Box margin={`${theme.custom.spacing.Spacing3Xl}px 0`}>
            <Typography>Your transaction history</Typography>
          </Box>

          {new Array(7).fill('').map((src, index) => (
            <TransactionItem
              key={`img-${index}`}
              value="$99.00"
              name={`NFT #${index}`}
              packageName="Pack Name"
              eventName="Drop Event Name"
              imageSource={src}
              date="22 Mar 2022"
              status="Complete"
            />
          ))}

          <Box
            margin="65px 0 0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="15px"
          >
            <Typography variant="caption">Add to your Collection</Typography>
            <Button
              type="secondary"
              size="lg"
              icon={<BaseIcon icon={<PlusIcon />} height={12} />}
              onClick={handleOnBuy}
            >
              Buy NFTs
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default HistoryPage;
