import { FC, useContext, useEffect, useState } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme, Box } from '@mui/material';
import Footer from '../../components/navigation/Footer';
import NavBar from '../../components/navigation/NavBar';
import BaseLayout from '../../components/layouts/BaseLayout';
import Attribute from '../../components/feedback/Attribute';
import Reveal from './components/Reveal';
import Media from '../../components/media/Media';
import { useQuery } from '../../tools/useQuery';
import SessionContext from '../../modules/session/Session.context';

const NftDetails: FC = () => {
  const { account, onUpdateAccount } = useContext(SessionContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showReveal, setShowReveal] = useState(true);
  const [signedInParam] = useQuery(['signed-in']);

  useEffect(() => {
    if (signedInParam === 'true' && !account?.id) {
      onUpdateAccount({ id: 'user-test-env', name: 'User Name', email: 'user@forte.io' });
    }
  }, [account?.id, onUpdateAccount, signedInParam]);

  return (
    <BaseLayout footer={<Footer />} navBar={<NavBar />}>
      {/* TODO: remove this behavior when reveal is connected properly */}
      <Reveal show={showReveal} onReveal={() => setShowReveal(false)} />
      <Container>
        <Grid container margin={isMobile ? '15px 0' : '80px 0 0'}>
          <Grid item xs={12} sm={5} margin="0 0 15px">
            <Media size="full-screen" aspectRatio="1:1" source="" isVideo />
          </Grid>

          <Grid item xs={12} sm={7}>
            <Box margin={isMobile ? '5px 0 0' : '20px 0 0 50px'}>
              <Typography variant="headline.desktop-lg" margin={isMobile ? '0 20px' : '0'}>
                NFT #425
              </Typography>

              <Box margin="25px 0 33px">
                <Attribute type="stacked" label="Minted" description="25 March 2022" margin="0 0 16px" />
                <Attribute type="stacked" label="Serial Number" description="#425/5000" margin="0 0 16px" />
              </Box>

              <Box margin="25px 0 0">
                <Attribute type="inline" label="Sword" description="Mystic" margin="0 0 10px" />
                <Attribute type="inline" label="Strength" description="Zeus" margin="0 0 10px" />
                <Attribute type="inline" label="Gem" description="Saphire" margin="0 0 10px" />
                <Attribute type="inline" label="Power" description="Next Level" margin="0 0 10px" />
                <Attribute type="inline" label="Character" description="Savage" margin="0 0 10px" />
                <Attribute type="inline" label="Mood" description="Monsterous" margin="0 0 10px" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </BaseLayout>
  );
};

export default NftDetails;
