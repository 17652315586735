import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import * as Styled from './PropertyCard.styles';

interface IPropertyCardProps {
  type: string;
  name: string;
  description: string;
}

const PropertyCard: FC<IPropertyCardProps> = ({ type, name, description }) => {
  const { custom } = useTheme();

  return (
    <Styled.Root>
      <Typography variant="label.md-strong" noWrap color={custom.colors.TextOnAccentWeak}>
        {type}
      </Typography>
      <Typography variant="label.md-strong" noWrap color={custom.colors.TextDefault}>
        {name}
      </Typography>
      <Typography variant="label.sm" noWrap color={custom.colors.TextWeak}>
        {description}
      </Typography>
    </Styled.Root>
  );
};

export default PropertyCard;
