import { FC, useEffect, useMemo } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import Styled from './RevealOneBackdrop.styles';
import { ReactComponent as CloseIcon } from '../../../components/icons/iconic/close.svg';
import RevealMediaPlaceholder from '../../../components/media/RevealMediaPlaceholder';
import BaseIcon from '../../../components/icons/BaseIcon';
import Button from '../../../components/basics/Button';

interface IRevealOneBackdrop {
  onClose: () => void;
  open: boolean;
  selectedId: string;
}

const RevealOneBackdrop: FC<IRevealOneBackdrop> = ({ onClose, open, selectedId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const size = useMemo(() => (isMobile ? 'calc(100vw - 100px)' : '336px'), [isMobile]);

  useEffect(() => {
    open ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'initial');
  }, [open]);

  return (
    <Styled.Root open={open}>
      <Box position="absolute" top="32px" right="32px">
        <BaseIcon icon={<CloseIcon />} onClick={onClose} cursor="pointer" />
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center">
        <AnimatePresence>
          {open && (
            <motion.div
              layoutId={`layout-${selectedId}`}
              exit={{ transition: { duration: 0.3 } }}
              transition={{ duration: 0.3 }}
              style={{
                width: size,
                height: size,
                zIndex: 9999,
              }}
            >
              <RevealMediaPlaceholder width={size} height={size} iconWidth="470px" iconHeight="470px" selected />

              <Box width="100%" margin="25px 0 0">
                <Button type="primary" size="lg" fullWidth onClick={onClose}>
                  Reveal your purchase
                </Button>
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </Styled.Root>
  );
};

export default RevealOneBackdrop;
