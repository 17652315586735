import { FC } from 'react';
import { useTheme } from '@mui/material';
import Styled from './NavBarSimple.styles';
import { ReactComponent as LogoIcon } from '../icons/logo.svg';
import BaseIcon from '../icons/BaseIcon';

const NavBarSimple: FC = () => {
  const theme = useTheme();

  return (
    <Styled.CustomAppBar position="fixed">
      <Styled.NavigationBar height="72px">
        <BaseIcon icon={<LogoIcon />} color={theme.custom.colors.IconWeak} />
      </Styled.NavigationBar>
    </Styled.CustomAppBar>
  );
};

export default NavBarSimple;
