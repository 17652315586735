import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const QuestionMarkIcon: FC = () => (
  <SvgIcon width="107" height="107" viewBox="0 0 107 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M43.5299 40.8223C43.5296 40.8244 43.5293 40.8267 43.5299 40.8223L43.5363 40.7751C43.5445 40.7166 43.5604 40.6106 43.5859 40.4641C43.6373 40.1698 43.7266 39.7199 43.8697 39.1679C44.1611 38.0439 44.6485 36.6022 45.422 35.2099C46.9081 32.535 49.2104 30.3125 53.5004 30.3125C59.0287 30.3125 61.002 31.9726 61.8791 33.2005C63.0038 34.7751 63.4379 37.1601 63.4379 40.25C63.4379 43.3407 61.7453 46.3687 58.2009 47.7382C53.9967 49.3627 48.5317 53.427 48.5317 60.125V61.7812C48.5317 64.5254 50.7563 66.75 53.5004 66.75C56.2446 66.75 58.4692 64.5254 58.4692 61.7812V60.125C58.4692 59.7828 58.592 59.3197 59.1428 58.7139C59.7251 58.0733 60.6533 57.4442 61.7826 57.0078C69.4581 54.042 73.3754 47.1147 73.3754 40.25C73.3754 36.7149 72.9815 31.6468 69.9656 27.4245C66.702 22.8555 61.2222 20.375 53.5004 20.375C44.5404 20.375 39.3897 25.6056 36.7351 30.3838C35.4383 32.7181 34.6835 35.003 34.2503 36.6739C34.0311 37.5193 33.8875 38.234 33.7965 38.7549C33.7509 39.0159 33.7183 39.2303 33.6958 39.3901L33.6692 39.5888L33.661 39.6561L33.6581 39.6815L33.6569 39.6921C33.6566 39.6943 33.6558 39.7013 38.5942 40.25L33.6558 39.7013C33.3528 42.4287 35.3181 44.8853 38.0455 45.1884C40.7643 45.4904 43.2142 43.5362 43.5299 40.8223Z" />
    <path
      d="M53.5 73.375C49.8411 73.375 46.875 76.3411 46.875 80C46.875 83.6589 49.8411 86.625 53.5 86.625C57.1589 86.625 60.125 83.6589 60.125 80C60.125 76.3411 57.1589 73.375 53.5 73.375Z"
      fill="#005D85"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 53.5C0.5 24.2289 24.2289 0.5 53.5 0.5C82.7711 0.5 106.5 24.2289 106.5 53.5C106.5 82.7711 82.7711 106.5 53.5 106.5C24.2289 106.5 0.5 82.7711 0.5 53.5ZM53.5 10.4375C29.7172 10.4375 10.4375 29.7172 10.4375 53.5C10.4375 77.2828 29.7172 96.5625 53.5 96.5625C77.2828 96.5625 96.5625 77.2828 96.5625 53.5C96.5625 29.7172 77.2828 10.4375 53.5 10.4375Z"
    />
  </SvgIcon>
);

export default QuestionMarkIcon;
