/**
 * Do not edit directly
 * Generated on Wed, 01 Jun 2022 13:46:22 GMT
 */

export const BgDefault = '#000';
export const BgLayer1 = '#202425';
export const BgLayer2 = '#2b2f31';
export const BgAside = '#151718';
export const BgAccent = '#68ddfd';
export const BgAccentWeak = '#083e59';
export const BgCritical = '#e5484d';
export const BgWarning = '#ffb224';
export const BgSuccess = '#30a46c';
export const BgHighlight = '#0091ff';
export const BgHighlightWeak = '#0f3058';
export const ActionAccent = '#68ddfd';
export const ActionAccentHover = '#2ec8ee';
export const ActionCritical = '#ff6369';
export const ActionCriticalHover = '#f2555a';
export const ActionSecondary = '#202425';
export const ActionSecondaryHover = '#26292b';
export const ActionTertiaryHover = '#26292b';
export const ActionDisabled = '#313538';
export const BorderDefault = '#3a3f42';
export const BorderSeparator = '#3a3f42';
export const BorderField = '#4c5155';
export const BorderTertiaryHover = '#697177';
export const BorderAccent = '#005d85';
export const BorderCritical = '#aa2429';
export const BorderHighlight = '#0954a5';
export const FieldDefault = '#1a1d1e';
export const FieldSearch = '#26292b';
export const FieldDisabled = '#2b2f31';
export const FieldCritical = '#3c181a';
export const FieldWarning = '#341c00';
export const FieldSuccess = '#0f291e';
export const FieldHighlight = '#10243e';
export const TextDefault = '#000';
export const TextWeak = '#9ba1a6';
export const TextHint = '#697177';
export const TextDisabled = '#697177';
export const TextOnAccent = '#064b6b';
export const TextOnAccentWeak = '#8decfffa';
export const TextOnCritical = '#feecee';
export const TextOnWarning = '#824e00';
export const TextOnSuccess = '#e5fbeb';
export const TextOnHighlight = '#eaf6ff';
export const TextCritical = '#ff6369';
export const TextWarning = '#f1a10d';
export const TextSuccess = '#4cc38a';
export const TextHighlight = '#52a9ff';
export const IconWeak = '#9ba1a6';
export const IconDisabled = '#697177';
export const IconOnAccent = '#005d85';
export const IconOnAccentWeak = '#68ddfd';
export const IconOnCritical = '#feecee';
export const IconOnWarning = '#fef3dd';
export const IconOnSuccess = '#e5fbeb';
export const IconOnHighlight = '#eaf6ff';
export const IconCritical = '#ff6369';
export const IconWarning = '#f1a10d';
export const IconSuccess = '#4cc38a';
export const IconHighlight = '#52a9ff';
export const White = '#ffffff';
export const Black = '#000000';
export const Yellow = '#FEC300';
export const Purple = '#7900FF';
export const TomatoDark1 = '#1d1412';
export const TomatoDark2 = '#2a1410';
export const TomatoDark3 = '#3b1813';
export const TomatoDark4 = '#481a14';
export const TomatoDark5 = '#541c15';
export const TomatoDark6 = '#652016';
export const TomatoDark7 = '#7f2315';
export const TomatoDark8 = '#a42a12';
export const TomatoDark9 = '#e54d2e';
export const TomatoDark10 = '#ec5e41';
export const TomatoDark11 = '#f16a50';
export const TomatoDark12 = '#feefec';
export const TomatoLight1 = '#fffcfc';
export const TomatoLight2 = '#fff8f7';
export const TomatoLight3 = '#fff0ee';
export const TomatoLight4 = '#ffe6e2';
export const TomatoLight5 = '#fdd8d3';
export const TomatoLight6 = '#fac7be';
export const TomatoLight7 = '#f3b0a2';
export const TomatoLight8 = '#ea9280';
export const TomatoLight9 = '#e54d2e';
export const TomatoLight10 = '#db4324';
export const TomatoLight11 = '#ca3214';
export const TomatoLight12 = '#341711';
export const TomatoDarkAlpha1 = '#00000000';
export const TomatoDarkAlpha2 = '#fd15000f';
export const TomatoDarkAlpha3 = '#ff301921';
export const TomatoDarkAlpha4 = '#fe331c30';
export const TomatoDarkAlpha5 = '#fe351e3d';
export const TomatoDarkAlpha6 = '#ff391e52';
export const TomatoDarkAlpha7 = '#ff37196e';
export const TomatoDarkAlpha8 = '#ff3a1299';
export const TomatoDarkAlpha9 = '#ff5431e0';
export const TomatoDarkAlpha10 = '#ff6445eb';
export const TomatoDarkAlpha11 = '#ff7054f0';
export const TomatoDarkAlpha12 = '#fff3f0fa';
export const TomatoLightAlpha1 = '#ff050503';
export const TomatoLightAlpha2 = '#ff260508';
export const TomatoLightAlpha3 = '#ff1f0112';
export const TomatoLightAlpha4 = '#ff22011c';
export const TomatoLightAlpha5 = '#f41d012b';
export const TomatoLightAlpha6 = '#ec230040';
export const TomatoLightAlpha7 = '#de25005e';
export const TomatoLightAlpha8 = '#d5240180';
export const TomatoLightAlpha9 = '#df2500d1';
export const TomatoLightAlpha10 = '#d52400db';
export const TomatoLightAlpha11 = '#c62100eb';
export const TomatoLightAlpha12 = '#260600ed';
export const RedDark1 = '#1f1315';
export const RedDark2 = '#291415';
export const RedDark3 = '#3c181a';
export const RedDark4 = '#481a1d';
export const RedDark5 = '#541b1f';
export const RedDark6 = '#671e22';
export const RedDark7 = '#822025';
export const RedDark8 = '#aa2429';
export const RedDark9 = '#e5484d';
export const RedDark10 = '#f2555a';
export const RedDark11 = '#ff6369';
export const RedDark12 = '#feecee';
export const RedLight1 = '#fffcfc';
export const RedLight2 = '#fff8f8';
export const RedLight3 = '#ffefef';
export const RedLight4 = '#ffe5e5';
export const RedLight5 = '#fdd8d8';
export const RedLight6 = '#f9c6c6';
export const RedLight7 = '#f3aeaf';
export const RedLight8 = '#eb9091';
export const RedLight9 = '#e5484d';
export const RedLight10 = '#dc3d43';
export const RedLight11 = '#cd2b31';
export const RedLight12 = '#381316';
export const RedDarkAlpha1 = '#00000000';
export const RedDarkAlpha2 = '#fd28150d';
export const RedDarkAlpha3 = '#fe3a3d21';
export const RedDarkAlpha4 = '#fe39402e';
export const RedDarkAlpha5 = '#ff353f3d';
export const RedDarkAlpha6 = '#ff353c52';
export const RedDarkAlpha7 = '#ff303b70';
export const RedDarkAlpha8 = '#ff2f369e';
export const RedDarkAlpha9 = '#ff4f55e0';
export const RedDarkAlpha10 = '#ff595ff0';
export const RedDarkAlpha11 = '#ff646afa';
export const RedDarkAlpha12 = '#fff0f2fa';
export const RedLightAlpha1 = '#ff050503';
export const RedLightAlpha2 = '#ff050508';
export const RedLightAlpha3 = '#ff01010f';
export const RedLightAlpha4 = '#ff00001a';
export const RedLightAlpha5 = '#f2000026';
export const RedLightAlpha6 = '#e4010138';
export const RedLightAlpha7 = '#d9000452';
export const RedLightAlpha8 = '#d1000470';
export const RedLightAlpha9 = '#db0007b8';
export const RedLightAlpha10 = '#d10007c2';
export const RedLightAlpha11 = '#c30007d4';
export const RedLightAlpha12 = '#280003ed';
export const CrimsonDark1 = '#1d1418';
export const CrimsonDark2 = '#27141c';
export const CrimsonDark3 = '#3c1827';
export const CrimsonDark4 = '#481a2d';
export const CrimsonDark5 = '#541b33';
export const CrimsonDark6 = '#641d3b';
export const CrimsonDark7 = '#801d45';
export const CrimsonDark8 = '#ae1955';
export const CrimsonDark9 = '#e93d82';
export const CrimsonDark10 = '#f04f88';
export const CrimsonDark11 = '#f76190';
export const CrimsonDark12 = '#feecf4';
export const CrimsonLight1 = '#fffcfd';
export const CrimsonLight2 = '#fff7fb';
export const CrimsonLight3 = '#feeff6';
export const CrimsonLight4 = '#fce5f0';
export const CrimsonLight5 = '#f9d8e7';
export const CrimsonLight6 = '#f4c6db';
export const CrimsonLight7 = '#edadc8';
export const CrimsonLight8 = '#e58fb1';
export const CrimsonLight9 = '#e93d82';
export const CrimsonLight10 = '#e03177';
export const CrimsonLight11 = '#d31e66';
export const CrimsonLight12 = '#3d0d1d';
export const CrimsonDarkAlpha1 = '#00000000';
export const CrimsonDarkAlpha2 = '#fb14710d';
export const CrimsonDarkAlpha3 = '#fe318624';
export const CrimsonDarkAlpha4 = '#fe338430';
export const CrimsonDarkAlpha5 = '#fe31863d';
export const CrimsonDarkAlpha6 = '#fe31864f';
export const CrimsonDarkAlpha7 = '#fe287e70';
export const CrimsonDarkAlpha8 = '#ff1c77a3';
export const CrimsonDarkAlpha9 = '#ff418de6';
export const CrimsonDarkAlpha10 = '#ff538fed';
export const CrimsonDarkAlpha11 = '#ff6495f5';
export const CrimsonDarkAlpha12 = '#fff0f8fa';
export const CrimsonLightAlpha1 = '#ff055803';
export const CrimsonLightAlpha2 = '#ff058208';
export const CrimsonLightAlpha3 = '#ef01700f';
export const CrimsonLightAlpha4 = '#e2006d1a';
export const CrimsonLightAlpha5 = '#d8006126';
export const CrimsonLightAlpha6 = '#ce015d38';
export const CrimsonLightAlpha7 = '#c7005352';
export const CrimsonLightAlpha8 = '#c4004f70';
export const CrimsonLightAlpha9 = '#e2005ac2';
export const CrimsonLightAlpha10 = '#d90057cf';
export const CrimsonLightAlpha11 = '#cd0052e0';
export const CrimsonLightAlpha12 = '#330011f2';
export const PinkDark1 = '#1f121b';
export const PinkDark2 = '#271421';
export const PinkDark3 = '#3a182f';
export const PinkDark4 = '#451a37';
export const PinkDark5 = '#501b3f';
export const PinkDark6 = '#601d48';
export const PinkDark7 = '#7a1d5a';
export const PinkDark8 = '#a71873';
export const PinkDark9 = '#d6409f';
export const PinkDark10 = '#e34ba9';
export const PinkDark11 = '#f65cb6';
export const PinkDark12 = '#feebf7';
export const PinkLight1 = '#fffcfe';
export const PinkLight2 = '#fff7fc';
export const PinkLight3 = '#feeef8';
export const PinkLight4 = '#fce5f3';
export const PinkLight5 = '#f9d8ec';
export const PinkLight6 = '#f3c6e2';
export const PinkLight7 = '#ecadd4';
export const PinkLight8 = '#e38ec3';
export const PinkLight9 = '#d6409f';
export const PinkLight10 = '#d23197';
export const PinkLight11 = '#cd1d8d';
export const PinkLight12 = '#3b0a2a';
export const PinkDarkAlpha1 = '#00000000';
export const PinkDarkAlpha2 = '#fd4ac10a';
export const PinkDarkAlpha3 = '#fe44c01f';
export const PinkDarkAlpha4 = '#ff41bf2b';
export const PinkDarkAlpha5 = '#ff3bc138';
export const PinkDarkAlpha6 = '#fe38b64a';
export const PinkDarkAlpha7 = '#ff2db569';
export const PinkDarkAlpha8 = '#ff1cac9c';
export const PinkDarkAlpha9 = '#ff4abdd1';
export const PinkDarkAlpha10 = '#ff53bde0';
export const PinkDarkAlpha11 = '#ff5fbcf5';
export const PinkDarkAlpha12 = '#ffeffbfa';
export const PinkLightAlpha1 = '#ff05ac03';
export const PinkLightAlpha2 = '#ff059f08';
export const PinkLightAlpha3 = '#f0019412';
export const PinkLightAlpha4 = '#e2008b1a';
export const PinkLightAlpha5 = '#d8008126';
export const PinkLightAlpha6 = '#c9017c38';
export const PinkLightAlpha7 = '#c4007952';
export const PinkLightAlpha8 = '#c0007670';
export const PinkLightAlpha9 = '#c8007fbf';
export const PinkLightAlpha10 = '#c7007ecf';
export const PinkLightAlpha11 = '#c7007ee3';
export const PinkLightAlpha12 = '#330021f5';
export const PlumDark1 = '#1d131d';
export const PlumDark2 = '#251425';
export const PlumDark3 = '#341a34';
export const PlumDark4 = '#3e1d40';
export const PlumDark5 = '#48214b';
export const PlumDark6 = '#542658';
export const PlumDark7 = '#692d6f';
export const PlumDark8 = '#883894';
export const PlumDark9 = '#ab4aba';
export const PlumDark10 = '#bd54c6';
export const PlumDark11 = '#d864d8';
export const PlumDark12 = '#fbecfc';
export const PlumLight1 = '#fefcff';
export const PlumLight2 = '#fff8ff';
export const PlumLight3 = '#fceffc';
export const PlumLight4 = '#f9e5f9';
export const PlumLight5 = '#f3d9f4';
export const PlumLight6 = '#ebc8ed';
export const PlumLight7 = '#dfafe3';
export const PlumLight8 = '#cf91d8';
export const PlumLight9 = '#ab4aba';
export const PlumLight10 = '#a43cb4';
export const PlumLight11 = '#9c2bad';
export const PlumLight12 = '#340c3b';
export const PlumDarkAlpha1 = '#00000000';
export const PlumDarkAlpha2 = '#fb2ffb0a';
export const PlumDarkAlpha3 = '#fe58fe1a';
export const PlumDarkAlpha4 = '#f153ff29';
export const PlumDarkAlpha5 = '#f158fe33';
export const PlumDarkAlpha6 = '#ee5cfe42';
export const PlumDarkAlpha7 = '#ee5aff5c';
export const PlumDarkAlpha8 = '#e959ff87';
export const PlumDarkAlpha9 = '#ea62ffb0';
export const PlumDarkAlpha10 = '#f36affbf';
export const PlumDarkAlpha11 = '#ff75ffd4';
export const PlumDarkAlpha12 = '#fff0fffa';
export const PlumLightAlpha1 = '#ac05ff03';
export const PlumLightAlpha2 = '#ff05ff08';
export const PlumLightAlpha3 = '#d001d00f';
export const PlumLightAlpha4 = '#c400c41a';
export const PlumLightAlpha5 = '#af02b526';
export const PlumLightAlpha6 = '#a300ac38';
export const PlumLightAlpha7 = '#9800a64f';
export const PlumLightAlpha8 = '#8f00a56e';
export const PlumLightAlpha9 = '#89009eb5';
export const PlumLightAlpha10 = '#88009dc2';
export const PlumLightAlpha11 = '#88009cd4';
export const PlumLightAlpha12 = '#2a0031f2';
export const PurpleDark1 = '#7900ff';
export const PurpleDark2 = '#221527';
export const PurpleDark3 = '#301a3a';
export const PurpleDark4 = '#3a1e48';
export const PurpleDark5 = '#432155';
export const PurpleDark6 = '#4e2667';
export const PurpleDark7 = '#5f2d84';
export const PurpleDark8 = '#7938b2';
export const PurpleDark9 = '#8e4ec6';
export const PurpleDark10 = '#9d5bd2';
export const PurpleDark11 = '#bf7af0';
export const PurpleDark12 = '#f7ecfc';
export const PurpleLight1 = '#fefcfe';
export const PurpleLight2 = '#fdfaff';
export const PurpleLight3 = '#f9f1fe';
export const PurpleLight4 = '#f3e7fc';
export const PurpleLight5 = '#eddbf9';
export const PurpleLight6 = '#e3ccf4';
export const PurpleLight7 = '#d3b4ed';
export const PurpleLight8 = '#be93e4';
export const PurpleLight9 = '#8e4ec6';
export const PurpleLight10 = '#8445bc';
export const PurpleLight11 = '#793aaf';
export const PurpleLight12 = '#2b0e44';
export const PurpleDarkAlpha1 = '#00000000';
export const PurpleDarkAlpha2 = '#b52afb0d';
export const PurpleDarkAlpha3 = '#bc43fe21';
export const PurpleDarkAlpha4 = '#be48fe30';
export const PurpleDarkAlpha5 = '#bc49ff40';
export const PurpleDarkAlpha6 = '#b74bff54';
export const PurpleDarkAlpha7 = '#b14aff75';
export const PurpleDarkAlpha8 = '#ab4bffa8';
export const PurpleDarkAlpha9 = '#b561ffbf';
export const PurpleDarkAlpha10 = '#bd6dffcc';
export const PurpleDarkAlpha11 = '#cb81ffed';
export const PurpleDarkAlpha12 = '#fcf0fffa';
export const PurpleLightAlpha1 = '#ab05ab03';
export const PurpleLightAlpha2 = '#9b05ff05';
export const PurpleLightAlpha3 = '#9200ed0d';
export const PurpleLightAlpha4 = '#8002e017';
export const PurpleLightAlpha5 = '#8001d524';
export const PurpleLightAlpha6 = '#7500c833';
export const PurpleLightAlpha7 = '#6b01c24a';
export const PurpleLightAlpha8 = '#6600bf6b';
export const PurpleLightAlpha9 = '#5c00adb0';
export const PurpleLightAlpha10 = '#5700a3ba';
export const PurpleLightAlpha11 = '#510097c4';
export const PurpleLightAlpha12 = '#1f0039f2';
export const VioletDark1 = '#17151f';
export const VioletDark2 = '#1c172b';
export const VioletDark3 = '#251e40';
export const VioletDark4 = '#2c2250';
export const VioletDark5 = '#32275f';
export const VioletDark6 = '#392c72';
export const VioletDark7 = '#443592';
export const VioletDark8 = '#5842c3';
export const VioletDark9 = '#6e56cf';
export const VioletDark10 = '#7c66dc';
export const VioletDark11 = '#9e8cfc';
export const VioletDark12 = '#f1eefe';
export const VioletLight1 = '#fdfcfe';
export const VioletLight2 = '#fbfaff';
export const VioletLight3 = '#f5f2ff';
export const VioletLight4 = '#ede9fe';
export const VioletLight5 = '#e4defc';
export const VioletLight6 = '#d7cff9';
export const VioletLight7 = '#c4b8f3';
export const VioletLight8 = '#aa99ec';
export const VioletLight9 = '#6e56cf';
export const VioletLight10 = '#644fc1';
export const VioletLight11 = '#5746af';
export const VioletLight12 = '#20134b';
export const VioletDarkAlpha1 = '#00000000';
export const VioletDarkAlpha2 = '#743afd0d';
export const VioletDarkAlpha3 = '#7452fe26';
export const VioletDarkAlpha4 = '#7650ff38';
export const VioletDarkAlpha5 = '#7654ff4a';
export const VioletDarkAlpha6 = '#7253ff5e';
export const VioletDarkAlpha7 = '#7053ff82';
export const VioletDarkAlpha8 = '#6f52ffba';
export const VioletDarkAlpha9 = '#8668ffc9';
export const VioletDarkAlpha10 = '#8e75ffd6';
export const VioletDarkAlpha11 = '#a18efffa';
export const VioletDarkAlpha12 = '#f5f2fffa';
export const VioletLightAlpha1 = '#5805ab03';
export const VioletLightAlpha2 = '#3705ff05';
export const VioletLightAlpha3 = '#3c00ff0d';
export const VioletLightAlpha4 = '#2e02f417';
export const VioletLightAlpha5 = '#2f01e821';
export const VioletLightAlpha6 = '#2a01df30';
export const VioletLightAlpha7 = '#2b01d447';
export const VioletLightAlpha8 = '#2a00d066';
export const VioletLightAlpha9 = '#2500b6a8';
export const VioletLightAlpha10 = '#1f00a5b0';
export const VioletLightAlpha11 = '#180091ba';
export const VioletLightAlpha12 = '#0e003ded';
export const IndigoDark1 = '#131620';
export const IndigoDark2 = '#15192d';
export const IndigoDark3 = '#192140';
export const IndigoDark4 = '#1c274f';
export const IndigoDark5 = '#1f2c5c';
export const IndigoDark6 = '#22346e';
export const IndigoDark7 = '#273e89';
export const IndigoDark8 = '#2f4eb2';
export const IndigoDark9 = '#3e63dd';
export const IndigoDark10 = '#5373e7';
export const IndigoDark11 = '#849dff';
export const IndigoDark12 = '#eef1fd';
export const IndigoLight1 = '#fdfdfe';
export const IndigoLight2 = '#f8faff';
export const IndigoLight3 = '#f0f4ff';
export const IndigoLight4 = '#e6edfe';
export const IndigoLight5 = '#d9e2fc';
export const IndigoLight6 = '#c6d4f9';
export const IndigoLight7 = '#aec0f5';
export const IndigoLight8 = '#8da4ef';
export const IndigoLight9 = '#3e63dd';
export const IndigoLight10 = '#3a5ccc';
export const IndigoLight11 = '#3451b2';
export const IndigoLight12 = '#101d46';
export const IndigoDarkAlpha1 = '#00000000';
export const IndigoDarkAlpha2 = '#3549fc0f';
export const IndigoDarkAlpha3 = '#3c63fe24';
export const IndigoDarkAlpha4 = '#3d67ff36';
export const IndigoDarkAlpha5 = '#3f69fe45';
export const IndigoDarkAlpha6 = '#3e6bff59';
export const IndigoDarkAlpha7 = '#3d6aff78';
export const IndigoDarkAlpha8 = '#3e6bffa6';
export const IndigoDarkAlpha9 = '#4571ffd9';
export const IndigoDarkAlpha10 = '#5a7effe3';
export const IndigoDarkAlpha11 = '#86a0fffa';
export const IndigoDarkAlpha12 = '#f2f5fffa';
export const IndigoLightAlpha1 = '#05058203';
export const IndigoLightAlpha2 = '#054cff08';
export const IndigoLightAlpha3 = '#0144ff0f';
export const IndigoLightAlpha4 = '#0247f51a';
export const IndigoLightAlpha5 = '#023ceb26';
export const IndigoLightAlpha6 = '#013de438';
export const IndigoLightAlpha7 = '#0038e052';
export const IndigoLightAlpha8 = '#0134db73';
export const IndigoLightAlpha9 = '#0031d2c2';
export const IndigoLightAlpha10 = '#002cbdc4';
export const IndigoLightAlpha11 = '#00259ecc';
export const IndigoLightAlpha12 = '#000e3af0';
export const BlueDark1 = '#0f1720';
export const BlueDark2 = '#0f1b2d';
export const BlueDark3 = '#10243e';
export const BlueDark4 = '#102a4c';
export const BlueDark5 = '#0f3058';
export const BlueDark6 = '#0d3868';
export const BlueDark7 = '#0a4481';
export const BlueDark8 = '#0954a5';
export const BlueDark9 = '#0091ff';
export const BlueDark10 = '#369eff';
export const BlueDark11 = '#52a9ff';
export const BlueDark12 = '#eaf6ff';
export const BlueLight1 = '#fbfdff';
export const BlueLight2 = '#f5faff';
export const BlueLight3 = '#edf6ff';
export const BlueLight4 = '#e1f0ff';
export const BlueLight5 = '#cee7fe';
export const BlueLight6 = '#b7d9f8';
export const BlueLight7 = '#96c7f2';
export const BlueLight8 = '#5eb0ef';
export const BlueLight9 = '#0091ff';
export const BlueLight10 = '#0081f1';
export const BlueLight11 = '#006adc';
export const BlueLight12 = '#00254d';
export const BlueDarkAlpha1 = '#00000000';
export const BlueDarkAlpha2 = '#0f5afc0f';
export const BlueDarkAlpha3 = '#1677fe24';
export const BlueDarkAlpha4 = '#1476fe33';
export const BlueDarkAlpha5 = '#0f7bfe40';
export const BlueDarkAlpha6 = '#097cff52';
export const BlueDarkAlpha7 = '#047dff70';
export const BlueDarkAlpha8 = '#057eff99';
export const BlueDarkAlpha9 = '#0095fffa';
export const BlueDarkAlpha10 = '#37a1fffa';
export const BlueDarkAlpha11 = '#53acfffa';
export const BlueDarkAlpha12 = '#effbfffa';
export const BlueLightAlpha1 = '#0582ff05';
export const BlueLightAlpha2 = '#0582ff0a';
export const BlueLightAlpha3 = '#0280ff12';
export const BlueLightAlpha4 = '#0180ff1f';
export const BlueLightAlpha5 = '#0180ef30';
export const BlueLightAlpha6 = '#0177e647';
export const BlueLightAlpha7 = '#0077df69';
export const BlueLightAlpha8 = '#0082e6a1';
export const BlueLightAlpha9 = '#0091fffa';
export const BlueLightAlpha10 = '#0080f1fa';
export const BlueLightAlpha11 = '#0066dbfa';
export const BlueLightAlpha12 = '#002149fa';
export const CyanDark1 = '#07191d';
export const CyanDark2 = '#061e24';
export const CyanDark3 = '#072830';
export const CyanDark4 = '#07303b';
export const CyanDark5 = '#073844';
export const CyanDark6 = '#064150';
export const CyanDark7 = '#045063';
export const CyanDark8 = '#00647d';
export const CyanDark9 = '#05a2c2';
export const CyanDark10 = '#00b1cc';
export const CyanDark11 = '#00c2d7';
export const CyanDark12 = '#e1f8fa';
export const CyanLight1 = '#fafdfe';
export const CyanLight2 = '#f2fcfd';
export const CyanLight3 = '#e7f9fb';
export const CyanLight4 = '#d8f3f6';
export const CyanLight5 = '#c4eaef';
export const CyanLight6 = '#aadee6';
export const CyanLight7 = '#84cdda';
export const CyanLight8 = '#3db9cf';
export const CyanLight9 = '#05a2c2';
export const CyanLight10 = '#0894b3';
export const CyanLight11 = '#0c7792';
export const CyanLight12 = '#04313c';
export const CyanDarkAlpha1 = '#00000000';
export const CyanDarkAlpha2 = '#00bbff08';
export const CyanDarkAlpha3 = '#07cbfc17';
export const CyanDarkAlpha4 = '#07c5ff21';
export const CyanDarkAlpha5 = '#07cdfe2b';
export const CyanDarkAlpha6 = '#02c8ff3b';
export const CyanDarkAlpha7 = '#00ccff4f';
export const CyanDarkAlpha8 = '#00c8ff6e';
export const CyanDarkAlpha9 = '#04d5ffba';
export const CyanDarkAlpha10 = '#00ddffc4';
export const CyanDarkAlpha11 = '#00e5fed1';
export const CyanDarkAlpha12 = '#e6fdfffa';
export const CyanLightAlpha1 = '#059bcd05';
export const CyanLightAlpha2 = '#00c6d80d';
export const CyanLightAlpha3 = '#02c0d517';
export const CyanLightAlpha4 = '#00b1c426';
export const CyanLightAlpha5 = '#01a4ba3b';
export const CyanLightAlpha6 = '#019cb454';
export const CyanLightAlpha7 = '#0097b27a';
export const CyanLightAlpha8 = '#00a3c0c2';
export const CyanLightAlpha9 = '#00a1c1fa';
export const CyanLightAlpha10 = '#0090b0f7';
export const CyanLightAlpha11 = '#00718df2';
export const CyanLightAlpha12 = '#002d38fa';
export const TealDark1 = '#091915';
export const TealDark2 = '#04201b';
export const TealDark3 = '#062923';
export const TealDark4 = '#07312b';
export const TealDark5 = '#083932';
export const TealDark6 = '#09443c';
export const TealDark7 = '#0b544a';
export const TealDark8 = '#0c6d62';
export const TealDark9 = '#12a594';
export const TealDark10 = '#10b3a3';
export const TealDark11 = '#0ac5b3';
export const TealDark12 = '#e1faf4';
export const TealLight1 = '#fafefd';
export const TealLight2 = '#f1fcfa';
export const TealLight3 = '#e7f9f5';
export const TealLight4 = '#d9f3ee';
export const TealLight5 = '#c7ebe5';
export const TealLight6 = '#afdfd7';
export const TealLight7 = '#8dcec3';
export const TealLight8 = '#53b9ab';
export const TealLight9 = '#12a594';
export const TealLight10 = '#0e9888';
export const TealLight11 = '#067a6f';
export const TealLight12 = '#10302b';
export const TealDarkAlpha1 = '#00000000';
export const TealDarkAlpha2 = '#00fbd508';
export const TealDarkAlpha3 = '#00fddc12';
export const TealDarkAlpha4 = '#00fde81a';
export const TealDarkAlpha5 = '#02fee424';
export const TealDarkAlpha6 = '#09ffe630';
export const TealDarkAlpha7 = '#11ffe342';
export const TealDarkAlpha8 = '#11ffe75e';
export const TealDarkAlpha9 = '#18ffe49c';
export const TealDarkAlpha10 = '#13ffe7ab';
export const TealDarkAlpha11 = '#0affe7bf';
export const TealDarkAlpha12 = '#e6fff9fa';
export const TealLightAlpha1 = '#05cd9b05';
export const TealLightAlpha2 = '#01c8a40d';
export const TealLightAlpha3 = '#02c09717';
export const TealLightAlpha4 = '#02af8c26';
export const TealLightAlpha5 = '#00a48938';
export const TealLightAlpha6 = '#0099804f';
export const TealLightAlpha7 = '#01927a73';
export const TealLightAlpha8 = '#009783ad';
export const TealLightAlpha9 = '#009e8ced';
export const TealLightAlpha10 = '#009281f2';
export const TealLightAlpha11 = '#00776bfa';
export const TealLightAlpha12 = '#00221df0';
export const GreenDark1 = '#0d1912';
export const GreenDark2 = '#0c1f17';
export const GreenDark3 = '#0f291e';
export const GreenDark4 = '#113123';
export const GreenDark5 = '#133929';
export const GreenDark6 = '#164430';
export const GreenDark7 = '#1b543a';
export const GreenDark8 = '#236e4a';
export const GreenDark9 = '#30a46c';
export const GreenDark10 = '#3cb179';
export const GreenDark11 = '#4cc38a';
export const GreenDark12 = '#e5fbeb';
export const GreenLight1 = '#fbfefc';
export const GreenLight2 = '#f2fcf5';
export const GreenLight3 = '#e9f9ee';
export const GreenLight4 = '#ddf3e4';
export const GreenLight5 = '#ccebd7';
export const GreenLight6 = '#b4dfc4';
export const GreenLight7 = '#92ceac';
export const GreenLight8 = '#5bb98c';
export const GreenLight9 = '#30a46c';
export const GreenLight10 = '#299764';
export const GreenLight11 = '#18794e';
export const GreenLight12 = '#153226';
export const GreenDarkAlpha1 = '#00000000';
export const GreenDarkAlpha2 = '#00f7ca08';
export const GreenDarkAlpha3 = '#2afebe12';
export const GreenDarkAlpha4 = '#33feb31a';
export const GreenDarkAlpha5 = '#38feb524';
export const GreenDarkAlpha6 = '#3dffb130';
export const GreenDarkAlpha7 = '#43ffad42';
export const GreenDarkAlpha8 = '#49ffaa5e';
export const GreenDarkAlpha9 = '#47ffa69c';
export const GreenDarkAlpha10 = '#54ffafa8';
export const GreenDarkAlpha11 = '#62ffb3bd';
export const GreenDarkAlpha12 = '#eafff0fa';
export const GreenLightAlpha1 = '#05c04305';
export const GreenLightAlpha2 = '#00c43b0d';
export const GreenLightAlpha3 = '#02ba3c17';
export const GreenLightAlpha4 = '#01a63521';
export const GreenLightAlpha5 = '#009b3633';
export const GreenLightAlpha6 = '#0193364a';
export const GreenLightAlpha7 = '#008c3d6e';
export const GreenLightAlpha8 = '#00934ca3';
export const GreenLightAlpha9 = '#008f4acf';
export const GreenLightAlpha10 = '#008346d6';
export const GreenLightAlpha11 = '#006b3be8';
export const GreenLightAlpha12 = '#002012eb';
export const GrassDark1 = '#0d1912';
export const GrassDark2 = '#0f1e13';
export const GrassDark3 = '#132819';
export const GrassDark4 = '#16301d';
export const GrassDark5 = '#193921';
export const GrassDark6 = '#1d4427';
export const GrassDark7 = '#245530';
export const GrassDark8 = '#2f6e3b';
export const GrassDark9 = '#46a758';
export const GrassDark10 = '#55b467';
export const GrassDark11 = '#63c174';
export const GrassDark12 = '#e5fbeb';
export const GrassLight1 = '#fbfefb';
export const GrassLight2 = '#f3fcf3';
export const GrassLight3 = '#ebf9eb';
export const GrassLight4 = '#dff3df';
export const GrassLight5 = '#ceebcf';
export const GrassLight6 = '#b7dfba';
export const GrassLight7 = '#97cf9c';
export const GrassLight8 = '#65ba75';
export const GrassLight9 = '#46a758';
export const GrassLight10 = '#3d9a50';
export const GrassLight11 = '#297c3b';
export const GrassLight12 = '#1b311e';
export const GrassDarkAlpha1 = '#00000000';
export const GrassDarkAlpha2 = '#68fc3f05';
export const GrassDarkAlpha3 = '#68fc7b12';
export const GrassDarkAlpha4 = '#67ff801a';
export const GrassDarkAlpha5 = '#63fe7d24';
export const GrassDarkAlpha6 = '#63ff8230';
export const GrassDarkAlpha7 = '#65ff8442';
export const GrassDarkAlpha8 = '#69ff825e';
export const GrassDarkAlpha9 = '#69ff829e';
export const GrassDarkAlpha10 = '#78ff91ab';
export const GrassDarkAlpha11 = '#83ff97ba';
export const GrassDarkAlpha12 = '#eafff0fa';
export const GrassLightAlpha1 = '#05c00505';
export const GrassLightAlpha2 = '#05c0050d';
export const GrassLightAlpha3 = '#02b30214';
export const GrassLightAlpha4 = '#01a00121';
export const GrassLightAlpha5 = '#01970630';
export const GrassLightAlpha6 = '#018e0c47';
export const GrassLightAlpha7 = '#008a0c69';
export const GrassLightAlpha8 = '#008d1a99';
export const GrassLightAlpha9 = '#008619ba';
export const GrassLightAlpha10 = '#007a19c2';
export const GrassLightAlpha11 = '#006316d6';
export const GrassLightAlpha12 = '#001904e3';
export const OrangeDark1 = '#1f1206';
export const OrangeDark2 = '#2b1400';
export const OrangeDark3 = '#391a03';
export const OrangeDark4 = '#441f04';
export const OrangeDark5 = '#4f2305';
export const OrangeDark6 = '#5f2a06';
export const OrangeDark7 = '#763205';
export const OrangeDark8 = '#943e00';
export const OrangeDark9 = '#f76808';
export const OrangeDark10 = '#ff802b';
export const OrangeDark11 = '#ff8b3e';
export const OrangeDark12 = '#feeadd';
export const OrangeLight1 = '#fefcfb';
export const OrangeLight2 = '#fef8f4';
export const OrangeLight3 = '#fff1e7';
export const OrangeLight4 = '#ffe8d7';
export const OrangeLight5 = '#ffdcc3';
export const OrangeLight6 = '#ffcca7';
export const OrangeLight7 = '#ffb381';
export const OrangeLight8 = '#fa934e';
export const OrangeLight9 = '#f76808';
export const OrangeLight10 = '#ed5f00';
export const OrangeLight11 = '#bd4b00';
export const OrangeLight12 = '#451e11';
export const OrangeDarkAlpha1 = '#00000000';
export const OrangeDarkAlpha2 = '#fd37000d';
export const OrangeDarkAlpha3 = '#fd54001f';
export const OrangeDarkAlpha4 = '#fe61002b';
export const OrangeDarkAlpha5 = '#fe620138';
export const OrangeDarkAlpha6 = '#ff65064a';
export const OrangeDarkAlpha7 = '#ff640363';
export const OrangeDarkAlpha8 = '#fe660085';
export const OrangeDarkAlpha9 = '#ff6b08f5';
export const OrangeDarkAlpha10 = '#ff842cfa';
export const OrangeDarkAlpha11 = '#ff8c3ffa';
export const OrangeDarkAlpha12 = '#ffeee1fa';
export const OrangeLightAlpha1 = '#c0430505';
export const OrangeLightAlpha2 = '#e860050a';
export const OrangeLightAlpha3 = '#ff6c0317';
export const OrangeLightAlpha4 = '#ff6e0029';
export const OrangeLightAlpha5 = '#ff6b013d';
export const OrangeLightAlpha6 = '#ff6b0159';
export const OrangeLightAlpha7 = '#ff660180';
export const OrangeLightAlpha8 = '#f86300b0';
export const OrangeLightAlpha9 = '#f76300f7';
export const OrangeLightAlpha10 = '#ed5b00fa';
export const OrangeLightAlpha11 = '#bc4800fa';
export const OrangeLightAlpha12 = '#380e00ed';
export const BrownDark1 = '#191513';
export const BrownDark2 = '#221813';
export const BrownDark3 = '#2e201a';
export const BrownDark4 = '#36261e';
export const BrownDark5 = '#3e2c22';
export const BrownDark6 = '#493528';
export const BrownDark7 = '#5c4332';
export const BrownDark8 = '#775940';
export const BrownDark9 = '#ad7f58';
export const BrownDark10 = '#bd8b60';
export const BrownDark11 = '#dba16e';
export const BrownDark12 = '#faf0e5';
export const BrownLight1 = '#fefdfc';
export const BrownLight2 = '#fcf9f6';
export const BrownLight3 = '#f8f1ea';
export const BrownLight4 = '#f4e9dd';
export const BrownLight5 = '#efddcc';
export const BrownLight6 = '#e8cdb5';
export const BrownLight7 = '#ddb896';
export const BrownLight8 = '#d09e72';
export const BrownLight9 = '#ad7f58';
export const BrownLight10 = '#a07653';
export const BrownLight11 = '#886349';
export const BrownLight12 = '#3f2c22';
export const BrownDarkAlpha1 = '#00000000';
export const BrownDarkAlpha2 = '#ff69130a';
export const BrownDarkAlpha3 = '#fd916317';
export const BrownDarkAlpha4 = '#fe9f6c1f';
export const BrownDarkAlpha5 = '#feac7229';
export const BrownDarkAlpha6 = '#feb07936';
export const BrownDarkAlpha7 = '#feb47e4a';
export const BrownDarkAlpha8 = '#febc8269';
export const BrownDarkAlpha9 = '#ffba7ea3';
export const BrownDarkAlpha10 = '#ffbb7fb5';
export const BrownDarkAlpha11 = '#ffbb7fd6';
export const BrownDarkAlpha12 = '#fff5e9fa';
export const BrownLightAlpha1 = '#ab580503';
export const BrownLightAlpha2 = '#ab58050a';
export const BrownLightAlpha3 = '#ab560214';
export const BrownLightAlpha4 = '#ad5a0121';
export const BrownLightAlpha5 = '#af550033';
export const BrownLightAlpha6 = '#b052014a';
export const BrownLightAlpha7 = '#ac530069';
export const BrownLightAlpha8 = '#aa4f008c';
export const BrownLightAlpha9 = '#823d00a6';
export const BrownLightAlpha10 = '#723300ad';
export const BrownLightAlpha11 = '#582500b5';
export const BrownLightAlpha12 = '#220c00de';
export const SkyDark1 = '#0c1820';
export const SkyDark2 = '#071d2a';
export const SkyDark3 = '#082636';
export const SkyDark4 = '#082d41';
export const SkyDark5 = '#08354c';
export const SkyDark6 = '#083e59';
export const SkyDark7 = '#064b6b';
export const SkyDark8 = '#005d85';
export const SkyDark9 = '#68ddfd';
export const SkyDark10 = '#8ae8ff';
export const SkyDark11 = '#2ec8ee';
export const SkyDark12 = '#eaf8ff';
export const SkyLight1 = '#f9feff';
export const SkyLight2 = '#f1fcff';
export const SkyLight3 = '#e4f9ff';
export const SkyLight4 = '#d5f4fd';
export const SkyLight5 = '#c1ecf9';
export const SkyLight6 = '#a4dff1';
export const SkyLight7 = '#79cfea';
export const SkyLight8 = '#2ebde5';
export const SkyLight9 = '#68ddfd';
export const SkyLight10 = '#5fd4f4';
export const SkyLight11 = '#0078a1';
export const SkyLight12 = '#003242';
export const SkyDarkAlpha1 = '#00000000';
export const SkyDarkAlpha2 = '#0087fe0d';
export const SkyDarkAlpha3 = '#00a5fe1a';
export const SkyDarkAlpha4 = '#00a6ff26';
export const SkyDarkAlpha5 = '#00a9fe33';
export const SkyDarkAlpha6 = '#00aefe42';
export const SkyDarkAlpha7 = '#00aefe57';
export const SkyDarkAlpha8 = '#00aeff73';
export const SkyDarkAlpha9 = '#6ae1fffa';
export const SkyDarkAlpha10 = '#8decfffa';
export const SkyDarkAlpha11 = '#31d6ffeb';
export const SkyDarkAlpha12 = '#effdfffa';
export const SkyLightAlpha1 = '#05d5ff05';
export const SkyLightAlpha2 = '#01c8ff0d';
export const SkyLightAlpha3 = '#01c8ff1c';
export const SkyLightAlpha4 = '#00baf32b';
export const SkyLightAlpha5 = '#01b1e73d';
export const SkyLightAlpha6 = '#00a5d85c';
export const SkyLightAlpha7 = '#00a5d787';
export const SkyLightAlpha8 = '#00afdfd1';
export const SkyLightAlpha9 = '#00c5fc96';
export const SkyLightAlpha10 = '#00baeda1';
export const SkyLightAlpha11 = '#00759ffa';
export const SkyLightAlpha12 = '#002e3efa';
export const MintDark1 = '#081917';
export const MintDark2 = '#05201e';
export const MintDark3 = '#052926';
export const MintDark4 = '#04312c';
export const MintDark5 = '#033a34';
export const MintDark6 = '#01453d';
export const MintDark7 = '#00564a';
export const MintDark8 = '#006d5b';
export const MintDark9 = '#70e1c8';
export const MintDark10 = '#95f3d9';
export const MintDark11 = '#25d0ab';
export const MintDark12 = '#e7fcf7';
export const MintLight1 = '#f9fefd';
export const MintLight2 = '#effefa';
export const MintLight3 = '#e1fbf4';
export const MintLight4 = '#d2f7ed';
export const MintLight5 = '#c0efe3';
export const MintLight6 = '#a5e4d4';
export const MintLight7 = '#7dd4c0';
export const MintLight8 = '#40c4aa';
export const MintLight9 = '#70e1c8';
export const MintLight10 = '#69d9c1';
export const MintLight11 = '#147d6f';
export const MintLight12 = '#09342e';
export const MintDarkAlpha1 = '#00000000';
export const MintDarkAlpha2 = '#00fbfb08';
export const MintDarkAlpha3 = '#00fded12';
export const MintDarkAlpha4 = '#00fde01a';
export const MintDarkAlpha5 = '#00fee024';
export const MintDarkAlpha6 = '#00fedc30';
export const MintDarkAlpha7 = '#00fed845';
export const MintDarkAlpha8 = '#00fed05e';
export const MintDarkAlpha9 = '#80ffe3de';
export const MintDarkAlpha10 = '#9dffe3f2';
export const MintDarkAlpha11 = '#2cffd1cc';
export const MintDarkAlpha12 = '#ecfffbfa';
export const MintLightAlpha1 = '#05d5ac05';
export const MintLightAlpha2 = '#01efb00f';
export const MintLightAlpha3 = '#01dda21f';
export const MintLightAlpha4 = '#01d29a2e';
export const MintLightAlpha5 = '#01be8f40';
export const MintLightAlpha6 = '#00b38659';
export const MintLightAlpha7 = '#00ab8382';
export const MintLightAlpha8 = '#00b08dbf';
export const MintLightAlpha9 = '#00c99e8f';
export const MintLightAlpha10 = '#00be9596';
export const MintLightAlpha11 = '#007263eb';
export const MintLightAlpha12 = '#002d27f5';
export const LimeDark1 = '#141807';
export const LimeDark2 = '#181d08';
export const LimeDark3 = '#1e260d';
export const LimeDark4 = '#252e0f';
export const LimeDark5 = '#2b3711';
export const LimeDark6 = '#344213';
export const LimeDark7 = '#415215';
export const LimeDark8 = '#536716';
export const LimeDark9 = '#99d52a';
export const LimeDark10 = '#c4f042';
export const LimeDark11 = '#87be22';
export const LimeDark12 = '#effbdd';
export const LimeLight1 = '#fcfdfa';
export const LimeLight2 = '#f7fcf0';
export const LimeLight3 = '#eefadc';
export const LimeLight4 = '#e4f7c7';
export const LimeLight5 = '#d7f2b0';
export const LimeLight6 = '#c9e894';
export const LimeLight7 = '#b1d16a';
export const LimeLight8 = '#94ba2c';
export const LimeLight9 = '#99d52a';
export const LimeLight10 = '#93c926';
export const LimeLight11 = '#5d770d';
export const LimeLight12 = '#263209';
export const LimeDarkAlpha1 = '#00000000';
export const LimeDarkAlpha2 = '#cafb3505';
export const LimeDarkAlpha3 = '#b8fd6a0f';
export const LimeDarkAlpha4 = '#c4fd5b1a';
export const LimeDarkAlpha5 = '#befe5124';
export const LimeDarkAlpha6 = '#c5ff492e';
export const LimeDarkAlpha7 = '#c8fe3f40';
export const LimeDarkAlpha8 = '#ccff3357';
export const LimeDarkAlpha9 = '#b7ff32d1';
export const LimeDarkAlpha10 = '#d1ff46f0';
export const LimeDarkAlpha11 = '#b5ff2cb8';
export const LimeDarkAlpha12 = '#f4ffe1fa';
export const LimeLightAlpha1 = '#699b0505';
export const LimeLightAlpha2 = '#77cc010f';
export const LimeLightAlpha3 = '#84db0124';
export const LimeLightAlpha4 = '#83db0038';
export const LimeLightAlpha5 = '#7cd5004f';
export const LimeLightAlpha6 = '#7fc8006b';
export const LimeLightAlpha7 = '#78b00094';
export const LimeLightAlpha8 = '#7eac00d4';
export const LimeLightAlpha9 = '#85cd00d6';
export const LimeLightAlpha10 = '#80c000d9';
export const LimeLightAlpha11 = '#547000f2';
export const LimeLightAlpha12 = '#1e2b00f5';
export const YellowDark1 = '#1c1500';
export const YellowDark2 = '#221a00';
export const YellowDark3 = '#2c2100';
export const YellowDark4 = '#352800';
export const YellowDark5 = '#3e3000';
export const YellowDark6 = '#493c00';
export const YellowDark7 = '#594a05';
export const YellowDark8 = '#705e00';
export const YellowDark9 = '#f5d90a';
export const YellowDark10 = '#ffef5c';
export const YellowDark11 = '#f0c000';
export const YellowDark12 = '#fffad1';
export const YellowLight1 = '#fdfdf9';
export const YellowLight2 = '#fffce8';
export const YellowLight3 = '#fffbd1';
export const YellowLight4 = '#fff8bb';
export const YellowLight5 = '#fef2a4';
export const YellowLight6 = '#f9e68c';
export const YellowLight7 = '#efd36c';
export const YellowLight8 = '#ebbc00';
export const YellowLight9 = '#f5d90a';
export const YellowLight10 = '#f7ce00';
export const YellowLight11 = '#946800';
export const YellowLight12 = '#35290f';
export const YellowDarkAlpha1 = '#00000000';
export const YellowDarkAlpha2 = '#facd0008';
export const YellowDarkAlpha3 = '#fdbe0012';
export const YellowDarkAlpha4 = '#fdc2001c';
export const YellowDarkAlpha5 = '#fec70026';
export const YellowDarkAlpha6 = '#fed80033';
export const YellowDarkAlpha7 = '#ffdb1345';
export const YellowDarkAlpha8 = '#fed8005e';
export const YellowDarkAlpha9 = '#ffe20af5';
export const YellowDarkAlpha10 = '#fff45efa';
export const YellowDarkAlpha11 = '#ffcc00ed';
export const YellowDarkAlpha12 = '#ffffd5fa';
export const YellowLightAlpha1 = '#abab0505';
export const YellowLightAlpha2 = '#ffdd0217';
export const YellowLightAlpha3 = '#ffea012e';
export const YellowLightAlpha4 = '#ffe60145';
export const YellowLightAlpha5 = '#fcdb005c';
export const YellowLightAlpha6 = '#f2c90073';
export const YellowLightAlpha7 = '#e3b20094';
export const YellowLightAlpha8 = '#ebbc00fa';
export const YellowLightAlpha9 = '#f5d800f5';
export const YellowLightAlpha10 = '#f7ce00fa';
export const YellowLightAlpha11 = '#926600fa';
export const YellowLightAlpha12 = '#291c00f0';
export const AmberDark1 = '#1f1300';
export const AmberDark2 = '#271700';
export const AmberDark3 = '#341c00';
export const AmberDark4 = '#3f2200';
export const AmberDark5 = '#4a2900';
export const AmberDark6 = '#573300';
export const AmberDark7 = '#693f05';
export const AmberDark8 = '#824e00';
export const AmberDark9 = '#ffb224';
export const AmberDark10 = '#ffcb47';
export const AmberDark11 = '#f1a10d';
export const AmberDark12 = '#fef3dd';
export const AmberLight1 = '#fefdfb';
export const AmberLight2 = '#fff9ed';
export const AmberLight3 = '#fff4d5';
export const AmberLight4 = '#ffecbc';
export const AmberLight5 = '#ffe3a2';
export const AmberLight6 = '#ffd386';
export const AmberLight7 = '#f3ba63';
export const AmberLight8 = '#ee9d2b';
export const AmberLight9 = '#ffb224';
export const AmberLight10 = '#ffa01c';
export const AmberLight11 = '#ad5700';
export const AmberLight12 = '#4e2009';
export const AmberDarkAlpha1 = '#00000000';
export const AmberDarkAlpha2 = '#fd83000a';
export const AmberDarkAlpha3 = '#fe730017';
export const AmberDarkAlpha4 = '#ff7b0024';
export const AmberDarkAlpha5 = '#ff840030';
export const AmberDarkAlpha6 = '#ff950040';
export const AmberDarkAlpha7 = '#ff970f54';
export const AmberDarkAlpha8 = '#ff990070';
export const AmberDarkAlpha9 = '#ffb625fa';
export const AmberDarkAlpha10 = '#ffce48fa';
export const AmberDarkAlpha11 = '#ffab0ef0';
export const AmberDarkAlpha12 = '#fff8e1fa';
export const AmberLightAlpha1 = '#c0820505';
export const AmberLightAlpha2 = '#ffab0212';
export const AmberLightAlpha3 = '#ffbb012b';
export const AmberLightAlpha4 = '#ffb70042';
export const AmberLightAlpha5 = '#ffb3005e';
export const AmberLightAlpha6 = '#ffa20178';
export const AmberLightAlpha7 = '#ec8d009c';
export const AmberLightAlpha8 = '#ea8900d4';
export const AmberLightAlpha9 = '#ffa600db';
export const AmberLightAlpha10 = '#ff9500e3';
export const AmberLightAlpha11 = '#ab5300fa';
export const AmberLightAlpha12 = '#481800f5';
export const GrayDark1 = '#161616';
export const GrayDark2 = '#1c1c1c';
export const GrayDark3 = '#232323';
export const GrayDark4 = '#282828';
export const GrayDark5 = '#2e2e2e';
export const GrayDark6 = '#343434';
export const GrayDark7 = '#3e3e3e';
export const GrayDark8 = '#505050';
export const GrayDark9 = '#707070';
export const GrayDark10 = '#7e7e7e';
export const GrayDark11 = '#a0a0a0';
export const GrayDark12 = '#ededed';
export const GrayLight1 = '#fcfcfc';
export const GrayLight2 = '#f8f8f8';
export const GrayLight3 = '#f3f3f3';
export const GrayLight4 = '#ededed';
export const GrayLight5 = '#e8e8e8';
export const GrayLight6 = '#e2e2e2';
export const GrayLight7 = '#dbdbdb';
export const GrayLight8 = '#c7c7c7';
export const GrayLight9 = '#8f8f8f';
export const GrayLight10 = '#858585';
export const GrayLight11 = '#6f6f6f';
export const GrayLight12 = '#171717';
export const GrayDarkAlpha1 = '#00000000';
export const GrayDarkAlpha2 = '#ffffff08';
export const GrayDarkAlpha3 = '#ffffff0f';
export const GrayDarkAlpha4 = '#ffffff14';
export const GrayDarkAlpha5 = '#ffffff1a';
export const GrayDarkAlpha6 = '#ffffff21';
export const GrayDarkAlpha7 = '#ffffff2b';
export const GrayDarkAlpha8 = '#ffffff40';
export const GrayDarkAlpha9 = '#ffffff63';
export const GrayDarkAlpha10 = '#ffffff73';
export const GrayDarkAlpha11 = '#ffffff96';
export const GrayDarkAlpha12 = '#ffffffeb';
export const GrayLightAlpha1 = '#00000003';
export const GrayLightAlpha2 = '#00000008';
export const GrayLightAlpha3 = '#0000000d';
export const GrayLightAlpha4 = '#00000012';
export const GrayLightAlpha5 = '#00000017';
export const GrayLightAlpha6 = '#0000001c';
export const GrayLightAlpha7 = '#00000024';
export const GrayLightAlpha8 = '#00000038';
export const GrayLightAlpha9 = '#00000070';
export const GrayLightAlpha10 = '#0000007a';
export const GrayLightAlpha11 = '#0000008f';
export const GrayLightAlpha12 = '#000000e8';
export const MauveDark1 = '#161618';
export const MauveDark2 = '#1c1c1f';
export const MauveDark3 = '#232326';
export const MauveDark4 = '#28282c';
export const MauveDark5 = '#2e2e32';
export const MauveDark6 = '#34343a';
export const MauveDark7 = '#3e3e44';
export const MauveDark8 = '#504f57';
export const MauveDark9 = '#706f78';
export const MauveDark10 = '#7e7d86';
export const MauveDark11 = '#a09fa6';
export const MauveDark12 = '#ededef';
export const MauveLight1 = '#fdfcfd';
export const MauveLight2 = '#f9f8f9';
export const MauveLight3 = '#f4f2f4';
export const MauveLight4 = '#eeedef';
export const MauveLight5 = '#e9e8ea';
export const MauveLight6 = '#e4e2e4';
export const MauveLight7 = '#dcdbdd';
export const MauveLight8 = '#c8c7cb';
export const MauveLight9 = '#908e96';
export const MauveLight10 = '#86848d';
export const MauveLight11 = '#6f6e77';
export const MauveLight12 = '#1a1523';
export const MauveDarkAlpha1 = '#00000000';
export const MauveDarkAlpha2 = '#d7d7fa08';
export const MauveDarkAlpha3 = '#ebebfe0f';
export const MauveDarkAlpha4 = '#e5e5fe17';
export const MauveDarkAlpha5 = '#eaeafe1c';
export const MauveDarkAlpha6 = '#e1e1fe26';
export const MauveDarkAlpha7 = '#e8e8fe30';
export const MauveDarkAlpha8 = '#eae7ff45';
export const MauveDarkAlpha9 = '#eeecff6b';
export const MauveDarkAlpha10 = '#f0eeff7a';
export const MauveDarkAlpha11 = '#f7f5ff9e';
export const MauveDarkAlpha12 = '#fdfdffed';
export const MauveLightAlpha1 = '#58055803';
export const MauveLightAlpha2 = '#29052908';
export const MauveLightAlpha3 = '#2700270d';
export const MauveLightAlpha4 = '#10011e12';
export const MauveLightAlpha5 = '#0d021817';
export const MauveLightAlpha6 = '#1201121c';
export const MauveLightAlpha7 = '#08010f24';
export const MauveLightAlpha8 = '#05001238';
export const MauveLightAlpha9 = '#05001270';
export const MauveLightAlpha10 = '#0400137a';
export const MauveLightAlpha11 = '#02001091';
export const MauveLightAlpha12 = '#05000feb';
export const SlateDark1 = '#151718';
export const SlateDark2 = '#1a1d1e';
export const SlateDark3 = '#202425';
export const SlateDark4 = '#26292b';
export const SlateDark5 = '#2b2f31';
export const SlateDark6 = '#313538';
export const SlateDark7 = '#3a3f42';
export const SlateDark8 = '#4c5155';
export const SlateDark9 = '#697177';
export const SlateDark10 = '#787f85';
export const SlateDark11 = '#9ba1a6';
export const SlateDark12 = '#ecedee';
export const SlateLight1 = '#fbfcfd';
export const SlateLight2 = '#f8f9fa';
export const SlateLight3 = '#f1f3f5';
export const SlateLight4 = '#eceef0';
export const SlateLight5 = '#e6e8eb';
export const SlateLight6 = '#dfe3e6';
export const SlateLight7 = '#d7dbdf';
export const SlateLight8 = '#c1c8cd';
export const SlateLight9 = '#889096';
export const SlateLight10 = '#7e868c';
export const SlateLight11 = '#687076';
export const SlateLight12 = '#11181c';
export const SlateDarkAlpha1 = '#00000000';
export const SlateDarkAlpha2 = '#d5feff08';
export const SlateDarkAlpha3 = '#d6fbfc0f';
export const SlateDarkAlpha4 = '#e2f0fd14';
export const SlateDarkAlpha5 = '#dff3fd1c';
export const SlateDarkAlpha6 = '#dfeffe24';
export const SlateDarkAlpha7 = '#e0f3ff2e';
export const SlateDarkAlpha8 = '#e5f2fe42';
export const SlateDarkAlpha9 = '#e1f1ff69';
export const SlateDarkAlpha10 = '#e7f3ff78';
export const SlateDarkAlpha11 = '#eff7ff9e';
export const SlateDarkAlpha12 = '#fdfeffed';
export const SlateLightAlpha1 = '#05448205';
export const SlateLightAlpha2 = '#05294d08';
export const SlateLightAlpha3 = '#0025490d';
export const SlateLightAlpha4 = '#021c3714';
export const SlateLightAlpha5 = '#0217351a';
export const SlateLightAlpha6 = '#01213921';
export const SlateLightAlpha7 = '#001a3329';
export const SlateLightAlpha8 = '#011e323d';
export const SlateLightAlpha9 = '#00111e78';
export const SlateLightAlpha10 = '#00101b82';
export const SlateLightAlpha11 = '#000e1896';
export const SlateLightAlpha12 = '#00080ced';
export const SageDark1 = '#141716';
export const SageDark2 = '#191d1b';
export const SageDark3 = '#1f2421';
export const SageDark4 = '#252a27';
export const SageDark5 = '#2a2f2c';
export const SageDark6 = '#303633';
export const SageDark7 = '#393f3c';
export const SageDark8 = '#4a524e';
export const SageDark9 = '#66736d';
export const SageDark10 = '#75817b';
export const SageDark11 = '#99a29e';
export const SageDark12 = '#eceeed';
export const SageLight1 = '#fbfdfc';
export const SageLight2 = '#f8faf9';
export const SageLight3 = '#f1f4f3';
export const SageLight4 = '#ecefed';
export const SageLight5 = '#e6e9e8';
export const SageLight6 = '#dfe4e2';
export const SageLight7 = '#d7dcda';
export const SageLight8 = '#c2c9c6';
export const SageLight9 = '#8a918e';
export const SageLight10 = '#808784';
export const SageLight11 = '#6a716e';
export const SageLight12 = '#111c18';
export const SageDarkAlpha1 = '#00000000';
export const SageDarkAlpha2 = '#d4fed608';
export const SageDarkAlpha3 = '#d5fbd70f';
export const SageDarkAlpha4 = '#e3ffe514';
export const SageDarkAlpha5 = '#e8feea1a';
export const SageDarkAlpha6 = '#e5feee21';
export const SageDarkAlpha7 = '#eafef22b';
export const SageDarkAlpha8 = '#e8fef240';
export const SageDarkAlpha9 = '#e3fff166';
export const SageDarkAlpha10 = '#e8fff375';
export const SageDarkAlpha11 = '#f2fff999';
export const SageDarkAlpha12 = '#fdfffeed';
export const SageLightAlpha1 = '#05824405';
export const SageLightAlpha2 = '#054d2908';
export const SageLightAlpha3 = '#0037250d';
export const SageLightAlpha4 = '#022a0f14';
export const SageLightAlpha5 = '#0221171a';
export const SageLightAlpha6 = '#01291921';
export const SageLightAlpha7 = '#00201329';
export const SageLightAlpha8 = '#011e113d';
export const SageLightAlpha9 = '#000f0975';
export const SageLightAlpha10 = '#000e0880';
export const SageLightAlpha11 = '#000c0794';
export const SageLightAlpha12 = '#000c08ed';
export const OliveDark1 = '#151715';
export const OliveDark2 = '#1a1d19';
export const OliveDark3 = '#20241f';
export const OliveDark4 = '#262925';
export const OliveDark5 = '#2b2f2a';
export const OliveDark6 = '#313530';
export const OliveDark7 = '#3b3f3a';
export const OliveDark8 = '#4c514b';
export const OliveDark9 = '#687366';
export const OliveDark10 = '#778175';
export const OliveDark11 = '#9aa299';
export const OliveDark12 = '#eceeec';
export const OliveLight1 = '#fcfdfc';
export const OliveLight2 = '#f8faf8';
export const OliveLight3 = '#f2f4f2';
export const OliveLight4 = '#ecefec';
export const OliveLight5 = '#e6e9e6';
export const OliveLight6 = '#e0e4e0';
export const OliveLight7 = '#d8dcd8';
export const OliveLight8 = '#c3c8c2';
export const OliveLight9 = '#8b918a';
export const OliveLight10 = '#818780';
export const OliveLight11 = '#6b716a';
export const OliveLight12 = '#141e12';
export const OliveDarkAlpha1 = '#00000000';
export const OliveDarkAlpha2 = '#d5feaf08';
export const OliveDarkAlpha3 = '#d6fbc40f';
export const OliveDarkAlpha4 = '#effee214';
export const OliveDarkAlpha5 = '#e9fedf1a';
export const OliveDarkAlpha6 = '#ecfee521';
export const OliveDarkAlpha7 = '#f1feeb2b';
export const OliveDarkAlpha8 = '#f1ffed40';
export const OliveDarkAlpha9 = '#e6ffe166';
export const OliveDarkAlpha10 = '#ebffe775';
export const OliveDarkAlpha11 = '#f3fff199';
export const OliveDarkAlpha12 = '#fdfffded';
export const OliveLightAlpha1 = '#05580503';
export const OliveLightAlpha2 = '#054d0508';
export const OliveLightAlpha3 = '#0027000d';
export const OliveLightAlpha4 = '#022a0214';
export const OliveLightAlpha5 = '#0221021a';
export const OliveLightAlpha6 = '#0122011f';
export const OliveLightAlpha7 = '#001a0026';
export const OliveLightAlpha8 = '#051a013d';
export const OliveLightAlpha9 = '#020f0075';
export const OliveLightAlpha10 = '#030e0080';
export const OliveLightAlpha11 = '#020c0094';
export const OliveLightAlpha12 = '#020d00ed';
export const SandDark1 = '#161615';
export const SandDark2 = '#1c1c1a';
export const SandDark3 = '#232320';
export const SandDark4 = '#282826';
export const SandDark5 = '#2e2e2b';
export const SandDark6 = '#353431';
export const SandDark7 = '#3e3e3a';
export const SandDark8 = '#51504b';
export const SandDark9 = '#717069';
export const SandDark10 = '#7f7e77';
export const SandDark11 = '#a1a09a';
export const SandDark12 = '#ededec';
export const SandLight1 = '#fdfdfc';
export const SandLight2 = '#f9f9f8';
export const SandLight3 = '#f3f3f2';
export const SandLight4 = '#eeeeec';
export const SandLight5 = '#e9e9e6';
export const SandLight6 = '#e3e3e0';
export const SandLight7 = '#dbdbd7';
export const SandLight8 = '#c8c7c1';
export const SandLight9 = '#90908c';
export const SandLight10 = '#868682';
export const SandLight11 = '#706f6c';
export const SandLight12 = '#1b1b18';
export const SandDarkAlpha1 = '#00000000';
export const SandDarkAlpha2 = '#fdfdd508';
export const SandDarkAlpha3 = '#fefeda0f';
export const SandDarkAlpha4 = '#fdfdef14';
export const SandDarkAlpha5 = '#fdfde91a';
export const SandDarkAlpha6 = '#fdf6e621';
export const SandDarkAlpha7 = '#ffffec2b';
export const SandDarkAlpha8 = '#fefbea40';
export const SandDarkAlpha9 = '#fffcec63';
export const SandDarkAlpha10 = '#fffdee73';
export const SandDarkAlpha11 = '#fffdf499';
export const SandDarkAlpha12 = '#fffffeeb';
export const SandLightAlpha1 = '#58580503';
export const SandLightAlpha2 = '#29290508';
export const SandLightAlpha3 = '#1414000d';
export const SandLightAlpha4 = '#1c1c0214';
export const SandLightAlpha5 = '#2121021a';
export const SandLightAlpha6 = '#1a1a011f';
export const SandLightAlpha7 = '#1a1a0029';
export const SandLightAlpha8 = '#1e19013d';
export const SandLightAlpha9 = '#09090073';
export const SandLightAlpha10 = '#0909007d';
export const SandLightAlpha11 = '#07060094';
export const SandLightAlpha12 = '#040400e8';
export const GoldDark1 = '#171613';
export const GoldDark2 = '#1c1a15';
export const GoldDark3 = '#26231c';
export const GoldDark4 = '#2e2a21';
export const GoldDark5 = '#353026';
export const GoldDark6 = '#3e382c';
export const GoldDark7 = '#504737';
export const GoldDark8 = '#6b5d48';
export const GoldDark9 = '#978365';
export const GoldDark10 = '#a59071';
export const GoldDark11 = '#bfa888';
export const GoldDark12 = '#f7f4e7';
export const GoldLight1 = '#fdfdfc';
export const GoldLight2 = '#fbf9f2';
export const GoldLight3 = '#f5f2e9';
export const GoldLight4 = '#eeeadd';
export const GoldLight5 = '#e5dfd0';
export const GoldLight6 = '#dad1bd';
export const GoldLight7 = '#cbbda4';
export const GoldLight8 = '#b8a383';
export const GoldLight9 = '#978365';
export const GoldLight10 = '#8c795d';
export const GoldLight11 = '#776750';
export const GoldLight12 = '#3b352b';
export const GoldDarkAlpha1 = '#00000000';
export const GoldDarkAlpha2 = '#facb6e05';
export const GoldDarkAlpha3 = '#fede9d0f';
export const GoldDarkAlpha4 = '#fdde9f1a';
export const GoldDarkAlpha5 = '#fedda521';
export const GoldDarkAlpha6 = '#fedfa72b';
export const GoldDarkAlpha7 = '#ffdda640';
export const GoldDarkAlpha8 = '#fed9a55c';
export const GoldDarkAlpha9 = '#ffdca78c';
export const GoldDarkAlpha10 = '#ffdcac9c';
export const GoldDarkAlpha11 = '#ffe0b4ba';
export const GoldDarkAlpha12 = '#fffceef7';
export const GoldLightAlpha1 = '#58580503';
export const GoldLightAlpha2 = '#b08a000d';
export const GoldLightAlpha3 = '#8c6a0217';
export const GoldLightAlpha4 = '#80630121';
export const GoldLightAlpha5 = '#72520130';
export const GoldLightAlpha6 = '#704d0042';
export const GoldLightAlpha7 = '#6e45005c';
export const GoldLightAlpha8 = '#6d42007d';
export const GoldLightAlpha9 = '#53320099';
export const GoldLightAlpha10 = '#4a2d00a3';
export const GoldLightAlpha11 = '#392100b0';
export const GoldLightAlpha12 = '#130c00d4';
export const BronzeDark1 = '#191514';
export const BronzeDark2 = '#1f1917';
export const BronzeDark3 = '#2a211f';
export const BronzeDark4 = '#332824';
export const BronzeDark5 = '#3b2e29';
export const BronzeDark6 = '#453530';
export const BronzeDark7 = '#57433c';
export const BronzeDark8 = '#74594e';
export const BronzeDark9 = '#a18072';
export const BronzeDark10 = '#b08c7d';
export const BronzeDark11 = '#cba393';
export const BronzeDark12 = '#f9ede7';
export const BronzeLight1 = '#fdfcfc';
export const BronzeLight2 = '#fdf8f6';
export const BronzeLight3 = '#f8f1ee';
export const BronzeLight4 = '#f2e8e4';
export const BronzeLight5 = '#eaddd7';
export const BronzeLight6 = '#e0cec7';
export const BronzeLight7 = '#d1b9b0';
export const BronzeLight8 = '#bfa094';
export const BronzeLight9 = '#a18072';
export const BronzeLight10 = '#977669';
export const BronzeLight11 = '#846358';
export const BronzeLight12 = '#43302b';
export const BronzeDarkAlpha1 = '#00000000';
export const BronzeDarkAlpha2 = '#f7aa8308';
export const BronzeDarkAlpha3 = '#ffb7a912';
export const BronzeDarkAlpha4 = '#fdbca01c';
export const BronzeDarkAlpha5 = '#ffbea226';
export const BronzeDarkAlpha6 = '#febca630';
export const BronzeDarkAlpha7 = '#ffbfa845';
export const BronzeDarkAlpha8 = '#ffc1a666';
export const BronzeDarkAlpha9 = '#ffcab396';
export const BronzeDarkAlpha10 = '#ffcab4a8';
export const BronzeDarkAlpha11 = '#ffccb8c4';
export const BronzeDarkAlpha12 = '#fff3edf7';
export const BronzeLightAlpha1 = '#58050503';
export const BronzeLightAlpha2 = '#c73c050a';
export const BronzeLightAlpha3 = '#972e0112';
export const BronzeLightAlpha4 = '#8426001c';
export const BronzeLightAlpha5 = '#79270029';
export const BronzeLightAlpha6 = '#72210038';
export const BronzeLightAlpha7 = '#6e21004f';
export const BronzeLightAlpha8 = '#671d006b';
export const BronzeLightAlpha9 = '#551a008c';
export const BronzeLightAlpha10 = '#4e160096';
export const BronzeLightAlpha11 = '#431100a6';
export const BronzeLightAlpha12 = '#1d0600d4';
export const OverlaysWhiteAlpha1 = '#00000000';
export const OverlaysWhiteAlpha2 = '#ffffff03';
export const OverlaysWhiteAlpha3 = '#ffffff08';
export const OverlaysWhiteAlpha4 = '#ffffff0f';
export const OverlaysWhiteAlpha5 = '#ffffff17';
export const OverlaysWhiteAlpha6 = '#ffffff1f';
export const OverlaysWhiteAlpha7 = '#ffffff2e';
export const OverlaysWhiteAlpha8 = '#ffffff40';
export const OverlaysWhiteAlpha9 = '#ffffff63';
export const OverlaysWhiteAlpha10 = '#ffffff73';
export const OverlaysWhiteAlpha11 = '#ffffff96';
export const OverlaysWhiteAlpha12 = '#ffffffeb';
export const OverlaysBlackAlpha1 = '#00000003';
export const OverlaysBlackAlpha2 = '#00000008';
export const OverlaysBlackAlpha3 = '#0000000d';
export const OverlaysBlackAlpha4 = '#00000012';
export const OverlaysBlackAlpha5 = '#00000017';
export const OverlaysBlackAlpha6 = '#0000001c';
export const OverlaysBlackAlpha7 = '#00000024';
export const OverlaysBlackAlpha8 = '#00000038';
export const OverlaysBlackAlpha9 = '#00000070';
export const OverlaysBlackAlpha10 = '#0000007a';
export const OverlaysBlackAlpha11 = '#0000008f';
export const OverlaysBlackAlpha12 = '#000000e8';
