import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Typography, useTheme } from '@mui/material';
import { GTMEvents } from 'src/lhu-toolkit/gtm/events';
import Styled from './SectionPromo.styles';
import Box from '../../../components/basics/Box';
import BaseIcon from '../../../components/icons/BaseIcon';
import Image from '../../../components/media/Image';
import SectionTitle from './SectionTitle';
import ButtonDiscordSVG from './ButtonDiscordSVG';
import DiscordSocialIcon from '../../../components/icons/DiscordSocialIcon';
import { nameSpace } from '../../../modules/i18n';

const Promo: FC = () => {
  const { t } = useTranslation(nameSpace.home);
  const theme = useTheme();
  return (
    <Styled.PromoSection>
      <Container>
        <Grid className="promo-wrapper" display="flex" flexWrap="wrap">
          <Grid item sm={8} padding="1rem">
            <SectionTitle header={t('home.promo.title')} isTitle padding="0" />
            <Box className="animated delay-2" margin="0">
              <Typography display="flex" variant="h4" color={theme.custom.colors.Yellow}>
                <BaseIcon fill={theme.custom.colors.Yellow} icon={<DiscordSocialIcon />} height="30px" />
                {t('home.promo.content')}
              </Typography>
              <Typography display="flex" variant="h4" color={theme.custom.colors.Yellow}>
                <BaseIcon fill={theme.custom.colors.Yellow} icon={<DiscordSocialIcon />} height="30px" />
                {t('home.promo.content2')}
              </Typography>
              <ButtonDiscordSVG
                type="button"
                title={t('home.hero.community')}
                color="#000"
                bgcolor="#FFF"
                bgcolor2="gray"
                hoverEffect
                onClick={() => {
                  GTMEvents.clickBtnDiscord({ eventContext: 'home-cta' });
                  window.open(t('home.hero.discord'), '_blank');
                }}
              />
            </Box>
          </Grid>
          <Grid className="animated delay-3 img-wrapper" item sm={4} padding="0 1rem">
            <Image source={`/assets/${t('home.promo.asset')}`} alt={t('home.promo.title')} />
          </Grid>
        </Grid>
      </Container>
    </Styled.PromoSection>
  );
};

export default Promo;
