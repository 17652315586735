import { CSSProperties } from 'react';
import { styled } from '@mui/material';
import BaseIcon from '../../../../components/icons/BaseIcon';

export const Root = styled('div')<{ padding: CSSProperties['padding'] }>`
  padding: ${({ padding }) => padding};
`;

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 48px;
`;

export const Hexagon = styled(BaseIcon)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
`;

export const ChildrenBox = styled('div')`
  display: inline-block;
`;
