import { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination, Autoplay } from 'swiper';
import { Container, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { YouTube as YouTubeIcon } from '@mui/icons-material';
import { GTMEvents } from 'src/lhu-toolkit/gtm/events';
import Box from '../../../components/basics/Box';
import BaseIcon from '../../../components/icons/BaseIcon';
import DiscordSocialIcon from '../../../components/icons/DiscordSocialIcon';
import TwitterSocialIcon from '../../../components/icons/TwitterSocialIcon';
import ButtonDiscordSVG from './ButtonDiscordSVG';
import EmailIcon from '../../../components/icons/EmailIcon';
import Styled from './SectionHero.styles';
import SectionTitle from './SectionTitle';
import { nameSpace } from '../../../modules/i18n';
import Pictures from './Pictures';
import { ReactComponent as ArrowIcon } from '../../../components/icons/iconic/custom-arrow.svg';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

type itemProps = {
  id: number;
  path: string;
  title: string;
};

const SectionHero: FC = () => {
  const { t } = useTranslation(nameSpace.home);
  const [progressActive, setProgressActive] = useState<boolean>(false);
  const heroeItems = t<string, itemProps[]>('home.hero.assets', { returnObjects: true });

  const ScrollTo = useCallback((element: string) => {
    const section = document.querySelector(element);
    (section as HTMLInputElement).scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <Styled.HeaderSection>
      <Swiper
        slidesPerView="auto"
        loop
        speed={800}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="bullet-${index} ${className}"><svg xmlns="http://www.w3.org/2000/svg" width="12.728" height="12.728" viewBox="0 0 12.728 12.728"><g id="Rectangle_7" data-name="Rectangle 7" transform="translate(6.364) rotate(45)" stroke-width="2"><rect width="9" height="9" stroke="none"/><rect x="1" y="1" width="7" height="7" fill="none"/></g></svg></span>`;
          },
        }}
        onSlideChange={() => {
          setProgressActive(false);
          setTimeout(() => {
            setProgressActive(true);
          }, 50);
        }}
        modules={[Pagination, Autoplay]}
      >
        {heroeItems.map(({ id, path, title }) => (
          <SwiperSlide key={id}>
            <Pictures source={`/assets/${path}`} sourceMobile={`/assets/mobile_${path}`} alt={title} />
          </SwiperSlide>
        ))}

        <Styled.Overlay>
          <Container style={{ height: '100%' }}>
            <Styled.OverlayContent>
              <div className="wrapper">
                <SectionTitle
                  header={`${t('home.hero.title')}`}
                  body={`${t('home.hero.content')}`}
                  padding={'0 0 1.8rem'}
                />
                <ButtonDiscordSVG
                  type="button"
                  title={t('home.hero.community')}
                  color="#000"
                  bgcolor="#FFF"
                  bgcolor2="gray"
                  hoverEffect
                  onClick={() => {
                    GTMEvents.clickBtnDiscord({ eventContext: 'home-cta' });
                    ScrollTo('#discord');
                  }}
                />
                <Box
                  className="social social-register"
                  display="flex"
                  alignItems="center"
                  onClick={() => {
                    GTMEvents.clickBtnEmail({ eventContext: 'home-cta' });
                    ScrollTo('#newsletter');
                  }}
                >
                  <Typography variant="body.lg">
                    <h5 className="pointer">{t('home.hero.register')}</h5>
                  </Typography>
                  <BaseIcon fill="#FEC300" cursor="pointer" icon={<EmailIcon />} height="40px" />
                </Box>
              </div>
            </Styled.OverlayContent>
          </Container>
        </Styled.Overlay>
      </Swiper>

      <Styled.SocialSection>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Styled.HeaderProgressBar className={progressActive ? 'active' : ''} />
          <Container>
            <Styled.Icon icon={<ArrowIcon />} fill="#FEC300" responsive />
            <Styled.Icon icon={<ArrowIcon />} fill="#FEC300" responsive />
            <Box
              className="social-wrapper"
              display="flex"
              alignItems="center"
              gap="15px"
              height="60px"
              justifyContent="center"
              margin="0 0 25px"
            >
              <Styled.SocialContent>
                <span>{t('home.hero.discord.title')}</span> {t('home.hero.discord.title2')}
              </Styled.SocialContent>

              <Box
                className="social-icons"
                display="flex"
                alignItems="center"
                gap="15px"
                height="60px"
                justifyContent="center"
                margin="0"
              >
                <BaseIcon
                  fill="#000"
                  cursor="pointer"
                  hoverColor="#FEC300"
                  icon={<DiscordSocialIcon />}
                  height="30px"
                  onClick={() => {
                    GTMEvents.clickBtnDiscord({ eventContext: 'home-social-section' });
                    window.open(t('home.hero.discord'), '_blank');
                  }}
                />
                <BaseIcon
                  fill="#000"
                  hoverColor="#FEC300"
                  cursor="pointer"
                  icon={<TwitterSocialIcon />}
                  height="30px"
                  onClick={() => {
                    GTMEvents.clickBtnTwitter({ eventContext: 'home-social-section' });
                    window.open(t('home.hero.twitter'), '_blank');
                  }}
                />
                <BaseIcon
                  fill="#000"
                  cursor="pointer"
                  hoverColor="#FEC300"
                  icon={<YouTubeIcon />}
                  height="30px"
                  onClick={() => {
                    GTMEvents.clickBtnYoutube({ eventContext: 'home-social-section' });
                    window.open(t('home.hero.youtube'), '_blank');
                  }}
                />
              </Box>
            </Box>
            <Styled.Icon icon={<ArrowIcon />} fill="#FEC300" direction="-1" responsive />
            <Styled.Icon icon={<ArrowIcon />} fill="#FEC300" direction="-1" responsive />
          </Container>
        </Box>
      </Styled.SocialSection>
    </Styled.HeaderSection>
  );
};

export default SectionHero;
