import { styled } from '@mui/material';
import { motion } from 'framer-motion';
import { getTextShadow } from '../../tools/helpers';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  box-shadow: ${({ theme }) => getTextShadow(theme.custom.shadows.ShadowShadowMd)};
  background-color: ${({ theme }) => theme.custom.colors.BgLayer1};
  height: 411px;
  min-width: 316px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
`;

const Media = styled(motion.div)`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 300px;

  div:nth-of-type(1) {
    > * {
      border: none;
      border-radius: 8px;
    }
  }
`;

const DetailsHover = styled(motion.div)`
  position: absolute;
  width: 316px;
  height: 148px;
  display: flex;
  top: 296px;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 16px;
  background-color: ${({ theme }) => theme.custom.colors.BgLayer1};

  span:nth-of-type(1) {
    z-index: 10;
    margin: 0 0 11px;
  }

  span:nth-of-type(2) {
    margin: 0 0 4px;
  }

  span:nth-of-type(3) {
    margin: 0 0 32px;
  }
`;

const Gradient = styled(motion.div)`
  position: absolute;
  width: 316px;
  height: 150px;
  left: calc(50% - 316px / 2);
  bottom: 115px;
  background: linear-gradient(180deg, rgba(32, 36, 37, 0) 0%, #202425 71.87%);
  z-index: 9;
`;

export default { Root, DetailsHover, Gradient, Media };
