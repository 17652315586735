import { FC, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import Avatar from '../../../components/basics/Avatar';
import Button from '../../../components/basics/Button';
import useAnalytics from '../../../modules/analytics/useAnalytics';

interface IProfileProps {
  username: string;
  exam?: string;
  estimated?: string;
  isLoggedUserProfile?: boolean;
}

const Profile: FC<IProfileProps> = props => {
  const { isLoggedUserProfile, username, exam, estimated } = props;
  const theme = useTheme();
  const { onPageAction } = useAnalytics();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOnEditProfile = useCallback(() => {
    onPageAction({
      title: document.title,
      path: pathname,
      href: location.href,
      actionName: 'edit_profile',
      actionCategory: 'account',
    });
  }, [onPageAction, pathname]);

  if (isMobile) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        margin={`0 0 ${theme.custom.spacing.SpacingXl}px 0`}
      >
        <Box margin={`${theme.custom.spacing.Spacing3Xl}px 0 0`} padding="0">
          <Avatar iconSize={50} />
        </Box>

        <Box display="flex" margin="0" padding="0" alignItems="center" flexDirection="column">
          <Box margin={`${theme.custom.spacing.SpacingBase}px 0`} padding="0">
            <Typography variant="body.lg" color={theme.custom.colors.TextWeak}>
              {username}
            </Typography>
          </Box>
          {isLoggedUserProfile && (
            <Box margin={`${theme.custom.spacing.SpacingBase}px 0`} padding="0">
              <Typography variant="headline.mobile-sm" color={theme.custom.colors.TextDefault}>
                {exam} EXAM
              </Typography>
            </Box>
          )}
          {isLoggedUserProfile && (
            <Box margin={`${theme.custom.spacing.SpacingXs}px 0`} padding="0">
              <Typography variant="label.sm" color={theme.custom.colors.TextWeak}>
                Est. {estimated} USD
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box margin={`${theme.custom.spacing.Spacing5Xl}px 0 0`} padding="0">
        <Avatar iconSize={50} />
      </Box>
      <Box display="flex" justifyContent="space-between" margin="0" padding="0" alignItems="center">
        <Box margin={`${theme.custom.spacing.Spacing2Xl}px 0 ${theme.custom.spacing.Spacing3Xl}px 0`} padding="0">
          <Typography>@jameshue</Typography>
        </Box>
        {isLoggedUserProfile && (
          <Button type="secondary" size="sm" onClick={handleOnEditProfile}>
            Edit Profile
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Profile;
