import { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import MobileDatePicker from 'src/components/inputs/MobileDatePicker';
import { ReactComponent as SortIcon } from '../../../components/icons/iconic/sort-descending.svg';
import Box from '../../../components/basics/Box';
import SearchBar from '../components/SearchBar';
import MarketplaceProviderContext from '../MarketplaceProvider.context';
import ButtonMenu from '../../../components/basics/ButtonMenu';
import FilterButton from '../components/FilterButton';
import Tag from '../../../components/basics/Tag';
import Button from '../../../components/basics/Button';
import { CustomExpandIcon } from '../MarketplaceNftDetail';
import DesktopDatePicker from '../../../components/inputs/DesktopDatePicker';
import FilterOptionType from '../components/FilterOptionType';

interface ISearchProps {
  children: ReactNode;
}

const Search: FC<ISearchProps> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    searchBarOptions,
    showFilterOptions,
    loadingSeries,
    onClearAllFilters,
    onSearch,
    onSort,
    onChangeDateFrom,
    onChangeDateTo,
    onSelectSeries,
    filters,
    sortOptions,
    attributesOptions,
    collectionOptions,
    onChangeCollectionFilter,
    onChangeAttributeFilter,
  } = useContext(MarketplaceProviderContext);
  const [showFilters, setShowFilters] = useState(false);

  const handleOnToggleFilters = useCallback(() => setShowFilters(!showFilters), [showFilters]);

  const handleOnShowMobileFilters = useCallback(() => {
    setShowFilters(true);
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.left = '0px';
    document.body.style.top = '0px';
    document.body.style.right = '0px';
    document.body.style.bottom = '0px';
  }, []);

  const handleOnHideMobileFilters = useCallback(() => {
    setShowFilters(false);
    document.body.style.overflow = 'initial';
    document.body.style.position = 'initial';
    document.body.style.left = 'initial';
    document.body.style.top = 'initial';
    document.body.style.right = 'initial';
    document.body.style.bottom = 'initial';
  }, []);

  const handleOnSearch = useCallback(
    (textSearch: string) => {
      onSearch(textSearch ? { id: textSearch, label: textSearch } : undefined);
    },
    [onSearch],
  );

  const handleOnChangeSort = useCallback(
    (value: string) => {
      onSort(value || undefined);
    },
    [onSort],
  );

  const handleOnChangeDateFrom = useCallback(
    (value: Date | null) => {
      onChangeDateFrom(value || undefined);
    },
    [onChangeDateFrom],
  );

  const handleOnChangeDateTo = useCallback(
    (value: Date | null) => {
      onChangeDateTo(value || undefined);
    },
    [onChangeDateTo],
  );

  const handleOnRemoveSeries = useCallback(() => {
    onSelectSeries(undefined);
  }, [onSelectSeries]);

  const handleOnClearAllFilters = useCallback(() => {
    onClearAllFilters();
  }, [onClearAllFilters]);

  const handleOnClickCollectionFilter = (optionId: string) => () => {
    onChangeCollectionFilter(optionId);
  };

  const handleOnClickAttributeFilter = (optionId: string) => () => {
    onChangeAttributeFilter(optionId);
  };

  const filterCount = useMemo(() => {
    const { collection = [], attributes = [] } = filters;

    const hasCollectionFilters = +(collection?.length > 0);
    const hasAttributeFilters = +(attributes?.length > 0);
    const total = hasCollectionFilters + hasAttributeFilters;

    return total ? `${total}` : '';
  }, [filters]);

  useEffect(() => {
    if (!showFilterOptions) {
      setShowFilters(false);
    }
  }, [showFilterOptions]);

  if (isMobile) {
    return (
      <>
        <AnimatePresence>
          {showFilters && (
            <motion.div
              initial={{
                opacity: 0,
                top: 48,
                width: '100vw',
                position: 'absolute',
                zIndex: '999',
              }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { delay: 0.2 } }}
            >
              <Box
                width="100vw"
                padding="0 20px"
                sx={{ backgroundColor: theme.custom.colors.BgDefault, overflowY: 'scroll' }}
                maxHeight="calc(100vh - 120px)"
              >
                <Box display="flex" justifyContent="center" alignItems="center" padding="24px 0">
                  <Typography variant="title.lg-strong">Filters</Typography>
                </Box>

                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  gap="24px"
                  flexDirection="column"
                  margin="0 0 35px"
                >
                  <Box width="100%">
                    <SearchBar
                      loading={loadingSeries}
                      onChange={handleOnSearch}
                      options={searchBarOptions}
                      value={filters.searchOption}
                    />
                  </Box>

                  <Box width="100%">
                    <AnimatePresence>
                      {showFilterOptions && (
                        <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                          <Box width="100%" display="flex" gap="12px" justifyContent="space-between">
                            <FilterButton
                              value={filterCount}
                              ButtonProps={{
                                size: 'sm',
                                type: 'secondary',
                                height: '40px',
                                justifyContent: 'center',
                              }}
                              onClick={handleOnHideMobileFilters}
                            />

                            <Box width="100%" maxWidth="55vw">
                              <ButtonMenu
                                ButtonProps={{
                                  size: 'sm',
                                  type: 'secondary',
                                  height: '40px',
                                  justifyContent: 'flex-start',
                                  icon: <SortIcon />,
                                }}
                                onChange={handleOnChangeSort}
                                options={sortOptions}
                              />
                            </Box>
                          </Box>
                        </motion.span>
                      )}
                    </AnimatePresence>
                  </Box>
                </Box>

                <Box margin="0 0 300px">
                  <Accordion>
                    <AccordionSummary expandIcon={<CustomExpandIcon />}>
                      <Typography variant="headline.mobile-sm">Collection</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {collectionOptions.map(option => (
                        <FilterOptionType
                          key={`collection-${option.id}`}
                          title={option.label}
                          onClick={handleOnClickCollectionFilter(option.id)}
                          checked={filters?.collection?.some(collection => collection === option.id)}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<CustomExpandIcon />}>
                      <Typography variant="headline.mobile-sm">Attributes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {attributesOptions.map(option => (
                        <FilterOptionType
                          key={`attributes-${option.id}`}
                          title={option.label}
                          onClick={handleOnClickAttributeFilter(option.id)}
                          checked={filters?.attributes?.some(collection => collection === option.id)}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<CustomExpandIcon />}>
                      <Typography variant="headline.mobile-sm">Price</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" alignItems="end" gap="25px" padding="0 16px 24px">
                        <Box width="100%" maxWidth="108px">
                          <Typography variant="label.sm">Min</Typography>
                          <TextField placeholder="USD" />
                        </Box>
                        <Box padding="0 0 10px">
                          <Typography variant="body.lg-strong">to</Typography>
                        </Box>
                        <Box width="100%" maxWidth="108px">
                          <Typography variant="label.sm">Max</Typography>
                          <TextField placeholder="USD" />
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<CustomExpandIcon />}>
                      <Typography variant="headline.mobile-sm">Date Listed</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" alignItems="end" gap="25px" padding="0 16px 24px">
                        <Box width="100%">
                          <Typography variant="label.sm">From</Typography>
                          <MobileDatePicker
                            format="MM/dd/yy"
                            onChange={handleOnChangeDateFrom}
                            value={filters.dateFrom}
                          />
                        </Box>
                        <Box width="100%">
                          <Typography variant="label.sm">To</Typography>
                          <MobileDatePicker format="MM/dd/yy" onChange={handleOnChangeDateTo} value={filters.dateTo} />
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>

                <Box
                  height="88px"
                  sx={{ backgroundColor: theme.custom.colors.BgLayer1 }}
                  padding="20px"
                  position="fixed"
                  left="0"
                  right="0"
                  bottom="0"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button type="primary" size="lg" onClick={handleOnHideMobileFilters} fullWidth>
                    Done
                  </Button>
                </Box>
              </Box>
            </motion.div>
          )}
        </AnimatePresence>

        <Box width="100%">
          <Box width="100%" display="flex" justifyContent="center" gap="24px" flexDirection="column">
            <Box width="100%" padding="0 20px">
              <SearchBar
                loading={loadingSeries}
                onChange={handleOnSearch}
                options={searchBarOptions}
                value={filters.searchOption}
              />
            </Box>

            <Box width="100%" padding="0 20px">
              <AnimatePresence>
                {showFilterOptions && (
                  <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <Box width="100%" display="flex" gap="12px" justifyContent="space-between">
                      <FilterButton
                        value={filterCount}
                        ButtonProps={{
                          size: 'sm',
                          type: 'secondary',
                          height: '40px',
                          justifyContent: 'center',
                        }}
                        onClick={handleOnShowMobileFilters}
                      />

                      <Box width="100%" maxWidth="50vw">
                        <ButtonMenu
                          ButtonProps={{
                            size: 'sm',
                            type: 'secondary',
                            height: '40px',
                            justifyContent: 'flex-start',
                            icon: <SortIcon />,
                          }}
                          onChange={handleOnChangeSort}
                          options={sortOptions}
                        />
                      </Box>
                    </Box>
                  </motion.span>
                )}
              </AnimatePresence>
            </Box>
          </Box>

          <AnimatePresence>
            {showFilterOptions && (
              <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Box width="100%" margin="16px 0" padding="0 20px">
                  <Box width="100%" display="flex" alignItems="center" gap="12px">
                    <Button type="tertiary" size="sm" onClick={handleOnClearAllFilters}>
                      Clear Filters
                    </Button>
                    {filters?.selectedSeries && (
                      <Tag value={filters?.selectedSeries} onClose={handleOnRemoveSeries} closable />
                    )}
                  </Box>
                </Box>
              </motion.span>
            )}
          </AnimatePresence>

          {children}
        </Box>
      </>
    );
  }

  return (
    <Box width="100%" padding="0">
      <Box width="100vw" display="flex" justifyContent="center" gap="24px" padding="0 40px">
        <AnimatePresence>
          {showFilterOptions ? (
            <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Box maxWidth="280px">
                <FilterButton
                  value={filterCount}
                  ButtonProps={{
                    size: 'sm',
                    type: 'secondary',
                    height: '40px',
                    justifyContent: 'center',
                  }}
                  onClick={handleOnToggleFilters}
                />
              </Box>
            </motion.span>
          ) : (
            <div />
          )}
        </AnimatePresence>

        <Box width="100%" maxWidth={showFilterOptions ? 'initial' : '640px'}>
          <SearchBar
            loading={loadingSeries}
            onChange={handleOnSearch}
            options={searchBarOptions}
            value={filters.searchOption}
          />
        </Box>

        <AnimatePresence>
          {showFilterOptions ? (
            <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Box width="100%" maxWidth="280px">
                <ButtonMenu
                  ButtonProps={{
                    size: 'sm',
                    type: 'secondary',
                    height: '40px',
                    justifyContent: 'flex-start',
                    icon: <SortIcon />,
                  }}
                  options={sortOptions}
                  onChange={handleOnChangeSort}
                />
              </Box>
            </motion.span>
          ) : (
            <div />
          )}
        </AnimatePresence>
      </Box>

      <Box width="100%" margin="16px 0 32px" padding="0 40px">
        <AnimatePresence>
          {showFilterOptions && (
            <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Box width="100%" display="flex" alignItems="center" gap="12px">
                <Button type="tertiary" size="sm" onClick={handleOnClearAllFilters}>
                  Clear Filters
                </Button>
                {filters?.selectedSeries && (
                  <Tag value={filters?.selectedSeries} onClose={handleOnRemoveSeries} closable />
                )}
              </Box>
            </motion.span>
          )}
        </AnimatePresence>
      </Box>

      <Box display="flex">
        <AnimatePresence>
          {showFilters && (
            <motion.div
              initial={{ width: 0 }}
              animate={{
                width: 348,
                transition: { duration: 0.3 },
              }}
              exit={{
                width: 0,
                transition: { duration: 0.3 },
              }}
            >
              <Box width="316px" padding="0 0 0 40px">
                <motion.span
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.2 } }}
                >
                  <Accordion>
                    <AccordionSummary expandIcon={<CustomExpandIcon />}>
                      <Typography variant="headline.mobile-sm">Collection</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {collectionOptions.map(option => (
                        <FilterOptionType
                          key={`collection-${option.id}`}
                          title={option.label}
                          onClick={handleOnClickCollectionFilter(option.id)}
                          checked={filters?.collection?.some(collection => collection === option.id)}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<CustomExpandIcon />}>
                      <Typography variant="headline.mobile-sm">Attributes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {attributesOptions.map(option => (
                        <FilterOptionType
                          key={`attribute-${option.id}`}
                          title={option.label}
                          onClick={handleOnClickAttributeFilter(option.id)}
                          checked={filters?.attributes?.some(attribute => attribute === option.id)}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<CustomExpandIcon />}>
                      <Typography variant="headline.mobile-sm">Price</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" alignItems="end" gap="25px" padding="0 16px 24px">
                        <Box width="100%" maxWidth="108px">
                          <Typography variant="label.sm">Min</Typography>
                          <TextField placeholder="USD" />
                        </Box>
                        <Box padding="0 0 10px">
                          <Typography variant="body.lg-strong">to</Typography>
                        </Box>
                        <Box width="100%" maxWidth="108px">
                          <Typography variant="label.sm">Max</Typography>
                          <TextField placeholder="USD" />
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<CustomExpandIcon />}>
                      <Typography variant="headline.mobile-sm">Date Listed</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" alignItems="end" gap="25px" padding="0 16px 24px">
                        <Box width="100%">
                          <Typography variant="label.sm">From</Typography>
                          <DesktopDatePicker
                            format="MM/dd/yy"
                            onChange={handleOnChangeDateFrom}
                            value={filters.dateFrom}
                          />
                        </Box>
                        <Box width="100%">
                          <Typography variant="label.sm">To</Typography>
                          <DesktopDatePicker format="MM/dd/yy" onChange={handleOnChangeDateTo} value={filters.dateTo} />
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </motion.span>
              </Box>
            </motion.div>
          )}
        </AnimatePresence>

        {children}
      </Box>
    </Box>
  );
};

export default Search;
