import { CSSProperties, FC } from 'react';
import { useTheme } from '@mui/material';
import Styled from './Avatar.styles';
import { ReactComponent as UserCircleIcon } from '../icons/iconic/user.svg';
import BaseIcon from '../icons/BaseIcon';

interface IAvatarProps {
  margin?: CSSProperties['margin'];
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  iconSize?: CSSProperties['height'];
}

const Avatar: FC<IAvatarProps> = ({ height = '80px', margin = '0', width = '80px', iconSize = '32px' }) => {
  const { custom } = useTheme();

  return (
    <Styled.Root height={height} width={width} margin={margin}>
      <BaseIcon icon={<UserCircleIcon />} width={iconSize} height={iconSize} color={custom.colors.IconOnAccentWeak} />
    </Styled.Root>
  );
};

export default Avatar;
