import { useContext } from 'react';
import AnalyticsContext from './Analytics.context';

const useAnalytics = () => {
  const { pageAction, pageView, purchaseAction } = useContext(AnalyticsContext);

  return { onPageAction: pageAction, onPageView: pageView, onPurchaseAction: purchaseAction };
};

export default useAnalytics;
