import { FC, useCallback, useState, MouseEvent } from 'react';
import { Menu, MenuItem } from '@mui/material';
import Button, { IButtonProps } from './Button';
import { ReactComponent as CheckmarkIcon } from '../icons/iconic/checkmark.svg';
import BaseIcon from '../icons/BaseIcon';
import Box from './Box';

interface IButtonMenuOption {
  id: string;
  label: string;
}

interface IButtonMenuProps {
  ButtonProps: Omit<IButtonProps, 'children'>;
  options: IButtonMenuOption[];
  onChange?: (option: IButtonMenuOption['id']) => void;
}

const ButtonMenu: FC<IButtonMenuProps> = ({ ButtonProps, options, onChange = () => undefined }) => {
  const [firstOption] = options;
  const [selectedOption, setSelectedOption] = useState<IButtonMenuOption['id']>(firstOption?.label || '');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOnClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleOnClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOnSelectOption = (option: IButtonMenuOption) => () => {
    setAnchorEl(null);
    setSelectedOption(option.label);

    onChange(option.id);
  };

  return (
    <>
      <Button {...ButtonProps} onClick={handleOnClick} fullWidth forceShowBorder={open}>
        {selectedOption}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map(option => (
          <MenuItem
            disableRipple
            key={`option-${option.id}`}
            selected={selectedOption === option.label}
            onClick={handleOnSelectOption(option)}
          >
            <Box margin={selectedOption === option.label ? '0 5px 0 0' : '0 35px 0 0'}>
              {selectedOption === option.label && <BaseIcon width="30px" icon={<CheckmarkIcon />} />}
            </Box>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ButtonMenu;
