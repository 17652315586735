import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const MastercardIcon: FC = () => (
  <SvgIcon width="34" height="26" viewBox="0 0 34 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="32" height="24" rx="1.89474" fill="#353A48" />
    <g clipPath="url(#mastercard_clip)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7756 17.3399C15.6875 18.2612 14.2759 18.8174 12.7335 18.8174C9.29193 18.8174 6.50195 16.0483 6.50195 12.6323C6.50195 9.21642 9.29193 6.44727 12.7335 6.44727C14.2759 6.44727 15.6875 7.00346 16.7756 7.9248C17.8638 7.00346 19.2753 6.44727 20.8177 6.44727C24.2593 6.44727 27.0493 9.21642 27.0493 12.6323C27.0493 16.0483 24.2593 18.8174 20.8177 18.8174C19.2753 18.8174 17.8638 18.2612 16.7756 17.3399Z"
        fill="#FF5E00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7756 7.9248C15.4358 9.05925 14.5862 10.7473 14.5862 12.6323C14.5862 14.5174 15.4358 16.2054 16.7756 17.3399C15.6875 18.2612 14.2759 18.8174 12.7335 18.8174C9.29193 18.8174 6.50195 16.0483 6.50195 12.6323C6.50195 9.21642 9.29193 6.44727 12.7335 6.44727C14.2759 6.44727 15.6875 7.00346 16.7756 7.9248Z"
        fill="#ED0006"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7756 17.3399C18.1155 16.2054 18.9651 14.5174 18.9651 12.6323C18.9651 10.7473 18.1155 9.05925 16.7756 7.9248C17.8638 7.00346 19.2753 6.44727 20.8177 6.44727C24.2593 6.44727 27.0493 9.21642 27.0493 12.6323C27.0493 16.0483 24.2593 18.8174 20.8177 18.8174C19.2753 18.8174 17.8638 18.2612 16.7756 17.3399Z"
        fill="#F9A000"
      />
    </g>
    <path
      d="M3 2H31V0H3V2ZM32 3V23H34V3H32ZM31 24H3V26H31V24ZM2 23V3H0V23H2ZM3 24C2.44772 24 2 23.5523 2 23H0C0 24.6569 1.34315 26 3 26V24ZM32 23C32 23.5523 31.5523 24 31 24V26C32.6569 26 34 24.6569 34 23H32ZM31 2C31.5523 2 32 2.44772 32 3H34C34 1.34315 32.6569 0 31 0V2ZM3 0C1.34315 0 0 1.34314 0 3H2C2 2.44772 2.44771 2 3 2V0Z"
      fill="url(#mastercard_paint_linear)"
      fillOpacity="0.2"
    />
    <defs>
      <linearGradient id="mastercard_paint_linear" x1="17" y1="1" x2="17" y2="25" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="mastercard_clip">
        <rect width="20.8" height="12.8" fill="white" transform="translate(6.3335 6.3335)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default MastercardIcon;
