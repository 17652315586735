import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import Box from '../../../components/basics/Box';
import * as Styled from './ProgressInfo.styles';

interface IProgressInfoProps {
  line: string;
  wait: string;
}

const ProgressInfo: FC<IProgressInfoProps> = props => {
  const { line, wait } = props;
  const { custom } = useTheme();

  return (
    <Styled.Root>
      <Box display="flex" flexDirection="column" gap={`${custom.spacing.SpacingBase}px`}>
        <Typography variant="body.lg" color={custom.colors.TextWeak}>
          Your line number
        </Typography>
        <Typography variant="body.lg" color={custom.colors.TextWeak}>
          Your estimated wait
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={`${custom.spacing.SpacingBase}px`}>
        <Typography variant="body.lg-strong" color={custom.colors.TextDefault}>
          {line}
        </Typography>
        <Typography variant="body.lg-strong" color={custom.colors.TextDefault}>
          {wait}
        </Typography>
      </Box>
    </Styled.Root>
  );
};

export default ProgressInfo;
