import { FC, useEffect, useMemo } from 'react';
import { Container, Typography } from '@mui/material';
import Footer from '../../components/navigation/Footer';
import NavBar, { TabRouteItem } from '../../components/navigation/NavBar';
import BaseLayout from '../../components/layouts/BaseLayout';
import HelpCircleIcon from '../../components/icons/HelpCircleIcon';
import ListItem from '../../components/feedback/ListItem';
import Box from '../../components/basics/Box';

// TODO: confirm if this pages will be include on dropkit
const Faq: FC = () => {
  useEffect(() => {
    document.title = 'FAQ';
  }, []);

  const items = useMemo(
    () => [
      {
        icon: <HelpCircleIcon />,
        label: 'What is this?',
        description:
          'This is an example of an answer to a question, that lives on a page, in a site built on Web3 technologies.',
      },
      {
        icon: <HelpCircleIcon />,
        label: 'What is this?',
        description:
          'This is an example of an answer to a question, that lives on a page, in a site built on Web3 technologies.',
      },
      {
        icon: <HelpCircleIcon />,
        label: 'What is this?',
        description:
          'This is an example of an answer to a question, that lives on a page, in a site built on Web3 technologies.',
      },
    ],
    [],
  );

  return (
    <BaseLayout footer={<Footer />} navBar={<NavBar selectedItem={TabRouteItem.faqs} />}>
      <Container>
        <Box margin="50px 0 0">
          <Typography variant="h4">FAQ</Typography>
        </Box>

        <Box>
          <Typography variant="h6">
            Here we try to answer all of your frequently asked questions. If you have any further questions please join
            our Discord and a moderator will be happy to help.
          </Typography>
        </Box>

        <Box margin="60px 0 0">
          {items.map((item, index) => (
            <ListItem key={`faq-${index}`} icon={item.icon} label={item.label} description={item.description} />
          ))}
        </Box>
      </Container>
    </BaseLayout>
  );
};

export default Faq;
