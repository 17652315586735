import { createContext } from 'react';

export interface IEventBaseConfig {
  cts: number;
  srcid: string;
  srcv: string;
  aid: string;
  av: string;
  ua: string;
  lang: string;
  ruid: string;
}

export interface IPageViewClientEventKeys {
  referrer: string; // "google.com/topgames" or "homepage/listing"
  type: string; //  "Home", "Event" or "Marketplace" or "Product Page"
  title: string; // "Home", "Legendary Summer Sale", "About Us", "Whitepaper", "FAQs", "Profile"
  href: string; // https://www.legendaryheroesunchained.com/docs/whitepaper
  path: string; //  /docs/whitepaper or /event/summer-sale
  assetId?: string;
  saleState?: string; // "countdown", "available", "limit_reached", "sold_out"
  searchKeyword?: string; // "{Collection: "Legendary", Price: <99.99} "
  sort?: string; //  "Newest First" or "Rarity: High to Low"
}

export interface IPageViewEventKeys {
  ec: 'DropKitViews';
  en: 'PageView';
  wid?: string;
  guid?: string;
  referrer: string;
  session_state: string;
  pg_type: string;
  pg_title: string;
  pg_href: string;
  pg_path: string;
  asset_id?: string;
  sale_state?: string;
  search_keyword?: string;
  sort?: string;
}

export interface IPageActionClientEventKeys {
  title: string;
  href: string;
  path: string;
  actionCategory: string; //  # defines category of the event eg. "navigation", "account", "social"
  actionName: string; // "event_details", "create_account", "sign_in", "join_discord", "follow_twitter"
  targetPage?: string; // # if button navigates to another url eg. "/whitepaper" or "/profile"
}

export interface IPageActionEventKeys {
  ec: 'DropKitActions';
  en: 'PageAction';
  session_state: string;
  pg_title: string;
  pg_href: string;
  pg_path: string;
  action_category: string;
  action_name: string;
  target_page?: string;
}

export interface IPurchaseActionClientEventKeys {
  title: string;
  href: string;
  path: string;
  actionCategory: string; //  # defines category of the event eg. "navigation", "account", "social"
  actionName: string; // "event_details", "create_account", "sign_in", "join_discord", "follow_twitter"
  targetPage?: string; // # if button navigates to another url eg. "/whitepaper" or "/profile"
  assetQty: string;
  assetId: string;
  assetName: string;
  txnCur: string;
  txnQty: string;
}

export interface IPurchaseActionEventKeys {
  ec: 'DropKitActions';
  en: 'PurchaseAction';
  session_state: string;
  pg_title: string;
  pg_href: string;
  pg_path: string;
  target_page?: string;
  asset_qty: string;
  asset_id: string;
  asset_name: string;
  txn_cur: string;
  txn_qty: string;
}

export interface IAnalyticsProviderContext {
  pageView: (event: IPageViewClientEventKeys) => void;
  pageAction: (event: IPageActionClientEventKeys) => void;
  purchaseAction: (event: IPurchaseActionClientEventKeys) => void;
}

export const initialContext: IAnalyticsProviderContext = {
  pageView: () => undefined,
  pageAction: () => undefined,
  purchaseAction: () => undefined,
};

const AnalyticsContext = createContext<IAnalyticsProviderContext>(initialContext);

AnalyticsContext.displayName = 'AnalyticsProviderContext';

export default AnalyticsContext;
