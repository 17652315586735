import { styled } from '@mui/material';
import BaseIcon from '../icons/BaseIcon';

const Root = styled('div')`
  width: 100%;
  margin: 0 0 10px;
  max-width: calc(100vw - 32px);
  position: relative;

  ${({ theme }) =>
    // @ts-ignore mobile
    theme.breakpoints.down('sm')} {
    max-height: calc(100vw + 85px);
  }

  ${({ theme }) =>
    // @ts-ignore desktop
    theme.breakpoints.up('sm')} {
    max-height: 548px;
  }
`;

const Edition = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 10px;
  position: absolute;
  width: auto;
  color: #ffffff;
  right: 12px;
  top: 12px;
  background-color: ${({ theme }) => theme.custom.colors.BgDefault}
  border-radius: 4px;
  z-index: 999;
`;

const GalleryWrapper = styled('div')`
  .swiper {
    ${({ theme }) =>
      // @ts-ignore mobile
      theme.breakpoints.down('sm')} {
      width: 100%;
      max-height: calc(100vw + 85px);
    }

    ${({ theme }) =>
      // @ts-ignore desktop
      theme.breakpoints.up('sm')} {
      width: 100%;
    }
  }

  .swiper-slide {
    width: auto;
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
`;

const GalleryPreviewWrapper = styled('div')`
  .swiper {
    ${({ theme }) =>
      // @ts-ignore mobile
      theme.breakpoints.down('sm')} {
      height: 85px;
    }

    ${({ theme }) =>
      // @ts-ignore desktop
      theme.breakpoints.up('sm')} {
      height: 120px;
    }

    width: 100%;
    transition: opacity 500ms;
  }

  .swiper-slide {
    border: 1px solid rgba(223, 239, 254, 0.14);
    border-radius: 8px;
    opacity: 0.6;
    transition: opacity 500ms;
    cursor: pointer;
    aspect-ratio: 1;

    ${({ theme }) =>
      // @ts-ignore mobile
      theme.breakpoints.down('sm')} {
      margin-top: 16px;
      height: 66px;
      min-width: 66px;
      max-width: 66px;
    }

    ${({ theme }) =>
      // @ts-ignore desktop
      theme.breakpoints.up('sm')} {
      margin-top: 32px;
      height: 84px;
      min-width: 84px;
      max-width: 84px;
    }

    :hover {
      opacity: 0.8;
    }
  }

  .swiper-slide-thumb-active {
    border: 1px solid white;
    border-radius: 8px;
    opacity: 1;
    transition: opacity 500ms;
  }
`;

const Thumbnail = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.custom.colors.BgAccentWeak};
`;

const ImagePreview = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid rgba(223, 239, 254, 0.14);
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.custom.colors.BgAccentWeak};
`;

const VideoPreview = styled('video')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid rgba(223, 239, 254, 0.14);
  box-sizing: border-box;
`;

const Playback = styled(BaseIcon)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  height: 60px;
  width: 60px;
`;

export default {
  Root,
  GalleryWrapper,
  GalleryPreviewWrapper,
  ImagePreview,
  Playback,
  Thumbnail,
  VideoPreview,
  Edition,
};
