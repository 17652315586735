import { FC, ReactNode, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useAnalytics from './modules/analytics/useAnalytics';

const RouteWrapper: FC<{ children: ReactNode; pageType: string }> = ({ children, pageType }) => {
  const routerLocation = useLocation();
  const analytics = useAnalytics();

  const handleOnPageChange = useCallback(() => {
    analytics.onPageView({
      title: document.title,
      referrer: document.referrer,
      href: location.href,
      path: routerLocation.pathname,
      type: pageType,
    });
  }, [analytics, pageType, routerLocation.pathname]);

  useEffect(() => {
    handleOnPageChange();
    // eslint-disable-next-line
  }, [routerLocation]);

  return <div>{children}</div>;
};

export default RouteWrapper;
