import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const CloseIcon: FC = () => (
  <SvgIcon width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.29506 6.08638C7.29502 6.0867 7.29498 6.08705 7.29506 6.08638L7.29603 6.07926C7.29727 6.07043 7.29966 6.05444 7.30352 6.03232C7.31128 5.98789 7.32476 5.91999 7.34636 5.83666C7.39035 5.66701 7.46392 5.44939 7.58067 5.23923C7.80498 4.83547 8.15251 4.5 8.80005 4.5C9.63451 4.5 9.93236 4.75058 10.0648 4.93593C10.2345 5.1736 10.3001 5.5336 10.3001 6C10.3001 6.46652 10.0446 6.92357 9.50956 7.1303C8.87496 7.3755 8.05005 7.98899 8.05005 9V9.25C8.05005 9.66421 8.38584 10 8.80005 10C9.21427 10 9.55005 9.66421 9.55005 9.25V9C9.55005 8.94835 9.5686 8.87844 9.65173 8.787C9.73962 8.69031 9.87973 8.59535 10.0502 8.52948C11.2088 8.08182 11.8001 7.03618 11.8001 6C11.8001 5.4664 11.7406 4.7014 11.2854 4.06407C10.7927 3.37442 9.9656 3 8.80005 3C7.4476 3 6.67013 3.78953 6.26944 4.51077C6.07369 4.86311 5.95976 5.20799 5.89437 5.46022C5.86129 5.58782 5.83961 5.6957 5.82588 5.77432C5.819 5.81373 5.81406 5.84608 5.81067 5.87021L5.80666 5.90019L5.80542 5.91036L5.80498 5.91419L5.8048 5.91579C5.80476 5.91613 5.80464 5.91718 6.55005 6L5.80464 5.91718C5.7589 6.32886 6.05555 6.69967 6.46723 6.74541C6.87761 6.79101 7.24742 6.49604 7.29506 6.08638Z"
      fill="#EFF7FF"
      fillOpacity="0.62"
    />
    <path
      d="M8.79999 11C8.2477 11 7.79999 11.4477 7.79999 12C7.79999 12.5523 8.2477 13 8.79999 13C9.35227 13 9.79999 12.5523 9.79999 12C9.79999 11.4477 9.35227 11 8.79999 11Z"
      fill="#EFF7FF"
      fillOpacity="0.62"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.799988 8C0.799988 3.58172 4.38171 0 8.79999 0C13.2183 0 16.8 3.58172 16.8 8C16.8 12.4183 13.2183 16 8.79999 16C4.38171 16 0.799988 12.4183 0.799988 8ZM8.79999 1.5C5.21014 1.5 2.29999 4.41015 2.29999 8C2.29999 11.5899 5.21014 14.5 8.79999 14.5C12.3898 14.5 15.3 11.5899 15.3 8C15.3 4.41015 12.3898 1.5 8.79999 1.5Z"
      fill="#EFF7FF"
      fillOpacity="0.62"
    />
  </SvgIcon>
);

export default CloseIcon;
