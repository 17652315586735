import { FC, useEffect, useMemo } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactComponent as CloseIcon } from '../../../components/icons/iconic/close.svg';
import { ReactComponent as InfoIcon } from '../../../components/icons/iconic/information.svg';
import Styled from './RevealManyBackdrop.styles';
import RevealMediaPlaceholder from '../../../components/media/RevealMediaPlaceholder';
import BaseIcon from '../../../components/icons/BaseIcon';
import Button from '../../../components/basics/Button';
import Media from '../../../components/media/Media';

interface IBaseCollectionRevealMany {
  title: string;
  subtitle: string;
  description: string;
  type?: 'reveal';
}

export interface IBaseCollectionDictionary {
  [id: string]: IBaseCollectionRevealMany;
}

interface IRevealManyBackdrop {
  onClose: () => void;
  open: boolean;
  selectedId: string;
  onReveal: (id: string) => void;
  onRevealAll: () => void;
  collectionRevealManyDictionary: IBaseCollectionDictionary;
}

const RevealManyBackdrop: FC<IRevealManyBackdrop> = ({
  onClose,
  open,
  selectedId,
  collectionRevealManyDictionary,
  onReveal,
  onRevealAll,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const size = useMemo(() => (isMobile ? 'calc(100vw - 100px)' : '336px'), [isMobile]);

  const handleOnReveal = (id: string) => () => {
    onReveal(id);
  };

  const unrevealedItems = useMemo(() => {
    return Object.values(collectionRevealManyDictionary).reduce((count, collection) => {
      const { type } = collection;

      if (type) {
        return count + 1;
      }

      return count;
    }, 0);
  }, [collectionRevealManyDictionary]);

  const selectedIndex = useMemo(
    () => Object.keys(collectionRevealManyDictionary).indexOf(selectedId),
    [collectionRevealManyDictionary, selectedId],
  );

  useEffect(() => {
    open ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'initial');
  }, [open]);

  return (
    <Styled.Root open={open}>
      <Box position="absolute" top="50px" right="50px">
        <BaseIcon icon={<CloseIcon />} onClick={onClose} cursor="pointer" />
      </Box>

      <Box position="absolute" top={isMobile ? '15%' : '25%'}>
        <AnimatePresence>
          {open && (
            <Styled.Wrapper
              layoutId={`layout-${selectedId}`}
              exit={{ transition: { duration: 0.3 } }}
              transition={{ duration: 0.3 }}
              style={{
                width: size,
                height: size,
                zIndex: 9999,
              }}
            >
              <Swiper
                pagination={{
                  clickable: true,
                }}
                initialSlide={selectedIndex}
                spaceBetween={50}
                centeredSlides
                modules={[Pagination]}
                height={500}
              >
                {Object.keys(collectionRevealManyDictionary).map(key => (
                  <SwiperSlide key={key}>
                    <AnimatePresence>
                      {!!collectionRevealManyDictionary[key].type && (
                        <motion.div
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.3, ease: 'easeOut' }}
                        >
                          <Box display="flex" flexDirection="column" width="100%" height="100%" gap="30px">
                            <RevealMediaPlaceholder
                              width={size}
                              height={size}
                              iconWidth="470px"
                              iconHeight="470px"
                              selected
                            />

                            <Box width="100%" boxSizing="border-box" height="70px">
                              <Button type="primary" fullWidth size="lg" onClick={handleOnReveal(key)}>
                                Reveal your purchase
                              </Button>
                            </Box>
                          </Box>
                        </motion.div>
                      )}
                    </AnimatePresence>

                    <AnimatePresence>
                      {!collectionRevealManyDictionary[key].type && (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{
                            duration: 1,
                            delay: 0.6,
                            ease: [0, 0.71, 0.2, 1.01],
                          }}
                        >
                          <Box display="flex" flexDirection="column" width="100%" height="100%" gap="30px">
                            <Media size="medium" aspectRatio="1:1" minHeight={size} />
                            <Box display="flex" justifyContent="space-between" height="70px">
                              <Box display="flex" flexDirection="column" gap="5px">
                                <Typography variant="body.lg" color={theme.custom.colors.TextDefault}>
                                  {collectionRevealManyDictionary[key].title}
                                </Typography>
                                <Typography variant="body.md" color={theme.custom.colors.TextWeak}>
                                  {collectionRevealManyDictionary[key].subtitle}
                                </Typography>
                                <Typography variant="label.sm" color={theme.custom.colors.TextWeak}>
                                  {collectionRevealManyDictionary[key].description}
                                </Typography>
                              </Box>
                              <Box margin="5px 0 0">
                                <BaseIcon icon={<InfoIcon />} />
                              </Box>
                            </Box>
                          </Box>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </SwiperSlide>
                ))}
              </Swiper>

              {!!unrevealedItems && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  margin="30px 0 0"
                  flexDirection="column"
                  gap="24px"
                >
                  <Typography variant="body.lg" color={theme.custom.colors.TextWeak}>
                    {unrevealedItems} Unrevealed Items
                  </Typography>

                  <Button type="secondary" size="sm" onClick={onRevealAll}>
                    Reveal All
                  </Button>
                </Box>
              )}
            </Styled.Wrapper>
          )}
        </AnimatePresence>
      </Box>
    </Styled.Root>
  );
};

export default RevealManyBackdrop;
