import { styled } from '@mui/material';
import { CSSProperties } from 'react';
import { motion } from 'framer-motion';
import { getTextShadow } from '../../tools/helpers';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  box-shadow: ${({ theme }) => getTextShadow(theme.custom.shadows.ShadowShadowMd)};
  background-color: ${({ theme }) => theme.custom.colors.BgLayer1};
  min-width: 316px;
  max-width: 336px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
`;

const Media = styled('div')<{ height: CSSProperties['height'] }>`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  background-color: #0e1010;
  height: ${({ height }) => height};
  user-select: none;

  div:nth-of-type(1) {
    > * {
      user-select: none;
      border: none;
    }
  }
`;

const Details = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 148px;
  display: flex;
  top: 1px;
  flex-direction: column;
  padding: 16px;
`;

const Gradient = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 215px;
  left: 0;
  bottom: 55px;
  background: linear-gradient(180deg, rgba(32, 36, 37, 0) 0%, #202425 71.87%);
  z-index: 5;
  user-select: none;
`;

const DetailsWrapper = styled('div')`
  height: 148px;
  position: relative;
  width: 100%;
  max-width: 336px;
`;

export default { Root, Details, Gradient, Media, DetailsWrapper };
