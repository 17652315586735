import { FC, useContext, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  FormLabel,
  Grid,
  Link,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/navigation/Footer';
import NavBar, { TabRouteItem } from '../../components/navigation/NavBar';
import BaseLayout from '../../components/layouts/BaseLayout';
import BaseIcon from '../../components/icons/BaseIcon';
import ForteWhiteHeaderIcon from '../../components/icons/ForteWhiteHeaderIcon';
import MastercardIcon from '../../components/icons/MastercardIcon';
import VisaIcon from '../../components/icons/VisaIcon';
import AmexIcon from '../../components/icons/AmexIcon';
import { nameSpace } from '../../modules/i18n';
import Button from '../../components/basics/Button';
import Box from '../../components/basics/Box';
import { useQuery } from '../../tools/useQuery';
import SessionContext from '../../modules/session/Session.context';
import Announcement from '../../components/feedback/Announcement';
import Attribute from '../../components/feedback/Attribute';
import routes from '../../config/routes';
import PageNavigation from '../../components/navigation/PageNavigation';
import CardHighlightList from '../../components/media/CardHighlightList';
import ButtonMenu from '../../components/basics/ButtonMenu';
import { ReactComponent as SortIcon } from '../../components/icons/iconic/sort-descending.svg';
import { ReactComponent as ChevronIcon } from '../../components/icons/iconic/chevron-down.svg';
import { ReactComponent as LockIcon } from '../../components/icons/iconic/lock-fill.svg';
import { ReactComponent as EthIcon } from '../../components/icons/iconic/eth.svg';
import { ReactComponent as HelpCircleIcon } from '../../components/icons/iconic/help-circle.svg';
import Input from '../../components/inputs/Input';
import CardHorizontal from '../../components/media/CardHorizontal';
import useCurrency from '../../modules/currency/useCurrency';

interface LocationState {
  listed: boolean;
  owned: boolean;
}

const tableData = new Array(10).fill('').map(() => ({ price: '99', serial: '001', seller: '@username' }));

const MarketPlaceNftBuy: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { listed, owned } = (location.state as LocationState) || { listed: false, owned: false };
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { account, onUpdateAccount } = useContext(SessionContext);
  const { t } = useTranslation(nameSpace.eventNft);
  const [signedInParam, eventSoldOutParam, purchaseLimitReachedParams] = useQuery([
    'signed-in',
    'event-sold-out',
    'purchase-limit-reached',
  ]);
  const { onShowCurrencyPicker, selectedCurrency } = useCurrency();
  const purchaseLimitReached = useMemo(() => purchaseLimitReachedParams === 'true', [purchaseLimitReachedParams]);
  const eventSoldOut = useMemo(() => eventSoldOutParam === 'true', [eventSoldOutParam]);

  const edition = useMemo(() => {
    if (eventSoldOut) {
      return 'SOLD OUT';
    }

    return '2987/5000';
  }, [eventSoldOut]);

  const announcement = useMemo(() => {
    if (eventSoldOut) {
      return 'Event Ended! All Sold Out';
    }

    return 'Hurry {{edition}} left';
  }, [eventSoldOut]);

  useEffect(() => {
    if (signedInParam === 'true' && !account?.id) {
      onUpdateAccount({ id: 'user-test-env', name: 'User Name', email: 'user@forte.io' });
    }
  }, [account?.id, onUpdateAccount, signedInParam]);

  const containerMargin = useMemo(() => {
    return isMobile ? '5px 0 0' : '10px 0 0';
  }, [isMobile]);

  const containerDirection = useMemo(() => (isMobile ? 'column-reverse' : undefined), [isMobile]);
  const filterContainerGridTemplateColumn = useMemo(() => (isMobile ? '1fr' : '1fr minmax(100px, 260px)'), [isMobile]);
  const filterContainerGridTemplateRow = useMemo(() => (isMobile ? '1fr 1fr' : '1fr'), [isMobile]);

  const action = useMemo(() => {
    if (owned && !listed) {
      return 'List for Sale';
    }

    return 'Select and Buy';
  }, [owned, listed]);

  return (
    <BaseLayout footer={<Footer />} navBar={<NavBar selectedItem={TabRouteItem.nft} />}>
      <Announcement text={t(announcement, { edition })} />

      <Container sx={{ marginBottom: '200px' }}>
        <PageNavigation
          label="Back to Details"
          path={routes.marketplaceNftDetail.path}
          onBack={() => {
            navigate(routes.marketplaceNftDetail.path, { state: { listed, owned, origin: 'Listing' } });
          }}
        />
        <Grid container margin={containerMargin} flexDirection={containerDirection}>
          <Grid item xs={12} sm={8}>
            <Box>
              <Box
                width="100%"
                display="grid"
                gridTemplateColumns={filterContainerGridTemplateColumn}
                gridTemplateRows={filterContainerGridTemplateRow}
                columnGap="20px"
                rowGap="20px"
                marginBottom={`${theme.custom.spacing.Spacing3Xl}px`}
              >
                <Box display="flex" justifyContent="space-between">
                  <span>
                    <Typography variant="text-default" fontSize="23px" fontWeight="bold">
                      16
                    </Typography>{' '}
                    <Typography variant="text-default">Available for sale</Typography>
                  </span>
                  <Box display="flex" alignItems="center">
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      variant="body.md"
                      color={theme.custom.colors.ActionAccent}
                      onClick={onShowCurrencyPicker}
                    >
                      Change Currency
                    </Typography>
                  </Box>
                </Box>
                <ButtonMenu
                  ButtonProps={{
                    size: 'sm',
                    type: 'secondary',
                    height: '40px',
                    justifyContent: 'flex-start',
                    icon: <SortIcon />,
                  }}
                  options={[{ id: 'lowest-price-first', label: 'Lowest Price First' }]}
                />
              </Box>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '50px', padding: '0' }} />
                    <TableCell>Price({selectedCurrency})</TableCell>
                    <TableCell>Serial</TableCell>
                    <TableCell>Seller</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((item, index) => (
                    <TableRow key={`table-row-price-item-${index}`}>
                      <TableCell sx={{ padding: '0' }}>
                        <Radio value={`item-${index}`} />
                      </TableCell>
                      <TableCell>
                        ${item.price} {selectedCurrency}
                      </TableCell>
                      <TableCell>
                        <Typography variant="text-weak">#{item.serial}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="text-weak">{item.seller}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box padding={`0 0 ${theme.custom.spacing.SpacingXl}px ${theme.custom.spacing.SpacingXl}px`}>
              <Box marginBottom={`${theme.custom.spacing.SpacingLg}px`}>
                {isMobile ? (
                  <CardHorizontal />
                ) : (
                  <CardHighlightList
                    portrait
                    title="NFT Example Title"
                    actionTitle="Select and buy now"
                    aspectRatio="16:9"
                    serial=""
                    averagePrice={`$105.00 ${selectedCurrency}`}
                    lowestPrice={`$99.00 ${selectedCurrency}`}
                    onClickActionButton={() => undefined}
                  />
                )}
              </Box>
              {owned && (
                <Box
                  marginBottom={`${theme.custom.spacing.Spacing3Xl}px`}
                  padding={`${theme.custom.spacing.SpacingLg}px`}
                  borderRadius={`${theme.custom.borderRadius.BorderRadiusRadiusBase}px`}
                  border={`1px solid ${theme.custom.colors.BorderDefault}`}
                  width="100%"
                >
                  <Typography variant="text-default">Enter an asking price to list this item.</Typography>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    marginTop={`${theme.custom.spacing.SpacingLg}px`}
                    gap={`${theme.custom.spacing.SpacingLg}px`}
                  >
                    <Box gap={`${theme.custom.spacing.SpacingSm}px`} width="100%">
                      <FormLabel required>Currency</FormLabel>
                      <ButtonMenu
                        ButtonProps={{
                          size: 'sm',
                          type: 'outlined',
                          height: '40px',
                          iconPosition: 'after',
                          justifyContent: 'space-between',
                          icon: <ChevronIcon />,
                        }}
                        options={[{ id: 'usd', label: 'USD' }]}
                      />
                    </Box>
                    <Box>
                      <FormLabel required>Amount</FormLabel>
                      <Input id="Amount" height="22px" />
                    </Box>
                  </Box>
                </Box>
              )}
              <Button
                type="primary"
                size="lg"
                fullWidth
                disabled={eventSoldOut || purchaseLimitReached}
                onClick={() => navigate(routes.marketplaceNftBuy.path)}
              >
                {action}
              </Button>
              <Box display="flex" justifyContent="space-between" alignItems="center" gap="5px" margin="10px 0 32px">
                <Box display="flex" gap="5px" alignItems="center">
                  <BaseIcon icon={<LockIcon />} color={theme.custom.colors.TextWeak} />
                  <Typography variant="text-weak" fontSize="13px">
                    {t('eventNft.poweredBy')}
                  </Typography>
                  <BaseIcon width="60px" height="20px" icon={<ForteWhiteHeaderIcon />} margin="16px 0 0" />
                </Box>
                <Box display="flex" gap="5px" alignItems="center">
                  <BaseIcon width="24px" icon={<EthIcon />} />
                  <BaseIcon width="32px" icon={<MastercardIcon />} />
                  <BaseIcon width="32px" icon={<VisaIcon />} />
                  <BaseIcon width="32px" icon={<AmexIcon />} />
                </Box>
              </Box>

              <Box margin="0 0 32px">
                <Attribute
                  type="inline"
                  label={t('eventNft.properties.fees')}
                  description={t('eventNft.properties.no.fees')}
                  margin="0 0 6px"
                  padding="0"
                />

                <Attribute
                  type="inline"
                  label={t('eventNft.properties.royalties')}
                  description={t('eventNft.properties.no.royalties')}
                  margin="0 0 6px"
                  padding="0"
                />
              </Box>

              <Box display="flex" margin="0 0 32px">
                <Typography variant="label.lg" marginRight="5px">
                  {t('eventNft.needHelp')}
                </Typography>
                <Link href={routes.faq.path}>{t('eventNft.visitFaq')}</Link>
                <BaseIcon margin="0 0 0 8px" icon={<HelpCircleIcon />} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </BaseLayout>
  );
};

export default MarketPlaceNftBuy;
