import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const N3tworkWhiteHeaderIcon: FC = () => (
  <SvgIcon width="77" height="20" viewBox="0 0 668.61 192" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#fff"
          d="M668.6,1.61H631l-28.63,36.8V1.61H568.78V28.73C563.47,13.15,549,1.61,526.1,1.61H483.71V35.82A59.64,59.64,0,0,0,428.94,0c-31.38,0-47.46,26.21-47.46,26.21L358,64.48V1.61H323.15L284.59,64.48V1.61H176.37v8.5C169,3.63,157.82,0,143.31,0c-21.6,0-35.59,9.66-41.08,24.84V1.59H69V56.15L34.24,1.59H0V118.37H33.27V59.67l37.28,58.7h31.68V102c6.61,11.18,20,17.93,41.73,17.93,30.24,0,44.79-16.64,44.79-37.6,0-10.87-4-19.19-12-25.11,6.4-5.6,10.23-12.79,10.23-21.75,0-1-.05-2-.12-3h16v85.91h32V32.48h16.55v85.91h35.19l38.56-61v61h35.68l46.85-73.95h0A24.55,24.55,0,0,1,428.94,33c15.19,0,25.75,12.32,25.75,27s-10.08,27-25.75,27-25.28-12-25.57-26.36L381,96a59.9,59.9,0,0,0,102.7-11.57v34h33.44V32h7.36s12.31,0,12.31,13.59-12.31,13.59-12.31,13.59h-2.36V90.06l14.05,28.31h66.19V81.58L631,118.37h37.59L623.18,60ZM144.11,73.27c6.88,0,10.72,3.21,10.72,8.17,0,5.91-4.64,9.44-10.87,9.44-7.2,0-11.85-4-12-8.64V80.47H102.23V38.88h30.53V37.44c.15-5.11,4.17-9.91,11-9.91s11,3.36,11,9.28c0,5-4,8.48-11,8.48H130.52v28Zm424.67,36.26L551.55,79a37,37,0,0,0,17.23-21.16Z"
        />
        <path fill="#fff" d="M133.6,169.13h16.73v7.52H133.6Z" />
        <path
          fill="#fff"
          d="M163.14,176.65h10.13c.46,4.18,2.22,7.64,9.15,7.64,4.64,0,7.71-2.55,7.71-6.21s-2-5-8.82-6.07c-12-1.57-16.87-5.17-16.87-14.18,0-8,6.67-13.92,17-13.92,10.52,0,16.66,4.7,17.51,14h-9.74c-.65-4.25-3.13-6.21-7.77-6.21s-7,2.16-7,5.3c0,3.33,1.51,4.9,8.7,5.94,11.3,1.44,17.12,4.51,17.12,14.18,0,8.3-6.8,14.9-17.84,14.9C169.61,192,163.73,186,163.14,176.65Z"
        />
        <path fill="#fff" d="M223.53,152.79H210.91v-8.23h35.88v8.23H234.18v38.5H223.53Z" />
        <path
          fill="#fff"
          d="M260.85,173.05V144.56H271.5V172.4c0,7.78,2.29,11.17,8.69,11.17s9-3,9-11.56V144.56h10.65v28.1c0,12.74-7.12,19.34-19.74,19.34C267.84,192,260.85,185.47,260.85,173.05Z"
        />
        <path
          fill="#fff"
          d="M317.38,144.56h15.29c16.41,0,24.51,8.89,24.51,22.87V168c0,14-8.17,23.34-24.57,23.34H317.38Zm14.9,38.49c9.41,0,13.86-5.29,13.86-15v-.53c0-9.6-4.12-14.77-14-14.77H328v30.26Z"
        />
        <path fill="#fff" d="M372.93,144.56h10.66v46.73H372.93Z" />
        <path
          fill="#fff"
          d="M399.34,168.22v-.53c0-13.92,10.19-23.78,24.05-23.78,14.05,0,24.05,9.47,24.05,23.52V168c0,13.92-9.54,24.05-24.12,24.05S399.34,181.87,399.34,168.22Zm37.05-.13v-.53c0-9.08-4.83-15.29-13-15.29s-13,5.95-13,15.36v.52c0,9.54,5.49,15.42,13.14,15.42C431.43,183.57,436.39,177.5,436.39,168.09Z"
        />
        <path
          fill="#fff"
          d="M459.33,176.65h10.13c.46,4.18,2.23,7.64,9.15,7.64,4.64,0,7.72-2.55,7.72-6.21s-2-5-8.83-6.07c-12-1.57-16.86-5.17-16.86-14.18,0-8,6.67-13.92,17-13.92,10.53,0,16.67,4.7,17.52,14h-9.74c-.65-4.25-3.14-6.21-7.78-6.21s-7,2.16-7,5.3c0,3.33,1.5,4.9,8.69,5.94,11.31,1.44,17.13,4.51,17.13,14.18,0,8.3-6.8,14.9-17.85,14.9C465.81,192,459.92,186,459.33,176.65Z"
        />
        <path fill="#fff" d="M510.44,169.13h16.74v7.52H510.44Z" />
      </g>
    </g>
  </SvgIcon>
);

export default N3tworkWhiteHeaderIcon;
