import { styled } from '@mui/material';
import { flexGap } from '../../tools/flexGapPoly';

const RootMobile = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px 20px;
  filter: drop-shadow(0px 8px 16px rgba(6, 15, 25, 0.1)) drop-shadow(0px 4px 8px rgba(6, 15, 25, 0.05));
  border-radius: 4px;
  width: 100%;
`;

const ContainerMobile = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  ${flexGap('24px', 'column')}
  background: ${({ theme }) => theme.custom.colors.BgLayer2};
  border: 1px solid ${({ theme }) => theme.custom.colors.BorderDefault};
  box-shadow: 0 8px 16px rgba(6, 15, 25, 0.1), 0 4px 8px rgba(6, 15, 25, 0.05);
  border-radius: 4px;
  width: 100%;
`;

const RootDesktop = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  ${flexGap('24px', 'column')}
  background: ${({ theme }) => theme.custom.colors.BgLayer2};
  border: 1px solid ${({ theme }) => theme.custom.colors.BorderDefault};
  box-shadow: 0 8px 16px rgba(6, 15, 25, 0.1), 0 4px 8px rgba(6, 15, 25, 0.05);
  border-radius: 4px;
  min-width: 665px;
`;

export default { RootMobile, ContainerMobile, RootDesktop };
