import { ChangeEvent, FC, useCallback, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe';
import { GTMEvents } from 'src/lhu-toolkit/gtm/events';
import Styled from './NewsLetter.styles';
import Input from '../../../components/inputs/Input';
import FormFeedback from '../../../components/feedback/FormFeedback';
import { nameSpace } from '../../../modules/i18n';
import Box from '../../../components/basics/Box';
import useAnalytics from '../../../modules/analytics/useAnalytics';
import ButtonSVG from './ButtonSVG';
import { ReactComponent as InputIcon } from '../../../components/icons/iconic/custom-input.svg';

export interface AgreeFormFields extends EmailFormFields {
  AGREE_TO_TERMS: number;
}

const NewsLetter: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(nameSpace.feedback);
  const [success, setSuccess] = useState<boolean>(false);
  const { onPageAction } = useAnalytics();
  const { pathname } = useLocation();
  const [email, setEmail] = useState<string>('');
  const [agree, setAgree] = useState<boolean>(false);

  const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setEmail(value);
  }, []);

  const handleOnCheckBoxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const checked = !event.target.checked;
    setAgree(checked);
  }, []);

  const handleOnJoinMailList = useCallback(() => {
    GTMEvents.formSubmitEmailReg({ eventContext: 'home-email-reg' });
    setSuccess(true);
    onPageAction({
      title: document.title,
      path: pathname,
      href: location.href,
      actionName: 'join_mailing_list',
      actionCategory: 'social',
    });
  }, [onPageAction, pathname]);

  const mailChimpUrl =
    'https://n3twork.us20.list-manage.com/subscribe/post?u=8dd06fefc03133d9453455fe4&id=6a68076d80&f_id=00007fe7f0';

  return (
    <Styled.Root>
      <Box className="newsletter-wrapper" display="flex" justifyContent="center" flexWrap="wrap" gap="8px">
        <Typography variant="h2" color={theme.custom.colors.TextDefault}>
          <div className="animated">{t('mailingList.title')}</div>
        </Typography>

        <Styled.NewsLetter>
          {success ? (
            <FormFeedback
              className="complete-alert-message"
              margin="0.2rem 2rem 0"
              showIcon
              innerHtml
              variant="success"
              message={t('mailingList.alert.success')}
            />
          ) : (
            <MailchimpSubscribe<AgreeFormFields>
              render={hooks => (
                <>
                  <form
                    className="animated"
                    onSubmit={e => {
                      e.preventDefault();
                      hooks.subscribe({ EMAIL: email, AGREE_TO_TERMS: agree ? 1 : 0 });
                    }}
                  >
                    <Box className="form-wrapper" display="flex" alignItems="flex-start" gap="2px">
                      <Styled.InputWrapper>
                        <Styled.InputIcon icon={<InputIcon />} responsive />
                        <Input
                          id="email"
                          type="email"
                          height="30px"
                          required
                          value={email}
                          onChange={handleOnChange}
                          placeholder={t('mailingList.input.email')}
                        />
                        <Styled.InputAgree>
                          <FormControlLabel
                            control={
                              <Checkbox defaultChecked name="AGREE_TO_TERMS" onChange={handleOnCheckBoxChange} />
                            }
                            label={t('mailingList.input.agree')}
                          />
                        </Styled.InputAgree>
                      </Styled.InputWrapper>
                      <ButtonSVG
                        type="submit"
                        title={t('mailingList.action.join')}
                        color="#000"
                        bgcolor="#FFFFFF"
                        bgcolor2="#808080"
                        bgcolor3="#d5aa1e"
                        hoverEffect
                      />
                    </Box>
                  </form>
                  {hooks.status === 'error' && (
                    <FormFeedback
                      className="alert-message"
                      margin="1.5rem 0 0 0.5rem"
                      maxWidth="530px"
                      showIcon
                      variant="success"
                      message={t('mailingList.alert.critical')}
                    />
                  )}
                  {hooks.status === 'success' && handleOnJoinMailList()}
                </>
              )}
              url={mailChimpUrl}
            />
          )}
        </Styled.NewsLetter>
      </Box>
    </Styled.Root>
  );
};

export default NewsLetter;
