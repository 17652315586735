import { Backdrop, styled } from '@mui/material';
import { motion } from 'framer-motion';

const Root = styled(Backdrop)`
  z-index: 9999;
  background: rgba(0, 0, 0, 0.91);
  backdrop-filter: blur(12px);
`;

const Wrapper = styled(motion.div)`
  .swiper {
    height: 480px;
  }

  .swiper-pagination {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    border-radius: 4px;
    width: 10px;
    height: 8px;
    min-width: 30px;
    background: ${({ theme }) => theme.custom.colors.BorderSeparator};
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.custom.colors.BgAccent};
  }
`;

export default { Root, Wrapper };
