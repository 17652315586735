import { FC, useCallback, useMemo, useState } from 'react';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { nameSpace } from '../../modules/i18n';
import useEvents from '../../modules/events/useEvents';
import Footer from '../../components/navigation/Footer';
import NavBar, { TabRouteItem } from '../../components/navigation/NavBar';
import BaseLayout from '../../components/layouts/BaseLayout';
import Announcement from '../../components/feedback/Announcement';
import MailingList from '../../components/feedback/MailingList';
import MediaCarousel from '../../components/media/MediaCarousel';
import ContentMedia from '../../components/media/ContentMedia';
import Button from '../../components/basics/Button';
import Box from '../../components/basics/Box';
import Hero from '../../components/basics/Hero';
import Requirements from '../../components/feedback/Requirements';
import { PLACEHOLDER0, PLACEHOLDER1 } from '../../config/images';
import FaqList from '../../components/feedback/FaqList';
import HeaderBodyButton from '../../components/feedback/HeaderBodyButton';
import Rarity from '../../components/feedback/Rarity';
import { ReactComponent as CheckmarkIcon } from '../../components/icons/iconic/checkmark.svg';
import useSession from '../../modules/session/useSession';

const EventPage: FC = () => {
  const [requirementsSuccessResponse, setRequirementsSuccessResponse] = useState(false);
  const [clickOnSend, setClickOnSend] = useState(false);
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [showCheckButton, setShowCheckButton] = useState(true);

  const theme = useTheme();
  const { t } = useTranslation([nameSpace.event, nameSpace.home]);
  const { account } = useSession();
  const { status } = useEvents();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const signedIn = useMemo(() => !!account?.id, [account]);
  const eventLive = useMemo(() => status === 'live', [status]);
  const eventEnded = useMemo(() => status === 'ended', [status]);

  const onHandleCheckRequirements = useCallback(() => {
    setClickOnSend(true);
    setShowCheckButton(false);
    setShowRefreshButton(true);
    setRequirementsSuccessResponse(false);
  }, []);

  const onHandleRefreshRequirements = useCallback(() => {
    setShowRefreshButton(false);
    setRequirementsSuccessResponse(true);
  }, []);

  const eventTexts = useMemo(() => {
    if (eventEnded) {
      return {
        title: t('event.endedEvent.title'),
        body: t('event.endedEvent.description'),
      };
    }

    if (eventLive) {
      return {
        title: t('event.liveEvent.title'),
        body: (
          <Trans
            ns={nameSpace.event}
            i18nKey="event.liveEvent.description"
            components={{
              br: <br />,
              highlight: <span style={{ fontWeight: '600' }} />,
            }}
          />
        ),
      };
    }

    return {
      title: t('event.upcomingEvent.title'),
      body: t('event.upcomingEvent.description'),
    };
  }, [eventEnded, eventLive, t]);

  const announcement = useMemo(() => {
    if (eventEnded) {
      return 'event.announcement-ended';
    }

    if (eventLive) {
      return 'event.announcement-live';
    }

    return 'event.announcement';
  }, [eventEnded, eventLive]);

  return (
    <BaseLayout footer={<Footer />} navBar={<NavBar selectedItem={TabRouteItem.eventDetails} />}>
      <Announcement text={t(announcement)} />

      <Hero />

      <Box display="flex" flexDirection="column" alignItems="center" padding="0 20px">
        <HeaderBodyButton
          alignItems="center"
          header={eventTexts.title}
          body={eventTexts.body}
          maxWidth="665px"
          textAlign="center"
          actions={
            isMobile && (
              <>
                {!signedIn && (
                  <Button type="primary" size="lg" fullWidth>
                    {t('home.upcomingEventMobile.action-primary', { ns: nameSpace.home })}
                  </Button>
                )}
                {signedIn && eventLive && (
                  <Button type="primary" size="lg" fullWidth>
                    {t('home.upcomingEventMobile.action-primary-live', { ns: nameSpace.home })}
                  </Button>
                )}
              </>
            )
          }
        />
      </Box>

      {!eventLive && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <MailingList description={eventEnded ? t('event.mailingList.event-ended') : t('event.mailingList.default')} />
        </Box>
      )}

      <Container>
        <Requirements
          onClickCheckButton={onHandleCheckRequirements}
          onClickRefreshButton={onHandleRefreshRequirements}
          showCheckButton={signedIn && showCheckButton}
          showRefreshButton={signedIn && showRefreshButton}
          showSuccessAlert={clickOnSend && requirementsSuccessResponse}
          showWarningAlert={clickOnSend && !requirementsSuccessResponse}
        />
        <Box margin="80px 0">
          <ContentMedia
            variant="md"
            body={
              <Box>
                <Box marginBottom={`${theme.custom.spacing.Spacing3Xl}px`}>{t('event.content.item.1.description')}</Box>
                <Rarity
                  icon={<CheckmarkIcon />}
                  title="Dedicated team"
                  marginBottom={`${theme.custom.spacing.SpacingMd}px`}
                />
                <Rarity
                  icon={<CheckmarkIcon />}
                  title="Growing community"
                  marginBottom={`${theme.custom.spacing.SpacingMd}px`}
                />
                <Rarity icon={<CheckmarkIcon />} title="Pro Gaming tokenomics" />
              </Box>
            }
            header={t('event.content.item.1.title')}
            source={PLACEHOLDER0}
          />
        </Box>
      </Container>
      <Box margin="50px auto" width="100vw">
        <MediaCarousel
          items={[
            {
              id: '1',
              src: PLACEHOLDER0,
            },
            {
              id: '2',
              src: PLACEHOLDER1,
            },
            {
              id: '3',
              src: PLACEHOLDER0,
            },
            {
              id: '4',
              src: PLACEHOLDER1,
            },

            {
              id: '5',
              src: PLACEHOLDER0,
            },
            {
              id: '5',
              src: PLACEHOLDER1,
            },
          ]}
        />
      </Box>

      <Container>
        <Box margin="80px 0">
          <ContentMedia
            invert={!isMobile}
            variant="md"
            body={t('event.content.item.2.description')}
            header={t('event.content.item.2.title')}
            source={PLACEHOLDER1}
          />
        </Box>
        <Box margin="80px 0">
          <ContentMedia
            variant="md"
            body={t('event.content.item.3.description')}
            header={t('event.content.item.3.title')}
            source={PLACEHOLDER0}
            isSourceVideo
          />
        </Box>
        <FaqList />
      </Container>
    </BaseLayout>
  );
};

export default EventPage;
