import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { Container, Typography, IconButton, Button } from '@mui/material';
import { YouTube as YouTubeIcon } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { GTMEvents } from 'src/lhu-toolkit/gtm/events';
import routes from '../../config/routes';
import BaseIcon from '../icons/BaseIcon';
import TwitchSocialIcon from '../icons/TwitchSocialIcon';
import TwitterSocialIcon from '../icons/TwitterSocialIcon';
import LegendaryIcon from '../../assets/LegendaryIcon.png';
import { ReactComponent as CloseIcon } from '../icons/iconic/close.svg';
import { nameSpace } from '../../modules/i18n';
import Box from '../basics/Box';
import Styled from './Footer.styles';
import BaseFooter from './BaseFooter';

const Footer: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(nameSpace.common);
  const [cookies, setCookie] = useCookies(['privacy']);
  const [showCookiePrivacy, setShowCookiePrivacy] = useState(true);

  const handleOnGoTo = (path: string) => () => navigate(path);
  const handleCookie = () => {
    setShowCookiePrivacy(false);
    setCookie('privacy', 1, {
      path: '/',
      expires: new Date(new Date().getTime() + 60 * 60 * 1000 * 24 * 60),
    });
  };
  return (
    <Styled.Footer>
      <Box>
        <Container>
          <Box margin="0 auto" display="block" padding="0 1rem" width="90px">
            <img className="img-fluid" src={LegendaryIcon} alt={t('common.project.name')} />
          </Box>

          <Styled.FooterNav>
            <Typography variant="text-default" onClick={handleOnGoTo(routes.homePage.path)}>
              {t('common.footer.menu.item.1')}
            </Typography>
            <Typography variant="text-default" onClick={handleOnGoTo('/home#roadmap')}>
              {t('common.footer.menu.item.2')}
            </Typography>
            <Typography
              variant="text-default"
              onClick={() => {
                GTMEvents.clickBtnWhitepaper({ eventContext: 'footer' });
                window.open(t('common.footer.menu.link.3'), '_blank');
              }}
            >
              {t('common.footer.menu.item.3')}
            </Typography>
            <Typography variant="text-default" onClick={handleOnGoTo('/home#faqs')}>
              {t('common.footer.menu.item.4')}
            </Typography>
          </Styled.FooterNav>

          <Box display="flex" gap="20px" justifyContent="center" margin="0 0 25px">
            <BaseIcon
              cursor="pointer"
              hoverColor="#FEC300"
              icon={<TwitchSocialIcon />}
              height="30px"
              onClick={() => {
                GTMEvents.clickBtnDiscord({ eventContext: 'footer' });
                window.open(t('common.social.discord'), '_blank');
              }}
            />
            <BaseIcon
              hoverColor="#FEC300"
              cursor="pointer"
              icon={<TwitterSocialIcon />}
              height="30px"
              onClick={() => {
                GTMEvents.clickBtnTwitter({ eventContext: 'footer' });
                window.open(t('common.social.twitter'), '_blank');
              }}
            />
            <BaseIcon
              cursor="pointer"
              hoverColor="#FEC300"
              icon={<YouTubeIcon />}
              height="30px"
              onClick={() => {
                GTMEvents.clickBtnYoutube({ eventContext: 'footer' });
                window.open(t('common.social.youtube'), '_blank');
              }}
            />
          </Box>
        </Container>

        <BaseFooter />
      </Box>
      {!cookies.privacy && showCookiePrivacy && (
        <Styled.CookiePrivacy>
          <Box display="flex" gap="0" justifyContent="space-between" margin="0">
            <Box display="flex" gap="15px" margin="0" alignItems="center">
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('common.footer.cookie')) }}
              />
              <Button id="agree" size="small" type="button" onClick={handleCookie}>
                OK
              </Button>
            </Box>
            <IconButton size="small" edge="end" onClick={() => setShowCookiePrivacy(false)}>
              <BaseIcon icon={<CloseIcon />} height={20} margin="0 10px 0" />
            </IconButton>
          </Box>
        </Styled.CookiePrivacy>
      )}
    </Styled.Footer>
  );
};

export default Footer;
