import { useContext } from 'react';
import CurrencyProviderContext from './Currency.context';

const useCurrency = () => {
  const currency = useContext(CurrencyProviderContext);

  return currency;
};

export default useCurrency;
