export const {
  REACT_APP_DROPKIT_ANALYTICS_API_KEY: DROPKIT_ANALYTICS_API_KEY,
  REACT_APP_DROPKIT_ANALYTICS_SRCID: DROPKIT_ANALYTICS_SRCID,
  REACT_APP_DROPKIT_ANALYTICS_AID: DROPKIT_ANALYTICS_AID,
  REACT_APP_DROPKIT_ANALYTICS_SRCV: DROPKIT_ANALYTICS_SRCV,
} = process.env;

export const analyticsAPIKey = DROPKIT_ANALYTICS_API_KEY || ''; // your API key

export const analyticsEventConfig = {
  srcid: DROPKIT_ANALYTICS_SRCID || '',
  aid: DROPKIT_ANALYTICS_AID || '',
  srcv: DROPKIT_ANALYTICS_SRCV || '',
};
