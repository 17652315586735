import { CSSProperties } from 'react';
import { styled } from '@mui/material';

const Root = styled('div')<{ margin: CSSProperties['margin'] }>`
  height: 1px;
  background-color: #3a3f42;
  margin: ${({ margin }) => margin};
`;

export default { Root };
