import { TagManagerArgs } from 'react-gtm-module';

// Go to Google Tag Manager -> ADMIN -> Environments -> Actions -> Get Snippet
// The values for fields 'auth' and 'preview' can be found within the script block
// provided by GTM. The param keys to look for are:
// 1) gtm_auth
// 2) htm_preview
const args: TagManagerArgs = { gtmId: 'GTM-N2GM97V' };
const argsProd: TagManagerArgs = {
  ...args,
  auth: 'i5WlccnhyHcPwe_Wyql-xQ',
  preview: 'env-1',
  dataLayer: { env: 'prod' },
};
const argsStaging: TagManagerArgs = {
  ...args,
  auth: 'JN0V5OPP6mevmlgZD_oHsg',
  preview: 'env-32',

  dataLayer: { env: 'staging' },
};
const argsQA: TagManagerArgs = {
  ...args,
  auth: '81yGc4B9IjgiPmFoUgzMrg',
  preview: 'env-40',
  dataLayer: { env: 'qa' },
};
const argsDev: TagManagerArgs = {
  ...args,
  auth: '8h4y4n9MDX39VKyQVrku5A',
  preview: 'env-33',
  dataLayer: { env: 'dev' },
};

export const getTagManagerConfig = (env: string | undefined): TagManagerArgs => {
  switch (env) {
    case 'prod':
      return argsProd;
    case 'staging':
      return argsStaging;
    case 'qa':
      return argsQA;
    case 'dev':
      return argsDev;
    default:
      return argsDev;
  }
};
