import { Toolbar, styled, AppBar } from '@mui/material';
import { CSSProperties } from 'react';

const CustomAppBar = styled(AppBar)`
  box-shadow: none;
`;

const NavigationBar = styled(Toolbar)<{ height: CSSProperties['height'] }>`
  height: ${({ height }) => height};
  justify-content: center;
  background-color: ${({ theme }) => theme.custom.colors.BgLayer1};
`;

export default { NavigationBar, CustomAppBar };
