import { FC, useCallback, useMemo } from 'react';
import { Container, Typography, useTheme } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/navigation/Footer';
import BaseLayout from '../../components/layouts/BaseLayout';
import NavBar from '../../components/navigation/NavBar';
import Box from '../../components/basics/Box';
import BaseIcon from '../../components/icons/BaseIcon';
import { ReactComponent as BackIcon } from '../../components/icons/iconic/arrow-left.svg';
import routes from '../../config/routes';
import Media from '../../components/media/Media';

const CollectionDetailsPage: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleOnGoToCollected = useCallback(() => {
    navigate(`${routes.account.path}/${routes.account.nestedRoutes?.collected.path}`);
  }, [navigate]);

  const collections = useMemo(() => new Array(150).fill(''), []);

  return (
    <BaseLayout footer={<Footer />} navBar={<NavBar />}>
      <Container>
        <Box
          display="flex"
          margin="25px 0 0"
          sx={{ cursor: 'pointer' }}
          width="fit-content"
          gap="10px"
          onClick={handleOnGoToCollected}
        >
          <BaseIcon icon={<BackIcon />} color={theme.custom.colors.TextWeak} />
          <Typography variant="text-weak">Collected</Typography>
        </Box>

        <Box margin="25px 0 0">
          <Typography variant="headline.mobile-sm">Collection Name</Typography>
        </Box>

        <AnimatePresence>
          <Box
            display="flex"
            flexDirection="row"
            padding={0}
            flexWrap="wrap"
            alignItems="flex-start"
            gap="16px"
            margin="25px 0 0"
            justifyContent="center"
          >
            {collections.map((_collection, index) => (
              <motion.div
                key={`collection-${index}`}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.2 }}
                exit={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <motion.div
                  transition={{ duration: 0.5, delay: 0.3 }}
                  variants={{
                    offscreen: {
                      opacity: 0,
                      y: 60,
                    },
                    onscreen: {
                      opacity: 1,
                      y: 0,
                    },
                  }}
                >
                  <Media size="medium" aspectRatio="1:1" minHeight="160px" />
                </motion.div>
              </motion.div>
            ))}
          </Box>
        </AnimatePresence>
      </Container>
    </BaseLayout>
  );
};

export default CollectionDetailsPage;
