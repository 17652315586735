import { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Styled from './Header.styles';
import { ReactComponent as PhotoIcon } from '../../../components/icons/iconic/photo.svg';

const Header: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Styled.HeaderSection>
        <Styled.HeaderImage />
        <Styled.Gradient />
        <AnimatePresence>
          <Styled.Content
            key="content"
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Styled.MediaIcon icon={<PhotoIcon height="95px" width="95px" />} />
            <Typography
              variant={isMobile ? 'headline.mobile-lg' : 'headline.desktop-lg'}
              color={theme.custom.colors.TextDefault}
              zIndex="50"
            >
              Marketplace
            </Typography>
          </Styled.Content>
        </AnimatePresence>
      </Styled.HeaderSection>
      <Styled.Border />
    </>
  );
};

export default Header;
