import { Box, styled } from '@mui/material';
import { CSSProperties } from 'react';
import { flexGap } from '../../tools/flexGapPoly';

export const Container = styled(Box)<{
  alignItems: CSSProperties['alignItems'];
  maxWidth: CSSProperties['maxWidth'];
}>`
  padding: 0 0 40px;
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: column;
  max-width: ${({ maxWidth }) => maxWidth};
  ${({ theme }) => flexGap(`${theme.custom.spacing.Spacing3Xl}px`, 'column')}
`;

export const WrapperDesktop = styled(Box)<{ alignItems: CSSProperties['alignItems'] }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems};
  ${({ theme }) => flexGap(`${theme.custom.spacing.SpacingLg}px`, 'column')}
`;

export const WrapperMobile = styled(Box)<{ alignItems: CSSProperties['alignItems'] }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems};
  ${({ theme }) => flexGap(`${theme.custom.spacing.SpacingMd}px`, 'column')}
`;

export const Actions = styled(Box)<{ alignItems: CSSProperties['alignItems'] }>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: row;
  ${({ theme }) => flexGap(`${theme.custom.spacing.SpacingMd}px`, 'column')}
  width: 100%;
`;
