interface IShadow {
  blur: number;
  color: string;
  x: number;
  y: number;
}

export const getTextShadow = (shadows: IShadow[]): string => {
  return shadows.map(({ x, y, blur, color }) => `${x}px ${y}px ${blur}px ${color}`).join(', ');
};

export const hexToRgba = (hex: string, opacity: number): string => {
  const R = parseInt(hex.slice(1, 3), 16);
  const G = parseInt(hex.slice(3, 5), 16);
  const B = parseInt(hex.slice(5, 7), 16);

  return opacity ? `rgba(${R}, ${G}, ${B}, ${opacity})` : `rgba(${R}, ${G}, ${B})`;
};
