import { styled } from '@mui/material';
import { CSSProperties } from 'react';

const Root = styled('div')<{ margin?: CSSProperties['margin']; width: CSSProperties['width'] }>`
  width: ${({ width = '100vw' }) => width};
  position: relative;

  .swiper {
    cursor: grab;
  }

  .swiper-slide {
    position: relative;
    width: auto;
  }

  .swiper-wrapper {
    box-sizing: border-box;

    .swiper-slide:first-of-type {
      margin-left: ${({ margin }) => margin};
    }
    .swiper-slide:last-of-type {
      margin-right: ${({ margin }) => margin};
    }
  }
`;

export default { Root };
