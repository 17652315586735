import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import BaseLayout from '../../components/layouts/BaseLayout';
import Box from '../../components/basics/Box';
import Button from '../../components/basics/Button';
import ProgressInfo from './components/ProgressInfo';
import * as Styled from './Queue.styles';
import BaseFooter from '../../components/navigation/BaseFooter';
import NavBarSimple from '../../components/navigation/NavBarSimple';
import routes from '../../config/routes';
import useAnalytics from '../../modules/analytics/useAnalytics';
import useQueue from './useQueue';

const QueueTime: FC = () => {
  const { currentLine, progress, eta } = useQueue();

  const [showLeaveConfirmDialog, setShowLeaveConfirmDialog] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { onPageAction } = useAnalytics();
  const { pathname } = useLocation();

  const titleVariant = useMemo(() => (isMobile ? 'headline.mobile-lg' : 'headline.desktop-lg'), [isMobile]);
  const buttonSize = useMemo(() => (isMobile ? 'sm' : 'lg'), [isMobile]);

  const handleOnShowLeaveConfirmDialog = useCallback(() => setShowLeaveConfirmDialog(true), []);
  const handleOnHideLeaveConfirmDialog = useCallback(() => setShowLeaveConfirmDialog(false), []);
  const handleOnLeave = useCallback(() => {
    handleOnHideLeaveConfirmDialog();
    navigate(routes.root.path);
    onPageAction({
      title: document.title,
      path: pathname,
      href: location.href,
      actionName: 'leave_queue',
      actionCategory: 'navigation',
    });
  }, [handleOnHideLeaveConfirmDialog, navigate, onPageAction, pathname]);

  const showAlmostDoneAlert = useMemo(() => {
    return progress > 80;
  }, [progress]);

  useEffect(() => {
    document.title = 'Queue';
  }, []);

  return (
    <>
      <BaseLayout footer={<BaseFooter />} navBar={<NavBarSimple />}>
        <Styled.Media>
          <Styled.Hero />
        </Styled.Media>
        <Styled.Root>
          <Box position="absolute" left="0" right="0" top="20px" width="100%" justifyContent="center" display="flex">
            <AnimatePresence>
              {showAlmostDoneAlert && (
                <motion.div
                  initial={{ y: -50, opacity: 0 }}
                  exit={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 1.0 }}
                >
                  <Box width="fit-content">
                    <Alert severity="info" variant="filled">
                      You’re next. You’ll have 15 minutes to complete your purchase.
                    </Alert>
                  </Box>
                </motion.div>
              )}
            </AnimatePresence>
          </Box>

          <AnimatePresence>
            <motion.div
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            >
              <Container>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  marginTop="20vh"
                >
                  <Box display="flex" flexDirection="column" maxWidth="450px">
                    <Typography variant={titleVariant} sx={{ marginBottom: `${theme.custom.spacing.SpacingMd}px` }}>
                      You are now in line
                    </Typography>
                    <Typography variant="body.lg" sx={{ marginBottom: `${theme.custom.spacing.Spacing2Xl}px` }}>
                      You are in line to purchase <b>Example</b>. You will have <b>15 minutes</b> to complete your
                      purchase at the checkout.
                    </Typography>

                    <LinearProgress
                      variant="determinate"
                      value={progress}
                      sx={{ marginBottom: `${theme.custom.spacing.Spacing2Xl}px` }}
                    />

                    <Box marginBottom={`${theme.custom.spacing.Spacing3Xl}px`}>
                      <ProgressInfo line={`${currentLine}`} wait={`${eta}`} />
                    </Box>
                    <Box
                      marginTop={`${theme.custom.spacing.Spacing4Xl}px`}
                      marginBottom={`${theme.custom.spacing.Spacing5Xl}px`}
                      display="flex"
                      justifyContent="center"
                    >
                      <Button type="tertiary" size={buttonSize} onClick={handleOnShowLeaveConfirmDialog}>
                        Leave the Line
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </motion.div>
          </AnimatePresence>
        </Styled.Root>
      </BaseLayout>

      <Dialog open={showLeaveConfirmDialog} onClose={handleOnHideLeaveConfirmDialog}>
        <DialogTitle>Leave the Line?</DialogTitle>

        <DialogContent>
          <DialogContentText>
            If you leave the line you will lose your place. If you want to purchase this item you will need to rejoin
            the line.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="secondary" size={buttonSize} onClick={handleOnHideLeaveConfirmDialog}>
            Cancel
          </Button>
          <Button type="primary" size={buttonSize} onClick={handleOnLeave}>
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QueueTime;
