import { styled } from '@mui/material';
import Box from '../basics/Box';
import { flexGap } from '../../tools/flexGapPoly';

export const Root = styled(Box)`
  max-width: 100%;
  border-radius: ${({ theme }) => theme.custom.borderRadius.BorderRadiusRadiusLg}px;
  display: flex;
  background-color: ${({ theme }) => theme.custom.colors.BgLayer1};
  overflow: hidden;
`;

export const MediaContainer = styled(Box)`
  display: flex;
  width: 100%;
`;

export const DetailsContainer = styled(Box)`
  padding: ${({ theme }) => theme.custom.spacing.SpacingLg}px;
  min-width: 180px;
  display: flex;
  flex-direction: column;
`;

export const DetailsList = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${({ theme }) => flexGap(`${theme.custom.spacing.SpacingBase}px`)}
`;

export const DetailsListItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;
