import { FC, useCallback, useEffect, useContext } from 'react';
import { IconButton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GTMEvents } from 'src/lhu-toolkit/gtm/events';
import { YouTube as YouTubeIcon } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import routes from '../../config/routes';
import AccountNavigation from '../basics/AccountNavigation';
import { ReactComponent as MenuIcon } from '../icons/iconic/menu.svg';
import { ReactComponent as CloseIcon } from '../icons/iconic/close.svg';
import BaseIcon from '../icons/BaseIcon';
import Styled from './NavBarMobile.styles';
import { nameSpace } from '../../modules/i18n';
import TwitchSocialIcon from '../icons/TwitchSocialIcon';
import TwitterSocialIcon from '../icons/TwitterSocialIcon';
import Box from '../basics/Box';
import Logo from '../../assets/lhu_logo.png';
import SessionContext from '../../modules/session/Session.context';
import { TabRouteItem } from './NavBar';

interface INavBarMobileProps {
  height?: number;
  selectedItem?: TabRouteItem;
}

const NavBarMobile: FC<INavBarMobileProps> = ({ height = 72, selectedItem = false }) => {
  const { t } = useTranslation(nameSpace.common);
  const { onUpdateNavigationMenuVisibility, showNavigationMenu } = useContext(SessionContext);
  const navigate = useNavigate();
  const { hash } = useLocation();

  const handleOnShowMenu = useCallback(() => {
    onUpdateNavigationMenuVisibility(!showNavigationMenu);
  }, [onUpdateNavigationMenuVisibility, showNavigationMenu]);
  const handleOnHideMenu = useCallback(() => {
    onUpdateNavigationMenuVisibility(false);
  }, [onUpdateNavigationMenuVisibility]);
  const handleOnGoTo = (path: string) => () => {
    onUpdateNavigationMenuVisibility(false);
    navigate(path);
  };

  useEffect(() => {
    if (hash) {
      setTimeout(function () {
        const targetElement = document.getElementById(hash.substring(1));
        targetElement?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [hash]);

  return (
    <>
      <Styled.CustomAppBar className={showNavigationMenu ? 'hasMenu' : ''}>
        <Styled.NavigationBar height={`${height}px`}>
          <IconButton
            className="hamburger-menu"
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleOnShowMenu}
          >
            <BaseIcon icon={showNavigationMenu ? <CloseIcon /> : <MenuIcon />} height={32} margin="0 10px 0" />
          </IconButton>
          <Button className="brand-logo" onClick={() => navigate(routes.homePage.path)}>
            <img className="img-fluid" src={Logo} alt={t('common.project.name')} />
          </Button>
          <AccountNavigation />
        </Styled.NavigationBar>
      </Styled.CustomAppBar>

      <Styled.AppMenu anchor="left" open={showNavigationMenu} onClose={handleOnHideMenu}>
        <Box margin="20px 0 0" gap="24px" padding="0" display="flex" flexDirection="column" alignItems="flex-start">
          <Box margin="24px 0 0" width="100%">
            <MenuItem onClick={handleOnGoTo('/')} selected={selectedItem === 'home' && !hash}>
              {t('common.footer.menu.item.1')}
            </MenuItem>
            <MenuItem onClick={handleOnGoTo('/home#roadmap')} selected={selectedItem === 'home' && hash === '#roadmap'}>
              {t('common.footer.menu.item.2')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                GTMEvents.clickBtnWhitepaper({ eventContext: 'nav-bar' });
                window.open(t('common.footer.menu.link.3'), '_blank');
              }}
            >
              {t('common.footer.menu.item.3')}
            </MenuItem>
            <MenuItem onClick={handleOnGoTo('/home#faqs')} selected={selectedItem === 'faqs'}>
              {t('common.footer.menu.item.4')}
            </MenuItem>
          </Box>

          <Box margin="24px 0 0" display="flex" flexDirection="row" alignItems="center" padding="0 20px" gap="16px">
            <BaseIcon
              cursor="pointer"
              hoverColor="#FEC300"
              icon={<TwitchSocialIcon />}
              height="30px"
              onClick={() => window.open(t('common.social.discord'), '_blank')}
            />
            <BaseIcon
              hoverColor="#FEC300"
              cursor="pointer"
              icon={<TwitterSocialIcon />}
              height="30px"
              onClick={() => window.open(t('common.social.twitter'), '_blank')}
            />
            <BaseIcon
              cursor="pointer"
              hoverColor="#FEC300"
              icon={<YouTubeIcon />}
              height="30px"
              onClick={() => window.open(t('common.social.youtube'), '_blank')}
            />
          </Box>
        </Box>
      </Styled.AppMenu>
    </>
  );
};

export default NavBarMobile;
