import { CSSProperties } from 'react';
import { styled } from '@mui/material';
import BaseIcon from '../icons/BaseIcon';

const Root = styled('div')<{
  height: CSSProperties['height'];
  width: CSSProperties['width'];
  minwidth?: CSSProperties['minWidth'];
  minheight?: CSSProperties['minHeight'];
  maxwidth?: CSSProperties['maxWidth'];
  maxheight?: CSSProperties['maxHeight'];
  selected: number;
}>`
  overflow: hidden;
  max-width: ${({ maxwidth }) => maxwidth};
  min-width: ${({ minwidth }) => minwidth};
  max-height: ${({ maxheight }) => maxheight};
  min-height: ${({ minheight }) => minheight};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: relative;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.custom.colors.BgAccentWeak};
  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.custom.colors.White : theme.custom.colors.BorderDefault)};
  box-sizing: border-box;
  z-index: 9999;
`;

const Icon = styled(BaseIcon)`
  color: ${({ theme }) => theme.custom.colors.IconOnAccent};
  position: absolute;
`;

const OptionWrapper = styled('div')`
  width: 100%;
  height: 100%;
  padding: 8px;
  display: flex;
  align-items: flex-end;
  z-index: 10;
  justify-content: center;
`;

const RevealOption = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.custom.colors.ActionAccent};
  color: ${({ theme }) => theme.custom.colors.TextOnAccent};
  font-size: ${({ theme }) => theme.custom.typography.LabelLgStrong.fontSize};
  font-family: ${({ theme }) => theme.custom.typography.LabelLgStrong.fontFamily}
    ${({ theme }) => theme.custom.typography.LabelLgStrong.fontWeight};
  height: 32px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
`;

const UnreleasedOption = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.custom.colors.BgDefault};
  color: ${({ theme }) => theme.custom.colors.White};
  font-size: ${({ theme }) => theme.custom.typography.LabelLg.fontSize};
  font-family: ${({ theme }) => theme.custom.typography.LabelLg.fontFamily}
    ${({ theme }) => theme.custom.typography.LabelLg.fontWeight};
  height: 32px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
`;

export default { Image, Root, Icon, OptionWrapper, RevealOption, UnreleasedOption };
