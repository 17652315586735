import { FC } from 'react';
import { Dialog, DialogTitle, useTheme, Typography } from '@mui/material';
import { Currency } from '../Currency.context';
import { ReactComponent as CheckmarkIcon } from '../../../components/icons/iconic/checkmark.svg';
import Box from '../../../components/basics/Box';

interface ICurrencySelectorProps {
  show: boolean;
  onHide?: VoidFunction;
  currencies: Currency[];
  onChangeCurrency?: (currencyCode: Currency['code']) => void;
  selectedCurrency: Currency['code'];
}

const CurrencyPicker: FC<ICurrencySelectorProps> = props => {
  const { show, currencies, onHide, onChangeCurrency, selectedCurrency } = props;
  const theme = useTheme();

  const handleOnSelectCurrency = (currency: Currency) => () => {
    onChangeCurrency && onChangeCurrency(currency.code);
  };

  return (
    <Dialog
      open={show}
      onClose={onHide}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': { minWidth: '336px', maxWidth: '336px', margin: 0 },
        },
      }}
    >
      <DialogTitle marginBottom={`${theme.custom.spacing.SpacingXl}px`}>Select Currency</DialogTitle>
      {currencies.map((currency, index) => (
        <Box
          display="flex"
          height="40px"
          alignContent="center"
          justifyContent="space-between"
          key={`currency-item-${index}`}
          onClick={handleOnSelectCurrency(currency)}
          sx={{ cursor: 'pointer' }}
        >
          <span>
            <Typography variant="text-default" marginRight={`${theme.custom.spacing.SpacingBase}px`}>
              {currency.code}
            </Typography>
            <Typography variant="text-weak">{currency.label}</Typography>
          </span>
          <span>{selectedCurrency === currency.code && <CheckmarkIcon />}</span>
        </Box>
      ))}
    </Dialog>
  );
};

export default CurrencyPicker;
