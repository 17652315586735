import { FC } from 'react';
import Styled from './MediaHeader.styles';
import BaseIcon from '../../../components/icons/BaseIcon';
import { ReactComponent as PhotoIcon } from '../../../components/icons/iconic/photo.svg';

interface IMediaHeaderProps {
  coverUrl?: string;
}

const MediaHeader: FC<IMediaHeaderProps> = ({ coverUrl }) => {
  if (!coverUrl) {
    return (
      <Styled.PlaceholderCover>
        <BaseIcon icon={<PhotoIcon />} width={53} />
      </Styled.PlaceholderCover>
    );
  }

  return <Styled.ImageCover />;
};

export default MediaHeader;
