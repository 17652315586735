import { createContext } from 'react';

export interface IEventsContext {
  eventSoldOut: boolean;
  joinEvent: boolean;
  nextEventDate: Date | null;
  onChangeEventSoldOut: (join: IEventsContext['eventSoldOut']) => void;
  onChangeJoinEvent: (join: IEventsContext['joinEvent']) => void;
  onChangeNextEventDate: (join: IEventsContext['nextEventDate']) => void;
  onChangePurchaseLimitReached: (join: IEventsContext['purchaseLimitReached']) => void;
  onChangeStatus: (join: IEventsContext['status']) => void;
  purchaseLimitReached: boolean;
  status: 'pre' | 'live' | 'ended';
}

export const initialContext: IEventsContext = {
  eventSoldOut: false,
  joinEvent: false,
  nextEventDate: null,
  onChangeEventSoldOut: () => undefined,
  onChangeJoinEvent: () => undefined,
  onChangeNextEventDate: () => undefined,
  onChangePurchaseLimitReached: () => undefined,
  onChangeStatus: () => undefined,
  purchaseLimitReached: false,
  status: 'pre',
};

const EventsContext = createContext<IEventsContext>(initialContext);

EventsContext.displayName = 'EventsContext';

export default EventsContext;
