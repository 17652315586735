import { styled } from '@mui/material';

export const Root = styled('div')`
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr 1fr;
  border: 1px solid ${({ theme }) => theme.custom.colors.BorderDefault};
  background-color: ${({ theme }) => theme.custom.colors.BgLayer2};
  padding: ${({ theme }) => theme.custom.spacing.Spacing2Xl}px;
  border-radius: ${({ theme }) => theme.custom.borderRadius.BorderRadiusRadiusBase}px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 70% 30%;
  }
`;
