import { FC, useEffect, useRef, useState } from 'react';
import Styled, { IPictureStyledProps } from './Pictures.styles';

interface IPictureProps extends IPictureStyledProps {
  alt?: string;
  source?: string | File;
  sourceMobile?: string | File;
  border?: string;
  borderRadius?: string;
  width?: string;
  maxWidth?: string;
  height?: string;
}

type TPictureHandleOnLoad = () => void;

const Pictures: FC<IPictureProps> = props => {
  const {
    alt = '',
    source,
    sourceMobile,
    width = '100%',
    height = 'auto',
    maxWidth = '100%',
    borderRadius = '0',
    border = '0',
  } = props;
  const [loaded, setLoaded] = useState<boolean>(false);
  const pictureReference = useRef<HTMLImageElement | null>(null);
  const pictureSource =
    typeof source === 'string' || source === undefined || source === null ? source : URL.createObjectURL(source);
  const pictureSourceMobile =
    typeof sourceMobile === 'string' || sourceMobile === undefined || sourceMobile === null
      ? sourceMobile
      : URL.createObjectURL(sourceMobile);

  const handleOnLoad: TPictureHandleOnLoad = () => setLoaded(true);

  useEffect(() => {
    if (
      pictureReference.current &&
      pictureReference.current?.complete &&
      source !== undefined &&
      source !== '' &&
      source !== null
    ) {
      setLoaded(true);

      return;
    }
    setLoaded(false);
  }, [source]);

  return (
    <picture>
      <source srcSet={pictureSource} media="(min-width: 768px)" />
      <Styled.Pic
        alt={alt}
        borderRadius={borderRadius}
        border={border}
        height={height}
        width={width}
        maxWidth={maxWidth}
        onLoad={handleOnLoad}
        src={pictureSourceMobile}
        sizes="(max-width: 767px)"
        loaded={loaded}
      />
    </picture>
  );
};

export default Pictures;
