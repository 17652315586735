import { FC } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import routes, { IRoute, routesRootRedirect } from './config/routes';
import packageJson from '../package.json';
import logger from './modules/logger';
import RouteWrapper from './RouteWrapper';

const log = logger.getLogger('Dropkit');

log.info(`===${packageJson.name} v${packageJson.version}===`);

const App: FC = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route caseSensitive element={<Navigate to={routesRootRedirect.to} />} path={routesRootRedirect.from} />
        {Object.values<IRoute>(routes)
          .filter(route => !route.root)
          .map((route, index) => (
            <Route
              caseSensitive
              element={
                route.component && (
                  <RouteWrapper pageType={route.pageType || ''}>
                    <route.component />
                  </RouteWrapper>
                )
              }
              key={index}
              path={route.path}
            >
              {Object.values(route.nestedRoutes || {})?.map((nestedRoute, nestedIndex) => (
                <Route
                  key={`nested-route-${nestedIndex}`}
                  path={nestedRoute.path}
                  element={nestedRoute.component && <nestedRoute.component />}
                />
              ))}
            </Route>
          ))}
      </Routes>
    </Router>
  );
};

export default App;
