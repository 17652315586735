import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const VisaIcon: FC = () => (
  <SvgIcon width="34" height="26" viewBox="0 0 34 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="32" height="24" rx="1.89474" fill="#224DBA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7504 15.8759H9.87519L8.46902 10.4999C8.40228 10.2526 8.26057 10.034 8.05211 9.93094C7.53188 9.672 6.95862 9.46592 6.33325 9.36198V9.15501H9.35405C9.77097 9.15501 10.0837 9.46592 10.1358 9.82701L10.8654 13.7049L12.7397 9.15501H14.5627L11.7504 15.8759ZM15.605 15.8759H13.8341L15.2923 9.15501H17.0633L15.605 15.8759ZM19.3545 11.0169C19.4066 10.6549 19.7193 10.4479 20.0841 10.4479C20.6574 10.396 21.2818 10.4999 21.803 10.758L22.1157 9.31091C21.5945 9.10394 21.0213 9 20.501 9C18.7822 9 17.5314 9.93094 17.5314 11.223C17.5314 12.2059 18.4174 12.722 19.0427 13.0329C19.7193 13.3429 19.9799 13.5499 19.9278 13.8599C19.9278 14.3249 19.4066 14.5319 18.8864 14.5319C18.261 14.5319 17.6357 14.3769 17.0633 14.118L16.7506 15.5659C17.376 15.8239 18.0526 15.9279 18.6779 15.9279C20.6053 15.9789 21.803 15.0489 21.803 13.6529C21.803 11.895 19.3545 11.7919 19.3545 11.0169ZM28.0009 15.8759L26.5947 9.15501H25.0843C24.7717 9.15501 24.459 9.36198 24.3547 9.672L21.7509 15.8759H23.5739L23.9378 14.8939H26.1778L26.3863 15.8759H28.0009ZM25.3449 10.9649L25.8651 13.4979H24.4068L25.3449 10.9649Z"
      fill="white"
    />
    <path
      d="M3 2H31V0H3V2ZM32 3V23H34V3H32ZM31 24H3V26H31V24ZM2 23V3H0V23H2ZM3 24C2.44772 24 2 23.5523 2 23H0C0 24.6569 1.34315 26 3 26V24ZM32 23C32 23.5523 31.5523 24 31 24V26C32.6569 26 34 24.6569 34 23H32ZM31 2C31.5523 2 32 2.44772 32 3H34C34 1.34315 32.6569 0 31 0V2ZM3 0C1.34315 0 0 1.34314 0 3H2C2 2.44772 2.44771 2 3 2V0Z"
      fill="url(#visa_paint_linear)"
      fillOpacity="0.2"
    />
    <defs>
      <linearGradient id="visa_paint_linear" x1="17" y1="1" x2="17" y2="25" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default VisaIcon;
