import { FC, useContext, useMemo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/navigation/Footer';
import NavBar, { TabRouteItem } from '../../components/navigation/NavBar';
import BaseLayout from '../../components/layouts/BaseLayout';
import Gallery from '../../components/media/Gallery';
import BaseIcon from '../../components/icons/BaseIcon';
import { ReactComponent as LockIcon } from '../../components/icons/iconic/lock-fill.svg';
import ForteWhiteHeaderIcon from '../../components/icons/ForteWhiteHeaderIcon';
import { ReactComponent as EthIcon } from '../../components/icons/iconic/eth.svg';
import MastercardIcon from '../../components/icons/MastercardIcon';
import VisaIcon from '../../components/icons/VisaIcon';
import AmexIcon from '../../components/icons/AmexIcon';
import { ReactComponent as HelpCircleIcon } from '../../components/icons/iconic/help-circle.svg';
import { ReactComponent as CheckmarkIcon } from '../../components/icons/iconic/checkmark.svg';
import { nameSpace } from '../../modules/i18n';
import Button from '../../components/basics/Button';
import Box from '../../components/basics/Box';
import { useQuery } from '../../tools/useQuery';
import SessionContext from '../../modules/session/Session.context';
import Announcement from '../../components/feedback/Announcement';
import Attribute from '../../components/feedback/Attribute';
import Rarity from '../../components/feedback/Rarity';
import routes from '../../config/routes';
import { ReactComponent as MinusIcon } from '../../components/icons/iconic/chevron-down.svg';
import { ReactComponent as PlusIcon } from '../../components/icons/iconic/chevron-up.svg';
import PageNavigation from '../../components/navigation/PageNavigation';
import PropertyCard from '../../components/basics/PropertyCard';
import useCurrency from '../../modules/currency/useCurrency';

interface LocationState {
  listed: boolean;
  owned: boolean;
  origin: string;
}

export const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapseIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
        },
      }}
    >
      <div className="expandIconWrapper">
        <MinusIcon />
      </div>
      <div className="collapseIconWrapper">
        <PlusIcon />
      </div>
    </Box>
  );
};

const salesHistory = new Array(10)
  .fill('')
  .map(() => ({ buyer: '@username', salePrice: '$99.00 USD', serial: '#0001', date: 'Jun 30, 2021 8:08PM' }));

const attributes = [
  {
    type: 'Background',
    name: 'Mystic World',
    description: '1% have this attribute',
  },
  {
    type: 'Hair',
    name: 'Long',
    description: '8% have this attribute',
  },
  {
    type: 'Face',
    name: 'Angry',
    description: '9% have this attribute',
  },
  {
    type: 'Eyes',
    name: 'Blue',
    description: '4% have this attribute',
  },
  {
    type: 'Weapon',
    name: 'Legendary Sword of the Greatest Lord',
    description: '34% have this attribute',
  },
  {
    type: 'Potion',
    name: 'Jamison’s Health Potion of Life',
    description: '3% have this attribute',
  },
  {
    type: 'Foreground',
    name: 'Emerald',
    description: '12% have this attribute',
  },
  {
    type: 'Aura',
    name: 'None',
    description: '23% have this attribute',
  },
  {
    type: 'Armour',
    name: 'None',
    description: '87% have this attribute',
  },
];

const MarketPlaceNftDetail: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const { listed, owned, origin } = (location.state as LocationState) || { listed: false, owned: false, origin: '' };
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { account, onUpdateAccount } = useContext(SessionContext);
  const { t } = useTranslation(nameSpace.eventNft);
  const [signedInParam, eventSoldOutParam] = useQuery(['signed-in', 'event-sold-out', 'purchase-limit-reached']);
  const eventSoldOut = useMemo(() => eventSoldOutParam === 'true', [eventSoldOutParam]);
  const { onShowCurrencyPicker, selectedCurrency } = useCurrency();

  const edition = useMemo(() => {
    if (eventSoldOut) {
      return 'SOLD OUT';
    }

    return '2987/5000';
  }, [eventSoldOut]);

  const action = useMemo(() => {
    if (owned && !listed) {
      return 'List for Sale';
    }

    if (owned && listed) {
      return 'Remove Listing';
    }

    if (!owned && listed) {
      return 'This item is not listed for sale';
    }

    return 'Select and Buy';
  }, [owned, listed]);

  const actionType = useMemo(() => {
    if (owned) {
      return 'secondary';
    }

    return 'primary';
  }, [owned]);

  const actionDisabled = useMemo(() => {
    return !owned && listed;
  }, [owned, listed]);

  const announcement = useMemo(() => {
    if (owned && !listed) {
      return 'Event Ended! All Sold Out';
    }

    if (listed) {
      return 'Next Drop on June 30th 2022 ';
    }

    return 'Hurry {{edition}} left';
  }, [listed, owned]);

  useEffect(() => {
    if (signedInParam === 'true' && !account?.id) {
      onUpdateAccount({ id: 'user-test-env', name: 'User Name', email: 'user@forte.io' });
    }
  }, [account?.id, onUpdateAccount, signedInParam]);

  const containerMargin = useMemo(() => {
    return isMobile ? '5px 0 0' : '10px 0 0';
  }, [isMobile]);

  const availability = useMemo(() => {
    if (owned && listed) {
      return {
        count: '18',
        info: 'Sold in the last 14 days',
      };
    }

    return {
      count: '24',
      info: 'Available for sale',
    };
  }, [owned, listed]);

  return (
    <BaseLayout footer={<Footer />} navBar={<NavBar selectedItem={TabRouteItem.nft} />}>
      <Announcement text={t(announcement, { edition })} />

      <Container sx={{ marginBottom: '200px' }}>
        <PageNavigation
          label={origin}
          path={routes.marketplace.path}
          onBack={() => {
            if (origin === 'Listing') {
              navigate(routes.marketplace.path);
              return;
            }
            navigate(-1);
          }}
        />
        <Grid container margin={containerMargin}>
          <Grid item xs={12} sm={5}>
            <Gallery
              editionText={edition}
              items={[
                {
                  id: 'a1',
                  src: '',
                  thumbnail: '',
                  mediaType: 'image',
                },
                {
                  id: 'a2',
                  src: '',
                  thumbnail: '',
                  mediaType: 'video',
                },
                {
                  id: 'a3',
                  src: '',
                  thumbnail: '',
                  mediaType: 'image',
                },
                {
                  id: 'a4',
                  src: '',
                  thumbnail: '',
                  mediaType: 'image',
                },

                {
                  id: 'a5',
                  src: '',
                  thumbnail: '',
                  mediaType: 'image',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Box paddingLeft={isMobile ? '0' : `${theme.custom.spacing.Spacing2Xl}px`}>
              <Box sx={{ maxWidth: '430px' }}>
                <Box marginBottom="12px">
                  <Typography variant="headline.mobile-md" lineHeight="120%">
                    NFT Example Title
                  </Typography>
                </Box>
                <Box marginBottom={`${theme.custom.spacing.SpacingSm}px`}>
                  <Typography variant="text-weak" fontWeight="bold" fontSize="20px">
                    Collection Title
                  </Typography>
                </Box>
                <Box marginBottom={`${theme.custom.spacing.Spacing5Xl}px`}>
                  <Typography variant="text-weak" fontWeight="bold" fontSize="20px">
                    #0001
                  </Typography>
                  {owned && (
                    <Box marginTop={`${theme.custom.spacing.SpacingBase}px`}>
                      <Typography variant="text-weak" fontSize="16px">
                        Owned by @username
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box display="flex" marginBottom="32px" alignItems="baseline" flexDirection="column">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    marginBottom={!owned && !listed ? `${theme.custom.spacing.Spacing2Xl}px` : '0'}
                  >
                    <span>
                      <Typography variant="text-default" fontSize="23px" fontWeight="bold">
                        {availability.count}
                      </Typography>{' '}
                      <Typography variant="text-default">{availability.info}</Typography>
                    </span>
                  </Box>
                </Box>
                {!owned && !listed && (
                  <>
                    <Box
                      display="flex"
                      marginBottom={`${theme.custom.spacing.SpacingLg}px`}
                      alignItems="baseline"
                      flexDirection="column"
                    >
                      <Typography variant="title.lg-strong" margin={`0 0 ${theme.custom.spacing.SpacingSm}px`}>
                        $99.00{' '}
                        <Typography variant="body.md-strong" color={theme.custom.colors.TextWeak}>
                          {selectedCurrency}
                        </Typography>
                      </Typography>
                      <Typography variant="label.sm" color={theme.custom.colors.TextWeak} margin="0">
                        Lowest Asking Price
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      marginBottom={`${theme.custom.spacing.SpacingLg}px`}
                      alignItems="baseline"
                      flexDirection="column"
                    >
                      <Typography variant="title.lg-strong" margin={`0 0 ${theme.custom.spacing.SpacingSm}px`}>
                        $105.00{' '}
                        <Typography variant="body.md-strong" color={theme.custom.colors.TextWeak}>
                          {selectedCurrency}
                        </Typography>
                      </Typography>
                      <Typography variant="label.sm" color={theme.custom.colors.TextWeak} margin="0">
                        Average Sale Price
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="baseline" flexDirection="column">
                      <Typography variant="title.lg-strong" margin={`0 0 ${theme.custom.spacing.SpacingSm}px`}>
                        $340.00{' '}
                        <Typography variant="body.md-strong" color={theme.custom.colors.TextWeak}>
                          {selectedCurrency}
                        </Typography>
                      </Typography>
                      <Typography variant="label.sm" color={theme.custom.colors.TextWeak} margin="0">
                        Top Sale Price
                      </Typography>
                    </Box>
                  </>
                )}

                {owned && listed && (
                  <Box display="flex" alignItems="baseline" flexDirection="column">
                    <Typography variant="title.lg-strong" margin={`0 0 ${theme.custom.spacing.SpacingSm}px`}>
                      $102.00{' '}
                      <Typography variant="body.md-strong" color={theme.custom.colors.TextWeak}>
                        {selectedCurrency}
                      </Typography>
                    </Typography>
                    <Typography variant="label.sm" color={theme.custom.colors.TextWeak} margin="0">
                      Asking Price
                    </Typography>
                  </Box>
                )}
                <Box display="flex" width="100%" paddingTop={`${theme.custom.spacing.Spacing2Xl}px`}>
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    variant="body.md"
                    color={theme.custom.colors.ActionAccent}
                    onClick={onShowCurrencyPicker}
                  >
                    Change Currency
                  </Typography>
                </Box>
                <Box width="100%" marginTop="48px">
                  <Button
                    type={actionType}
                    size="lg"
                    fullWidth
                    disabled={actionDisabled}
                    onClick={() => {
                      if (owned && listed) {
                        setShowRemoveModal(true);
                        return;
                      }
                      navigate(routes.marketplaceNftBuy.path, { state: { listed, owned } });
                    }}
                  >
                    {t(action)}
                  </Button>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" gap="5px" margin="10px 0 32px">
                  <Box display="flex" gap="5px" alignItems="center">
                    <BaseIcon icon={<LockIcon />} color={theme.custom.colors.TextWeak} />
                    <Typography variant="text-weak" fontSize="13px">
                      {t('eventNft.poweredBy')}
                    </Typography>
                    <BaseIcon width="60px" height="20px" icon={<ForteWhiteHeaderIcon />} margin="16px 0 0" />
                  </Box>
                  <Box display="flex" gap="5px" alignItems="center">
                    <BaseIcon width="24px" icon={<EthIcon />} />
                    <BaseIcon width="32px" icon={<MastercardIcon />} />
                    <BaseIcon width="32px" icon={<VisaIcon />} />
                    <BaseIcon width="32px" icon={<AmexIcon />} />
                  </Box>
                </Box>

                <Box margin="0 0 32px">
                  <Attribute
                    type="inline"
                    label={t('eventNft.properties.fees')}
                    description={t('eventNft.properties.no.fees')}
                    margin="0 0 6px"
                    padding="0"
                  />

                  <Attribute
                    type="inline"
                    label={t('eventNft.properties.royalties')}
                    description={t('eventNft.properties.no.royalties')}
                    margin="0 0 6px"
                    padding="0"
                  />
                </Box>

                <Box display="flex" margin="0 0 32px">
                  <Typography variant="label.lg" marginRight="5px">
                    {t('eventNft.needHelp')}
                  </Typography>
                  <Link lineHeight="120%" href={routes.faq.path}>
                    {t('eventNft.visitFaq')}
                  </Link>
                  <BaseIcon margin="-2px 0 0 8px" icon={<HelpCircleIcon />} />
                </Box>
              </Box>

              <Accordion>
                <AccordionSummary expandIcon={<CustomExpandIcon />}>
                  <Typography variant="headline.mobile-sm">Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body.lg" sx={{ marginBottom: `${theme.custom.spacing.Spacing2Xl}px` }}>
                      This is where you can give specific details of this NFT. You can make it your own and write what
                      you’d like. The floor is yours.
                    </Typography>
                    <Typography variant="body.lg" sx={{ marginBottom: `${theme.custom.spacing.Spacing2Xl}px` }}>
                      This will all be HTML content so you should be able to style this and add what you would like.
                      Including links, images, video, lists and buttons.
                    </Typography>
                    <Rarity
                      icon={<CheckmarkIcon />}
                      title="Dedicated team"
                      marginBottom={`${theme.custom.spacing.SpacingMd}px`}
                    />
                    <Rarity
                      icon={<CheckmarkIcon />}
                      title="Growing community"
                      marginBottom={`${theme.custom.spacing.SpacingMd}px`}
                    />
                    <Rarity icon={<CheckmarkIcon />} title="Pro Gaming tokenomics" />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<CustomExpandIcon />}>
                  <Typography variant="headline.mobile-sm">Attributes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    display="grid"
                    gridTemplateColumns="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)"
                    rowGap={`${theme.custom.spacing.SpacingLg}px`}
                    columnGap={`${theme.custom.spacing.SpacingLg}px`}
                    overflow="hidden"
                  >
                    {attributes.map((attr, index) => (
                      <PropertyCard key={`item-property-card-${index}`} {...attr} />
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<CustomExpandIcon />}>
                  <Typography variant="headline.mobile-sm">Sales History</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box marginBottom={`${theme.custom.spacing.Spacing2Xl}px`}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: `${theme.custom.spacing.SpacingLg}px ${theme.custom.spacing.SpacingLg}px ${theme.custom.spacing.SpacingLg}px 0`,
                            }}
                          >
                            Buyer
                          </TableCell>
                          <TableCell>Sale Price</TableCell>
                          <TableCell>Serial</TableCell>
                          <TableCell>Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {salesHistory.map((saleItem, index) => (
                          <TableRow key={`table-row-item-${index}`}>
                            <TableCell
                              sx={{
                                padding: `${theme.custom.spacing.SpacingLg}px ${theme.custom.spacing.SpacingLg}px ${theme.custom.spacing.SpacingLg}px 0`,
                              }}
                            >
                              <Typography variant="text-weak">{saleItem.buyer}</Typography>
                            </TableCell>
                            <TableCell>{saleItem.salePrice}</TableCell>
                            <TableCell>
                              <Typography variant="text-weak">{saleItem.salePrice}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="text-weak">{saleItem.date}</Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        open={showRemoveModal}
        onClose={() => setShowRemoveModal(false)}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': { maxWidth: '336px' },
          },
        }}
      >
        <DialogTitle>Remove Listing?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to remove this listing? It will no longer be for sale.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="secondary" size="sm">
            Cancel
          </Button>
          <Button type="primary" size="sm">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </BaseLayout>
  );
};

export default MarketPlaceNftDetail;
