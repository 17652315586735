import { CSSProperties, FC, ReactNode } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import BaseIcon from '../icons/BaseIcon';
import Box from '../basics/Box';

interface IRarityProps {
  icon: ReactNode;
  title: string | ReactNode;
  description?: string;
  percentage?: string;
  iconSize?: string;
  marginBottom?: CSSProperties['marginBottom'];
}

const Rarity: FC<IRarityProps> = ({ icon, title, description, percentage, iconSize = '24px', marginBottom }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        padding="0 20px"
        gap="20px"
        width="100%"
        marginBottom={marginBottom}
      >
        <Box display="flex" flexDirection="row" alignItems="flex-start" padding="0" width="100%">
          <BaseIcon
            icon={icon}
            width={iconSize}
            height={iconSize}
            margin={`0 ${theme.custom.spacing.SpacingMd}px 0 0`}
          />
          <Box display="flex" flexDirection="column" alignItems="flex-start" gap="4px">
            <Typography
              color={theme.custom.colors.TextDefault}
              variant={description ? 'body.lg-strong' : 'body.lg'}
              marginBottom={`${theme.custom.spacing.SpacingSm}px`}
            >
              {title}
            </Typography>
            {description && (
              <Typography color={theme.custom.colors.TextDefault} variant="body.lg">
                {description}
              </Typography>
            )}
          </Box>
        </Box>
        {percentage && (
          <Box display="flex" flexDirection="row" alignItems="flex-start" padding="0" gap="2px">
            <Typography variant="headline.mobile-sm" color={theme.custom.colors.TextDefault}>
              {percentage}
            </Typography>
            <Typography variant="label.sm" color={theme.custom.colors.TextDefault}>
              %
            </Typography>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      padding="0"
      gap="20px"
      width="100%"
      marginBottom={marginBottom}
    >
      <Box display="flex" flexDirection="row" alignItems="flex-start" padding="0" width="100%">
        <BaseIcon icon={icon} width={iconSize} height={iconSize} margin={`0 ${theme.custom.spacing.SpacingLg}px 0 0`} />
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography
            color={theme.custom.colors.TextDefault}
            variant={description ? 'title.lg-strong' : 'body.lg'}
            marginBottom={`${theme.custom.spacing.SpacingBase}px`}
          >
            {title}
          </Typography>
          {description && (
            <Typography color={theme.custom.colors.TextDefault} variant="body.lg">
              {description}
            </Typography>
          )}
        </Box>
      </Box>
      {percentage && (
        <Box display="flex" flexDirection="row" alignItems="flex-start" padding="0" gap="2px">
          <Typography variant="headline.mobile-sm" color={theme.custom.colors.TextDefault}>
            {percentage}
          </Typography>
          <Typography variant="label.sm" color={theme.custom.colors.TextDefault}>
            %
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Rarity;
