import { ICustomTheme } from '../../../theme';
import { Colors, BorderRadius, BoxShadow, FontFamilies, Spacing, Typography } from './tokens';

const customThemeOptions: ICustomTheme = {
  custom: {
    colors: {
      BgDefault: Colors.BgDefault,
      BgLayer1: Colors.BgLayer1,
      BgLayer2: Colors.BgLayer2,
      BgAside: Colors.BgAside,
      BgAccent: Colors.BgAccent,
      BgAccentWeak: Colors.BgAccentWeak,
      BgCritical: Colors.BgCritical,
      BgWarning: Colors.BgWarning,
      BgSuccess: Colors.BgSuccess,
      BgHighlight: Colors.BgHighlight,
      BgHighlightWeak: Colors.BgHighlightWeak,

      ActionAccent: Colors.ActionAccent,
      ActionAccentHover: Colors.ActionAccentHover,
      ActionCritical: Colors.ActionCritical,
      ActionCriticalHover: Colors.ActionCriticalHover,
      ActionSecondary: Colors.ActionSecondary,
      ActionSecondaryHover: Colors.ActionSecondaryHover,
      ActionTertiaryHover: Colors.ActionTertiaryHover,
      ActionDisabled: Colors.ActionDisabled,

      BorderDefault: Colors.BorderDefault,
      BorderSeparator: Colors.BorderSeparator,
      BorderField: Colors.BorderField,
      BorderTertiaryHover: Colors.BorderTertiaryHover,
      BorderAccent: Colors.BorderAccent,
      BorderCritical: Colors.BorderCritical,
      BorderHighlight: Colors.BorderHighlight,

      FieldDefault: Colors.FieldDefault,
      FieldSearch: Colors.FieldSearch,
      FieldDisabled: Colors.FieldDisabled,
      FieldCritical: Colors.FieldCritical,
      FieldWarning: Colors.FieldWarning,
      FieldSuccess: Colors.FieldSuccess,
      FieldHighlight: Colors.FieldHighlight,

      TextDefault: Colors.TextDefault,
      TextWeak: Colors.TextWeak,
      TextHint: Colors.TextHint,
      TextDisabled: Colors.TextDisabled,
      TextOnAccent: Colors.TextOnAccent,
      TextOnAccentWeak: Colors.TextOnAccentWeak,
      TextOnCritical: Colors.TextOnCritical,
      TextOnWarning: Colors.TextOnWarning,
      TextOnSuccess: Colors.TextOnSuccess,
      TextOnHighlight: Colors.TextOnHighlight,
      TextCritical: Colors.TextCritical,
      TextWarning: Colors.TextWarning,
      TextSuccess: Colors.TextSuccess,
      TextHighlight: Colors.TextHighlight,

      IconWeak: Colors.IconWeak,
      IconDisabled: Colors.IconDisabled,
      IconOnAccent: Colors.IconOnAccent,
      IconOnAccentWeak: Colors.IconOnAccentWeak,
      IconOnCritical: Colors.IconOnCritical,
      IconOnWarning: Colors.IconOnWarning,
      IconOnSuccess: Colors.IconOnSuccess,
      IconOnHighlight: Colors.IconOnHighlight,
      IconCritical: Colors.IconCritical,
      IconWarning: Colors.IconWarning,
      IconSuccess: Colors.IconSuccess,
      IconHighlight: Colors.IconHighlight,

      White: Colors.White,
      Black: Colors.Black,
      Yellow: Colors.Yellow,
      Purple: Colors.Purple,

      // others
      SlateDarkAlpha6: Colors.SlateDarkAlpha6,
    },
    borderRadius: {
      BorderRadiusRadiusScale: BorderRadius.BorderRadiusRadiusScale,
      BorderRadiusRadiusNone: BorderRadius.BorderRadiusRadiusNone,
      BorderRadiusRadiusSm: BorderRadius.BorderRadiusRadiusSm,
      BorderRadiusRadiusBase: BorderRadius.BorderRadiusRadiusBase,
      BorderRadiusRadiusMd: BorderRadius.BorderRadiusRadiusMd,
      BorderRadiusRadiusLg: BorderRadius.BorderRadiusRadiusLg,
      BorderRadiusRadiusXl: BorderRadius.BorderRadiusRadiusXl,
      BorderRadiusRadius2Xl: BorderRadius.BorderRadiusRadius2Xl,
      BorderRadiusRadius3Xl: BorderRadius.BorderRadiusRadius3Xl,
      BorderRadiusRadiusFull: BorderRadius.BorderRadiusRadiusFull,
    },
    shadows: {
      ShadowShadowXl: BoxShadow.ShadowShadowXl,
      ShadowShadowLg: BoxShadow.ShadowShadowLg,
      ShadowShadowMd: BoxShadow.ShadowShadowMd,
      ShadowShadowBase: BoxShadow.ShadowShadowBase,
      ShadowShadowInner: BoxShadow.ShadowShadowInner,
    },
    fontFamily: {
      FontFamiliesBody: FontFamilies.FontFamiliesHeadline,
      FontFamiliesHeadline: FontFamilies.FontFamiliesHeadline,
      FontFamiliesMono: FontFamilies.FontFamiliesMono,
    },
    spacing: {
      SpacingXs: Spacing.SpacingXs,
      SpacingSm: Spacing.SpacingSm,
      SpacingBase: Spacing.SpacingBase,
      SpacingMd: Spacing.SpacingMd,
      SpacingLg: Spacing.SpacingLg,
      SpacingXl: Spacing.SpacingXl,
      Spacing2Xl: Spacing.Spacing2Xl,
      Spacing3Xl: Spacing.Spacing3Xl,
      Spacing4Xl: Spacing.Spacing4Xl,
      Spacing5Xl: Spacing.Spacing5Xl,
      Spacing6Xl: Spacing.Spacing6Xl,
    },
    typography: {
      HeadlineMobileLg: Typography.HeadlineMobileLg,
      HeadlineMobileMd: Typography.HeadlineMobileMd,
      HeadlineMobileSm: Typography.HeadlineMobileSm,
      HeadlineDesktopLg: Typography.HeadlineDesktopLg,
      HeadlineDesktopMd: Typography.HeadlineDesktopMd,
      HeadlineDesktopSm: Typography.HeadlineDesktopSm,
      TitleLg: Typography.TitleLg,
      TitleLgStrong: Typography.TitleLgStrong,
      BodyLg: Typography.BodyLg,
      BodyLgStrong: Typography.BodyLgStrong,
      BodyMd: Typography.BodyMd,
      BodyMdStrong: Typography.BodyMdStrong,
      LabelLg: Typography.LabelLg,
      LabelLgStrong: Typography.LabelLgStrong,
      LabelMd: Typography.LabelMd,
      LabelMdStrong: Typography.LabelMdStrong,
      LabelSm: Typography.LabelSm,
      CodeLg: Typography.CodeLg,
      CodeMd: Typography.CodeMd,
      CodeSm: Typography.CodeSm,
    },
  },
};

export default customThemeOptions;
