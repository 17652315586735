import { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import NavBarMobile from './NavBarMobile';
import NavBarDesktop from './NavBarDesktop';

export enum TabRouteItem {
  home = 'home',
  faqs = 'faqs',
  roadMap = 'roadMap',
  whitePaper = 'whitePaper',
  eventDetails = 'eventDetails',
  nft = 'nft',
  marketplace = 'marketplace',
}

interface INavBarProps {
  selectedItem?: TabRouteItem;
}

const NavBar: FC<INavBarProps> = ({ selectedItem }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (matches) {
    return <NavBarMobile selectedItem={selectedItem} />;
  }

  return <NavBarDesktop selectedItem={selectedItem} />;
};

export default NavBar;
