import { FC } from 'react';
import { DesktopDatePicker as BasePicker } from '@mui/x-date-pickers/DesktopDatePicker/DesktopDatePicker';
import Styled from './DesktopDatePicker.styles';
import { ReactComponent as calendarIcon } from '../icons/iconic/calendar.svg';

interface IDesktopDatePicker {
  onChange: (value: Date | null, keyboardInputValue?: string | undefined) => void;
  format: string;
  value?: Date;
}

const DesktopDatePicker: FC<IDesktopDatePicker> = ({ format, onChange, value }) => (
  <BasePicker
    inputFormat={format}
    onChange={onChange}
    value={value}
    components={{
      OpenPickerIcon: calendarIcon,
    }}
    // @ts-ignore
    renderInput={params => <Styled.TextField {...params} />}
  />
);

export default DesktopDatePicker;
