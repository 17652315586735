import { FC, useContext } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import MarketplaceProviderContext from '../MarketplaceProvider.context';
import SearchResult from '../components/SearchResult';
import LatestSeries from '../components/LatestSeries';

const Results: FC = () => {
  const { showFilterOptions } = useContext(MarketplaceProviderContext);

  return (
    <>
      <AnimatePresence>
        {!showFilterOptions && (
          <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <LatestSeries />
          </motion.span>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showFilterOptions && (
          <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <SearchResult />
          </motion.span>
        )}
      </AnimatePresence>
    </>
  );
};

export default Results;
