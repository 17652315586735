import { FC, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GTMEvents } from 'src/lhu-toolkit/gtm/events';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { nameSpace } from '../../modules/i18n';
import Styled from './NavBarDesktop.styles';
import AccountNavigation from '../basics/AccountNavigation';
import routes from '../../config/routes';
import Logo from '../../assets/lhu_logo.png';
import { TabRouteItem } from './NavBar';

interface INavBarDesktopProps {
  height?: number;
  selectedItem?: TabRouteItem;
}

const NavBarDesktop: FC<INavBarDesktopProps> = ({ height = 72, selectedItem = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(nameSpace.common);
  const { hash } = useLocation();

  const handleOnGoTo = (path: string) => () => {
    navigate(path);
  };

  useEffect(() => {
    if (hash) {
      setTimeout(function () {
        const targetElement = document.getElementById(hash.substring(1));
        targetElement?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [hash]);

  return (
    <Styled.CustomAppBar position="fixed">
      <Styled.NavigationBar height={`${height}px`}>
        <Box className="nav-bar" width="100%" display="flex" justifyContent="space-between">
          <Styled.NavBar>
            <MenuItem onClick={handleOnGoTo('/')} selected={selectedItem === 'home' && !hash}>
              {t('common.footer.menu.item.1')}
            </MenuItem>
            <MenuItem onClick={handleOnGoTo('/home#roadmap')} selected={selectedItem === 'home' && hash === '#roadmap'}>
              {t('common.footer.menu.item.2')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                GTMEvents.clickBtnWhitepaper({ eventContext: 'nav-bar' });
                window.open(t('common.footer.menu.link.3'), '_blank');
              }}
            >
              {t('common.footer.menu.item.3')}
            </MenuItem>
            <MenuItem onClick={handleOnGoTo('/home#faqs')} selected={selectedItem === 'faqs'}>
              {t('common.footer.menu.item.4')}
            </MenuItem>
          </Styled.NavBar>
          <Button className="brand-logo" onClick={() => navigate(routes.homePage.path)}>
            <img className="img-fluid" src={Logo} alt={t('common.project.name')} />
          </Button>
        </Box>
        <AccountNavigation />
      </Styled.NavigationBar>
    </Styled.CustomAppBar>
  );
};

export default NavBarDesktop;
