import { FC } from 'react';
import { Typography } from '@mui/material';
import Styled from './Tag.styles';
import CloseIcon from '../icons/CloseIcon';

interface ITagProps {
  value: string;
  closable?: boolean;
  onClose?: () => void;
}

const Tag: FC<ITagProps> = ({ value, closable, onClose }) => {
  return (
    <Styled.Tag>
      <Typography variant="label.sm">{value}</Typography>
      {closable && (
        <div onClick={onClose}>
          <CloseIcon />
        </div>
      )}
    </Styled.Tag>
  );
};

export default Tag;
