import { FC } from 'react';
import HomePage from '../pages/home/HomePage';
import EventPage from '../pages/event/EventPage';
import FaqPage from '../pages/faq/Faq';
import EventNftPage from '../pages/eventNft/EventNft';
import NftDetailsPage from '../pages/nftDetails/NftDetails';
import NotFoundPage from '../pages/notFound/NotFound';
import MarketplacePage from '../pages/marketplace/Marketplace';
import MarketplaceNftDetail from '../pages/marketplace/MarketplaceNftDetail';
import MarketplaceNftBuy from '../pages/marketplace/MarketplaceNftBuy';
import ProfilePage from '../pages/account/profile/ProfilePage';
import CollectedPage from '../pages/account/collected/CollectedPage';
import ListedPage from '../pages/account/listed/ListedPage';
import HistoryPage from '../pages/account/history/HistoryPage';
import VerificationPage from '../pages/account/verification/VerificationPage';
import CollectionDetailsPage from '../pages/collectionDetails/CollectionDetailsPage';
import OthersAccount from '../pages/account/OthersAccount';
import MyAccount from '../pages/account/MyAccount';
import QueueTimePage from '../pages/queue/QueueTime';
import QueuePage from '../pages/queue/Queue';

export interface IRoute {
  path: string;
  root?: boolean;
  component?: FC;
  pageType: 'Home' | 'Event' | 'Marketplace' | 'Product Page' | 'Queue' | 'Account';
  nestedRoutes?: Record<string, IRoute>;
}

const routes: Record<string, IRoute> = {
  root: {
    path: '/',
    root: true,
    pageType: 'Home',
  },
  eventDetails: {
    path: '/event-details',
    component: EventPage,
    pageType: 'Event',
  },

  homePage: {
    path: '/home',
    component: HomePage,
    pageType: 'Home',
  },

  // homePage alias
  homeAlias0Page: {
    path: '/index',
    component: HomePage,
    pageType: 'Home',
  },
  homeAlias1Page: {
    path: '/index.html',
    component: HomePage,
    pageType: 'Home',
  },

  collectionDetails: {
    path: '/account/:collection/collection-details',
    component: CollectionDetailsPage,
    pageType: 'Account',
  },

  account: {
    path: '/account',
    component: MyAccount,
    pageType: 'Event',
    nestedRoutes: {
      profile: {
        path: 'profile',
        pageType: 'Account',
        component: ProfilePage,
      },
      collected: {
        path: 'collected',
        pageType: 'Account',
        component: CollectedPage,
      },
      listed: {
        path: 'listed',
        pageType: 'Account',
        component: ListedPage,
      },
      history: {
        path: 'history',
        pageType: 'Account',
        component: HistoryPage,
      },
      verification: {
        path: 'verification',
        pageType: 'Account',
        component: VerificationPage,
      },
    },
  },

  othersAccount: {
    path: '/account/:userId',
    component: OthersAccount,
    pageType: 'Account',
    nestedRoutes: {
      profile: {
        path: 'profile',
        pageType: 'Account',
        component: ProfilePage,
      },
      collected: {
        path: 'collected',
        pageType: 'Account',
        component: CollectedPage,
      },
      history: {
        path: 'history',
        pageType: 'Account',
        component: HistoryPage,
      },
    },
  },

  faq: {
    path: '/faq',
    component: FaqPage,
    pageType: 'Event',
  },
  eventNft: {
    path: '/event-nft',
    component: EventNftPage,
    pageType: 'Event',
  },
  nftDetails: {
    path: '/nft-details',
    component: NftDetailsPage,
    pageType: 'Event',
  },

  // Marketplace

  marketplace: {
    path: '/marketplace',
    component: MarketplacePage,
    pageType: 'Marketplace',
  },

  marketplaceNftDetail: {
    path: '/marketplace/:nftId/detail',
    component: MarketplaceNftDetail,
    pageType: 'Marketplace',
  },

  marketplaceNftBuy: {
    path: '/marketplace/:nftId/buy',
    component: MarketplaceNftBuy,
    pageType: 'Marketplace',
  },

  notFound: {
    path: '/404',
    component: NotFoundPage,
    pageType: 'Home',
  },

  notFoundAlias: {
    path: '*',
    component: NotFoundPage,
    pageType: 'Home',
  },

  // Queue
  queue: {
    path: '/queue/',
    component: QueuePage,
    pageType: 'Queue',
  },
  queueTime: {
    path: '/queue-time/',
    component: QueueTimePage,
    pageType: 'Queue',
  },

  // Roadmap
  roadMap: {
    path: '/home#roadmap',
    component: HomePage,
    pageType: 'Home',
  },
};

export const routesRootRedirect = {
  from: '/',
  to: routes.homePage.path,
};

export default routes;
