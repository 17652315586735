import { styled } from '@mui/material';

export const Root = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgDefault};
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  .brand-logo {
    max-width: 300px;
  }
`;
