import { Drawer, Toolbar, styled, AppBar } from '@mui/material';
import { CSSProperties } from 'react';

const CustomAppBar = styled(AppBar)`
  position: absolute;
  box-shadow: none;
  background-image: none;
  background-color: transparent;
  height: 78px;
  &.hasMenu {
    background-color: ${({ theme }) => theme.custom.colors.BgLayer1};
  }
`;

const NavigationBar = styled(Toolbar)<{ height: CSSProperties['height'] }>`
  height: ${({ height }) => height};
  .brand-logo {
    position: relative;
    left: -25px;
    margin: auto;
    max-width: 144px;
    top: 5px;
    &:hover {
      background-color: transparent;
    }
  }
  .hamburger-menu {
    width: 54px;
    padding: 8px 0;
    path {
      stroke-opacity: 1;
      transition: stroke 0.4s ease;
    }
    &:hover {
      background: none;
      path {
        stroke: ${({ theme }) => theme.custom.colors.Yellow};
      }
    }
  }
`;

const AppMenu = styled(Drawer)`
  top: 78px;
  .MuiBackdrop-root {
    top: 78px;
  }
  .MuiDrawer-paper {
    top: 78px;
    width: 100%;
    background-color: ${({ theme }) => theme.custom.colors.BgLayer1};
    background-image: none;
    box-shadow: none;

    li {
      position: relative;
      font-family: ${({ theme }) => theme.custom.fontFamily.FontFamiliesBody};
      font-style: normal;
      font-size: 1.6rem;
      line-height: 100%;
      display: block;
      cursor: pointer;
      color: ${({ theme }) => theme.custom.colors.White};
      transition: all 0.3s ease;
      margin: 0 23px;
      padding: 0;
      text-transform: uppercase;
      &:before {
        content: '';
        position: absolute;
        bottom: 8px;
        width: 0;
        height: 1px;
        transition: width 0.3s ease;
        background-color: ${({ theme }) => theme.custom.colors.Yellow};
      }
      &:hover,
      &.Mui-selected {
        background-color: transparent !important;
        color: ${({ theme }) => theme.custom.colors.Yellow};
        &:before {
          width: 100%;
        }
      }
    }
  }
`;

export default { NavigationBar, AppMenu, CustomAppBar };
