import { FC, useCallback, useMemo, useState } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import Styled from './CardHighlightList.styles';
import Media, { IMediaProps } from './Media';
import Button from '../basics/Button';
import Box from '../basics/Box';

interface ICardHighlight {
  serial: string;
  title: string;
  lowestPrice: string;
  averagePrice: string;
  mediaSource?: string;
  actionTitle: string;
  onClickActionButton: () => void;
  aspectRatio: IMediaProps['aspectRatio'];
  portrait?: IMediaProps['portrait'];
  crop?: boolean;
}

const CardHighlightList: FC<ICardHighlight> = ({
  serial,
  title,
  lowestPrice,
  averagePrice,
  actionTitle,
  onClickActionButton,
  mediaSource,
  aspectRatio,
  portrait,
  crop,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [hover, setHover] = useState<boolean>(false);

  const handleOnHover = useCallback(() => setHover(!hover), [hover]);

  const size = useMemo(() => {
    if (crop) {
      return { width: { min: '216px', max: '216px' }, height: { min: '224px', max: '268px' } };
    }

    if (isMobile) {
      return { width: { min: '336px', max: '336px' }, height: { min: 'auto', max: 'auto' } };
    }

    return { width: { min: '316px', max: '316px' }, height: { min: 'auto', max: 'auto' } };
  }, [crop, isMobile]);

  return (
    <Styled.Root onMouseEnter={handleOnHover} onMouseLeave={handleOnHover}>
      <Styled.Media height={crop ? '316px' : 'auto'}>
        <Media
          source={mediaSource}
          size="default"
          height="auto"
          width="auto"
          aspectRatio={aspectRatio}
          portrait={portrait}
          borderRadius="0"
          maxWidth={size.width.max}
          minWidth={size.width.min}
          maxHeight={size.height.max}
        />
      </Styled.Media>

      <Styled.DetailsWrapper>
        <AnimatePresence>
          <Styled.Details animate={{ y: hover ? -85 : 0 }} transition={{ duration: 0.15, ease: 'easeOut' }}>
            <Styled.Gradient animate={{ opacity: +hover }} transition={{ duration: 0.15, ease: 'easeOut' }} />

            <Box zIndex="10">
              <Box display="flex" flexDirection="column" padding="0" gap="8px">
                <Typography variant="label.sm" color={theme.custom.colors.TextOnAccentWeak}>
                  {serial}
                </Typography>
                <Box display="flex" flexDirection="column" padding="0" gap="4px">
                  <Typography variant="label.sm" color={theme.custom.colors.TextWeak}>
                    Collection Name
                  </Typography>

                  <Typography variant="title.lg-strong" color={theme.custom.colors.TextDefault}>
                    {title}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" padding="0" gap="2px" margin="15px 0 0">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="0"
                  gap="2px"
                >
                  <Typography variant="label.sm" color={theme.custom.colors.TextWeak}>
                    Lowest Asking Price
                  </Typography>
                  <Typography variant="body.md" color={theme.custom.colors.TextDefault}>
                    {lowestPrice}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="0"
                  gap="2px"
                >
                  <Typography variant="label.sm" color={theme.custom.colors.TextWeak}>
                    Average Sale Price
                  </Typography>
                  <Typography variant="label.sm" color={theme.custom.colors.TextWeak}>
                    {averagePrice}
                  </Typography>
                </Box>
              </Box>

              <Box margin="32px 0 0">
                <Button type="primary" size="lg" onClick={onClickActionButton} fullWidth>
                  {actionTitle}
                </Button>
              </Box>
            </Box>
          </Styled.Details>
        </AnimatePresence>
      </Styled.DetailsWrapper>
    </Styled.Root>
  );
};

export default CardHighlightList;
