import { FC, useCallback, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import Styled from './CardHighlightSmall.styles';
import Media from './Media';
import Button from '../basics/Button';

interface ICardHighlightSmall {
  title: string;
  percentage: string;
  rarity: string;
  mediaSource?: string;
  actionTitle: string;
  onClickActionButton: () => void;
}

const CardHighlightSmall: FC<ICardHighlightSmall> = ({
  title,
  percentage,
  rarity,
  actionTitle,
  onClickActionButton,
  mediaSource,
}) => {
  const theme = useTheme();
  const [hover, setHover] = useState<boolean>(false);

  const handleOnHover = useCallback(() => setHover(!hover), [hover]);

  return (
    <Styled.Root onMouseEnter={handleOnHover} onMouseLeave={handleOnHover}>
      <Styled.Media animate={{ scale: hover ? 1.75 : 1, y: 10 }} transition={{ duration: 0.15, ease: 'easeOut' }}>
        <Media source={mediaSource} size="default" width="160px" aspectRatio="1:1" height="160px" borderRadius="0" />
      </Styled.Media>
      <AnimatePresence>
        <Styled.DetailsHover animate={{ y: hover ? -70 : 0 }} transition={{ duration: 0.15, ease: 'easeOut' }}>
          <Styled.Gradient animate={{ opacity: +hover }} transition={{ duration: 0.15, ease: 'easeOut' }} />
          <Typography variant="headline.mobile-sm" color={theme.custom.colors.TextOnAccentWeak}>
            {title}
          </Typography>
          <Typography variant="headline.mobile-sm" color={theme.custom.colors.TextDefault}>
            {percentage}
          </Typography>
          <Typography variant="label.md" color={theme.custom.colors.TextDefault}>
            {rarity}
          </Typography>
          <Button type="primary" size="lg" onClick={onClickActionButton} fullWidth>
            {actionTitle}
          </Button>
        </Styled.DetailsHover>
      </AnimatePresence>
    </Styled.Root>
  );
};

export default CardHighlightSmall;
