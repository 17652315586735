import { FC, ReactNode, useMemo, useState } from 'react';
import SessionContext, { initialContext, ISessionContext } from './Session.context';

interface ISessionProviderProps {
  children: ReactNode;
}

const SessionProvider: FC<ISessionProviderProps> = ({ children }) => {
  const [account, setAccount] = useState<ISessionContext['account']>(initialContext.account);
  const [loading] = useState<boolean>(initialContext.loading);
  const [showProfileMenu, setShowProfileMenu] = useState<ISessionContext['showProfileMenu']>(
    initialContext.showProfileMenu,
  );
  const [showNavigationMenu, setShowNavigationMenu] = useState<ISessionContext['showNavigationMenu']>(
    initialContext.showNavigationMenu,
  );
  const value = useMemo(
    () => ({
      account,
      loading,
      showProfileMenu,
      showNavigationMenu,
      onUpdateNavigationMenuVisibility: setShowNavigationMenu,
      onUpdateAccount: setAccount,
      onUpdateProfileMenuVisibility: setShowProfileMenu,
    }),
    [account, loading, showProfileMenu, showNavigationMenu],
  );

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
