import { ChangeEvent, FC, useCallback, useState } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Styled from './MailingList.styles';
import Input from '../inputs/Input';
import { getTextShadow } from '../../tools/helpers';
import FormFeedback from './FormFeedback';
import { nameSpace } from '../../modules/i18n';
import Button from '../basics/Button';
import Box from '../basics/Box';
import useAnalytics from '../../modules/analytics/useAnalytics';

interface IMailingListProps {
  description?: string;
}

const MailingList: FC<IMailingListProps> = ({ description }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation(nameSpace.feedback);
  const [email, setEmail] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const { onPageAction } = useAnalytics();
  const { pathname } = useLocation();

  const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setEmail(value);
  }, []);

  const handleOnJoinMailList = useCallback(() => {
    setSuccess(true);
    onPageAction({
      title: document.title,
      path: pathname,
      href: location.href,
      actionName: 'join_mailing_list',
      actionCategory: 'social',
    });
  }, [onPageAction, pathname]);

  if (isMobile) {
    return (
      <Styled.RootMobile>
        <Styled.ContainerMobile>
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography
              variant="title.lg-strong"
              color={theme.custom.colors.TextDefault}
              sx={{ textShadow: getTextShadow(theme.custom.shadows.ShadowShadowBase) }}
            >
              {t('joinMailingList.title')}
            </Typography>
            <Typography
              variant="body.lg"
              color={theme.custom.colors.TextDefault}
              sx={{ textShadow: getTextShadow(theme.custom.shadows.ShadowShadowBase) }}
            >
              {description}
            </Typography>
          </Box>
          {success ? (
            <FormFeedback showIcon variant="success" message={t('joinMailingList.alert.success')} />
          ) : (
            <Box display="flex" flexDirection="column" gap="8px" width="100%">
              <Input id="email" value={email} onChange={handleOnChange} placeholder="Email address" />
              <Button type="primary" size="lg" onClick={handleOnJoinMailList}>
                {t('joinMailingList.action.join')}
              </Button>
            </Box>
          )}
        </Styled.ContainerMobile>
      </Styled.RootMobile>
    );
  }

  return (
    <Styled.RootDesktop>
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography
          variant="title.lg-strong"
          color={theme.custom.colors.TextDefault}
          sx={{ textShadow: getTextShadow(theme.custom.shadows.ShadowShadowBase) }}
        >
          {t('joinMailingList.title')}
        </Typography>
        <Typography
          variant="body.lg"
          color={theme.custom.colors.TextDefault}
          sx={{ textShadow: getTextShadow(theme.custom.shadows.ShadowShadowBase) }}
        >
          {description}
        </Typography>
      </Box>
      {success ? (
        <FormFeedback showIcon variant="success" message={t('joinMailingList.alert.success')} />
      ) : (
        <Box display="grid" width="calc(100% - 8px)" gridTemplateColumns="65% 35%" gap="8px">
          <Input id="email" height="30px" value={email} onChange={handleOnChange} placeholder="Email address" />
          <Button type="primary" size="lg" onClick={handleOnJoinMailList}>
            {t('joinMailingList.action.join')}
          </Button>
        </Box>
      )}
    </Styled.RootDesktop>
  );
};

export default MailingList;
