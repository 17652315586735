import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import ConfigurationContext from './Configuration.context';

interface IConfigurationProviderProps {
  children: ReactNode;
}

const ConfigurationProvider: FC<IConfigurationProviderProps> = ({ children }) => {
  const [showConfiguration, setShowConfiguration] = useState(false);

  const handleShowConfiguration = useCallback(() => {
    setShowConfiguration(true);
  }, []);

  const handleHideConfiguration = useCallback(() => {
    setShowConfiguration(false);
  }, []);

  const value = useMemo(
    () => ({
      showConfiguration,
      onShowConfiguration: handleShowConfiguration,
      onHideConfiguration: handleHideConfiguration,
    }),
    [showConfiguration, handleShowConfiguration, handleHideConfiguration],
  );

  return <ConfigurationContext.Provider value={value}>{children}</ConfigurationContext.Provider>;
};

export default ConfigurationProvider;
