import { createContext } from 'react';

export type Currency = {
  code: string;
  label: string;
};

export interface ICurrencyContext {
  currencies: Currency[];
  selectedCurrency: Currency['code'];
  onChangeCurrency?: (currencyCode: Currency['code']) => void;
  onHideCurrencyPicker?: VoidFunction;
  onShowCurrencyPicker?: VoidFunction;
}

export const initialContext: ICurrencyContext = {
  currencies: [],
  selectedCurrency: '',
  onChangeCurrency: undefined,
  onHideCurrencyPicker: undefined,
  onShowCurrencyPicker: undefined,
};

const CurrencyContext = createContext<ICurrencyContext>(initialContext);

CurrencyContext.displayName = 'CurrencyContext';

export default CurrencyContext;
