import { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
import Button from '../../../components/basics/Button';

const VerificationPage: FC = () => {
  return (
    <Container>
      <Box margin="25px 0">
        <Typography variant="text-default">Your verification status</Typography>
      </Box>

      <Box
        margin="65px 0 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="15px"
      >
        <Typography variant="text-weak">Verify ID to increase your spending limits</Typography>
        <Button type="secondary" size="lg">
          Complete ID Verification
        </Button>
      </Box>
    </Container>
  );
};

export default VerificationPage;
