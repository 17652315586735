import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tabs, Tab, Box, Container, useTheme, useMediaQuery } from '@mui/material';
import Footer from '../../components/navigation/Footer';
import BaseLayout from '../../components/layouts/BaseLayout';
import MediaHeader from './components/MediaHeader';
import NavBar from '../../components/navigation/NavBar';
import Divider from '../../components/basics/Divider';
import SessionContext from '../../modules/session/Session.context';
import Profile from './components/Profile';
import routes, { IRoute } from '../../config/routes';

interface IUserData {
  username: string;
  exam?: string;
  estimated?: string;
}

enum SelectedMenu {
  Profile,
  Collected,
  History,
  Listed,
  Verification,
}

interface IAccountPageProps {
  basePath: string;
  nestedRoutes: IRoute['nestedRoutes'];
  isLoggedUserProfile: boolean;
  userData: IUserData;
}

const AccountPage: FC<IAccountPageProps> = props => {
  const { basePath, nestedRoutes, isLoggedUserProfile, userData } = props;
  const { userId } = useParams();
  const { account } = useContext(SessionContext);
  const loggedUserId = account?.id;
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState<SelectedMenu>(SelectedMenu.Profile);
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOnSetMenu = useCallback(() => {
    if (pathname === '/account') {
      navigate(routes.account.nestedRoutes?.profile.path || '');

      return;
    }

    switch (pathname) {
      case `${basePath}/${nestedRoutes?.profile.path}`: {
        setSelectedMenu(SelectedMenu.Profile);
        break;
      }
      case `${basePath}/${nestedRoutes?.collected.path}`: {
        setSelectedMenu(SelectedMenu.Collected);
        break;
      }
      case `${basePath}/${nestedRoutes?.listed?.path}`: {
        setSelectedMenu(SelectedMenu.Listed);
        break;
      }
      case `${basePath}/${nestedRoutes?.history.path}`: {
        setSelectedMenu(SelectedMenu.History);
        break;
      }
      case `${basePath}/${nestedRoutes?.verification?.path}`: {
        setSelectedMenu(SelectedMenu.Verification);
        break;
      }
      default:
        break;
    }
  }, [pathname, basePath, nestedRoutes, navigate]);

  const handleOnChangeMenu = useCallback(
    (_: unknown, menu: SelectedMenu) => {
      switch (menu) {
        case SelectedMenu.Profile: {
          navigate(routes.account.nestedRoutes?.profile.path || '');
          break;
        }
        case SelectedMenu.Collected: {
          navigate(routes.account.nestedRoutes?.collected.path || '');
          break;
        }
        case SelectedMenu.Listed: {
          navigate(routes.account.nestedRoutes?.listed.path || '');
          break;
        }
        case SelectedMenu.History: {
          navigate(routes.account.nestedRoutes?.history.path || '');
          break;
        }
        case SelectedMenu.Verification: {
          navigate(routes.account.nestedRoutes?.verification.path || '');
          break;
        }
        default:
          break;
      }
    },
    [navigate],
  );

  useEffect(() => {
    handleOnSetMenu();
  }, [handleOnSetMenu, pathname]);

  useEffect(() => {
    const splitLocation = pathname.split('/');

    if (isLoggedUserProfile && splitLocation[2] === loggedUserId) {
      navigate(routes.account.path);
    }
  }, [isLoggedUserProfile, navigate, pathname, userId, loggedUserId]);

  return (
    <BaseLayout footer={<Footer />} navBar={<NavBar />}>
      <MediaHeader />
      <Box position="relative">
        <Container sx={{ backgroundColor: isMobile ? theme.custom.colors.BgLayer1 : 'transparent' }}>
          <Profile
            isLoggedUserProfile={isLoggedUserProfile}
            username={userData.username}
            estimated={userData.estimated}
            exam={userData.exam}
          />
          <Box maxWidth="calc(100vw - 50px)">
            <Tabs value={selectedMenu} onChange={handleOnChangeMenu} variant="scrollable">
              <Tab label="Profile" sx={{ marginLeft: 0 }} value={SelectedMenu.Profile} />
              <Tab label="Collected" value={SelectedMenu.Collected} />
              {isLoggedUserProfile && <Tab label="Listed" value={SelectedMenu.Listed} />}
              <Tab label="History" value={SelectedMenu.History} />
              {isLoggedUserProfile && <Tab label="Verification" value={SelectedMenu.Verification} />}
            </Tabs>
          </Box>
        </Container>

        <Divider margin="0" />
        <Box minHeight="300px" padding="0 0 25px">
          <Outlet />
        </Box>
      </Box>
    </BaseLayout>
  );
};

export default AccountPage;
