import { styled } from '@mui/material';

export const Hero = styled('div')`
  position: absolute;
  height: 145px;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.custom.colors.BgAccentWeak} 0%,
    ${({ theme }) => theme.custom.colors.BgDefault} 56.62%
  );
`;

export const Media = styled('div')`
  position: absolute;
  height: 200px;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.custom.colors.BgAccentWeak};
`;

export const Root = styled('div')`
  .MuiTypography-root {
    z-index: 99;
  }
`;
