import { CSSProperties, FC, ReactNode } from 'react';
import { Typography, TypographyProps, useMediaQuery, useTheme } from '@mui/material';
import { getTextShadow } from '../../tools/helpers';
import * as Styled from './HeaderBodyButton.styles';

interface IHeaderBodyButtonProps {
  header: ReactNode;
  body: ReactNode;
  actions?: ReactNode;
  maxWidth?: CSSProperties['maxWidth'];
  alignItems?: CSSProperties['alignItems'];
  textAlign?: CSSProperties['textAlign'];
  titleMobileVariant?: TypographyProps['variant'];
  titleDesktopVariant?: TypographyProps['variant'];
}

const HeaderBodyButton: FC<IHeaderBodyButtonProps> = ({
  maxWidth = 'initial',
  header,
  body,
  actions,
  alignItems = 'flex-start',
  textAlign = 'initial',
  titleMobileVariant = 'headline.mobile-lg',
  titleDesktopVariant = 'headline.desktop-lg',
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Styled.Container alignItems={alignItems} maxWidth={maxWidth}>
        <Styled.WrapperMobile alignItems={alignItems}>
          <Typography
            variant={titleMobileVariant}
            color={theme.custom.colors.TextDefault}
            sx={{ textShadow: getTextShadow(theme.custom.shadows.ShadowShadowXl) }}
            textAlign={textAlign}
          >
            {header}
          </Typography>
          <Typography
            variant="body.lg"
            color={theme.custom.colors.TextDefault}
            sx={{ textShadow: getTextShadow(theme.custom.shadows.ShadowShadowBase) }}
            textAlign={textAlign}
          >
            {body}
          </Typography>
        </Styled.WrapperMobile>
        {actions && <Styled.Actions alignItems={alignItems}>{actions}</Styled.Actions>}
      </Styled.Container>
    );
  }

  return (
    <Styled.Container alignItems={alignItems} maxWidth={maxWidth}>
      <Styled.WrapperDesktop alignItems={alignItems}>
        <Typography
          variant={titleDesktopVariant}
          color={theme.custom.colors.TextDefault}
          sx={{ textShadow: getTextShadow(theme.custom.shadows.ShadowShadowXl) }}
          textAlign={textAlign}
        >
          {header}
        </Typography>
        <Typography
          variant="body.lg"
          color={theme.custom.colors.TextDefault}
          sx={{ textShadow: getTextShadow(theme.custom.shadows.ShadowShadowBase) }}
          textAlign={textAlign}
        >
          {body}
        </Typography>
      </Styled.WrapperDesktop>
      {actions && <Styled.Actions alignItems={alignItems}>{actions}</Styled.Actions>}
    </Styled.Container>
  );
};

export default HeaderBodyButton;
