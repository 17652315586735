import { FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';
import Vimeo from '@vimeo/player';
import CardMedia from '@mui/material/CardMedia';
import ButtonBase from '@mui/material/ButtonBase';
import Media from '../../components/media/Media';
import Footer from '../../components/navigation/Footer';
import NavBar, { TabRouteItem } from '../../components/navigation/NavBar';
import BaseLayout from '../../components/layouts/BaseLayout';
import SectionHero from './components/SectionHero';
import SectionCollectible from './components/SectionCollectible';
import SectionCards from './components/SectionCards';
import SectionPromo from './components/SectionPromo';
import SectionRoadmap from './components/SectionRoadmap';
import SectionFaqs from './components/SectionFaqs';
import SectionTitle from './components/SectionTitle';
import NewsLetter from './components/NewsLetter';
import { nameSpace } from '../../modules/i18n';
import Box from '../../components/basics/Box';
import CollectibleTitle from './components/CollectibleTitle';
import BaseIcon from '../../components/icons/BaseIcon';
import Styled from './HomePage.styles';
import { ReactComponent as DiamondIcon } from '../../components/icons/iconic/custom-large-diamond.svg';
import LegendaryIcon from '../../assets/LegendaryIcon.png';

const HomePage: FC = () => {
  const { t } = useTranslation(nameSpace.home);
  const [inViewSect1, setInViewSect1] = useState<boolean>(false);
  const [inVideoView, setInVideoView] = useState<boolean>(false);
  const [inViewSect3, setInViewSect3] = useState<boolean>(false);
  const [inViewSect4, setInViewSect4] = useState<boolean>(false);
  const [inViewSect5, setInViewSect5] = useState<boolean>(false);
  const [inViewSect6, setInViewSect6] = useState<boolean>(false);
  const [inViewSect7, setInViewSect7] = useState<boolean>(false);
  const [showVideoFrame, setShowVideoFrame] = useState<boolean>(true);
  const [playVideo, setPlayVideo] = useState<boolean>(false);

  const handlePlayVideo = useCallback(() => {
    const vimeoPlayer = new Vimeo('player');
    setShowVideoFrame(false);
    vimeoPlayer.play();
  }, []);

  useEffect(() => {
    const vimeoPlayer = new Vimeo('player');
    vimeoPlayer.on('play', function () {
      setPlayVideo(true);
    });
    vimeoPlayer.on('pause', function () {
      setPlayVideo(false);
    });
    vimeoPlayer.on('ended', function () {
      setPlayVideo(false);
      setShowVideoFrame(true);
    });
  }, []);

  return (
    <BaseLayout footer={<Footer />} navBar={<NavBar selectedItem={TabRouteItem.home} />}>
      <Styled.Root>
        <SectionHero />

        <Styled.CollectionSection>
          <Styled.DiamondIcon icon={<DiamondIcon />} responsive />
          <Waypoint onEnter={() => setInViewSect1(true)} bottomOffset="10%">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={`inview animated ${inViewSect1 ? 'fadeInUp' : ''}`}
            >
              <Styled.CollectionTitleWrapper>
                <BaseIcon color="#000" icon={<CollectibleTitle title={t('home.collectible.title')} />} responsive />
                <SectionTitle
                  alignItems="center"
                  body={t('home.collectible.body')}
                  maxWidth="665px"
                  textAlign="center"
                  margin="2rem auto 0"
                />
              </Styled.CollectionTitleWrapper>
              <SectionCollectible />
            </Box>
          </Waypoint>
        </Styled.CollectionSection>

        <Styled.MainWrapper>
          <Waypoint onEnter={() => setInVideoView(true)} onLeave={() => setInVideoView(false)} bottomOffset="20%">
            <Styled.VideoWrapper>
              <Box className={`video-container-wrapper ${!inVideoView && playVideo ? 'mini' : ''}`}>
                <Styled.VideoInner>
                  <Styled.VideoEmbed>
                    <CardMedia
                      id="player"
                      component="iframe"
                      src={t('home.video')}
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                      frameBorder="0"
                    />
                  </Styled.VideoEmbed>
                  {showVideoFrame && !playVideo && (
                    <ButtonBase className="video-embed-responsive" onClick={handlePlayVideo}>
                      <Media
                        size="medium"
                        isVideo
                        borderRadius="0"
                        width="100%"
                        maxWidth="100%"
                        height="auto"
                        source="/assets/lhu_video_placerholder.jpg"
                        aspectRatio="16:9"
                      />
                    </ButtonBase>
                  )}
                </Styled.VideoInner>
              </Box>
            </Styled.VideoWrapper>
          </Waypoint>

          <Styled.StoryWrapper>
            <Waypoint onEnter={() => setInViewSect3(true)} bottomOffset="20%">
              <Box className={`inview animated ${inViewSect3 ? 'fadeInUp' : ''}`}>
                <Box className="center-section story-section">
                  <SectionTitle
                    alignItems="center"
                    header={t('home.story.title')}
                    body={t('home.story.content')}
                    textAlign="center"
                    isTitle
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  className="center-section collection-section"
                >
                  <div className="brand-icon animated">
                    <img className="img-fluid" src={LegendaryIcon} alt="Logo" />
                  </div>
                  <SectionTitle
                    alignItems="center"
                    header={t('home.cards.title')}
                    body={t('home.cards.subtitle')}
                    maxWidth="665px"
                    textAlign="center"
                  />
                </Box>
                <SectionCards />
              </Box>
            </Waypoint>
          </Styled.StoryWrapper>
        </Styled.MainWrapper>

        <Styled.RoadMapWrapper>
          <Waypoint onEnter={() => setInViewSect4(true)} bottomOffset="10%">
            <Box
              id="roadmap"
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={`roadmap-content inview animated ${inViewSect4 ? 'fadeInUp' : ''}`}
            >
              <SectionTitle alignItems="center" header={t('home.roadmap.title')} maxWidth="665px" textAlign="center" />
              <SectionRoadmap />
            </Box>
          </Waypoint>
        </Styled.RoadMapWrapper>

        <Waypoint onEnter={() => setInViewSect5(true)} bottomOffset="10%">
          <Styled.PromoWrapper>
            <Box
              id="discord"
              display="flex"
              flexDirection="column"
              alignItems="center"
              padding="0"
              gap="0"
              className={`inview animated ${inViewSect5 ? 'fadeInUp' : ''}`}
            >
              <SectionPromo />
            </Box>
          </Styled.PromoWrapper>
        </Waypoint>

        <Waypoint onEnter={() => setInViewSect6(true)} bottomOffset="10%">
          <Styled.NewsletterWrapper>
            <Box
              id="newsletter"
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={`inview animated ${inViewSect6 ? 'fadeInUp' : ''}`}
            >
              <NewsLetter />
            </Box>
          </Styled.NewsletterWrapper>
        </Waypoint>

        <Waypoint onEnter={() => setInViewSect7(true)} bottomOffset="10%">
          <Styled.FaqsWrapper>
            <Box
              id="faqs"
              display="flex"
              flexDirection="column"
              alignItems="center"
              padding="0"
              gap="0"
              className={`inview animated ${inViewSect7 ? 'fadeInUp' : ''}`}
            >
              <SectionFaqs />
            </Box>
          </Styled.FaqsWrapper>
        </Waypoint>
      </Styled.Root>
    </BaseLayout>
  );
};

export default HomePage;
