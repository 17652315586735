import { FC } from 'react';
import { Typography } from '@mui/material';
import Box from '../../../components/basics/Box';
import Checkbox from '../../../components/inputs/Checkbox';

interface IFilterOptionTypeProps {
  checked?: boolean;
  title: string;
  onClick: () => void;
}

const FilterOptionType: FC<IFilterOptionTypeProps> = ({ checked = false, onClick, title }) => {
  return (
    <Box
      onClick={onClick}
      display="flex"
      alignItems="center"
      gap="16px"
      height="46px"
      padding="8px 16px"
      sx={{ cursor: 'pointer' }}
    >
      <Checkbox checked={checked} />
      <Typography variant="body.lg">{title}</Typography>
    </Box>
  );
};

export default FilterOptionType;
