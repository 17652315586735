import { FC } from 'react';
import { MobileDatePicker as BasePicker } from '@mui/x-date-pickers/MobileDatePicker/MobileDatePicker';
import Styled from './MobileDatePicker.styles';
import { ReactComponent as CalendarIcon } from '../icons/iconic/calendar.svg';
import BaseIcon from '../icons/BaseIcon';

interface IMobileDatePicker {
  onChange: (value: Date | null, keyboardInputValue?: string | undefined) => void;
  format: string;
  value?: Date;
}

const MobileDatePicker: FC<IMobileDatePicker> = ({ format, onChange, value }) => (
  <BasePicker
    inputFormat={format}
    onChange={onChange}
    value={value}
    // @ts-ignore
    renderInput={params => (
      <Styled.TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: <BaseIcon icon={<CalendarIcon />} width="20px" margin="0 5px 0 0" />,
        }}
      />
    )}
  />
);

export default MobileDatePicker;
