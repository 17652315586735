import { Toolbar, styled, AppBar } from '@mui/material';
import { CSSProperties } from 'react';

const CustomAppBar = styled(AppBar)`
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  position: absolute;
`;

const NavBar = styled('div')`
  margin-top: 8px;
  width: 360px;
  display: flex;
  li {
    position: relative;
    padding: 0;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 13px;
    margin: 0 15px;
    color: ${({ theme }) => theme.custom.colors.White};
    transition: color 0.4s ease;
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      margin: auto;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      transform: scale(0);
      transition: all 0.4s ease;
      background-color: ${({ theme }) => theme.custom.colors.Yellow};
      ${({ theme }) => theme.breakpoints.down('lg')} {
        top: 15px;
      }
    }
    &:hover,
    &.Mui-selected {
      background-color: transparent !important;
      color: ${({ theme }) => theme.custom.colors.Yellow};
      &:before {
        transform: scale(1);
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 300px;
    li {
      font-size: 11px;
      margin: 0 8px;
    }
  }
`;

const NavigationBar = styled(Toolbar)<{ height: CSSProperties['height'] }>`
  height: ${({ height }) => height};
  .brand-logo {
    max-width: 240px;
    position: relative;
    top: 5px;
    left: -180px;
    margin: auto;
    &:hover {
      background: none !important;
    }
    ${({ theme }) => theme.breakpoints.down('lg')} {
      max-width: 173px;
      left: -150px;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      max-width: 144px;
    }
  }
  .nav-bar {
    height: 100%;
    width: 100%;
  }
`;

export default { NavigationBar, NavBar, CustomAppBar };
