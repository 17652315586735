import TagManager from 'react-gtm-module';

type EventOptions = {
  eventContext?: string;
};

const clickBtnWhitepaper = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'click-btn',
      eventSubject: 'whitepaper',
      eventContext: options?.eventContext || null,
    },
  });
};
const clickBtnDiscord = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'click-btn',
      eventSubject: 'discord',
      eventContext: options?.eventContext || null,
    },
  });
};
const clickBtnTwitter = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'click-btn',
      eventSubject: 'twitter',
      eventContext: options?.eventContext || null,
    },
  });
};
const clickBtnYoutube = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'click-btn',
      eventSubject: 'youtube',
      eventContext: options?.eventContext || null,
    },
  });
};
const clickBtnEmail = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'click-btn',
      eventSubject: 'email',
      eventContext: options?.eventContext || null,
    },
  });
};
const formSubmitEmailReg = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'form-submit',
      eventSubject: 'email-registration',
      eventContext: options?.eventContext || null,
    },
  });
};

export const GTMEvents = {
  clickBtnWhitepaper,
  clickBtnDiscord,
  clickBtnTwitter,
  clickBtnYoutube,
  clickBtnEmail,
  formSubmitEmailReg,
};
