import { Box, Container, styled } from '@mui/material';

export const Root = styled(Box)`
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  height: 600px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 300px;
  }
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.custom.colors.BgAccentWeak} 0%,
    rgba(26, 29, 30, 0) 56.62%
  );
  padding-bottom: 40px;
`;
export const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Media = styled(Box)`
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 470px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.custom.colors.BgAccentWeak};
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(21, 23, 24, 0.1), 0 4px 8px rgba(21, 23, 24, 0.05);

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 189px;
  }
`;
