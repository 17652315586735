import { CSSProperties, FC } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '../basics/Box';

interface IAttributeProps {
  type: 'inline' | 'stacked';
  label: string;
  description: string;
  margin?: CSSProperties['margin'];
  padding?: CSSProperties['padding'];
}

const Attribute: FC<IAttributeProps> = ({ label, description, type, margin = 0, padding }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (type === 'inline') {
    return (
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="1fr"
        gap="4px"
        padding={isMobile ? padding || '0 20px' : '0'}
        width="100%"
        margin={margin}
      >
        <Typography variant="body.lg" textAlign="start" color={theme.custom.colors.TextWeak}>
          {label}
        </Typography>
        <Typography variant="label.lg" textAlign="end" color={theme.custom.colors.TextDefault}>
          {description}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      display="grid"
      margin={margin}
      gridTemplateColumns="1fr"
      gridTemplateRows="1fr"
      gap="4px"
      padding={isMobile ? padding || '0 20px' : '0'}
      width="100%"
    >
      <Typography variant="label.lg-strong" color={theme.custom.colors.TextWeak}>
        {label}
      </Typography>
      <Typography variant="body.lg" color={theme.custom.colors.TextDefault}>
        {description}
      </Typography>
    </Box>
  );
};

export default Attribute;
