import { AppBar, styled } from '@mui/material';

export const Root = styled('div')`
  position: sticky;
  height: 48px;
  width: 100%;
  background: ${({ theme }) => theme.custom.colors.BgHighlight};
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  cursor: ${onClick => (onClick ? 'pointer' : 'initial')};
  user-select: none;
  z-index: 999;
`;

export const CustomAnnouncementBar = styled(AppBar)`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.custom.colors.BgHighlight};
  top: 72px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    top: 72px;
  }
`;
