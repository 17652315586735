import { styled, TextField as BaseTextField } from '@mui/material';

const TextField = styled(BaseTextField)`
  & .MuiOutlinedInput-root {
    padding: 8px;

    & .MuiInputAdornment-root {
      button {
        padding: 0 20px 0 0;

        :hover {
          background-color: unset;
        }
        * {
          background-color: unset;
        }
        svg {
          width: 15px;
          color: ${({ theme }) => theme.custom.colors.IconWeak};
        }
      }
    }
  }
`;

export default { TextField };
