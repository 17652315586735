import { TagManagerArgs } from 'react-gtm-module';
import { getTagManagerConfig } from './gtm/google-tag-manager';

type Environment = 'dev' | 'staging' | 'qa' | 'prod';
type LogLevel = 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'SILENT';

interface GlobalConfigInterface {
  is_prod: boolean;
  tag_manager_config: TagManagerArgs;
  logger_levels: {
    main: LogLevel;
  };
}

interface ConfigInterface extends GlobalConfigInterface {
  env: Environment;
}

const getConfig = (): ConfigInterface => {
  const env: string | undefined = process.env.REACT_APP_ENV;
  const isProd: boolean = env === 'prod';

  /**
   * Set global config settings
   */
  const GLOBAL: GlobalConfigInterface = {
    is_prod: isProd,
    tag_manager_config: getTagManagerConfig(env),
    // Each key in 'logger_levels' represents a separate logger
    logger_levels: {
      main: isProd ? 'INFO' : 'DEBUG',
    },
  };

  /**
   * Set environment-specific config settings
   */
  const dev: ConfigInterface = {
    ...GLOBAL,
    env: 'dev',
  };

  const staging: ConfigInterface = {
    ...GLOBAL,
    env: 'staging',
  };

  const qa: ConfigInterface = {
    ...GLOBAL,
    env: 'qa',
  };

  const prod: ConfigInterface = {
    ...GLOBAL,
    env: 'prod',
  };

  // Based on environment return the appropriate configuration
  switch (env) {
    case 'dev':
      return dev;
    case 'staging':
      return staging;
    case 'qa':
      return qa;
    case 'prod':
      return prod;
    default:
      return dev;
  }
};

export const config: ConfigInterface = getConfig();
