import { CSSProperties, FC } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { getTextShadow } from '../../tools/helpers';
import Box from '../basics/Box';

interface ISubHeaderBodyProps {
  header: string;
  body: string;
  maxWidth?: CSSProperties['maxWidth'];
}

const SubHeaderBody: FC<ISubHeaderBodyProps> = ({ maxWidth = 'initial', header, body }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box
        padding={isMobile ? '0 0 40px' : '0 20px 40px'}
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        gap="12px"
        maxWidth={maxWidth}
      >
        <Typography
          variant="headline.mobile-md"
          color={theme.custom.colors.TextDefault}
          sx={{ textShadow: getTextShadow(theme.custom.shadows.ShadowShadowMd) }}
        >
          {header}
        </Typography>
        <Typography
          variant="body.lg"
          color={theme.custom.colors.TextDefault}
          sx={{ textShadow: getTextShadow(theme.custom.shadows.ShadowShadowBase) }}
        >
          {body}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      padding="0 0 40px"
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      gap="16px"
      maxWidth={maxWidth}
    >
      <Box display="flex" alignItems="flex-start" flexDirection="column" gap="16px">
        <Typography
          variant="headline.desktop-md"
          color={theme.custom.colors.TextDefault}
          sx={{ textShadow: getTextShadow(theme.custom.shadows.ShadowShadowMd) }}
        >
          {header}
        </Typography>
        <Typography
          variant="body.lg"
          color={theme.custom.colors.TextDefault}
          sx={{ textShadow: getTextShadow(theme.custom.shadows.ShadowShadowBase) }}
        >
          {body}
        </Typography>
      </Box>
    </Box>
  );
};

export default SubHeaderBody;
