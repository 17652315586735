import { CSSProperties, FC, ReactNode } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import HeaderBodyButton from '../feedback/HeaderBodyButton';
import Media from './Media';
import Box from '../basics/Box';

interface IContentMediaProps {
  actions?: ReactNode;
  body: ReactNode;
  invert?: boolean;
  header: string;
  maxWidth?: CSSProperties['maxWidth'];
  source: string;
  isSourceVideo?: boolean;
  textAlign?: CSSProperties['textAlign'];
  variant?: 'lg' | 'md';
}

const ContentMedia: FC<IContentMediaProps> = ({
  maxWidth = 'initial',
  header,
  body,
  actions,
  invert,
  source,
  isSourceVideo,
  textAlign = 'initial',
  variant = 'lg',
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <Box
        display="grid"
        alignItems="flex-start"
        gridTemplateRows="auto auto"
        gridTemplateColumns="calc(100vw - 32px)"
        gap="16px"
        maxWidth={maxWidth}
      >
        {invert ? (
          <>
            <Media
              size="full-width"
              width="100%"
              aspectRatio="16:9"
              height="211px"
              source={source}
              borderRadius="8px"
              isVideo={isSourceVideo}
            />
            <HeaderBodyButton
              header={header}
              body={body}
              actions={actions}
              textAlign={textAlign}
              titleDesktopVariant={`headline.mobile-${variant}`}
              titleMobileVariant={`headline.mobile-${variant}`}
            />
          </>
        ) : (
          <>
            <HeaderBodyButton
              header={header}
              body={body}
              actions={actions}
              textAlign={textAlign}
              titleDesktopVariant={`headline.mobile-${variant}`}
              titleMobileVariant={`headline.mobile-${variant}`}
            />
            <Media
              size="full-width"
              aspectRatio="16:9"
              height="211px"
              source={source}
              borderRadius="8px"
              isVideo={isSourceVideo}
            />
          </>
        )}
      </Box>
    );
  }

  return (
    <Box
      display="grid"
      alignItems="flex-start"
      flexDirection="row"
      gap="24px"
      gridTemplateColumns="1fr 1fr"
      maxWidth={maxWidth}
      padding="32px 0"
    >
      {invert ? (
        <>
          <Media
            size="full-width"
            height="310px"
            aspectRatio="16:9"
            source={source}
            borderRadius="8px"
            isVideo={isSourceVideo}
          />
          <HeaderBodyButton
            header={header}
            body={body}
            actions={actions}
            textAlign={textAlign}
            titleDesktopVariant={`headline.desktop-${variant}`}
            titleMobileVariant={`headline.mobile-${variant}`}
          />
        </>
      ) : (
        <>
          <HeaderBodyButton
            header={header}
            body={body}
            actions={actions}
            textAlign={textAlign}
            titleDesktopVariant={`headline.desktop-${variant}`}
            titleMobileVariant={`headline.mobile-${variant}`}
          />
          <Media
            size="full-width"
            height="310px"
            aspectRatio="16:9"
            source={source}
            borderRadius="8px"
            isVideo={isSourceVideo}
          />
        </>
      )}
    </Box>
  );
};

export default ContentMedia;
