import { FC, useCallback, useMemo, useState } from 'react';
import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Media from '../../../components/media/Media';
import Button from '../../../components/basics/Button';
import BaseIcon from '../../../components/icons/BaseIcon';
import { ReactComponent as PlusIcon } from '../../../components/icons/iconic/plus.svg';
import { ReactComponent as RightArrowIcon } from '../../../components/icons/iconic/chevron-right.svg';
import Carousel from '../../../components/media/Carousel';
import RevealMediaPlaceholder from '../../../components/media/RevealMediaPlaceholder';
import RevealOneBackdrop from '../components/RevealOneBackdrop';
import RevealManyBackdrop, { IBaseCollectionDictionary } from '../components/RevealManyBackdrop';
import routes from '../../../config/routes';

const baseCollectionRevealMany: IBaseCollectionDictionary = {
  collection0: { type: 'reveal', description: 'Collection Name', subtitle: 'Pack Name', title: 'NFT #420' },
  collection1: { type: 'reveal', description: 'Collection Name', subtitle: 'Pack Name', title: 'NFT #421' },
  collection2: { type: 'reveal', description: 'Collection Name', subtitle: 'Pack Name', title: 'NFT #423' },
  collection3: { type: 'reveal', description: 'Collection Name', subtitle: 'Pack Name', title: 'NFT #424' },
  collection4: { type: 'reveal', description: 'Collection Name', subtitle: 'Pack Name', title: 'NFT #425' },
  collection5: { type: 'reveal', description: 'Collection Name', subtitle: 'Pack Name', title: 'NFT #426' },
};

const CollectedPage: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedRevealId, setSelectedRevealId] = useState<string>('');
  const [selectedRevealManyId, setSelectedRevealManyId] = useState<string>('');
  const [collectionRevealManyDictionary, setCollectionRevealManyDictionary] =
    useState<IBaseCollectionDictionary>(baseCollectionRevealMany);

  // TODO: remove to get data from services and handle empty states
  const hasData = Math.random() < 0.5;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnShowRevealBackdrop = (id: string) => () => setSelectedRevealId(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnShowRevealManyBackdrop = (id: string) => () => setSelectedRevealManyId(id);

  const handleOnHideRevealBackdrop = useCallback(() => {
    setSelectedRevealId('');
  }, []);

  const handleOnHideRevealManyBackdrop = useCallback(() => {
    setSelectedRevealManyId('');
  }, []);

  const collectionSingle = useMemo(
    () => new Array(10).fill('').map(() => <Media size="medium" aspectRatio="1:1" minHeight="140px" />),
    [],
  );

  const handleOnGoToViewCollection = useCallback(() => {
    navigate(routes.collectionDetails.path);
  }, [navigate]);

  const collectionRevealOneByOne = useMemo(() => {
    const reveal = new Array(1).fill('').map((_, index) => (
      <motion.div
        layoutId={`layout-${index}`}
        exit={{ transition: { duration: 0.3 } }}
        transition={{ duration: 0.3 }}
        style={{
          width: '140px',
          height: '140px',
        }}
      >
        <RevealMediaPlaceholder
          width="140px"
          height="140px"
          iconHeight="100px"
          iconWidth="170px"
          type="reveal"
          onClick={handleOnShowRevealBackdrop(index.toString())}
        />
      </motion.div>
    ));

    const unreleased = new Array(10)
      .fill('')
      .map(() => (
        <RevealMediaPlaceholder width="140px" height="140px" iconHeight="100px" iconWidth="140px" type="unreleased" />
      ));

    unreleased.unshift(...reveal);

    return unreleased;
  }, [handleOnShowRevealBackdrop]);

  const handleOnRevealCollection = useCallback(
    (id: string) => {
      const selectedItem = collectionRevealManyDictionary[id];

      setCollectionRevealManyDictionary({
        ...collectionRevealManyDictionary,
        [id]: {
          ...selectedItem,
          type: undefined,
        },
      });
      setSelectedRevealManyId(id);
    },
    [collectionRevealManyDictionary],
  );

  const handleOnRevealAllCollection = useCallback(() => {
    const updatedDictionary = Object.keys(collectionRevealManyDictionary).reduce((collection, key) => {
      return {
        ...collection,
        [key]: {
          ...collectionRevealManyDictionary[key],
          type: undefined,
        },
      };
    }, {});

    setCollectionRevealManyDictionary(updatedDictionary);
  }, [collectionRevealManyDictionary]);

  const collectionRevealManyComponents = useMemo(() => {
    return Object.keys(collectionRevealManyDictionary).map(key => (
      <motion.div
        layoutId={`layout-${key}`}
        exit={{ transition: { duration: 0.3 } }}
        transition={{ duration: 0.3 }}
        style={{
          width: '140px',
          height: '140px',
        }}
      >
        {collectionRevealManyDictionary[key].type ? (
          <RevealMediaPlaceholder
            key={key}
            width="140px"
            height="140px"
            iconHeight="100px"
            iconWidth="140px"
            type={collectionRevealManyDictionary[key].type}
            onClick={handleOnShowRevealManyBackdrop(key)}
          />
        ) : (
          <Media size="medium" aspectRatio="1:1" minHeight="140px" />
        )}
      </motion.div>
    ));
  }, [collectionRevealManyDictionary, handleOnShowRevealManyBackdrop]);

  if (isMobile) {
    return (
      <>
        {!hasData && (
          <Container>
            <Box marginTop={`${theme.custom.spacing.Spacing3Xl}px`}>
              <Typography variant="text-default">No collected items yet</Typography>
            </Box>
          </Container>
        )}
        {hasData && (
          <Box width="100%">
            <Box margin="25px">
              <Typography>Your purchases</Typography>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" padding="15px 25px">
              <Typography variant="label.md">Collection Name</Typography>
              <Button
                type="tertiary"
                size="sm"
                icon={<RightArrowIcon />}
                iconPosition="after"
                onClick={handleOnGoToViewCollection}
              >
                View All
              </Button>
            </Box>

            <Box display="flex" flexDirection="row" padding={0} flexWrap="wrap" alignItems="flex-start">
              <AnimatePresence>
                <motion.div
                  initial={{ x: 50, opacity: 0 }}
                  exit={{ x: 50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <Carousel items={collectionSingle} />
                </motion.div>
              </AnimatePresence>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" padding="15px 25px">
              <Typography variant="label.md">Collection Name</Typography>
              <Button
                type="tertiary"
                size="sm"
                icon={<RightArrowIcon />}
                iconPosition="after"
                onClick={handleOnGoToViewCollection}
              >
                View All
              </Button>
            </Box>

            <Box width="100vw">
              <AnimatePresence>
                <motion.div
                  initial={{ x: 50, opacity: 0 }}
                  exit={{ x: 50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  {/* @ts-ignore */}
                  <AnimateSharedLayout type="crossfade">
                    <RevealOneBackdrop
                      onClose={handleOnHideRevealBackdrop}
                      open={!!selectedRevealId}
                      selectedId={selectedRevealId}
                    />

                    <Carousel width="auto" items={collectionRevealOneByOne} />
                  </AnimateSharedLayout>
                </motion.div>
              </AnimatePresence>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" padding="15px 25px">
              <Typography variant="label.md">Collection Name</Typography>
              <Button
                type="tertiary"
                size="sm"
                icon={<RightArrowIcon />}
                iconPosition="after"
                onClick={handleOnGoToViewCollection}
              >
                View All
              </Button>
            </Box>

            <Box width="100vw">
              <AnimatePresence>
                <motion.div
                  initial={{ x: 50, opacity: 0 }}
                  exit={{ x: 50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  {/* @ts-ignore */}
                  <AnimateSharedLayout type="crossfade">
                    <RevealManyBackdrop
                      onClose={handleOnHideRevealManyBackdrop}
                      open={!!selectedRevealManyId}
                      onReveal={handleOnRevealCollection}
                      collectionRevealManyDictionary={collectionRevealManyDictionary}
                      selectedId={selectedRevealManyId}
                      onRevealAll={handleOnRevealAllCollection}
                    />

                    <Carousel width="auto" items={collectionRevealManyComponents} />
                  </AnimateSharedLayout>
                </motion.div>
              </AnimatePresence>
            </Box>

            <Box
              margin="65px 0 0"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              gap="15px"
            >
              <Typography variant="caption">Add to your collection</Typography>
              <Button type="secondary" size="lg" icon={<BaseIcon icon={<PlusIcon />} height={12} />}>
                Buy NFTs
              </Button>
            </Box>
          </Box>
        )}
      </>
    );
  }

  return (
    <Container>
      {!hasData && (
        <Box marginTop={`${theme.custom.spacing.Spacing3Xl}px`}>
          <Typography variant="text-default">No collected items yet</Typography>
        </Box>
      )}
      {hasData && (
        <>
          <Box margin="25px">
            <Typography>Your purchases</Typography>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" padding="15px 25px">
            <Typography variant="label.md">Collection Name</Typography>
            <Button
              type="tertiary"
              size="sm"
              icon={<RightArrowIcon />}
              iconPosition="after"
              onClick={handleOnGoToViewCollection}
            >
              View All
            </Button>
          </Box>

          <Box maxWidth="1200px" width="100%">
            <AnimatePresence>
              <motion.div
                initial={{ x: 50, opacity: 0 }}
                exit={{ x: 50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <Carousel width="auto" items={collectionSingle} />
              </motion.div>
            </AnimatePresence>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" padding="15px 25px">
            <Typography variant="label.md">Collection Name</Typography>
            <Button
              type="tertiary"
              size="sm"
              icon={<RightArrowIcon />}
              iconPosition="after"
              onClick={handleOnGoToViewCollection}
            >
              View All
            </Button>
          </Box>

          <Box maxWidth="1200px" width="100%">
            <AnimatePresence>
              <motion.div
                initial={{ x: 50, opacity: 0 }}
                exit={{ x: 50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                {/* @ts-ignore */}
                <AnimateSharedLayout type="crossfade">
                  <RevealOneBackdrop
                    onClose={handleOnHideRevealBackdrop}
                    open={!!selectedRevealId}
                    selectedId={selectedRevealId}
                  />

                  <Carousel width="auto" items={collectionRevealOneByOne} />
                </AnimateSharedLayout>
              </motion.div>
            </AnimatePresence>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" padding="15px 25px">
            <Typography variant="label.md">Collection Name</Typography>
            <Button
              type="tertiary"
              size="sm"
              icon={<RightArrowIcon />}
              iconPosition="after"
              onClick={handleOnGoToViewCollection}
            >
              View All
            </Button>
          </Box>

          <Box maxWidth="1200px" width="100%">
            <AnimatePresence>
              <motion.div
                initial={{ x: 50, opacity: 0 }}
                exit={{ x: 50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                {/* @ts-ignore */}
                <AnimateSharedLayout type="crossfade">
                  <RevealManyBackdrop
                    onClose={handleOnHideRevealManyBackdrop}
                    open={!!selectedRevealManyId}
                    onReveal={handleOnRevealCollection}
                    collectionRevealManyDictionary={collectionRevealManyDictionary}
                    selectedId={selectedRevealManyId}
                    onRevealAll={handleOnRevealAllCollection}
                  />

                  <Carousel width="auto" items={collectionRevealManyComponents} />
                </AnimateSharedLayout>
              </motion.div>
            </AnimatePresence>
          </Box>

          <Box
            margin="65px 0 0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="15px"
          >
            <Typography variant="caption">Add to your collection</Typography>
            <Button type="secondary" size="lg" icon={<BaseIcon icon={<PlusIcon />} height={12} />}>
              Buy NFTs
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default CollectedPage;
