import { CSSProperties, FC, ReactNode, useMemo } from 'react';
import * as Styled from './Button.styles';

export interface IButtonProps {
  type: 'primary' | 'secondary' | 'tertiary' | 'critical' | 'outlined';
  size: 'sm' | 'lg';
  disabled?: boolean;
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  iconPosition?: 'before' | 'after';
  icon?: ReactNode;
  fullWidth?: boolean;
  height?: CSSProperties['height'];
  justifyContent?: CSSProperties['justifyContent'];
  forceShowBorder?: boolean;
}

const Button: FC<IButtonProps> = ({
  children,
  size,
  type,
  disabled = false,
  onClick = () => undefined,
  iconPosition = 'before',
  icon = null,
  fullWidth = false,
  height,
  justifyContent = 'center',
  forceShowBorder = false,
}) => {
  const buttonHeight = useMemo(() => {
    if (height) {
      return height;
    }

    return size === 'sm' ? '32px' : '48px';
  }, [size, height]);
  const padding = useMemo(() => (size === 'sm' ? '8px 12px' : '16px'), [size]);
  const flexDirection = useMemo(() => (iconPosition === 'after' && icon ? 'row-reverse' : 'row'), [iconPosition, icon]);
  const handleOnClick = useMemo(() => (disabled ? () => undefined : onClick), [disabled, onClick]);

  if (type === 'primary') {
    const PrimaryButton = disabled ? Styled.PrimaryButtonDisabled : Styled.PrimaryButton;

    return (
      <PrimaryButton
        height={buttonHeight}
        padding={padding}
        flexdirection={flexDirection}
        justifycontent={justifyContent}
        onClick={handleOnClick}
        width={fullWidth ? '100%' : 'auto'}
      >
        {icon}
        <Styled.ButtonContent>{children}</Styled.ButtonContent>
      </PrimaryButton>
    );
  }

  if (type === 'secondary') {
    const SecondaryButton = disabled ? Styled.SecondaryButtonDisabled : Styled.SecondaryButton;

    return (
      <SecondaryButton
        height={buttonHeight}
        padding={padding}
        flexdirection={flexDirection}
        justifycontent={justifyContent}
        onClick={handleOnClick}
        width={fullWidth ? '100%' : 'auto'}
        forceshowborder={+forceShowBorder}
      >
        {icon}
        <Styled.ButtonContent>{children}</Styled.ButtonContent>
      </SecondaryButton>
    );
  }

  if (type === 'tertiary') {
    const TertiaryButton = disabled ? Styled.TertiaryButtonDisabled : Styled.TertiaryButton;

    return (
      <TertiaryButton
        height={buttonHeight}
        padding={padding}
        flexdirection={flexDirection}
        justifycontent={justifyContent}
        onClick={handleOnClick}
        width={fullWidth ? '100%' : 'auto'}
      >
        {icon}
        <Styled.ButtonContent>{children}</Styled.ButtonContent>
      </TertiaryButton>
    );
  }

  if (type === 'critical') {
    const CriticalButton = disabled ? Styled.CriticalButtonDisabled : Styled.CriticalButton;

    return (
      <CriticalButton
        height={buttonHeight}
        padding={padding}
        flexdirection={flexDirection}
        justifycontent={justifyContent}
        onClick={handleOnClick}
        width={fullWidth ? '100%' : 'auto'}
      >
        {icon}
        <Styled.ButtonContent>{children}</Styled.ButtonContent>
      </CriticalButton>
    );
  }

  if (type === 'outlined') {
    const OutlinedButton = disabled ? Styled.OutlinedButtonDisabled : Styled.OutlinedButton;

    return (
      <OutlinedButton
        height={buttonHeight}
        padding={padding}
        flexdirection={flexDirection}
        justifycontent={justifyContent}
        onClick={handleOnClick}
        width={fullWidth ? '100%' : 'auto'}
      >
        {icon}
        <Styled.ButtonContent>{children}</Styled.ButtonContent>
      </OutlinedButton>
    );
  }

  return null;
};

export default Button;
