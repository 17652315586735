import { createContext } from 'react';

export interface IConfigurationContext {
  showConfiguration: boolean;
  onShowConfiguration?: VoidFunction;
  onHideConfiguration?: VoidFunction;
}

export const initialContext: IConfigurationContext = {
  showConfiguration: false,
  onShowConfiguration: undefined,
  onHideConfiguration: undefined,
};

const ConfigurationContext = createContext<IConfigurationContext>(initialContext);

ConfigurationContext.displayName = 'ConfigurationContext';

export default ConfigurationContext;
