import { Popover, styled } from '@mui/material';

const Root = styled(Popover)`
  .MuiPopover-paper {
    ${({ theme }) => theme.breakpoints.up('sm')} {
      border-radius: 8px;
      margin: 25px 0 0 15px;
      max-width: 378px;
      width: 100%;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      border-radius: 0 0 16px 16px;
      margin: 0;
      max-width: 100vw;
      width: 100vw;
    }

    background-color: ${({ theme }) => theme.custom.colors.BgDefault};
    border: 1px solid ${({ theme }) => theme.custom.colors.BorderDefault};
  }
`;

export default { Root };
