import { Avatar, styled } from '@mui/material';
import { CSSProperties } from 'react';

const Root = styled(Avatar)<{
  margin?: CSSProperties['margin'];
  width: CSSProperties['width'];
  height: CSSProperties['height'];
}>`
  background-color: ${({ theme }) => theme.custom.colors.BgAccentWeak};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border: 2px solid ${({ theme }) => theme.custom.colors.BgLayer1};
  border-radius: ${({ theme }) => theme.custom.borderRadius.BorderRadiusRadiusFull};
`;

export default { Root };
