import { styled } from '@mui/material';
import { flexGap } from '../../tools/flexGapPoly';

const Footer = styled('div')`
  position: relative;
  padding: 2.5rem 0 1rem;
  .legal-links {
    span {
      position: relative;
      cursor: pointer;
      transition: color 0.3s ease;
      &:hover {
        color: ${({ theme }) => theme.custom.colors.Yellow};
      }
      &:first-of-type {
        margin-right: 20px;
        &:after {
          content: '|';
          position: absolute;
          right: -12px;
          color: #fff !important;
        }
      }
    }
  }
`;

const FooterNav = styled('div')`
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem auto;
  ${({ theme }) => flexGap(`${theme.custom.spacing.Spacing4Xl}px`)}
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    text-align: center;
    padding: 20px;
    ${({ theme }) => flexGap(`${theme.custom.spacing.SpacingLg}px`, 'column')}
  }
  span {
    color: ${({ theme }) => theme.custom.colors.White};
    text-transform: uppercase;
    transition: color 0.3s ease;
    &:hover {
      color: ${({ theme }) => theme.custom.colors.Yellow};
    }
  }
`;

const CookiePrivacy = styled('div')`
  background: rgba(50, 50, 58, 0.9);
  position: fixed;
  z-index: 99;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 8px 20px;
  font-size: 11px;
  a {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
  #agree {
    height: 23px;
    width: auto;
    padding: 3px 10px;
    min-width: auto;
    line-height: 1px;
    color: ${({ theme }) => theme.custom.colors.White};
    background-color: #000000;
    transition: background 0.4s ease;
    &:hover {
      background-color: ${({ theme }) => theme.custom.colors.Yellow};
    }
  }
  button {
    width: 30px;
    &:hover {
      color: ${({ theme }) => theme.custom.colors.Yellow};
      background-color: transparent;
    }
  }
`;

export default { Footer, FooterNav, CookiePrivacy };
