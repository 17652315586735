import { FC } from 'react';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as PlusIcon } from '../icons/iconic/plus.svg';
import { ReactComponent as MinusIcon } from '../icons/iconic/minus.svg';
import Box from '../basics/Box';

export const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapseIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
        },
      }}
    >
      <div className="expandIconWrapper">
        <MinusIcon />
      </div>
      <div className="collapseIconWrapper">
        <PlusIcon />
      </div>
    </Box>
  );
};
// TODO: remove this when mocks be done
const questions: { question: string; answer: string }[] = new Array(7).fill({
  question: 'This is a question',
  answer: 'this is an answer',
});

const FaqList: FC = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%" marginBottom="140px">
      <Typography textAlign="left" width="100%" maxWidth="664px" variant="headline.desktop-md" marginBottom="40px">
        FAQ
      </Typography>
      {questions.map(({ question, answer }, index) => (
        <Accordion key={`faq-${index}`}>
          <AccordionSummary expandIcon={<CustomExpandIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="title.lg-strong">{question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body.lg">{answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FaqList;
