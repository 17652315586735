import { FC } from 'react';
import Button, { IButtonProps } from '../../../components/basics/Button';
import { ReactComponent as SettingsIcon } from '../../../components/icons/iconic/settings-sliders.svg';
import Box from '../../../components/basics/Box';
import Badge from '../../../components/basics/Badge';

interface IFilterButtonProps {
  ButtonProps: Omit<IButtonProps, 'children'>;
  onClick?: () => void;
  value?: string;
}

const FilterButton: FC<IFilterButtonProps> = ({ ButtonProps, onClick = () => undefined, value }) => {
  return (
    <Button {...ButtonProps} onClick={onClick}>
      <Box display="flex" justifyContent="center" alignItems="center" gap="8px">
        <SettingsIcon />
        <span>Filters</span>
        {value && <Badge value={value} />}
      </Box>
    </Button>
  );
};

export default FilterButton;
