import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useMediaQuery, useTheme } from '@mui/material';
import Styled from './SearchResult.styles';
import CardHighlightList from '../../../components/media/CardHighlightList';
import Box from '../../../components/basics/Box';
import MarketplaceProviderContext from '../MarketplaceProvider.context';
import routes from '../../../config/routes';

const variants = {
  offscreen: {
    opacity: 0,
    y: 30,
  },
  onscreen: {
    opacity: 1,
    y: 0,
  },
};

const SearchResult: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { loadingSeries } = useContext(MarketplaceProviderContext);
  const navigate = useNavigate();

  return (
    <Box width="100%" margin="0 0 150px" padding={isMobile ? '0 5px' : '0 40px'}>
      <Styled.Section>
        <Styled.Row>
          <AnimatePresence>
            {!loadingSeries &&
              new Array(Math.floor(Math.random() * 20) + 8).fill('').map((_, index) => (
                <motion.div
                  key={`series-${index}`}
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.2 }}
                  exit={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <motion.div variants={variants}>
                    <CardHighlightList
                      title="NFT Example Title"
                      actionTitle="Select and buy now"
                      onClickActionButton={() => {
                        navigate(routes.marketplaceNftDetail.path, { state: { origin: 'Listing' } });
                      }}
                      aspectRatio="1:1"
                      serial="Legendary /5000"
                      averagePrice="$105.00 USD"
                      lowestPrice="$99.00 USD"
                    />
                  </motion.div>
                </motion.div>
              ))}
          </AnimatePresence>
        </Styled.Row>
      </Styled.Section>
    </Box>
  );
};

export default SearchResult;
