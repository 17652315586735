import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import Styled from './RoadmapHeaderBody.styles';
import Box from '../../../components/basics/Box';

interface ISubHeaderBodyProps {
  id: number;
  date: string;
  header: string;
  body: string;
}

const RoadMapHeaderBody: FC<ISubHeaderBodyProps> = ({ id, date, header, body }) => {
  const theme = useTheme();

  return (
    <Styled.Wrapper>
      <Box padding="0 0 40px" display="flex" alignItems="flex-start" flexDirection="column" gap="16px">
        <Box className={`animated delay-${id}`} display="flex" alignItems="flex">
          <div className="bullet">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <g transform="translate(6.364) rotate(45)" strokeWidth="2">
                <rect width="10" height="10" stroke="none" />
                <rect x="1" y="1" width="8" height="8" fill="none" />
              </g>
            </svg>
          </div>
          <Box className="time-entry" display="flex" alignItems="flex-start" flexDirection="column">
            <Typography variant="headline.desktop-md" color={theme.custom.colors.TextDefault}>
              <p className="date">{date}</p>
            </Typography>
            <Typography variant="headline.desktop-md" color={theme.custom.colors.TextDefault}>
              <h4 className="headline">{header}</h4>
            </Typography>
            <Typography variant="body.lg" color={theme.custom.colors.TextDefault}>
              <p className="content">{body}</p>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Styled.Wrapper>
  );
};

export default RoadMapHeaderBody;
