import { FC } from 'react';
import Styled from './ButtonSVG.styles';

export interface IButtonSVGProps {
  bgcolor: string;
  bgcolor2: string;
  bgcolor3?: string;
  type?: 'submit' | 'button' | 'reset';
  offsetX?: string;
  color: string;
  title: string;
  hoverEffect?: boolean;
  onClick?: VoidFunction;
}

const ButtonDiscordSVG: FC<IButtonSVGProps> = ({
  bgcolor = '#FFF',
  bgcolor2,
  bgcolor3 = '#F9C43C',
  offsetX = 8,
  color,
  type = 'button',
  title,
  hoverEffect = false,
  onClick = () => undefined,
}) => {
  return (
    <Styled.Root hoverEffect={hoverEffect} type={type} bgcolor={bgcolor} onClick={onClick}>
      <svg width="164.908" height="61.112" viewBox="0 0 164.908 61.112">
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.5"
            y1="1.105"
            x2="0.5"
            y2="-1.093"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor={bgcolor2} />
          </linearGradient>
          <linearGradient
            id="linear-gradient-hover"
            x1="0.5"
            y1="2.105"
            x2="0.5"
            y2="-0.493"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="-5" stopColor={bgcolor2} />
            <stop offset="3" stopColor="#fff" />
          </linearGradient>
        </defs>
        <g id="Group_16" data-name="Group 16" transform="translate(-329 -780.196)">
          <g id="Group_15" data-name="Group 15">
            <g transform="translate(330.464 780.705)">
              <path
                id="Path_34"
                data-name="Path 34"
                d="M-367.446,597.52l-19.682-30,19.682-30H-245l20.6,30-20.6,30Z"
                transform="translate(387.129 -537.511)"
                fill={bgcolor3}
              />
            </g>
            <path
              id="Path_39"
              data-name="Path 39"
              d="M-374.039,533.511l-17.709,26.112,17.709,26.112h117.434l17.709-26.112-17.709-26.112Z"
              transform="translate(726.776 250.732)"
              fill={bgcolor}
            />
            <path
              id="Path_40"
              data-name="Path 40"
              d="M-389.5,517.511l-20.722,30.556,20.722,30.556h123.463l20.723-30.556-20.723-30.556Zm122.709,60H-388.746l-19.969-29.445,19.969-29.445H-266.79l19.969,29.445Z"
              transform="translate(739.222 262.685)"
              fill={bgcolor}
            />
            <path
              id="svgGradient"
              data-name="svgGradient"
              d="M-370.269,587.373l-16.86-24.931,16.86-24.931h115.275l16.86,24.931-16.86,24.931Z"
              transform="translate(723.997 247.871)"
              fill="url(#linear-gradient)"
            />
            <text
              transform="translate(375.477 801.743)"
              fill={color}
              fontSize="14"
              fontFamily="Barlow-Bold, Barlow"
              fontWeight="700"
            >
              <tspan x={offsetX} y="14">
                {title}
              </tspan>
            </text>
          </g>
          <g id="Group_16" data-name="Group 16" transform="translate(350 800)">
            <path
              id="Path_42"
              data-name="Path 42"
              d="M24.147,2.214a23.522,23.522,0,0,0-5.806-1.8.088.088,0,0,0-.093.044,16.385,16.385,0,0,0-.723,1.485,21.715,21.715,0,0,0-6.521,0A15.028,15.028,0,0,0,10.269.457a.092.092,0,0,0-.093-.044,23.456,23.456,0,0,0-5.806,1.8.083.083,0,0,0-.038.033A24.073,24.073,0,0,0,.118,18.482a.1.1,0,0,0,.037.067,23.653,23.653,0,0,0,7.122,3.6.092.092,0,0,0,.1-.033,16.906,16.906,0,0,0,1.457-2.37.09.09,0,0,0-.049-.126A15.577,15.577,0,0,1,6.56,18.56a.092.092,0,0,1-.009-.152c.15-.112.3-.229.442-.346a.088.088,0,0,1,.092-.012,16.868,16.868,0,0,0,14.335,0,.088.088,0,0,1,.093.011c.143.118.292.235.443.347a.091.091,0,0,1-.008.152,14.617,14.617,0,0,1-2.226,1.059.091.091,0,0,0-.048.127,18.982,18.982,0,0,0,1.456,2.369.09.09,0,0,0,.1.034,23.575,23.575,0,0,0,7.134-3.6.092.092,0,0,0,.037-.066A23.915,23.915,0,0,0,24.184,2.248.073.073,0,0,0,24.147,2.214ZM9.532,15.241a2.741,2.741,0,0,1-2.563-2.875A2.727,2.727,0,0,1,9.532,9.492,2.711,2.711,0,0,1,12.1,12.367,2.727,2.727,0,0,1,9.532,15.241Zm9.478,0a2.741,2.741,0,0,1-2.563-2.875A2.727,2.727,0,0,1,19.01,9.492a2.712,2.712,0,0,1,2.563,2.875A2.719,2.719,0,0,1,19.01,15.241Z"
              transform="translate(0 -0.246)"
              fill="#23272a"
            />
          </g>
        </g>
      </svg>
    </Styled.Root>
  );
};

export default ButtonDiscordSVG;
