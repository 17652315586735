import { FC } from 'react';
import Styled from './Badge.styles';

interface IBadgeProps {
  value: string;
}

const Badge: FC<IBadgeProps> = ({ value }) => <Styled.Badge>{value}</Styled.Badge>;

export default Badge;
