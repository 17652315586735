import { styled } from '@mui/material';

const Root = styled('button')<{
  bgcolor: string;
  type: string;
  hoverEffect?: boolean;
}>`
  background-color: transparent;
  border: 0px solid #000;
  svg {
    cursor: ${({ hoverEffect }) => (hoverEffect ? 'pointer' : 'inherit')};
    path {
      transition: all 0.4s ease-in;
    }
    ${({ hoverEffect }) => (hoverEffect ? `&:hover { #svgGradient { fill: url(#linear-gradient-hover); } }` : '')};
  }
`;

export default { Root };
