import { FC, useCallback, useContext } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Styled from './LatestSeries.styles';
import { ReactComponent as RightArrowIcon } from '../../../components/icons/iconic/chevron-right.svg';
import CardHighlight from '../../../components/media/CardHighlight';
import CardHighlightSmall from '../../../components/media/CardHighlightSmall';
import CardHighlightList from '../../../components/media/CardHighlightList';
import Box from '../../../components/basics/Box';
import Button from '../../../components/basics/Button';
import Media from '../../../components/media/Media';
import Carousel from '../../../components/media/Carousel';
import MarketplaceProviderContext from '../MarketplaceProvider.context';
import routes from '../../../config/routes';

const variants = {
  offscreen: {
    opacity: 0,
    y: 30,
  },
  onscreen: {
    opacity: 1,
    y: 0,
  },
};

const LatestSeries: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { onSelectSeries } = useContext(MarketplaceProviderContext);
  const navigate = useNavigate();

  const handleOnSelectSeries = useCallback(() => {
    onSelectSeries('Legendary');
  }, [onSelectSeries]);

  const series0 = new Array(10).fill('').map(() => {
    return (
      <CardHighlight
        title="Legendary"
        actionTitle="View Items"
        onClickActionButton={handleOnSelectSeries}
        percentage="2.5%"
        rarity="Rarity"
      />
    );
  });
  const series1 = new Array(10).fill('').map(() => {
    return (
      <CardHighlightSmall
        title="Legendary"
        actionTitle="View Items"
        onClickActionButton={handleOnSelectSeries}
        percentage="2.5%"
        rarity="Rarity"
      />
    );
  });
  const series2 = new Array(10).fill('').map(() => <Media size="default" aspectRatio="1:1" minHeight="316px" />);
  const series3 = new Array(10).fill('').map(() => (
    <CardHighlightList
      title="NFT Example Title"
      actionTitle="Select and buy now"
      onClickActionButton={() => {
        navigate(routes.marketplaceNftDetail.path, { state: { origin: 'Listing' } });
      }}
      aspectRatio="1:1"
      serial="Legendary /5000"
      averagePrice="$105.00 USD"
      lowestPrice="$99.00 USD"
    />
  ));

  return (
    <Box width="100%" margin="0 0 150px">
      <Styled.Section>
        <Box padding={isMobile ? '0 20px' : '0 35px'}>
          <Typography variant={isMobile ? 'headline.mobile-md' : 'headline.desktop-md'}>Series Name</Typography>
        </Box>

        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.1 }}
          transition={{ duration: 0.15, ease: 'easeOut' }}
        >
          <motion.div variants={variants}>{<Carousel centeredSlides={isMobile} items={series0} />}</motion.div>
        </motion.div>
      </Styled.Section>

      <Styled.Section>
        <Box padding={isMobile ? '0 20px' : '0 35px'}>
          <Typography variant={isMobile ? 'headline.mobile-md' : 'headline.desktop-md'}>Series Name</Typography>
        </Box>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          transition={{ duration: 0.15, ease: 'easeOut' }}
        >
          <motion.div variants={variants}>{<Carousel centeredSlides={isMobile} items={series1} />}</motion.div>
        </motion.div>
      </Styled.Section>

      <Styled.Section>
        <Box padding={isMobile ? '0 20px' : '0 35px'}>
          <Typography variant={isMobile ? 'headline.mobile-md' : 'headline.desktop-md'}>Series Name</Typography>
        </Box>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          transition={{ duration: 0.15, ease: 'easeOut' }}
        >
          <motion.div variants={variants}>{<Carousel centeredSlides={isMobile} items={series2} />}</motion.div>
        </motion.div>
      </Styled.Section>

      <Styled.Section>
        <Box
          padding={isMobile ? '0 20px' : '0 35px'}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant={isMobile ? 'headline.mobile-md' : 'headline.desktop-md'}>Latest Listings</Typography>
          <Button type="tertiary" size="lg" icon={<RightArrowIcon />} iconPosition="after">
            Explore All
          </Button>
        </Box>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          transition={{ duration: 0.15, ease: 'easeOut' }}
        >
          <motion.div variants={variants}>{<Carousel centeredSlides={isMobile} items={series3} />}</motion.div>
        </motion.div>
      </Styled.Section>
    </Box>
  );
};

export default LatestSeries;
