import { CSSProperties, FC } from 'react';
import Styled from './Divider.styles';

interface IDivider {
  margin: CSSProperties['margin'];
}

const Divider: FC<IDivider> = ({ margin }) => <Styled.Root margin={margin} />;

export default Divider;
