import { styled } from '@mui/material';

const PlaceholderCover = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgAccentWeak};
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 72px;
  }
`;

const ImageCover = styled('img')`
  background-color: ${({ theme }) => theme.custom.colors.BgAccentWeak};
  object-fit: cover;
  width: 100%;
  height: 110px;
  position: absolute;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 72px;
  }
`;

export default { ImageCover, PlaceholderCover };
