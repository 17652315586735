import { Box, styled } from '@mui/material';
import { flexGap } from '../../tools/flexGapPoly';

export const Root = styled(Box)`
  border-radius: ${({ theme }) => theme.custom.borderRadius.BorderRadiusRadiusBase}px;
  background-color: ${({ theme }) => theme.custom.colors.BgAccentWeak};
  border: 1px solid ${({ theme }) => theme.custom.colors.BorderAccent};
  padding: ${({ theme }) => theme.custom.spacing.SpacingBase}px;
  display: flex;
  flex-direction: column;
  ${({ theme }) => flexGap(`${theme.custom.spacing.SpacingBase}px`, 'column')}
`;
