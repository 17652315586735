import { FC } from 'react';
import { Container, Typography, useTheme } from '@mui/material';
import Box from '../../../components/basics/Box';
import { ReactComponent as ShieldIcon } from '../../../components/icons/iconic/shield.svg';
import { ReactComponent as KeyIcon } from '../../../components/icons/iconic/key.svg';
import BaseIcon from '../../../components/icons/BaseIcon';
import GameBadge from './components/GameBadge';
import Button from '../../../components/basics/Button';

const gameStats = [
  {
    id: '1',
    icon: <ShieldIcon />,
    label: 'Game Stats 9999',
  },
  {
    id: '2',
    icon: <ShieldIcon />,
    label: 'Game Stats 9999',
  },
  {
    id: '3',
    icon: <ShieldIcon />,
    label: 'Game Stats 9999',
  },
];

const ProfilePage: FC = () => {
  const theme = useTheme();
  // TODO: remove to get data from services and handle empty states
  const hasData = Math.random() < 0.5;

  return (
    <Container>
      {!hasData && (
        <Box marginTop={`${theme.custom.spacing.Spacing3Xl}px`}>
          <Typography variant="text-default">No profile data</Typography>
        </Box>
      )}
      {hasData && (
        <Box
          marginTop={`${theme.custom.spacing.Spacing3Xl}px`}
          display="flex"
          flexDirection="column"
          gap={`${theme.custom.spacing.SpacingBase}px`}
        >
          {gameStats.map(stats => (
            <Box display="flex" alignItems="center" key={`game-stats-item-${stats.id}`}>
              <BaseIcon icon={stats.icon} padding={`0 ${theme.custom.spacing.SpacingLg}px 0 0 `} />
              <Typography variant="body.md" color={theme.custom.colors.TextWeak}>
                {stats.label}
              </Typography>
            </Box>
          ))}
          <Box
            display="flex"
            marginTop={`${theme.custom.spacing.Spacing3Xl}px`}
            gap={`${theme.custom.spacing.SpacingMd}px`}
          >
            <GameBadge>
              <BaseIcon icon={<KeyIcon />} color={theme.custom.colors.IconWeak} />
            </GameBadge>
            <GameBadge>
              <BaseIcon icon={<KeyIcon />} color={theme.custom.colors.IconWeak} />
            </GameBadge>
            <GameBadge>
              <BaseIcon icon={<KeyIcon />} color={theme.custom.colors.IconWeak} />
            </GameBadge>
            <GameBadge>
              <BaseIcon icon={<KeyIcon />} color={theme.custom.colors.IconWeak} />
            </GameBadge>
          </Box>
          <Box width="106px" marginTop={`${theme.custom.spacing.Spacing3Xl}px`}>
            <Button type="secondary" size="sm">
              Edit Profile
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default ProfilePage;
