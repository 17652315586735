import { FC, useMemo } from 'react';
import {
  Container,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/navigation/Footer';
import NavBar, { TabRouteItem } from '../../components/navigation/NavBar';
import BaseLayout from '../../components/layouts/BaseLayout';
import Gallery from '../../components/media/Gallery';
import BaseIcon from '../../components/icons/BaseIcon';
import { ReactComponent as LockIcon } from '../../components/icons/iconic/lock-fill.svg';
import { ReactComponent as ShareIcon } from '../../components/icons/iconic/share.svg';
import { ReactComponent as UploadIcon } from '../../components/icons/iconic/upload.svg';
import ForteWhiteHeaderIcon from '../../components/icons/ForteWhiteHeaderIcon';
import { ReactComponent as EthIcon } from '../../components/icons/iconic/eth.svg';
import { ReactComponent as EthereumIcon } from '../../components/icons/iconic/ethereum.svg';
import MastercardIcon from '../../components/icons/MastercardIcon';
import VisaIcon from '../../components/icons/VisaIcon';
import AmexIcon from '../../components/icons/AmexIcon';
import routes from '../../config/routes';
import { ReactComponent as HelpCircleIcon } from '../../components/icons/iconic/help-circle.svg';
import { ReactComponent as MinimizeIcon } from '../../components/icons/iconic/minimize.svg';
import { ReactComponent as ShieldIcon } from '../../components/icons/iconic/shield.svg';
import { ReactComponent as BackIcon } from '../../components/icons/iconic/arrow-left.svg';
import Divider from '../../components/basics/Divider';
import { ReactComponent as CheckmarkIcon } from '../../components/icons/iconic/checkmark.svg';
import { ReactComponent as ContractIcon } from '../../components/icons/iconic/contract.svg';
import TwitchSocialIcon from '../../components/icons/TwitchSocialIcon';
import TwitterSocialIcon from '../../components/icons/TwitterSocialIcon';
import MediaCarousel from '../../components/media/MediaCarousel';
import { nameSpace } from '../../modules/i18n';
import Button from '../../components/basics/Button';
import Box from '../../components/basics/Box';
import Announcement from '../../components/feedback/Announcement';
import Attribute from '../../components/feedback/Attribute';
import Rarity from '../../components/feedback/Rarity';
import Media from '../../components/media/Media';
import useEvents from '../../modules/events/useEvents';
import useCurrency from '../../modules/currency/useCurrency';

const EventNft: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { eventSoldOut, purchaseLimitReached } = useEvents();
  const { selectedCurrency, onShowCurrencyPicker } = useCurrency();
  const { t } = useTranslation(nameSpace.eventNft);

  const edition = useMemo(() => {
    if (eventSoldOut) {
      return 'SOLD OUT';
    }

    return '2987/5000';
  }, [eventSoldOut]);

  const action = useMemo(() => {
    if (eventSoldOut) {
      return 'eventNft.actions.sold-out';
    }

    if (purchaseLimitReached) {
      return 'eventNft.actions.purchase-limit-reached';
    }

    return 'eventNft.actions.buy-now';
  }, [eventSoldOut, purchaseLimitReached]);

  const announcement = useMemo(() => {
    if (eventSoldOut) {
      return 'Event Ended! All Sold Out';
    }

    return 'Hurry {{edition}} left';
  }, [eventSoldOut]);

  const rarities = useMemo(
    () => [
      {
        icon: <MinimizeIcon />,
        title: 'Common',
        description: 'Base level characteristics',
        percentage: '60',
      },
      {
        icon: <ShieldIcon />,
        title: 'Rare',
        description: 'A unique set of values that will unlock VIP experiences',
        percentage: '30',
      },
      {
        icon: <EthereumIcon />,
        title: 'Legendary',
        description: 'Holographic charms that will create amazing game moments',
        percentage: '10',
      },
    ],
    [],
  );

  const containerMargin = useMemo(() => {
    return isMobile ? '5px 0 0' : '80px 0 0';
  }, [isMobile]);

  return (
    <BaseLayout footer={<Footer />} navBar={<NavBar selectedItem={TabRouteItem.nft} />}>
      <Announcement text={t(announcement, { edition })} />
      <Container>
        <Box display="flex" margin="68px 0 0" width="100%" padding="20px 0" justifyContent="space-between">
          <Box display="flex">
            <BaseIcon icon={<BackIcon />} color={theme.custom.colors.TextWeak} />
            <Box marginLeft="5px">
              <Typography variant="text-weak">Event name</Typography>
            </Box>
          </Box>
          <Box>
            <BaseIcon icon={<UploadIcon />} color={theme.custom.colors.TextWeak} />
          </Box>
        </Box>
        <Grid container margin={containerMargin}>
          <Grid item xs={12} sm={5}>
            <Gallery
              editionText={edition}
              items={[
                {
                  id: 'a1',
                  src: '',
                  thumbnail: '',
                  mediaType: 'image',
                },
                {
                  id: 'a2',
                  src: '',
                  thumbnail: '',
                  mediaType: 'video',
                },
                {
                  id: 'a3',
                  src: '',
                  thumbnail: '',
                  mediaType: 'image',
                },
                {
                  id: 'a4',
                  src: '',
                  thumbnail: '',
                  mediaType: 'image',
                },

                {
                  id: 'a5',
                  src: '',
                  thumbnail: '',
                  mediaType: 'image',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Box margin={isMobile ? '5px 0 0' : '50px 0 0 50px'} sx={{ maxWidth: 430 }}>
              <Box marginBottom="12px">
                <Typography variant="headline.mobile-md">NFT Example Title</Typography>
              </Box>
              <Box display="flex" alignItems="baseline" marginBottom="32px" gap="5px" justifyContent="space-between">
                <span>
                  <Typography variant="title.lg-strong" margin={`0 0 ${theme.custom.spacing.SpacingMd}px`}>
                    $99.00
                  </Typography>
                  <Typography
                    variant="body.md-strong"
                    marginLeft={`${theme.custom.spacing.SpacingBase}px`}
                    color={theme.custom.colors.TextWeak}
                  >
                    {selectedCurrency}
                  </Typography>
                  <Typography variant="label.sm" color={theme.custom.colors.TextWeak} margin="0 0 0 10px">
                    (0.0003ETH)
                  </Typography>
                </span>
                <Typography
                  sx={{ cursor: 'pointer' }}
                  variant="body.md"
                  color={theme.custom.colors.ActionAccent}
                  onClick={onShowCurrencyPicker}
                >
                  Change Currency
                </Typography>
              </Box>

              <Button type="primary" size="lg" fullWidth disabled={eventSoldOut || purchaseLimitReached}>
                {t(action)}
              </Button>
              <Box display="flex" justifyContent="space-between" alignItems="center" gap="5px" margin="10px 0 32px">
                <Box display="flex" gap="5px" alignItems="center">
                  <BaseIcon icon={<LockIcon />} color={theme.custom.colors.TextWeak} />
                  <Typography variant="text-weak" fontSize="13px">
                    {t('eventNft.poweredBy')}
                  </Typography>
                  <BaseIcon width="60px" height="20px" icon={<ForteWhiteHeaderIcon />} margin="16px 0 0" />
                </Box>
                <Box display="flex" gap="5px" alignItems="center">
                  <BaseIcon width="24px" icon={<EthIcon />} />
                  <BaseIcon width="32px" icon={<MastercardIcon />} />
                  <BaseIcon width="32px" icon={<VisaIcon />} />
                  <BaseIcon width="32px" icon={<AmexIcon />} />
                </Box>
              </Box>

              <Box margin="0 0 32px">
                <Attribute
                  type="inline"
                  label={t('eventNft.properties.fees')}
                  description={t('eventNft.properties.no.fees')}
                  margin="0 0 6px"
                  padding="0"
                />

                <Attribute
                  type="inline"
                  label={t('eventNft.properties.royalties')}
                  description={t('eventNft.properties.no.royalties')}
                  margin="0 0 6px"
                  padding="0"
                />
              </Box>

              <Box display="flex" margin="0 0 32px">
                <Typography variant="label.lg" marginRight="5px">
                  {t('eventNft.needHelp')}
                </Typography>
                <Link lineHeight="120%" href={routes.faq.path}>
                  {t('eventNft.visitFaq')}
                </Link>
                <BaseIcon margin="-2px 0 0 8px" icon={<HelpCircleIcon />} />
              </Box>
            </Box>

            <Box margin={isMobile ? '5px 0 0' : '50px 0 0 50px'}>
              <Divider margin="20px 0" />
              <Typography variant="headline.mobile-sm">Rarity</Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                padding="0"
                gap="24px"
                width="100%"
                margin="50px 0"
              >
                {rarities.map((rarity, index) => (
                  <Rarity
                    key={`rarity-item-test-${index}`}
                    icon={rarity.icon}
                    title={rarity.title}
                    description={rarity.description}
                    percentage={rarity.percentage}
                  />
                ))}
                <Button type="secondary" size="sm">
                  View All
                </Button>
              </Box>

              <Divider margin="20px 0px" />

              <Box display="flex" flexDirection="column">
                <Typography variant="headline.mobile-sm" marginBottom={`${theme.custom.spacing.Spacing3Xl}px`}>
                  Details
                </Typography>
                <Typography variant="body.lg" sx={{ marginBottom: `${theme.custom.spacing.Spacing2Xl}px` }}>
                  This is where you can give specific details of this NFT. You can make it your own and write what you’d
                  like. The floor is yours.
                </Typography>
                <Typography variant="body.lg" sx={{ marginBottom: `${theme.custom.spacing.Spacing2Xl}px` }}>
                  This will all be HTML content so you should be able to style this and add what you would like.
                  Including links, images, video, lists and buttons.
                </Typography>
                <Rarity
                  icon={<CheckmarkIcon />}
                  title="Dedicated team"
                  marginBottom={`${theme.custom.spacing.SpacingMd}px`}
                />
                <Rarity
                  icon={<CheckmarkIcon />}
                  title="Growing community"
                  marginBottom={`${theme.custom.spacing.SpacingMd}px`}
                />
                <Rarity icon={<CheckmarkIcon />} title="Pro Gaming tokenomics" />
              </Box>

              <Divider margin="30px 0px" />

              <Box display="flex" flexDirection="column">
                <Typography variant="headline.mobile-sm" sx={{ marginBottom: `${theme.custom.spacing.Spacing3Xl}px` }}>
                  Rules
                </Typography>
                <Typography variant="body.lg" sx={{ marginBottom: `${theme.custom.spacing.Spacing3Xl}px` }}>
                  There are some rules regarding the minting of your NFTs.
                </Typography>

                <Rarity
                  icon={<ContractIcon />}
                  title="Only 1 NFT per purchase"
                  marginBottom={`${theme.custom.spacing.SpacingMd}px`}
                />
                <Rarity icon={<ContractIcon />} title="Max 5 NFTs per account" />
              </Box>
              <Divider margin="30px 0px" />

              <Box display="flex" flexDirection="column">
                <Typography variant="headline.mobile-sm" marginBottom={`${theme.custom.spacing.Spacing3Xl}px`}>
                  Description
                </Typography>
                <Typography variant="body.lg" sx={{ marginBottom: `${theme.custom.spacing.Spacing3Xl}px` }}>
                  This is where you can give an overview of your project and this drop. This is less about the actual
                  NFT and more about the project as a whole. You can make it your own and write what you’d like. The
                  floor is yours.
                </Typography>
                <Typography variant="body.lg" sx={{ marginBottom: `${theme.custom.spacing.Spacing3Xl}px` }}>
                  This will all be HTML content so you should be able to style this and add what you would like.
                  Including links, images, video, lists and buttons.
                </Typography>
              </Box>

              <Divider margin="30px 10px" />

              <Box display="flex" flexDirection="column">
                <Typography variant="headline.mobile-sm" marginBottom={`${theme.custom.spacing.Spacing3Xl}px`}>
                  Sales History
                </Typography>

                <Box sx={{ width: '100%', overflow: 'scroll', maxWidth: 'calc(100vw - 32px)' }}>
                  <TableContainer sx={{ maxHeight: 440, minWidth: 800 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" />
                          <TableCell align="left">
                            <Typography variant="label.sm" color={theme.custom.colors.TextDefault}>
                              Item
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="label.sm" color={theme.custom.colors.TextDefault}>
                              Price
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="label.sm" color={theme.custom.colors.TextDefault}>
                              Serial
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="label.sm" color={theme.custom.colors.TextDefault}>
                              Buyer
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="label.sm" color={theme.custom.colors.TextDefault}>
                              Seller
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="label.sm" color={theme.custom.colors.TextDefault}>
                              Date
                            </Typography>
                          </TableCell>
                          <TableCell align="left" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {new Array(10).fill('').map(() => {
                          return (
                            <TableRow role="checkbox" sx={{ height: 51 }}>
                              <TableCell>
                                <Media size="small" aspectRatio="1:1" minWidth="16px" />
                              </TableCell>
                              <TableCell>
                                <Typography variant="label.sm" color={theme.custom.colors.TextDefault}>
                                  Example Title
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="label.sm" color={theme.custom.colors.TextDefault}>
                                  $99.00 USD
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="label.sm" color={theme.custom.colors.TextWeak}>
                                  #0001
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="label.sm" color={theme.custom.colors.TextWeak}>
                                  @username
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="label.sm" color={theme.custom.colors.TextWeak}>
                                  @username
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="label.sm" color={theme.custom.colors.TextWeak}>
                                  Jun 30, 2021 4:09 PM
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <BaseIcon icon={<ShareIcon />} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Box margin="auto" width="100vw">
        <MediaCarousel
          items={[
            {
              id: '1',
              src: '',
            },
            {
              id: '2',
              src: '',
            },
            {
              id: '3',
              src: '',
            },
            {
              id: '4',
              src: '',
            },

            {
              id: '5',
              src: '',
            },
            {
              id: '5',
              src: '',
            },

            {
              id: '56',
              src: '',
            },
          ]}
        />
      </Box>

      <Container>
        <Grid container>
          <Grid item xs={12} sm={5}>
            <div />
          </Grid>

          <Grid item xs={12} sm={7}>
            <Box margin={isMobile ? '5px 0 0' : '50px 0 100px 50px'} display="flex" flexDirection="column">
              <Typography variant="headline.mobile-sm" sx={{ marginBottom: `${theme.custom.spacing.Spacing3Xl}px` }}>
                {t('eventNft.community.title')}
              </Typography>

              <Typography variant="body.lg" sx={{ marginBottom: `${theme.custom.spacing.Spacing2Xl}px` }}>
                {t('eventNft.community.description')}
              </Typography>
              <Box display="flex" gap="20px">
                <BaseIcon icon={<TwitchSocialIcon />} height="30px" />
                <BaseIcon icon={<TwitterSocialIcon />} height="30px" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </BaseLayout>
  );
};

export default EventNft;
