import { useContext } from 'react';
import ConfigurationProviderContext from './Configuration.context';

const useConfiguration = () => {
  const configuration = useContext(ConfigurationProviderContext);

  return configuration;
};

export default useConfiguration;
