import { FC } from 'react';
import * as Styled from './LoaderScreen.styles';
import Logo from '../../assets/lhu_logo.png';

const LoaderScreen: FC = () => {
  return (
    <Styled.Root>
      <div className="brand-logo">
        <img className="img-fluid" src={Logo} alt="Brand Logo" />
      </div>
    </Styled.Root>
  );
};

export default LoaderScreen;
