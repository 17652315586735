import { styled } from '@mui/material';
import { CSSProperties } from 'react';
import { flexGap } from '../../tools/flexGapPoly';

export const BaseButton = styled('button')<{
  padding: CSSProperties['padding'];
  height: CSSProperties['height'];
  flexdirection: CSSProperties['flexDirection'];
  width: CSSProperties['width'];
  justifycontent?: CSSProperties['justifyContent'];
}>`
  font-size: ${({ theme }) => `${theme.custom.typography.LabelLgStrong.fontSize}px`};
  font-family: ${({ theme }) => theme.custom.typography.LabelLgStrong.fontFamily}
    ${({ theme }) => theme.custom.typography.LabelLgStrong.fontWeight};
  transition: all 300ms ease-out;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border-radius: 4px;
  display: flex;
  flex-direction: ${({ flexdirection }) => flexdirection};
  justify-content: ${({ justifycontent }) => justifycontent};
  align-items: center;
  padding: ${({ padding }) => padding};

  ${({ flexdirection }) => flexGap('8px', flexdirection)}
`;

export const PrimaryButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.custom.colors.ActionAccent};
  color: ${({ theme }) => theme.custom.colors.TextOnAccent};
  cursor: pointer;
  border: 3px solid ${({ theme }) => theme.custom.colors.ActionAccent};
  outline: unset;

  :hover {
    background-color: ${({ theme }) => theme.custom.colors.ActionAccentHover};
    border: 3px solid ${({ theme }) => theme.custom.colors.ActionAccentHover};
  }

  :active {
    outline: 1px solid ${({ theme }) => theme.custom.colors.ActionAccentHover};
    border: 3px solid ${({ theme }) => theme.custom.colors.BgDefault};
  }
`;

export const PrimaryButtonDisabled = styled(BaseButton)`
  background-color: ${({ theme }) => theme.custom.colors.ActionDisabled};
  color: ${({ theme }) => theme.custom.colors.TextDisabled};
  cursor: not-allowed;
  border: unset;
  outline: unset;
`;

export const SecondaryButton = styled(BaseButton)<{ forceshowborder?: number }>`
  background-color: ${({ theme }) => theme.custom.colors.ActionSecondary};
  color: ${({ theme }) => theme.custom.colors.TextDefault};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.custom.colors.BorderDefault};
  outline: ${({ forceshowborder, theme }) =>
    forceshowborder ? `1px solid ${theme.custom.colors.ActionAccentHover}` : 'unset'};

  :hover {
    background-color: ${({ theme }) => theme.custom.colors.ActionSecondaryHover};
    border: 1px solid ${({ theme }) => theme.custom.colors.BorderDefault};
  }

  :active {
    outline: 1px solid ${({ theme }) => theme.custom.colors.ActionAccentHover};
    border: 1px solid ${({ theme }) => theme.custom.colors.BgDefault};
  }
`;

export const SecondaryButtonDisabled = styled(BaseButton)`
  background-color: ${({ theme }) => theme.custom.colors.ActionDisabled};
  color: ${({ theme }) => theme.custom.colors.TextDisabled};
  cursor: not-allowed;
  border: unset;
  outline: unset;
`;

export const TertiaryButton = styled(BaseButton)`
  background-color: unset;
  color: ${({ theme }) => theme.custom.colors.TextWeak};
  cursor: pointer;
  border: 1px solid transparent;
  outline: unset;

  :hover {
    background-color: ${({ theme }) => theme.custom.colors.ActionTertiaryHover};
    border: 1px solid ${({ theme }) => theme.custom.colors.BorderDefault};
  }

  :active {
    outline: 1px solid ${({ theme }) => theme.custom.colors.BorderTertiaryHover};
    border: 1px solid ${({ theme }) => theme.custom.colors.BgDefault};
  }
`;

export const TertiaryButtonDisabled = styled(BaseButton)`
  background-color: unset;
  color: ${({ theme }) => theme.custom.colors.TextDisabled};
  cursor: not-allowed;
  border: unset;
  outline: unset;
`;

export const CriticalButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.custom.colors.ActionCritical};
  color: ${({ theme }) => theme.custom.colors.TextOnCritical};
  cursor: pointer;
  border: 3px solid ${({ theme }) => theme.custom.colors.ActionCritical};
  outline: unset;

  :hover {
    background-color: ${({ theme }) => theme.custom.colors.ActionCriticalHover};
    border: 3px solid ${({ theme }) => theme.custom.colors.ActionCritical};
  }

  :active {
    outline: 1px solid ${({ theme }) => theme.custom.colors.ActionCritical};
    border: 3px solid ${({ theme }) => theme.custom.colors.BgDefault};
  }
`;

export const CriticalButtonDisabled = styled(BaseButton)`
  background-color: unset;
  color: ${({ theme }) => theme.custom.colors.TextDisabled};
  cursor: not-allowed;
  border: unset;
  outline: unset;
`;

export const ButtonContent = styled('div')`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const OutlinedButton = styled(BaseButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.custom.colors.TextDefault};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.custom.colors.BorderDefault};
  outline: unset;

  :hover {
    background-color: ${({ theme }) => theme.custom.colors.ActionSecondaryHover};
    border: 1px solid ${({ theme }) => theme.custom.colors.BorderDefault};
  }

  :active {
    outline: 1px solid ${({ theme }) => theme.custom.colors.ActionAccentHover};
    border: 1px solid ${({ theme }) => theme.custom.colors.BgDefault};
  }
`;

export const OutlinedButtonDisabled = styled(BaseButton)`
  background-color: ${({ theme }) => theme.custom.colors.ActionDisabled};
  color: ${({ theme }) => theme.custom.colors.TextDisabled};
  cursor: not-allowed;
  border: unset;
  outline: unset;
`;
