import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import MarketplaceProviderContext, { initialContext, IMarketplaceProviderContext } from './MarketplaceProvider.context';

interface IMarketplaceProviderProps {
  children: ReactNode;
}

const MarketplaceProvider: FC<IMarketplaceProviderProps> = ({ children }) => {
  const [filters, setFilters] = useState<IMarketplaceProviderContext['filters']>(initialContext.filters);
  const [loadingSeries, setLoadingSeries] = useState<boolean>(initialContext.loadingSeries);
  const handleOnUpdateLoadingSeries = useCallback((value: boolean) => setLoadingSeries(value), []);

  const handleOnFilterChange = useCallback(() => {
    setLoadingSeries(true);
    setTimeout(() => {
      setLoadingSeries(false);
    }, Math.floor(Math.random() * 500) + 300);
  }, []);

  const handleOnSearchChange: IMarketplaceProviderContext['onSearch'] = useCallback(
    searchOption => {
      setFilters({ ...filters, searchOption });
      handleOnFilterChange();
    },
    [handleOnFilterChange, filters],
  );

  const handleOnSortChange: IMarketplaceProviderContext['onSort'] = useCallback(
    sortOption => {
      setFilters({ ...filters, sortOption });
      handleOnFilterChange();
    },
    [handleOnFilterChange, filters],
  );

  const handleOnSelectSeries: IMarketplaceProviderContext['onSelectSeries'] = useCallback(
    selectedSeries => {
      setFilters({ ...filters, selectedSeries });
      handleOnFilterChange();
    },
    [handleOnFilterChange, filters],
  );

  const handleOnChangeDateFrom: IMarketplaceProviderContext['onChangeDateFrom'] = useCallback(
    dateFrom => {
      setFilters({ ...filters, dateFrom });
      handleOnFilterChange();
    },
    [filters, handleOnFilterChange],
  );
  const handleOnChangeDateTo: IMarketplaceProviderContext['onChangeDateTo'] = useCallback(
    dateTo => {
      setFilters({ ...filters, dateTo });
      handleOnFilterChange();
    },
    [filters, handleOnFilterChange],
  );

  const handleOnClearAllFilters = useCallback(() => {
    setFilters({});
    handleOnFilterChange();
  }, [handleOnFilterChange]);

  const showFilterOptions = useMemo(() => {
    return !!filters?.searchOption || !!filters?.selectedSeries || (!!filters?.dateFrom && !!filters?.dateTo);
  }, [filters]);

  const addOrRemoveOption = useCallback((option: string, options: string[]): string[] => {
    const exist = options.some(value => value === option);

    if (exist) {
      return options.filter(value => value !== option);
    }

    return [...options, option];
  }, []);

  const handleOnChangeAttributeFilter = useCallback(
    (optionId: string) => {
      const { attributes = [] } = filters;
      const updatedValues = addOrRemoveOption(optionId, attributes);

      setFilters({ ...filters, attributes: updatedValues });
      handleOnFilterChange();
    },
    [filters, addOrRemoveOption, handleOnFilterChange],
  );

  const handleOnChangeCollectionFilter = useCallback(
    (optionId: string) => {
      const { collection = [] } = filters;
      const updatedValues = addOrRemoveOption(optionId, collection);

      setFilters({ ...filters, collection: updatedValues });
      handleOnFilterChange();
    },
    [filters, addOrRemoveOption, handleOnFilterChange],
  );

  const value: IMarketplaceProviderContext = useMemo(
    () => ({
      filters,
      onChangeDateFrom: handleOnChangeDateFrom,
      onChangeDateTo: handleOnChangeDateTo,
      showFilterOptions,
      onClearAllFilters: handleOnClearAllFilters,
      onSort: handleOnSortChange,
      onSelectSeries: handleOnSelectSeries,
      loadingSeries,
      onUpdateLoadingSeries: handleOnUpdateLoadingSeries,
      searchBarOptions: initialContext.searchBarOptions,
      sortOptions: initialContext.sortOptions,
      onSearch: handleOnSearchChange,
      collectionOptions: initialContext.collectionOptions,
      attributesOptions: initialContext.attributesOptions,
      onChangeCollectionFilter: handleOnChangeCollectionFilter,
      onChangeAttributeFilter: handleOnChangeAttributeFilter,
    }),
    [
      filters,
      handleOnChangeDateFrom,
      handleOnChangeDateTo,
      showFilterOptions,
      handleOnClearAllFilters,
      handleOnSortChange,
      handleOnSelectSeries,
      loadingSeries,
      handleOnUpdateLoadingSeries,
      handleOnSearchChange,
      handleOnChangeCollectionFilter,
      handleOnChangeAttributeFilter,
    ],
  );

  return <MarketplaceProviderContext.Provider value={value}>{children}</MarketplaceProviderContext.Provider>;
};

export default MarketplaceProvider;
