import { styled } from '@mui/material';
import { flexGap } from '../../tools/flexGapPoly';

export const List = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgLayer2};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => theme.custom.spacing.SpacingLg}px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.custom.spacing.SpacingLg}px 0;
  }

  ${({ theme }) => flexGap(`${theme.custom.spacing.SpacingLg}px`, 'column')}
`;
