import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import Styled from './MenuItem.styles';

export interface IMenuItem {
  children: ReactNode;
  icon?: ReactNode;
  variant: 'extra-small' | 'small' | 'large';
  onClick?: () => void;
}

const MenuItem: FC<IMenuItem> = ({ children, variant, icon = null, onClick = () => undefined }) => {
  if (variant === 'large') {
    return (
      <Box
        gap="10px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding="16px 20px"
        minHeight="64px"
        width="100%"
        onClick={onClick}
      >
        <>
          <Styled.Large>{children}</Styled.Large>
          {icon}
        </>
      </Box>
    );
  }

  if (variant === 'small') {
    return (
      <Box
        gap="10px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding="8px 20px"
        minHeight="44px"
        width="100%"
        onClick={onClick}
      >
        <>
          <Styled.Small>{children}</Styled.Small>
          {icon}
        </>
      </Box>
    );
  }

  return (
    <Box
      gap="10px"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      padding="8px 20px"
      minHeight="48px"
      width="100%"
      onClick={onClick}
    >
      <>
        <Styled.ExtraSmall>{children}</Styled.ExtraSmall>
        {icon}
      </>
    </Box>
  );
};

export default MenuItem;
