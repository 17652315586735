import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const TwitchSocialIcon: FC = () => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.9818 10.8408C34.8415 11.3236 33.6166 11.6484 32.3309 11.795C33.644 11.0453 34.6508 9.8583 35.1251 8.44299C33.8978 9.13702 32.537 9.64135 31.0881 9.91396C29.9287 8.73605 28.2772 8 26.4493 8C22.939 8 20.0935 10.7136 20.0935 14.0588C20.0935 14.5336 20.1507 14.997 20.258 15.4389C14.9758 15.1867 10.2929 12.7741 7.15673 9.10748C6.61099 10.0026 6.29761 11.043 6.29761 12.155C6.29761 14.2564 7.41887 16.1113 9.124 17.1972C8.08257 17.1654 7.10192 16.8928 6.24518 16.4396C6.24518 16.4657 6.24518 16.4896 6.24518 16.5157C6.24518 19.4519 8.43527 21.9009 11.3439 22.4563C10.8113 22.5949 10.2488 22.6687 9.66854 22.6687C9.25984 22.6687 8.86066 22.6301 8.4734 22.5608C9.28248 24.9678 11.6299 26.7204 14.411 26.7693C12.2364 28.3947 9.49577 29.3636 6.51686 29.3636C6.00449 29.3636 5.49807 29.3352 5 29.2784C7.81328 30.997 11.1532 32 14.7434 32C26.435 32 32.8266 22.7676 32.8266 14.7608C32.8266 14.4984 32.8206 14.2371 32.8087 13.977C34.0515 13.1217 35.1299 12.0551 35.9818 10.8408Z"
      fill="white"
    />
  </SvgIcon>
);

export default TwitchSocialIcon;
