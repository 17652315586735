import { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery, useTheme } from '@mui/material';
import * as Styled from './MediaCarousel.styles';
import 'swiper/css';
import 'swiper/css/pagination';
import Media from './Media';

interface IMediaCarouselItem {
  id: string;
  src: string;
}

interface IMediaCarouselProps {
  items: IMediaCarouselItem[];
}

const MediaCarousel: FC<IMediaCarouselProps> = ({ items }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Styled.Root>
      <Swiper loop slidesPerView={isMobile ? 1.2 : 'auto'} spaceBetween={16} centeredSlides>
        {items.map((item, index) => (
          <SwiperSlide key={`media-item-${item.id}-${index}`}>
            <Media
              size="full-screen"
              maxWidth={isMobile ? 'calc(100vw - 64px)' : '312px'}
              height="176px"
              aspectRatio="16:9"
              source={item.src}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Styled.Root>
  );
};

export default MediaCarousel;
