import { FC } from 'react';
import Styled from './Checkbox.styles';
import { ReactComponent as CheckIcon } from '../icons/iconic/checkmark.svg';

interface ICheckboxProps {
  checked?: boolean;
}

const Checkbox: FC<ICheckboxProps> = ({ checked = false }) => {
  return <Styled.Root checked={+checked}>{checked && <CheckIcon />}</Styled.Root>;
};

export default Checkbox;
