import { styled, keyframes } from '@mui/material';
import BaseIcon from '../../../components/icons/BaseIcon';

const HeroColors = {
  '0': '#1ec993',
  '1': '#984dff',
  '2': '#ff5800',
  '3': '#ffea90',
  '4': '#69f3ff',
};

const getColors = (target: string) => {
  let str = '';
  Object.keys(HeroColors).forEach(num => {
    str += `&.bullet-${num} { svg { ${target}: ${HeroColors[num as keyof typeof HeroColors]};} }`;
  });
  return str;
};

const ProgressBar = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}`;

const HeaderSection = styled('div')`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  .swiper {
    ${({ theme }) => theme.breakpoints.down('sm')} {
        height: 100vh;
    }
    .swiper-pagination-bullets {
      right: 2rem;
      width: inherit;
      left: inherit;
      bottom: inherit;
      top: 50%;
      transform: translate3d(0px, -50%, 0);
      .swiper-pagination-bullet {
        background-color: transparent;
        opacity: 1;
        display: block;
        margin: 0;
        width: 20px;
        height: 20px;
        svg {
          transform-origin: center;
          transition: all 0.3s ease;
        }
        ${getColors('stroke')}
    }
    .swiper-pagination-bullet-active {
      svg {
        transform: scale(1.1);
      }
      ${getColors('fill')}
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      right: 10px;
    }
  }
`;

const Overlay = styled('div')`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 7vw;
  height: 100%;
  max-width: 35rem;
  ${({ theme }) => theme.breakpoints.down('xl')} {
    left: 2vw;
    max-width: 28rem;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    left: 0;
    max-width: 24rem;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 90%;
    top: inherit !important;
    bottom: 0;
    height: auto;
  }
`;

const OverlayContent = styled('div')`
  position: relative;
  align-items: center;
  display: flex;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: inherit;
    align-items: end;
    padding-bottom: 2.4rem;
  }
  h4 {
    font-size: 1.7rem;
    transition: color 0.4s ease;
    color: ${({ theme }) => theme.custom.colors.Yellow};
    margin: 0 5px 0 0;
    ${({ theme }) => theme.breakpoints.down('lg')} {
      font-size: 1.4rem;
    }
  }
  h5 {
    font-size: 1rem;
    transition: color 0.4s ease;
    color: ${({ theme }) => theme.custom.colors.Yellow};
    margin: 0 10px 0 0;
  }
  .headline {
    font-size: 6rem;
    line-height: 0.9;
    color: ${({ theme }) => theme.custom.colors.White};
    text-transform: uppercase;
    margin-bottom: 0.7rem;
    span {
      color: ${({ theme }) => theme.custom.colors.Yellow};
    }
    ${({ theme }) => theme.breakpoints.down('xl')} {
      font-size: 4.3rem;
      line-height: 0.98;
    }
    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 3rem;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      font-size: 3.5rem;
    }
  }
  .social {
    &.social-register {
      margin-top: 1.3rem;
      svg {
        height: 17px;
      }
    }
    &:hover {
      h4,
      h5 {
        color: ${({ theme }) => theme.custom.colors.White};
      }
      svg path,
      svg polygon {
        fill: ${({ theme }) => theme.custom.colors.White};
      }
    }
  }
  .content {
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 1.3;
    color: ${({ theme }) => theme.custom.colors.White};
    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.1rem;
    }
  }
`;

const HeaderProgressBar = styled('div')`
  position: absolute;
  left: 0;
  top: -4px;
  height: 4px;
  width: 0;
  &.active {
    background-color: ${({ theme }) => theme.custom.colors.Purple};
    animation: ${ProgressBar} 4s 1 alternate forwards ease-in;
  }
`;

const SocialSection = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.White};
  padding: 10px 2rem;
  position: absolute;
  z-index: 1;
  bottom: 6px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down('xl')} {
    position: relative;
    bottom: 7px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px 1rem;
    bottom: 0px;
  }
  .MuiContainer-root {
    position: relative;
    max-width: 670px;
    margin: auto;
    font-family: ${({ theme }) => theme.custom.fontFamily.FontFamiliesBody};
    line-height: 1.1;
    padding: 0 4rem;
    span {
      color: ${({ theme }) => theme.custom.colors.Yellow};
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      max-width: inherit;
      padding: 0 2rem;
    }
    .social-wrapper {
      ${({ theme }) => theme.breakpoints.down('md')} {
        margin-bottom: 5px;
      }
      ${({ theme }) => theme.breakpoints.down('sm')} {
        display: block;
        text-align: center;
        margin-bottom: 30px;
        .social-icons {
          height: 40px;
        }
      }
    }
  }
`;

const Icon = styled(BaseIcon)`
  position: absolute;
  top: 0;
  &:first-of-type {
    left: 0;
  }
  &:nth-of-type(2) {
    left: 14px;
  }
  &:nth-of-type(4) {
    right: 14px;
  }
  &:last-child {
    right: 0;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 40px;
    &:nth-of-type(2) {
      left: 12px;
    }
    &:nth-of-type(4) {
      right: 12px;
    }
  }
`;
const SocialContent = styled('div')`
  color: ${({ theme }) => theme.custom.colors.TextDefault};
  font-weight: ${({ theme }) => theme.custom.typography.BodyMdStrong.fontWeight};
`;

export default { HeaderSection, Overlay, OverlayContent, HeaderProgressBar, SocialSection, Icon, SocialContent };
