import { styled } from '@mui/material';
import { motion } from 'framer-motion';
import BaseIcon from '../../../components/icons/BaseIcon';

const HeaderSection = styled('div')`
  display: flex;
  position: relative;
  width: 100vw;
  background-color: ${({ theme }) => theme.custom.colors.BgLayer1};
  flex-direction: column;
  height: 371px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 302px;
  }
`;

const HeaderImage = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgAccentWeak};
  width: 100%;
  position: absolute;
  z-index: 10;
  height: 371px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 302px;
  }
`;

const Content = styled(motion.div)`
  z-index: 13;
  height: 371px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  user-select: none;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 15px;
  }
`;

const MediaIcon = styled(BaseIcon)`
  color: ${({ theme }) => theme.custom.colors.IconOnAccentWeak};
`;

const Gradient = styled('div')`
  position: absolute;
  width: 100%;
  height: 232px;
  left: 0;
  bottom: 0;
  z-index: 11;
  background: linear-gradient(180deg, rgba(26, 29, 30, 0) 0%, #1a1d1e 56.62%);

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 302px;
    background: linear-gradient(180deg, rgba(32, 36, 37, 0) 0%, #14191b 100%);
  }
`;

const Border = styled('div')`
  margin: 0 0 33px;
  height: 1px;
  background: ${({ theme }) => theme.custom.colors.BorderSeparator};
  width: 100vw;
  z-index: 11;
`;

export default { HeaderSection, Gradient, HeaderImage, MediaIcon, Content, Border };
