import { FC, ReactNode, useContext } from 'react';
import { Grid } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import Backdrop from '../feedback/Backdrop';
import SessionContext from '../../modules/session/Session.context';

interface IBaseLayoutProps {
  children: ReactNode;
  footer: ReactNode;
  navBar: ReactNode;
}

const BaseLayout: FC<IBaseLayoutProps> = ({ navBar, children, footer }) => {
  const { showProfileMenu } = useContext(SessionContext);

  return (
    <Grid height="100%" display="block" gridTemplateRows="auto 1fr">
      <Grid item xs={12}>
        {navBar}
      </Grid>
      <Backdrop show={showProfileMenu}>
        <AnimatePresence>
          <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Grid
              container
              minHeight="calc(100vh - 72px)"
              display="block"
              gridTemplateRows="1fr auto"
              position="relative"
              overflow="hidden"
            >
              <Grid item xs={12}>
                {children}
              </Grid>
              <Grid item xs={12}>
                {footer}
              </Grid>
            </Grid>
          </motion.span>
        </AnimatePresence>
      </Backdrop>
    </Grid>
  );
};

export default BaseLayout;
