import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import Styled from './SectionCards.styles';
import { nameSpace } from '../../../modules/i18n';
import Image from '../../../components/media/Image';
import 'swiper/css';
import 'swiper/css/autoplay';

type itemProps = {
  id: number;
  title: string;
  path: string;
  color: string;
};

const SectionCards: FC = () => {
  const { t } = useTranslation(nameSpace.home);
  const cardItems = t<string, itemProps[]>('home.cards.assets', { returnObjects: true });
  return (
    <Styled.CardsWrapper>
      <Swiper
        spaceBetween={10}
        loop
        speed={800}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        navigation
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 5,
          },
        }}
        modules={[Navigation, Autoplay]}
      >
        {cardItems.map(({ id, title, path, color }) => (
          <SwiperSlide key={id}>
            <svg xmlns="http://www.w3.org/2000/svg" width="322.615" height="459.029" viewBox="0 0 322.615 459.029">
              <g transform="translate(-63.036 -5041)">
                <g transform="translate(120.688 5149.293)">
                  <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M197.428,350.736H-57.652l.7-444.561H20.026l13.091-14.468H264.963V313.531H212.585ZM-53.339,346.43H194.533l15.157-37.2h50.967V-103.987H35.027L21.936-89.518H-52.657Z"
                    transform="translate(0)"
                    fill={color}
                  />
                </g>
              </g>
            </svg>
            <div className="zoom">
              <Image source={`/assets/${path}`} alt={`${title}`} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Styled.CardsWrapper>
  );
};

export default SectionCards;
