import { FC } from 'react';
import { Container, Link, Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/navigation/Footer';
import NavBar from '../../components/navigation/NavBar';
import BaseLayout from '../../components/layouts/BaseLayout';
import HeaderBodyButton from '../../components/feedback/HeaderBodyButton';
import { nameSpace } from '../../modules/i18n';

const NotFound: FC = () => {
  const { t } = useTranslation(nameSpace.notFound);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <BaseLayout footer={<Footer />} navBar={<NavBar />}>
      <Container>
        <Box width="100%" justifyContent="center" display="flex" margin="80px 0 0">
          <HeaderBodyButton
            maxWidth="423px"
            alignItems="center"
            textAlign="center"
            header={t('notFound.title')}
            body={t('notFound.description')}
            actions={
              <Box
                width="100%"
                marginBottom={`${theme.custom.spacing.Spacing6Xl * (isMobile ? 1 : 4)}px`}
                display="flex"
                justifyContent="center"
              >
                <Link href="/">{t('notFound.action')}</Link>
              </Box>
            }
          />
        </Box>
      </Container>
    </BaseLayout>
  );
};

export default NotFound;
