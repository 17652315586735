import { useCallback, useEffect, useMemo, useState } from 'react';
import { addSeconds, formatDistance } from 'date-fns';
import getQueue from './getQueue';
import log from '../../modules/logger';

const logger = log.getLogger('dropkit');
const INITIAL_QUEUE_LINE = Math.floor(Math.random() * 100) + 50;

const useQueue = () => {
  const [nextUpdateTime, setNextUpdateTime] = useState<number | null>(null);
  const [initialQueueLine, setInitialQueueLine] = useState<number | null>(null);
  const [currentLineInQueue, setCurrentLineInQueue] = useState<number | null>(null);
  const [etaAverage, setEtaAverage] = useState<{ sum: number; elements: number }>({ sum: 0, elements: 0 });

  const updateNextQueuePosition = useCallback(() => {
    if (currentLineInQueue === null || currentLineInQueue <= 0 || nextUpdateTime === null) {
      return;
    }

    const { position, nextCallIn } = getQueue();
    const nextLine = currentLineInQueue - position;
    const nextLineValue = nextLine <= 0 ? 0 : nextLine;

    logger.info(`Position: ${nextLineValue}, difference: ${position}, next update in: ${nextCallIn} sec`);

    setCurrentLineInQueue(nextLineValue);
    setNextUpdateTime(nextCallIn);
    setEtaAverage({ sum: etaAverage.sum + position, elements: etaAverage.elements + 1 });
  }, [currentLineInQueue, etaAverage.elements, etaAverage.sum, nextUpdateTime]);

  const initializeQueue = useCallback(() => {
    const { nextCallIn } = getQueue();

    // Simulate get initial queue
    setCurrentLineInQueue(INITIAL_QUEUE_LINE);
    setInitialQueueLine(INITIAL_QUEUE_LINE);
    setNextUpdateTime(nextCallIn);

    logger.info(`Position: ${INITIAL_QUEUE_LINE}, difference: ${0}, next update in: ${nextCallIn} sec`);
  }, []);

  const progress = useMemo(() => {
    if (currentLineInQueue === null || initialQueueLine === null) {
      return 0;
    }

    const value = ((initialQueueLine - currentLineInQueue) * 100) / initialQueueLine;

    return value <= 100 ? value : 100;
  }, [currentLineInQueue, initialQueueLine]);

  const eta = useMemo(() => {
    if (currentLineInQueue === null || currentLineInQueue <= 0 || nextUpdateTime === null) {
      return '-';
    }

    const average = etaAverage.sum / etaAverage.elements;

    if (!average) {
      return '-';
    }

    const secondsAverage = Math.ceil(currentLineInQueue / average) * (nextUpdateTime || 1);
    const actualDate = new Date();
    const nextDate = addSeconds(actualDate, secondsAverage);

    return formatDistance(nextDate, actualDate, { includeSeconds: true });
  }, [currentLineInQueue, etaAverage.elements, etaAverage.sum, nextUpdateTime]);

  useEffect(() => {
    if (currentLineInQueue === null || currentLineInQueue <= 0 || nextUpdateTime === null) {
      return () => undefined;
    }

    const timer = setTimeout(() => {
      updateNextQueuePosition();
    }, nextUpdateTime * 1000);

    return () => clearTimeout(timer);
  }, [nextUpdateTime, currentLineInQueue, updateNextQueuePosition]);

  useEffect(() => {
    initializeQueue();
  }, [initializeQueue]);

  return {
    eta,
    progress,
    currentLine: currentLineInQueue,
  };
};

export default useQueue;
