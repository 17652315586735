import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './SectionRoadmap.styles';
import RoadMapHeaderBody from './RoadMapHeaderBody';
import { nameSpace } from '../../../modules/i18n';

type itemProps = {
  id: number;
  date: string;
  title: string;
  content: string;
};

const SectionRoadmap: FC = () => {
  const { t } = useTranslation(nameSpace.home);
  const roadMapItems = t<string, itemProps[]>('home.roadmap.items', { returnObjects: true });
  return (
    <Styled.ScrollWrapper>
      {roadMapItems.map(({ id, date, title, content }) => (
        <Fragment key={id}>
          <RoadMapHeaderBody id={id} date={date} header={title} body={content} />
        </Fragment>
      ))}
    </Styled.ScrollWrapper>
  );
};

export default SectionRoadmap;
