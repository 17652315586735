import { FC } from 'react';
import Styled from './ButtonSVG.styles';

export interface IButtonSVGProps {
  bgcolor: string;
  bgcolor2: string;
  bgcolor3?: string;
  type?: 'submit' | 'button' | 'reset';
  offsetX?: string;
  color: string;
  title: string;
  hoverEffect?: boolean;
  onClick?: VoidFunction;
}

const ButtonSVG: FC<IButtonSVGProps> = ({
  bgcolor = '#FFF',
  bgcolor2,
  bgcolor3 = '#F9C43C',
  offsetX = -9,
  color,
  type = 'button',
  title,
  hoverEffect = false,
  onClick = () => undefined,
}) => {
  return (
    <Styled.Root hoverEffect={hoverEffect} type={type} bgcolor={bgcolor} onClick={onClick}>
      <svg width="140.388" height="61.112" viewBox="0 0 140.388 61.112">
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.5"
            y1="1.105"
            x2="0.5"
            y2="-1.093"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor={bgcolor2} />
          </linearGradient>
          <linearGradient
            id="linear-gradient-hover"
            x1="0.5"
            y1="2.105"
            x2="0.5"
            y2="-0.493"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="-5" stopColor={bgcolor2} />
            <stop offset="3" stopColor="#fff" />
          </linearGradient>
        </defs>
        <g id="Group_16" data-name="Group 16" transform="translate(-329 -780.196)">
          <g id="Group_15" data-name="Group 15">
            <g id="Text" transform="translate(-3 -45.036)">
              <path
                id="Path_34"
                data-name="Path 34"
                d="M-370.373,597.52l-16.756-30,16.756-30h104.237l17.535,30-17.535,30Z"
                transform="translate(720.375 288.231)"
                fill={bgcolor3}
              />
            </g>
            <path
              id="Path_39"
              data-name="Path 39"
              d="M-376.672,533.511l-15.076,26.112,15.076,26.112H-276.7l15.076-26.112L-276.7,533.511Z"
              transform="translate(725.88 250.732)"
              fill={bgcolor}
            />
            <path
              id="Path_40"
              data-name="Path 40"
              d="M-392.581,517.511l-17.641,30.556,17.641,30.556h105.105l17.641-30.556-17.641-30.556Zm104.463,60H-391.939l-17-29.445,17-29.445h103.822l17,29.445Z"
              transform="translate(739.222 262.685)"
              fill={bgcolor}
            />
            <path
              id="svgGradient"
              data-name="svgGradient"
              d="M-372.776,587.373l-14.353-24.931,14.353-24.931h98.135l14.353,24.931-14.353,24.931Z"
              transform="translate(722.827 247.871)"
              fill="url(#linear-gradient)"
            />
            <text
              transform="translate(375.477 801.743)"
              fill={color}
              fontSize="14"
              fontFamily="Barlow-Bold, Barlow"
              fontWeight="700"
            >
              <tspan x={offsetX} y="14">
                {title}
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    </Styled.Root>
  );
};

export default ButtonSVG;
