import { styled } from '@mui/material';

const Tag = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  height: 22px;
  gap: 4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.custom.colors.BgAccentWeak};
  color: ${({ theme }) => theme.custom.colors.TextOnAccentWeak};
  border: 1px solid ${({ theme }) => theme.custom.colors.BorderAccent};
  width: fit-content;

  span {
    user-select: none;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 8px;
      cursor: pointer;
      stroke: ${({ theme }) => theme.custom.colors.TextOnAccentWeak};
    }
  }
`;

export default { Tag };
