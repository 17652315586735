import { styled } from '@mui/material';

const Root = styled('div')<{ checked: number }>`
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background-color: ${({ checked, theme }) => (checked ? theme.custom.colors.BgHighlight : 'initial')};
  border-radius: 4px;
  border: ${({ checked, theme }) => (checked ? 'none' : `2px solid ${theme.custom.colors.BorderField}`)};

  svg {
    path {
      stroke-opacity: 1;
      color: ${({ theme }) => theme.custom.colors.White};
    }
  }
`;

export default { Root };
