import { useContext } from 'react';
import EventsProviderContext from './Events.context';

const useEvents = () => {
  const events = useContext(EventsProviderContext);

  return events;
};

export default useEvents;
