import { CSSProperties, FC, useCallback, useState } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ThumbsOptions } from 'swiper/types';
import Styled from './Gallery.styles';
import 'swiper/css';
import 'swiper/css/pagination';
import Media from './Media';

interface IGalleryItem {
  id: string;
  src: string;
  thumbnail: string;
  mediaType: 'image' | 'video';
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
}

interface IGalleryProps {
  items: IGalleryItem[];
  editionText?: string;
}

const Gallery: FC<IGalleryProps> = ({ items, editionText = '' }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<ThumbsOptions['swiper'] | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOnChange = useCallback((swipper: ThumbsOptions['swiper']) => setThumbsSwiper(swipper), []);

  return (
    <Styled.Root>
      {editionText && (
        <Styled.Edition>
          <Typography variant="body.lg-strong">{editionText}</Typography>
        </Styled.Edition>
      )}
      <Styled.GalleryWrapper>
        <Swiper
          spaceBetween={5}
          grabCursor
          navigation
          thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          modules={[FreeMode, Navigation, Thumbs]}
        >
          {items.map((item, index) => (
            <SwiperSlide key={`preview-${item.id}-${index}`}>
              <Media
                width={item.width ? item.width : '100%'}
                height={item.height ? item.height : '100%'}
                size="default"
                aspectRatio="initial"
                source={item.src}
                isVideo={item.mediaType === 'video'}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Styled.GalleryWrapper>

      <Styled.GalleryPreviewWrapper>
        <Swiper
          onSwiper={handleOnChange}
          slidesPerView={4}
          spaceBetween={isMobile ? 16 : 24}
          freeMode
          watchSlidesProgress
          modules={[FreeMode, Navigation, Thumbs]}
        >
          {items.map((item, index) => (
            <SwiperSlide key={`thumbnail-${item.id}-${index}`}>
              <Media
                size="small"
                height={isMobile ? '66px' : '84px'}
                width={isMobile ? '66px' : '84px'}
                aspectRatio="1:1"
                source={item.src}
                isVideo={item.mediaType === 'video'}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Styled.GalleryPreviewWrapper>
    </Styled.Root>
  );
};

export default Gallery;
