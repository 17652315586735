import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from '../../modules/i18n';
import Footer from '../../components/navigation/Footer';
import NavBar, { TabRouteItem } from '../../components/navigation/NavBar';
import Announcement from '../../components/feedback/Announcement';
import BaseLayout from '../../components/layouts/BaseLayout';
import Header from './components/Header';
import MarketplaceProvider from './MarketplaceProvider';
import Search from './containers/Search';
import Results from './containers/Results';

const Marketplace: FC = () => {
  const { t } = useTranslation(nameSpace.home);

  useEffect(() => {
    document.title = 'Marketplace';
  }, []);

  return (
    <BaseLayout footer={<Footer />} navBar={<NavBar selectedItem={TabRouteItem.marketplace} />}>
      <Announcement text={t('home.announcement')} />
      <Header />

      <MarketplaceProvider>
        <Search>
          <Results />
        </Search>
      </MarketplaceProvider>
    </BaseLayout>
  );
};

export default Marketplace;
