import { styled } from '@mui/material';

export const Root = styled('div')`
  width: 100%;
  user-select: none;

  .swiper-slide {
    ${({ theme }) => theme.breakpoints.up('sm')} {
      width: 312px;
      max-width: 312px;
    }

    height: 176px;
    padding: 5px 0;
    aspect-ratio: 16 / 9;
  }
`;
