import { createContext } from 'react';

interface IOption {
  id: string;
  label: string;
}

interface IFilters {
  searchOption?: IOption;
  sortOption?: IOption['id'];
  selectedSeries?: string;
  dateFrom?: Date;
  dateTo?: Date;
  collection?: IOption['id'][];
  attributes?: IOption['id'][];
}

export interface IMarketplaceProviderContext {
  showFilterOptions: boolean;
  loadingSeries: boolean;
  onUpdateLoadingSeries: (value: boolean) => void;
  onSearch: (searchOption: IFilters['searchOption']) => void;
  onSort: (sortOption: IFilters['sortOption']) => void;
  onSelectSeries: (selectedSeries: IFilters['selectedSeries']) => void;
  onChangeDateFrom: (dateFrom: IFilters['dateFrom']) => void;
  onChangeDateTo: (dateTo: IFilters['dateTo']) => void;
  onChangeCollectionFilter: (value: IOption['id']) => void;
  onChangeAttributeFilter: (value: IOption['id']) => void;
  searchBarOptions: IOption[];
  sortOptions: IOption[];
  filters: IFilters;
  onClearAllFilters: () => void;
  collectionOptions: IOption[];
  attributesOptions: IOption[];
}

export const initialContext: IMarketplaceProviderContext = {
  showFilterOptions: false,
  onSearch: () => undefined,
  onSort: () => undefined,
  onSelectSeries: () => undefined,
  loadingSeries: false,
  onUpdateLoadingSeries: () => undefined,
  onClearAllFilters: () => undefined,
  onChangeDateFrom: () => undefined,
  onChangeDateTo: () => undefined,
  onChangeCollectionFilter: () => undefined,
  onChangeAttributeFilter: () => undefined,
  filters: {},
  collectionOptions: [
    { id: '0', label: 'Collection Name' },
    { id: '1', label: 'Collection Name' },
    { id: '2', label: 'Collection Name' },
  ],
  attributesOptions: [
    { id: '0', label: 'Attribute' },
    { id: '1', label: 'Attribute' },
    { id: '2', label: 'Attribute' },
  ],
  searchBarOptions: [
    { id: 'Legendary', label: 'Legendary' },
    { id: 'Megatron', label: 'Megatron' },
    { id: 'Neotropalis', label: 'Neotropalis' },
    { id: 'Octometa', label: 'Octometa' },
    { id: 'Petaplex', label: 'Petaplex' },
    { id: 'Quatamix', label: 'Quatamix' },
    { id: 'Rilomia', label: 'Rilomia' },
    { id: 'Stellahubris', label: 'Stellahubris' },
  ],
  sortOptions: [
    { id: '0', label: 'Newest First' },
    { id: '1', label: 'Oldest First' },
    { id: '2', label: 'Lowest Asking Price: Low to High' },
    { id: '3', label: 'Lowest Asking Price: High to Low' },
    { id: '4', label: 'Average Asking Price: Low to High' },
    { id: '5', label: 'Average Asking Price: High to Low' },
    { id: '6', label: 'Rarity: Low to High' },
    { id: '7', label: 'Rarity: High to Low' },
  ],
};

const MarketplaceProviderContext = createContext<IMarketplaceProviderContext>(initialContext);

MarketplaceProviderContext.displayName = 'MarketplaceProviderContext';

export default MarketplaceProviderContext;
