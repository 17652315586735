import { styled } from '@mui/material';

const Large = styled('div')`
  position: relative;
  font-family: ${({ theme }) => theme.custom.fontFamily.FontFamiliesBody};
  font-style: normal;
  font-size: 1.6rem;
  line-height: 100%;
  display: block;
  cursor: pointer;
  color: ${({ theme }) => theme.custom.colors.White};
  transition: all 0.3s ease;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 2px;
    transition: width 0.3s ease;
    background-color: ${({ theme }) => theme.custom.colors.Yellow};
  }
  &:hover {
    color: ${({ theme }) => theme.custom.colors.Yellow};
    &:before {
      width: 100%;
    }
  }
`;

const Small = styled('div')`
  font-family: ${({ theme }) => theme.custom.fontFamily.FontFamiliesBody};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.custom.colors.White};
  transition: all 0.3s ease;
  &:hover {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const ExtraSmall = styled('div')`
  font-family: ${({ theme }) => theme.custom.fontFamily.FontFamiliesBody};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  cursor: pointer;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.custom.colors.White};
  transition: all 0.3s ease;
  &:hover {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

export default { Small, ExtraSmall, Large };
