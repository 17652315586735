import { FC, ReactNode, useMemo, useState } from 'react';
import EventsContext, { IEventsContext } from './Events.context';

interface IEventsProviderProps {
  children: ReactNode;
}

const EventsProvider: FC<IEventsProviderProps> = ({ children }) => {
  const [joinEvent, setJoinEvent] = useState<IEventsContext['joinEvent']>(false);
  const [purchaseLimitReached, setPurchaseLimitReached] = useState<IEventsContext['purchaseLimitReached']>(false);
  const [eventSoldOut, setEventSoldOut] = useState<IEventsContext['purchaseLimitReached']>(false);
  const [status, setStatus] = useState<IEventsContext['status']>('pre');
  const [nextEventDate, setNextEventDate] = useState<IEventsContext['nextEventDate']>(null);

  const value = useMemo(
    () => ({
      eventSoldOut,
      joinEvent,
      nextEventDate,
      onChangeEventSoldOut: setEventSoldOut,
      onChangeJoinEvent: setJoinEvent,
      onChangeNextEventDate: setNextEventDate,
      onChangePurchaseLimitReached: setPurchaseLimitReached,
      onChangeStatus: setStatus,
      purchaseLimitReached,
      status,
    }),
    [
      eventSoldOut,
      joinEvent,
      nextEventDate,
      setEventSoldOut,
      setJoinEvent,
      setNextEventDate,
      setPurchaseLimitReached,
      setStatus,
      purchaseLimitReached,
      status,
    ],
  );

  return <EventsContext.Provider value={value}>{children}</EventsContext.Provider>;
};

export default EventsProvider;
