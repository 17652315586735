import { CSSProperties, FC, ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Styled from './Carousel.styles';
import 'swiper/css';
import 'swiper/css/pagination';

interface ICarouselProps {
  spaceBetween?: number;
  items: ReactNode[];
  centeredSlides?: boolean;
  margin?: CSSProperties['margin'];
  width?: CSSProperties['width'];
}

const Carousel: FC<ICarouselProps> = ({ items, spaceBetween = 24, centeredSlides = false, margin = '20px', width }) => {
  return (
    <Styled.Root margin={margin} width={width}>
      <Swiper slidesPerView="auto" spaceBetween={spaceBetween} centeredSlides={centeredSlides}>
        {items.map((item, index) => (
          <SwiperSlide key={`carousel-item-${index}`}>{item}</SwiperSlide>
        ))}
      </Swiper>
    </Styled.Root>
  );
};

export default Carousel;
