import { styled } from '@mui/material';

const Badge = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.custom.colors.BgAccent};
  color: ${({ theme }) => theme.custom.colors.IconOnAccent};
`;

export default { Badge };
