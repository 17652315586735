import { useContext } from 'react';
import SessionProviderContext from './Session.context';

const useSession = () => {
  const session = useContext(SessionProviderContext);

  return session;
};

export default useSession;
