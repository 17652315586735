import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import * as Styled from './Announcement.styles';

interface IAnnouncementProps {
  text: string;
  onClick?: () => void;
}

const Announcement: FC<IAnnouncementProps> = ({ text, onClick }) => {
  const { custom } = useTheme();

  return (
    <Styled.CustomAnnouncementBar onClick={onClick}>
      <Typography variant="title.lg-strong" color={custom.colors.TextOnHighlight}>
        {text}
      </Typography>
    </Styled.CustomAnnouncementBar>
  );
};

export default Announcement;
