import { FC } from 'react';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Styled from './Reveal.styles';
import { nameSpace } from '../../../modules/i18n';
import Button from '../../../components/basics/Button';
import Box from '../../../components/basics/Box';

interface IRevealProps {
  show?: boolean;
  onReveal?: VoidFunction;
}
const Reveal: FC<IRevealProps> = props => {
  const { show = false, onReveal } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation(nameSpace.eventNft);

  if (!show) {
    return null;
  }

  return (
    <Styled.CustomBackDrop open={show}>
      <Box gap="40px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Skeleton
          variant="rectangular"
          height={isMobile ? '336px' : '432px'}
          width={isMobile ? '336px' : '432px'}
          animation={false}
        />
        <Button onClick={onReveal} size="lg" type="primary" fullWidth>
          {t('eventNft.reveal')}
        </Button>
      </Box>
    </Styled.CustomBackDrop>
  );
};

export default Reveal;
