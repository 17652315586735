import { Suspense } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createRoot } from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import TagManager from 'react-gtm-module';
import { logMain } from './lhu-toolkit/logger/logger';
import { config } from './lhu-toolkit/config';
import LoaderScreen from './components/basics/LoaderScreen';
import SessionProvider from './modules/session/Session.provider';
import ThemeProvider from './config/theme/ThemeProvider';
import App from './App';
import AnalyticsProvider from './modules/analytics/Analytics.provider';
import CurrencyProvider from './modules/currency/Currency.provider';
import EventsProvider from './modules/events/Events.provider';
import ConfigurationProvider from './modules/configuration/Configuration.provider';
import Configuration from './modules/configuration/components/Configuration';
import './styles/App.scss';

logMain.debug('[PUBLIC_URL]: ', process.env.PUBLIC_URL);
logMain.debug('[NODE_ENV]: ', process.env.NODE_ENV);
logMain.debug('[REACT_APP_ENV]: ', process.env.REACT_APP_ENV);

// Load GTM (Google Tag Manager)
TagManager.initialize(config.tag_manager_config);

const container = document.getElementById('__root') as Element;
const root = createRoot(container);

root.render(
  <ThemeProvider>
    <Suspense fallback={<LoaderScreen />}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SessionProvider>
          <AnalyticsProvider>
            <EventsProvider>
              <CurrencyProvider>
                <ConfigurationProvider>
                  <Configuration />
                  <App />
                </ConfigurationProvider>
              </CurrencyProvider>
            </EventsProvider>
          </AnalyticsProvider>
        </SessionProvider>
      </LocalizationProvider>
    </Suspense>
  </ThemeProvider>,
);
