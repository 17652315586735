import { CSSProperties, FC, ReactNode } from 'react';
import { Typography, useTheme } from '@mui/material';
import DOMPurify from 'dompurify';
import Box from '../../../components/basics/Box';

interface IHeaderBodyProps {
  header?: ReactNode;
  body?: ReactNode;
  delay?: number;
  maxWidth?: CSSProperties['maxWidth'];
  alignItems?: CSSProperties['alignItems'];
  textAlign?: CSSProperties['textAlign'];
  isTitle?: boolean;
  margin?: string;
  padding?: string;
  innerHtml?: boolean;
}

const SectionTitle: FC<IHeaderBodyProps> = ({
  maxWidth = 'initial',
  header,
  body,
  delay = 1,
  alignItems = 'flex-start',
  textAlign = 'initial',
  isTitle = false,
  margin = 'auto',
  padding = '0 0 2rem',
  innerHtml = false,
}) => {
  const theme = useTheme();
  return (
    <Box
      padding={padding}
      display="flex"
      alignItems={alignItems}
      flexDirection="column"
      gap="16px"
      maxWidth={maxWidth}
      margin={margin}
    >
      <Box display="flex" alignItems={alignItems} flexDirection="column" gap="16px">
        {header ? (
          <Typography variant="headline.desktop-lg" color={theme.custom.colors.TextDefault} textAlign={textAlign}>
            {isTitle ? (
              <h2
                className={`main-title animated delay-${delay}`}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: typeof header === 'string' ? DOMPurify.sanitize(header) : '' }}
              />
            ) : (
              <h3
                className={`headline animated delay-${delay}`}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: typeof header === 'string' ? DOMPurify.sanitize(header) : '' }}
              />
            )}
          </Typography>
        ) : (
          ''
        )}
        {body ? (
          <Typography variant="body.lg" color={theme.custom.colors.TextDefault} textAlign={textAlign}>
            {innerHtml ? (
              <div
                className={`content animated delay-${delay + 1}`}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: typeof body === 'string' ? DOMPurify.sanitize(body) : '' }}
              />
            ) : (
              <div className={`content animated delay-${delay + 1}`}>{body}</div>
            )}
          </Typography>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};

export default SectionTitle;
